export class HsCode {
  hs_code: string;
  description_standart: string;
  id: number = 0;
  number_of_items: number = 0;
  value: number = 0;

  constructor(hsCodeResponse: HsCodeResponse) {
    this.hs_code = hsCodeResponse.hsCode;
    this.description_standart = hsCodeResponse.description;
  }
}

export interface HsCodeResponse {
  hsCode: string;
  description: string;
}

export class HsCodeRequest {
  id: number;
  hs_code: string;
  description: string;
  value: number;
  number_of_items: number;

  constructor(hsCode: HsCode) {
    this.id = hsCode.id;
    this.hs_code = hsCode.hs_code;
    this.description = hsCode.description_standart;
    this.value = hsCode.value;
    this.number_of_items = hsCode.number_of_items;
  }
}
