import { CloseButtonContainer } from './close-button.styles';
import { ReactComponent as XCrossIcon } from '../../assets/xcross-icon.svg';

interface Props {
  size?: 'normal' | 'big';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const CloseButton = ({ size, onClick }: Props) => (
  <CloseButtonContainer
    type='button'
    $size={size}
    onClick={onClick}>
    <XCrossIcon />
  </CloseButtonContainer>
);

export default CloseButton;
