import { useEffect, useCallback } from 'react';
import { useStore } from '../stores/store';

const useOfflineStatus = () => {
  const {
    commonStore: { toastOffline, setIsOffline },
  } = useStore();

  const updateOfflineStatus = useCallback(() => {
    const isOffline = !navigator.onLine;
    setIsOffline(isOffline);
    if (isOffline) {
      toastOffline();
    }
  }, [setIsOffline, toastOffline]);

  useEffect(() => {
    updateOfflineStatus();

    window.addEventListener('online', updateOfflineStatus);
    window.addEventListener('offline', updateOfflineStatus);

    return () => {
      window.removeEventListener('online', updateOfflineStatus);
      window.removeEventListener('offline', updateOfflineStatus);
    };
  }, [updateOfflineStatus]);
};

export default useOfflineStatus;
