import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { HsCode } from '../../../models/hsCode';
import {
  ActionIcons,
  EditButton,
  RemoveButton,
  ParcelInfo,
  ParcelItemContainer,
  ParcelItemHeader,
  ParcelItemPropName,
  ParcelItemPropValue,
  ParcelItemSeparator,
  ParcelProps,
} from './parcel-items-management.styles';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import ParcelItemEditForm from './parcel-item-form.component';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';
import { useStore } from '../../../stores/store';
import CustomSkeleton from '../../../common/skeletons/custom-skeleton-component';
import { meest } from '../../../styles/meest';

interface Props {
  item: HsCode;
  itemInEditModeId?: number;
  withSeparator?: boolean;
  currencySymbol: string;
  shouldShowDeleteButton?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onCancel: () => void;
}

const ParcelItem = ({
  item,
  withSeparator,
  currencySymbol,
  itemInEditModeId,
  shouldShowDeleteButton,
  onEdit,
  onDelete,
  onCancel,
}: Props) => {
  const {
    localizationsStore: { isLanguageChanging },
  } = useStore();
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const isEditing = item.id === itemInEditModeId;

  const handleDeleteItem = () => {
    closeModal(Modals.DELETE_ITEM);
    onDelete();
  };

  const showDeleteItemModal = () => {
    openModal({
      id: Modals.DELETE_ITEM,
      name: Modals.DELETE_ITEM,
      props: {
        onApprove: handleDeleteItem,
      },
    });
  };

  return (
    <>
      <ParcelItemContainer>
        <ParcelInfo>
          {isLanguageChanging ? (
            <CustomSkeleton
              display='grid'
              height='2.2rem'
              pb='1.2rem'
            />
          ) : (
            <ParcelItemHeader>{item.description_standart}</ParcelItemHeader>
          )}
          <ParcelProps>
            <ParcelItemPropName>{t('quanitity')}</ParcelItemPropName>
            <ParcelItemPropValue>{item.number_of_items}</ParcelItemPropValue>
            <ParcelItemPropName>{t('declared_value')}</ParcelItemPropName>
            <ParcelItemPropValue>{`${getFormattedPrice(item?.value, currencySymbol)}`}</ParcelItemPropValue>
          </ParcelProps>
        </ParcelInfo>
        <ActionIcons>
          <EditButton
            onClick={onEdit}
            theme={meest}
            disabled={isLanguageChanging}>
            <EditIcon />
          </EditButton>
          {shouldShowDeleteButton && (
            <RemoveButton
              onClick={showDeleteItemModal}
              disabled={isLanguageChanging}>
              <DeleteIcon />
            </RemoveButton>
          )}
        </ActionIcons>
      </ParcelItemContainer>
      {withSeparator && <ParcelItemSeparator />}
      {isEditing && (
        <ParcelItemEditForm
          parcelItem={item}
          onClose={onCancel}
          displayCancelButton
          currencySymbol={currencySymbol}
        />
      )}
      {withSeparator && isEditing && <ParcelItemSeparator />}
    </>
  );
};

export default observer(ParcelItem);
