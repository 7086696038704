import { Placement } from '@floating-ui/react';
import { useTranslation } from 'react-i18next';
import HintTooltip from '../hint-tooltip/hint-tooltip.component';

interface Props {
  placement?: Placement;
}

const InsuranceTooltip = ({ placement = 'top' }: Props) => {
  const { t } = useTranslation();

  return (
    <HintTooltip
      contentMaxWidth='25rem'
      placement={placement}
      text={t('insurance_tooltip_text')}
    />
  );
};

export default InsuranceTooltip;
