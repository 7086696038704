import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { store } from './app/stores/store';
import config from './config.json';
import { client } from './app/config';

i18n
  .use(Backend) // Use the HTTP backend
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      // Specify the path to your JSON files
      loadPath: `/locales/${client}/{{lng}}/{{ns}}.json?version=${config.translationsVersion}`,
      ...(process.env.REACT_APP_NO_CACHE_FOR_TRANSLATIONS === 'true' && {
        requestOptions: {
          cache: 'no-store',
        },
      }),
    },

    ns: ['translation'],
    defaultNS: 'translation',
  });

export default i18n;

export type I18TranslateFunc = TFunction<'translation', undefined>;

export const translate = (key: string) =>
  i18n.t(key, { lng: store.localizationsStore.selectedLocalization.code });
