import styled, { css } from 'styled-components';
import { FontSizes, TextInput } from '../typography/typography.styles';
import { MenuItemContentWeight, MenuItemSize } from './menu-item.component';
import { getEncodedCheckMarkIconSvg } from '../../utils/svg/svg.utils';

export const MenuItems = styled.div`
  display: grid;
  align-content: start;
`;

interface OptionProps {
  $isSelected?: boolean;
  $isDisabled?: boolean;
  $hasSuffix?: boolean;
  $hasPrefix?: boolean;
  $size?: MenuItemSize;
  $contentWeight?: MenuItemContentWeight;
  $showBackgroundOnSelected?: boolean;
}

const getSizeConfig = (size: MenuItemSize) =>
  ({
    small: css`
      --padding-vertical: 0.8rem;
      --padding-horizontal: 1.2rem;

      font-size: 1.2rem;
    `,
    medium: css`
      --padding-vertical: 1.2rem;
      --padding-horizontal: 1.2rem;

      font-size: 1.6rem;
    `,
  })[size];

export const Container = styled.div`
  padding: 4px 8px;
`;

export const MenuItemInner = styled.button<OptionProps>`
  --gap: 1.2rem;

  background: none;
  border: none;
  cursor: pointer;
  padding: var(--padding-vertical) var(--padding-horizontal);
  display: grid;
  align-items: center;
  gap: var(--gap);
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  ${TextInput}
  font-size: ${FontSizes.medium};
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s;
  border-radius: 6px;
  text-align: left;
  ${({ $size = 'medium' }) => getSizeConfig($size)}
  width: 100%;
  font-weight: ${({ $contentWeight = 400 }) => $contentWeight};

  ${({ $hasSuffix, $hasPrefix }) => css`
    grid-template-columns: ${$hasPrefix ? 'auto' : ''} 1fr ${$hasSuffix
        ? 'auto'
        : ''};
  `}
  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.baseColors.grey5};
      }

      &:active {
        background-color: ${({ theme }) =>
          theme.colors.passwordValidationMarker.backgroundColor};
      }
    `}
    ${({ $isSelected, $showBackgroundOnSelected }) =>
    $isSelected &&
    $showBackgroundOnSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.baseColors.grey5};
    `}

    ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme.colors.namedColors.primary};
      svg {
        color: ${({ theme }) => theme.colors.namedColors.primary};
      }

      &:hover {
        background-color: #f1f2f4;
      }
    `}

    ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      color: #96999e;
    `}
    ${({ $isSelected, $hasSuffix }) =>
    $isSelected &&
    !$hasSuffix &&
    css`
      --size: 1em;

      padding-right: calc(var(--gap) + var(--size) + var(--padding-horizontal));
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: var(--padding-horizontal);
        transform: translateY(-50%);
        width: var(--size);
        height: var(--size);
        background-image: ${({ theme }) =>
          `url(${getEncodedCheckMarkIconSvg(theme.colors.namedColors.primary)})`};
        background-size: contain;
        background-repeat: no-repeat;
      }
    `};
`;

export const Prefix = styled.span`
  display: flex;
  width: 1.25em;
  height: 1.25em;
  color: #75777b;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Suffix = styled.span`
  display: flex;
`;
