import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ServicesUserInfo from '../services-user-info/services-user-info.component';
import ServicesGuestInfo from '../services-guest-info/services-guest-info.component';
import Button from '../../../common/button/button.component';
import {
  ServicesMenuBody,
  ServicesMenuContainer,
  ServicesMenuHeader,
  Services,
  Section,
  SectionTitle,
  ServicesSubItems,
} from './services-menu.styles';
import { useStore } from '../../../stores/store';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';
import { AppRoute } from '../../../constants/route';
import { BlurredMenuOverlay } from '../../../common/blurred-background/blurred-background.styles';
import {
  generateMyRoute,
  generateLocaleRoute,
} from '../../../utils/uri/uri.utils';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import {
  fullMenuItems,
  ServiceId,
  ServicesSubMenuItemId,
} from '../services-menu-item/config';
import ServicesMenuItem from '../services-menu-item/services-menu-item.component';
import SwitchToOldPortalButton from '../services-menu-item/switch-to-old-portal-button.component';

const ServicesMenu = () => {
  const {
    userStore: { user, logout },
    navStore: {
      selectedService,
      isLeftSidebarOpened,
      toggleLeftSidebar,
      setSelectedService,
      setSelectedMenuSections,
      setAuthTabIndex,
    },
    commonStore: { isGuestParcelCreation },
    feedbackStore: { setSendFrom },
  } = useStore();
  const location = useLocation();
  const handleStartParcelCreation = useStartParcelCreation();
  const isMobile = useIsMobile();
  const { pathname } = location;

  const { t } = useTranslation();

  const servicesMenuHeaderRef = useRef<HTMLDivElement | null>(null);

  useEffect(
    () => () => {
      setSelectedService(undefined);
      setSelectedMenuSections([]);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    fullMenuItems.forEach(({ id, items }) => {
      const route = items?.find(({ id: innerId }) =>
        pathname.includes(innerId)
      );
      if (route) {
        setSelectedService(`${id}:${route.id}`);
      }
      if (items?.length === 0 && pathname.includes(id)) {
        setSelectedService(id);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserExist = user !== null;

  const isParcelCreate = location.pathname.includes(AppRoute.PARCEL_CREATE);

  const handleSelectMenuItem = (
    serviceId: ServiceId,
    subItemId: ServicesSubMenuItemId
  ) => {
    if (subItemId === 'leavefeedback') {
      setSendFrom('from_settings');
    }
    setSelectedService(serviceId);
    isLeftSidebarOpened && toggleLeftSidebar();
  };

  return (
    <ServicesMenuContainer>
      <ServicesMenuHeader ref={servicesMenuHeaderRef}>
        {!isGuestParcelCreation && (
          <>
            <ServicesUserInfo />
            {!isParcelCreate && (
              <Button
                disabled={!isUserExist}
                onClick={() => {
                  handleStartParcelCreation();
                }}
                icon={{ glyph: 'plus' }}>
                {t('new_parcel')}
              </Button>
            )}
          </>
        )}
        {isGuestParcelCreation && (
          <>
            <ServicesGuestInfo />
            <Button
              onClick={() => setAuthTabIndex(1)}
              href={generateLocaleRoute(AppRoute.AUTH)}
              appearance='secondary'>
              {t('sign_up')}
            </Button>
          </>
        )}
      </ServicesMenuHeader>
      <ServicesMenuBody>
        <Services>
          {fullMenuItems.map((item) => (
            <Section key={item.id}>
              <SectionTitle>{t(item.title)}</SectionTitle>
              <ServicesSubItems>
                {item.items?.map((subItem) => {
                  const serviceId: ServiceId = `${item.id}:${subItem.id}`;
                  return (
                    <ServicesMenuItem
                      to={subItem.route ? generateMyRoute(subItem.route) : ''}
                      isDisabled={!user?.email_verified}
                      isSelected={serviceId === selectedService}
                      onClick={() =>
                        handleSelectMenuItem(serviceId, subItem.id)
                      }
                      icon={subItem.icon}
                      key={subItem.id}>
                      {t(subItem.title)}
                    </ServicesMenuItem>
                  );
                })}
              </ServicesSubItems>
            </Section>
          ))}
          {isMobile && (
            <ServicesMenuItem
              appearance='logout'
              icon='logout'
              onClick={async () => {
                logout();
                toggleLeftSidebar();
              }}>
              {t('logout')}
            </ServicesMenuItem>
          )}
          <SwitchToOldPortalButton href='https://www.mymeest.ca/MeestPortal/login'>
            {t('switch_to_old_portal')}
          </SwitchToOldPortalButton>
        </Services>
        {isGuestParcelCreation && <BlurredMenuOverlay />}
      </ServicesMenuBody>
    </ServicesMenuContainer>
  );
};

export default observer(ServicesMenu);
