import { type TabProps } from 'react-tabs';
import { TabContainer } from './tabs.styles';

export type TabTheme = 'blue' | 'red' | 'polonez';

interface Props extends TabProps {
  theme?: TabTheme;
  fullWidth?: boolean;
}

const Tab = ({ theme, fullWidth = true, ...props }: Props) => (
  <TabContainer
    $theme={theme}
    $fullWidth={fullWidth}
    {...props}
    className='react-tabs__tab'
  />
);

Tab.tabsRole = 'Tab';

export default Tab;
