import styled, { css } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';
import { InputMessageSize, InputMessageType } from './input-message.component';

interface Props {
  $hasIcon?: boolean;
  $fontSize?: string;
  $color?: string;
  $type?: InputMessageType;
  $size: InputMessageSize;
  $noMargin?: boolean;
}

const getInputMessageStyles = (props: Props) =>
  ({
    info: css<Props>`
      color: ${({ $color, theme }) =>
        $color || theme.colors.baseColors.grey100};
      align-items: baseline;
      svg {
        fill: #96999e;
        position: relative;
        top: 4px;
      }
    `,
    error: css<Props>`
      color: ${({ $color, theme }) => $color || theme.colors.input.errorColor};
      align-items: center;
    `,
    alert: css<Props>`
      color: ${({ $color, theme }) => $color || theme.colors.input.errorColor};
      align-items: center;
    `,
  })[props.$type ?? 'info'];

const getSize = (size: InputMessageSize) =>
  ({
    small: css<Props>`
      font-size: ${({ $fontSize = FontSizes.small }) => $fontSize};
      line-height: 1.6rem;
      gap: 0.4rem;
    `,
    medium: css<Props>`
      font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
      line-height: 2rem;
      gap: 0.8rem;
    `,
  })[size];

export const InputMessageContainer = styled.div<Props>`
  display: flex;
  align-items: start;
  justify-content: start;
  font-weight: 400;
  margin-top: ${({ $noMargin }) => ($noMargin ? '0' : '0.8rem')};

  svg {
    width: 1.6rem;
    height: 1.6rem;
    flex-shrink: 0;
  }

  ${(props) => getInputMessageStyles(props)}
  ${({ $size }) => getSize($size)}
`;
