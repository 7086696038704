import { observer } from 'mobx-react-lite';

import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../../common/button/button.component';
import Radio from '../../common/radio/radio.component';
import { EditFormProps } from '../../features/services/services-subsection/services-subsection.component';
import { useStore } from '../../stores/store';
import FormFieldSelect from '../../common/form-field-select/form-field-select.component';
import RadioGroup from '../../common/radio-group/radio-group.component';
import {
  createValidationRule,
  isInitialValuesEqualCurrentValues,
} from '../../utils/forms/forms.utils';
import useNavigateNewLocale from '../../hooks/use-navigate-to-new-locale.hook';
import { AccountSettingsForm, FormFields } from '../../common/form/form.styles';
import {
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN,
} from '../../constants/validation';

interface FormValues {
  lang: string;
  currency: string;
  measures: string;
}

const LocationSettingsForm = ({ onCancel }: EditFormProps) => {
  const {
    userStore: { user, measures, updatePreferences },
    localizationsStore: { availableCurrencies, availableLocalizations },
    commonStore: { toastSuccess },
  } = useStore();

  const { t } = useTranslation();
  const navigateToNewLocale = useNavigateNewLocale();

  const initialValues = {
    lang: user?.lang || 'en',
    currency: user?.currency || 'USD',
    measures: String(measures),
  };

  const languages = availableLocalizations.map(({ code, languageLabel }) => ({
    value: code,
    label: languageLabel,
  }));

  const handleFormSubmit = async (
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => {
    const vals = { ...formValues, measures: +formValues.measures };
    const success = await updatePreferences(vals);
    navigateToNewLocale({ langCode: vals.lang });
    success && toastSuccess(t('data_updated_successfully'));
    onCancel && onCancel();
    formikHelpers.resetForm();
  };

  const validationSchema = Yup.object({
    lang: createValidationRule(VALIDATION_RULE_LATIN).required(
      t('this_field_cannot_be_empty')
    ),
    currency: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC).required(
      t('this_field_cannot_be_empty')
    ),
    measures: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC).required(
      t('this_field_cannot_be_empty')
    ),
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
      {({ isValid, values, isSubmitting, handleSubmit, handleChange }) => (
        <AccountSettingsForm
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <FormFields>
            <div className='location-inputs'>
              <FormFieldSelect
                label={t('language')}
                placeholder={t('select_language')}
                name='lang'
                items={languages}
              />
              <FormFieldSelect
                label={t('currency')}
                placeholder={t('select_currency')}
                name='currency'
                items={availableCurrencies}
              />
            </div>
            <RadioGroup label={t('measures')}>
              <Radio
                name='measures'
                value='1'
                checked={values.measures === '1'}
                text={t('kg_/_cm')}
                onChange={handleChange}
              />
              <Radio
                name='measures'
                value='2'
                checked={values.measures === '2'}
                text={t('lb_/_in')}
                onChange={handleChange}
              />
            </RadioGroup>
          </FormFields>
          <div className='buttons'>
            <Button
              appearance='secondary'
              disabled={isSubmitting}
              onClick={onCancel}>
              {t('cancel')}
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={
                !isValid ||
                isInitialValuesEqualCurrentValues(initialValues, values)
              }
              type='submit'>
              {t('save_changes')}
            </Button>
          </div>
        </AccountSettingsForm>
      )}
    </Formik>
  );
};

export default observer(LocationSettingsForm);
