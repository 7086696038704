import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import {
  Card,
  FavoriteButton,
  Header,
  HeaderLeft,
  HeaderRight,
  Items,
  TrackingNumber,
  Info,
  InfoItem,
  InfoAbout,
  ItemsIcon,
  InfoItemLine,
  ItemsList,
  Footer,
  PaidWrapper,
  PaidLabel,
  PaidPrice,
} from './parcel-list-card.styles';
import Tag from '../../../common/tag/tag.component';
import {
  FontSizes,
  HeaderTertiary,
} from '../../../common/typography/typography.styles';
import { ReactComponent as ShipmentsIcon } from '../../../assets/shipments-icon.svg';
import { TimeLabel } from '../../parcel-creation/pudo-list-item/pudo-list-item.styles';
import { ReactComponent as StarIcon } from '../../../assets/star.svg';
import MoreDropdown from '../more-dropdown/more-dropdown.component';
import TruncatedTextWithTooltip from '../../../common/truncated-text-with-tooltip/truncated-text-with-tooltip.component';
import { IParcelListPathValue } from '../../../routes/parcel-list/config';
import MoreSidebar from '../more-sidebar/more-sidebar.component';
import DetailsSidebar from '../details-sidebar/details-sidebar.component';
import { useParcelItem } from '../hooks/use-parcel-item.hook';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../../common/tooltip/tooltip.component';
import PaymentButton from '../payment-button/payment-button.component';
import { theme } from '../../../styles/themes';

interface Props {
  parcel: ParcelListShipment;
  routeName: IParcelListPathValue['path'];
  onToggleFavorite: (value: boolean) => void;
  onToggleArchive: (value: boolean) => void;
  onCopy: () => void;
  isGuestUser?: boolean;
}

const ParcelListCard = ({
  parcel,
  routeName,
  onToggleFavorite,
  onToggleArchive,
  onCopy,
  isGuestUser,
}: Props) => {
  const {
    status,
    tracking_number: trackingNumber,
    is_favorite: isFavorite,
  } = parcel;
  const [isSelectingText, setIsSelectingText] = useState(false);

  const {
    actions,
    statusConfig,
    favoritesLabel,
    senderAddress,
    recipientAddress,
    senderName,
    recipientName,
    createdDate,
    deliveryDate,
    itemDescriptions,
    sidebarName,
    isLoadingParcel,
    canPay,
    isPaymentProcessing,
    totalPrice,
    handleClick,
    handleKeyDown,
  } = useParcelItem({
    parcel,
    routeName,
    onToggleFavorite,
    onToggleArchive,
    onCopy,
  });

  const { t } = useTranslation();

  return (
    <>
      <Card
        $routeName={routeName}
        tabIndex={0}
        onMouseDown={() => {
          setIsSelectingText(false);
        }}
        onKeyDown={(event) => handleKeyDown(event, parcel.id)}
        onMouseUp={() => {
          const selection = window.getSelection();
          if (selection && selection.toString().length > 0) {
            setIsSelectingText(true);
          } else {
            setIsSelectingText(false);
            handleClick(parcel.id);
          }
        }}>
        <Header>
          <HeaderLeft>
            <TrackingNumber>
              {!isGuestUser && (
                <FavoriteButton
                  onMouseUp={(event) => {
                    event.stopPropagation();
                    onToggleFavorite(!isFavorite);
                  }}
                  onKeyDown={(event) => event.stopPropagation()}
                  onKeyUp={(event) => {
                    if (event.key === ' ' || event.key === 'Enter') {
                      onToggleFavorite(!isFavorite);
                    }
                  }}
                  $isFavorite={isFavorite}
                  aria-label={t(favoritesLabel)}>
                  <Tooltip placement='bottom-start'>
                    <TooltipTrigger>
                      <StarIcon />
                    </TooltipTrigger>
                    <TooltipContent>{t(favoritesLabel)}</TooltipContent>
                  </Tooltip>
                </FavoriteButton>
              )}
              <HeaderTertiary>{trackingNumber}</HeaderTertiary>
            </TrackingNumber>
            <Tag
              size='small'
              type={statusConfig.type || 'semi-filled'}
              color={statusConfig.color}>
              {t(statusConfig.text)}
            </Tag>
          </HeaderLeft>
          {!isGuestUser && (
            <HeaderRight>
              <MoreDropdown items={actions} />
              <MoreSidebar
                id={parcel.id}
                items={actions}
              />
            </HeaderRight>
          )}
        </Header>
        <Info
          $isActive={status === 'received' || status === 'ready_to_pick_up'}>
          <InfoItem>
            <InfoAbout>
              <TruncatedTextWithTooltip color={theme.colors.baseColors.grey150}>
                {senderAddress}
              </TruncatedTextWithTooltip>
              <TruncatedTextWithTooltip textType='label'>
                {senderName}
              </TruncatedTextWithTooltip>
            </InfoAbout>
            <TimeLabel>{createdDate}</TimeLabel>
            <InfoItemLine />
          </InfoItem>
          <InfoItem>
            <InfoAbout>
              <TruncatedTextWithTooltip color={theme.colors.baseColors.grey150}>
                {recipientAddress}
              </TruncatedTextWithTooltip>
              <TruncatedTextWithTooltip textType='label'>
                {recipientName}
              </TruncatedTextWithTooltip>
            </InfoAbout>
            <TimeLabel>{deliveryDate}</TimeLabel>
          </InfoItem>
        </Info>
        <Items>
          <ItemsIcon>
            <ShipmentsIcon />
          </ItemsIcon>{' '}
          <ItemsList
            fontWeight={400}
            fontSize={FontSizes.small}
            textType='body'>
            {itemDescriptions}
          </ItemsList>
        </Items>
        {status !== 'draft' && (
          <Footer>
            {canPay && <PaymentButton parcel={parcel} />}
            {/* {canPay && isPaymentProcessing && (
              <PaidWrapper>
                <PaidLabel>
                  <PaymentProcessingText />
                </PaidLabel>
                <PaidPrice>{totalPrice}</PaidPrice>
              </PaidWrapper>
            )} */}
            {!canPay && (
              <PaidWrapper>
                <PaidLabel>{t('paid')}</PaidLabel>
                <PaidPrice>{totalPrice}</PaidPrice>
              </PaidWrapper>
            )}
          </Footer>
        )}
      </Card>
      <DetailsSidebar
        name={sidebarName}
        senderAddress={senderAddress}
        recipientAddress={recipientAddress}
        createdDate={createdDate}
        deliveryDate={deliveryDate}
        onToggleFavorite={onToggleFavorite}
        menuItems={actions}
        isLoadingParcel={isLoadingParcel}
        isGuestUser={isGuestUser}
      />
    </>
  );
};

export default ParcelListCard;
