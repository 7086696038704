import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s opacity ease;
  background-color: #fff;
  z-index: 99999;
  &.is-hiding {
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
`;

export const ProgressBarWrapper = styled.div`
  position: fixed;
  z-index: 12;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
`;
