import { FC, ReactElement, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  ArrowIconContainer,
  InputPlaceholder,
  InputSelect,
} from './input-select-with-sidebar.styles';
import { InputContainer } from '../form-field-text/form-field-text.styles';
import { TextLabel } from '../typography/typography.styles';
import { InputSearchContainer } from '../input-search/input-search.styles';
import Sidebar from '../sidebar-right/sidebar.component';
import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right-icon.svg';
import { useStore } from '../../stores/store';
import InputMessage from '../input-message/input-message.component';
import { SidebarName } from '../../types/sidebar.types';
import { Note } from '../input/input.styles';

export interface WithSelectionCallback<T> {
  onSelect: (selectedVal: T | undefined) => void;
}

interface Props<T> {
  name: SidebarName;
  renderSelectedItem: (item: T) => ReactElement;
  onSelectHandler: (item: T | undefined) => void;
  selectedFromOutsideItem?: T | undefined; // when there is a need to set selected value from outside context
  placeholder: string;
  sidebarTitle: string;
  SidebarContent: FC<WithSelectionCallback<T>>;
  disabled?: boolean;
  label?: string;
  error: string;
  maxInputHeight?: string;
  errorValidationPredicate: (item: T | undefined) => boolean;
  infoMessage?: string;
  showInfoMessagePredicate?: (item: T | undefined) => boolean;
  shouldSetTouchedOnClose?: boolean;
  isOptional?: boolean;
}

function InputSelectWithSidebar<T>({
  name,
  renderSelectedItem,
  onSelectHandler,
  selectedFromOutsideItem,
  placeholder,
  label,
  sidebarTitle,
  SidebarContent,
  maxInputHeight = '6.6rem',
  disabled,
  error,
  errorValidationPredicate,
  infoMessage,
  showInfoMessagePredicate,
  shouldSetTouchedOnClose = true,
  isOptional = false,
}: Props<T>) {
  const {
    navStore: { toggleSidebarByName },
  } = useStore();

  const [selectedItem, setSelectedItem] = useState<T | undefined>();
  const [touched, setTouched] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const onInputClick = () => {
    if (!disabled) {
      toggleSidebarByName(name);
    }
  };

  useEffect(
    () => selectedItem && onSelectHandler(selectedItem),
    // eslint-disable-next-line
    [selectedItem]
  );

  useEffect(
    () => setSelectedItem(selectedFromOutsideItem),
    [selectedFromOutsideItem]
  );

  useEffect(() => {
    setIsError(touched && errorValidationPredicate(selectedItem));

    showInfoMessagePredicate &&
      setShowInfoMessage(showInfoMessagePredicate(selectedItem));
    // eslint-disable-next-line
  }, [selectedItem, touched, isError, setIsError, setShowInfoMessage]);

  return (
    <InputSearchContainer
      $isDisabled={disabled}
      $treatWholeInputAsSearch
      $mb='0'>
      <InputContainer
        $error={isError}
        $maxInputHeight={maxInputHeight}>
        <TextLabel>
          {label} {isOptional && <Note> ({t('optional')})</Note>}
        </TextLabel>
        <InputSelect
          className='flag-emoji-font'
          $isDisabled={disabled}
          tabIndex={0}
          onClick={() => onInputClick()}>
          <ArrowIconContainer $isDisabled={disabled}>
            <ArrowRightIcon />
          </ArrowIconContainer>
          {selectedItem
            ? renderSelectedItem(selectedItem)
            : placeholder && <InputPlaceholder>{placeholder}</InputPlaceholder>}
        </InputSelect>
      </InputContainer>

      <Sidebar
        name={name}
        withBlurredBackground
        header={sidebarTitle}
        onClose={() => {
          if (shouldSetTouchedOnClose) {
            setTouched(true);
          }
        }}>
        <SidebarContent
          onSelect={(item) => {
            setSelectedItem(item);
            item && toggleSidebarByName(name);
            setTouched(true);
          }}
        />
      </Sidebar>

      {isError && error && <InputMessage type='error'>{error}</InputMessage>}
      {showInfoMessage && infoMessage && (
        <InputMessage>{infoMessage}</InputMessage>
      )}
    </InputSearchContainer>
  );
}

export default InputSelectWithSidebar;
