import styled from 'styled-components';
import { CardImage, Cards } from '../common/card.styles';
import { PrimaryButton } from '../../../common/button/button.styles';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;

  @media screen and (min-width: 650px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;
  }
`;

interface InstructionsCardsProps {
  $length: number;
}

export const InstructionsCards = styled(Cards)<InstructionsCardsProps>`
  ${PrimaryButton} {
    min-height: 64px;
    border-radius: var(--card-border-radius);
  }
  @media screen and (min-width: 650px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
export const SendParcel = styled.div``;
export const ReceiveParcel = styled.div``;

export const InstructionsCardImage = styled(CardImage)`
  color: #0061af;
`;

export const InstructionsTextInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (min-width: 650px) {
    gap: 8px;
  }
`;

export const TabsContainer = styled.div`
  .react-tabs {
    &__tab {
      @media screen and (min-width: 650px) {
        width: auto;
      }
    }
  }
`;
