import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from '../../stores/store';
import ParcelDetailsDeparture from '../../features/parcel-creation/parcel-details-departure/parcel-details-departure.component';
import ParcelDetailsDestination from '../../features/parcel-creation/parcel-details-destination/parcel-details-destination.component';
import ParcelDetailsDimensions from '../../features/parcel-creation/parcel-details-dimensions/parcel-details-dimensions.component';
import { SearchParam } from '../../constants/route';

const ParcelDetails = () => {
  const {
    parcelCreationStore: { loadShipmentCountries, setCalculatorDataFromUrl },
    localizationsStore: { selectedLocalization },
  } = useStore();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData() {
      const shipmentCountries = await loadShipmentCountries(true);
      if (!shipmentCountries?.at(0)) return;

      const dataParam = searchParams.get(SearchParam.DATA);

      if (dataParam) {
        try {
          const parsedData = JSON.parse(decodeURIComponent(dataParam));
          setCalculatorDataFromUrl(shipmentCountries, parsedData);
          navigate(location.pathname, { replace: true });
        } catch (error) {
          console.error('Failed to decode URI parameter', error);
        }
      }
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loadShipmentCountries,
    searchParams,
    navigate,
    setCalculatorDataFromUrl,
    selectedLocalization.code,
  ]);

  // need keys for case when user clicks Create new when still on route step-1 to remount components and clear their states.
  return (
    <>
      <ParcelDetailsDeparture key={`${location.state?.key}dep`} />
      <ParcelDetailsDestination key={`${location.state?.key}dest`} />
      <ParcelDetailsDimensions key={`${location.state?.key}dimen`} />
    </>
  );
};

export default observer(ParcelDetails);
