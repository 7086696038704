export interface ParcelDimensions {
  weight: number;
  length: number;
  width: number;
  height: number;
}

export interface IDeliveryLimitationsResponse {
  max_weight: number;
  min_weight: number;
  max_vol_weight: number;
  max_side: number;
}

export interface IDeliveryLimitationsRequest {
  departure_country: string;
  departure_zip: string;
  destination_country: string;
  destination_zip?: string;
}

export interface IDeliveryLimitations {
  maxWeight: number;
  minWeight: number;
  maxVolWeight: number;
  maxSide: number;
}

export const transformDeliveryLimitationsResponse = (
  response: IDeliveryLimitationsResponse
): IDeliveryLimitations => ({
  maxWeight: response.max_weight,
  minWeight: response.min_weight,
  maxVolWeight: response.max_vol_weight,
  maxSide: response.max_side,
});
