import styled, { css, DefaultTheme } from 'styled-components';
import { TagColor, TagSize, TagType } from './tag.component';
import { FontSizes } from '../typography/typography.styles';

interface ContainerProps {
  $size: TagSize;
  $color: TagColor;
  $type: TagType;
  $textColor?: string;
  theme: DefaultTheme;
}

type IGetTheme = {
  [key in TagColor]: {
    [innerKey in TagType]: {
      background: string;
      text: string;
    };
  };
};

const getTheme = ({
  $color,
  $type,
  theme,
}: Pick<ContainerProps, '$type' | '$color' | 'theme'>) =>
  (
    ({
      blue: {
        filled: {
          background: theme.colors.namedColors.primary,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: theme.colors.baseColors.blue10,
          text: theme.colors.namedColors.primary,
        },
      },
      'sky-blue': {
        filled: {
          background: theme.colors.namedColors.secondaryPrimary,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: theme.colors.baseColors.blue10,
          text: theme.colors.namedColors.primary,
        },
      },
      green: {
        filled: {
          background: theme.colors.baseColors.green100,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: theme.colors.baseColors.green10,
          text: theme.colors.baseColors.green150,
        },
      },
      yellow: {
        filled: {
          background: theme.colors.baseColors.yellow100,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: theme.colors.baseColors.yellow10,
          text: theme.colors.baseColors.yellow150,
        },
      },
      red: {
        filled: {
          background: theme.colors.baseColors.red100,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: theme.colors.baseColors.red10,
          text: theme.colors.baseColors.red100,
        },
      },
      polonez: {
        filled: {
          background: theme.colors.namedColors.primary,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: theme.colors.baseColors.grey5,
          text: theme.colors.namedColors.primary,
        },
      },
      grey: {
        filled: {
          background: theme.colors.baseColors.grey100,
          text: theme.colors.namedColors.white,
        },
        'semi-filled': {
          background: '#EAECEE',
          text: theme.colors.baseColors.grey100,
        },
      },
      white: {
        filled: {
          background: theme.colors.namedColors.white,
          text: theme.colors.baseColors.grey100,
        },
        'semi-filled': {
          background: theme.colors.baseColors.grey5,
          text: theme.colors.baseColors.grey80,
        },
      },
    }) as IGetTheme
  )[$color][$type];

const getSizes = ($size: TagSize) =>
  ({
    mini: {
      padding: '2px',
      fontSize: FontSizes.small,
    },
    small: {
      padding: '4px',
      fontSize: FontSizes.small,
    },
    medium: {
      padding: '4px',
      fontSize: FontSizes.medium,
    },
  })[$size];

export const Container = styled.div<ContainerProps>`
  --padding-vertical: 4px;
  --padding-horizontal: 10px;

  padding: var(--padding-vertical) var(--padding-horizontal);
  font-weight: 500;
  border-radius: 2.4rem;
  text-align: center;
  min-width: 50px;
  width: fit-content;
  line-height: 1.6rem;

  ${({ $size }) => {
    const sizes = getSizes($size);

    return css`
      --padding-vertical: ${sizes.padding};
      font-size: ${sizes.fontSize};
    `;
  }}

  ${({ $color, $type, $textColor, theme }) => {
    const selectedTheme = getTheme({ $color, $type, theme });
    return css`
      color: ${$textColor || selectedTheme.text};
      background-color: ${selectedTheme.background};
    `;
  }}
`;
