import styled from 'styled-components';
import { Link } from '../../../common/typography/typography.styles';

interface AuthMessageScreenProps {
  $height?: 'min' | 'fit-content';
}

export const AuthMessageScreen = styled.div<AuthMessageScreenProps>`
  --min-height: 400px;

  display: grid;
  align-content: start;
  gap: 24px;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  min-height: ${({ $height = 'min' }) =>
    $height === 'min' ? 'var(--min-height)' : 'fit-content'};

  @media screen and (min-height: 500px) {
    --min-height: 70vh;
  }

  @media (min-height: 700px) {
    --min-height: 600px;
  }

  ${Link} {
    justify-self: start;
  }
`;

export const AuthMessageScreenCenteredElement = styled.div`
  justify-self: center;
`;

export const AuthMessageReturn = styled.div`
  justify-self: start;
`;
