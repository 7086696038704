import styled, { css } from 'styled-components';
import {
  FontSizes,
  HeaderSecondary,
  TextBody,
} from '../../../common/typography/typography.styles';
import { BasePaddingComponent } from '../../../common/base-padding/base-padding.component';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';

interface Props {
  $numberOfBtns: number;
}

export const ProgressButtonContainer = styled(
  BaseMarginComponent('div')
)<Props>`
  width: 100%;
  gap: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 2.4rem 2.4rem;

  ${({ $numberOfBtns }) =>
    $numberOfBtns > 1 &&
    css`
      button:nth-child(1) {
        flex: 0 0 76px;
      }
    `}
  ${({ $numberOfBtns }) =>
    $numberOfBtns === 1 &&
    css`
      button:nth-child(1) {
        flex-grow: 1;
      }
    `}
    button:nth-child(2) {
    flex-grow: 1;
  }

  @media screen and (min-width: 650px) {
    gap: 2.4rem;
    padding: 1.5rem 2.4rem 3.2rem;
  }
`;

export const PaddingContainer = styled(BasePaddingComponent('div'))`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeliveryTimeContainer = styled(BasePaddingComponent('div'))`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
`;

interface ParcelCreationBottomContainerProps {
  $transparentBackground?: boolean;
  $mobileShow?: boolean;
}

// Disabled eslint because proposed formatting breaks syntax highlight.
// eslint-disable-next-line
export const ParcelCreationBottomContainer = styled(
  BasePaddingComponent('div')
)<ParcelCreationBottomContainerProps>`
  width: 100%;

  ${({ $mobileShow }) =>
    $mobileShow &&
    css`
      display: none;
    `}

  @media (max-width: 650px) {
    ${({ $mobileShow }) =>
      $mobileShow &&
      css`
        display: block;
        width: 100vw;
        margin-left: -1.6rem;
        border-radius: 10px 10px 0 0;
        margin-top: auto;
      `}

    ${({ $transparentBackground }) =>
      !$transparentBackground &&
      css`
        background-color: ${({ theme }) => theme.colors.namedColors.white};
        border-top: 1px solid
          ${({ theme }) =>
            theme.colors.passwordValidationMarker.backgroundColor};
        border-bottom: 1px solid
          ${({ theme }) =>
            theme.colors.passwordValidationMarker.backgroundColor};
      `}
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PriceExpandContainer = styled.div<{ $expanded?: boolean }>`
  width: 100%;
  height: ${({ $expanded }) => ($expanded ? 'auto' : '0')};
  overflow: hidden;
  transition: height 0.2s;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  @media screen and (max-width: 649px) {
    padding-top: ${({ $expanded }) => ($expanded ? '0.4rem' : '0')};
  }
`;

export const PriceExpandContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;

  & > *:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

export const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.4rem;
  border-top: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};

  @media (max-width: 650px) {
    padding: 1.6rem 0 1.2rem;
    margin-top: 0;
    border-top: none;
  }
`;

export const PriceTitle = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

export const PriceText = styled(HeaderSecondary)`
  font-size: ${FontSizes.medium};
  line-height: 2rem;
  margin-bottom: 0 !important;

  span {
    color: ${({ theme }) => theme.colors.baseColors.red100};
  }
`;

export const TimeTitle = styled.span`
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  font-size: ${FontSizes.normal};
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 2rem;
  margin-bottom: 0 !important;
`;

export const TimeText = styled.span`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  font-size: ${FontSizes.medium};
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 2.2rem;
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  gap: 4px;

  svg path {
    fill: ${({ theme }) => theme.colors.baseColors.grey100};
  }
`;

export const TotalTitle = styled(PriceTitle)`
  font-size: ${FontSizes.medium};
`;

export const TotalText = styled(HeaderSecondary)`
  flex: 1;
  text-align: right;
  font-size: ${FontSizes.mediumPlus};
  margin-bottom: 0 !important;

  @media screen and (min-width: 650px) {
    font-size: ${FontSizes.mediumBig};
  }
`;

export const Recaptcha = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.6rem 2.4rem 0.8rem;

  @media screen and (min-width: 650px) {
    justify-content: start;
    padding: 0 2.4rem;
  }
`;

export const Trigger = styled(FlexContainer).attrs({ as: 'button' })<{
  $expanded?: boolean;
}>`
  appearance: none;
  padding: 0;
  display: flex;
  gap: 0.8rem;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.namedColors.primary};
  font: inherit;
  cursor: pointer;

  svg {
    transition: transform 0.2s ease-out;
    transform: ${({ $expanded }) =>
      $expanded ? 'rotate(180deg)' : 'rotate(0)'};
  }
`;
