import styled, { css } from 'styled-components';

import {
  FieldContainer,
  InputContainer,
} from '../form-field-text/form-field-text.styles';
import { InputSelect } from '../input-select-with-sidebar/input-select-with-sidebar.styles';
import { TextLabel } from '../typography/typography.styles';

interface Props {
  $isDisabled?: boolean;
  $hIconPosition?: 'left' | 'right';
  $treatWholeInputAsSearch?: boolean;
  $disableSearchHover?: boolean;
}

export const ClearSearchButton = styled.button.attrs({ type: 'button' })`
  padding: 4px;
  background: none;
  border: none;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  z-index: 200000;

  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg path {
    transition: fill 0.2s;
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colors.baseColors.grey80};
    }
  }
`;

export const SearchIconContainer = styled.span<Props>`
  display: flex;
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  cursor: pointer;
  justify-content: center;
  align-items: center;

  svg path {
    transition: fill 0.2s;
  }

  ${({ $hIconPosition }) =>
    $hIconPosition === 'right' &&
    css`
      right: 1.6rem;
    `}

  ${({ $hIconPosition }) =>
    $hIconPosition === 'left' &&
    css`
      left: 1.6rem;
    `}

  ${({ $disableSearchHover }) =>
    $disableSearchHover &&
    css`
      cursor: default;
    `}

  ${({ $isDisabled, $disableSearchHover }) =>
    !$isDisabled &&
    !$disableSearchHover &&
    css`
      &:hover {
        svg path {
          fill: ${({ theme }) => theme.colors.namedColors.primary};
        }
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &:hover {
        svg path {
          fill: ${({ theme }) => theme.colors.baseColors.grey100};
        }
      }
    `}
`;

export const InputSearchContainer = styled(FieldContainer)<Props>`
  position: relative;

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      input:disabled,
      ${InputSelect} {
        background-color: ${({ theme }) => theme.colors.baseColors.grey5};
      }
    `}

  ${({ $isDisabled, $treatWholeInputAsSearch }) =>
    !$isDisabled &&
    $treatWholeInputAsSearch &&
    css`
      ${InputContainer}:hover ${SearchIconContainer} {
        svg path {
          fill: ${({ theme }) => theme.colors.namedColors.primary};
        }
      }
    `}

  ${TextLabel} {
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
  }

  ${({ $hIconPosition }) =>
    $hIconPosition === 'right' &&
    css`
      ${SearchIconContainer} {
        right: 1.6rem;
      }

      input {
        padding: 1rem 4.2rem 1rem 1.6rem;
      }
    `}

  ${({ $hIconPosition }) =>
    $hIconPosition === 'left' &&
    css`
      ${SearchIconContainer} {
        left: 1.6rem;
      }

      input {
        padding: 1rem 4rem 1rem 4.2rem;
      }
    `}
`;
