import { observer } from 'mobx-react-lite';
import { PropsWithChildren, useMemo } from 'react';
import { useStore } from '../../stores/store';
import { ModalContext } from './modal-context';

const ModalProvider = ({ children }: PropsWithChildren) => {
  const {
    modalStore: { currentModal, open, close, update, isModalVisible },
  } = useStore();

  const value = useMemo(
    () => ({
      currentModal,
      openModal: open,
      closeModal: close,
      updateModal: update,
      isModalVisible,
    }),
    [currentModal, open, update, close, isModalVisible]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default observer(ModalProvider);
