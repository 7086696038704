import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import React from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '../../../../../common/tabs/tabs.component';
import {
  OptionsContainer,
  TabPanelFlexContainer,
} from './estimated-delivery-cost.styles';
import { HeaderSecondary } from '../../../../../common/typography/typography.styles';
import { MarginContainer } from '../../../../../common/base-margin/base-margin.component';
import DeliveryTabTitle from '../../../../parcel-creation/parcel-delivery-options-tabs/parcel-delivery-tab-title.component';
import { SelectOption } from '../parcel-delivery-option/parcel-delivery-option.styles';
import ParcelDeliveryOption from '../parcel-delivery-option/parcel-delivery-option.component';
import Button from '../../../../../common/button/button.component';
import { useMediaQuery } from '../../../../../hooks/use-media-query.hook';
import { useStore } from '../../../../../stores/store';
import { getFirstMilesByDeliveryMethodSorted } from '../../../../../utils/parcel-creation/parcel-creation-utils';
import { getCheapestDeliveryOptions } from '../../helpers';

interface Props {
  handleClick: () => void;
}

const EstimatedDeliveryCostMobile = ({ handleClick }: Props) => {
  const {
    widgetCalculatorStore: {
      firstMiles,
      lastMiles,
      middleMileMethodsWithMaxDuration,
    },
  } = useStore();

  const isTablet = useMediaQuery('(max-width: 767px)');

  const cheapestDeliveryOptions = middleMileMethodsWithMaxDuration.map(
    (mile) => ({
      method: mile.method,
      deliveryOptions: getCheapestDeliveryOptions(
        getFirstMilesByDeliveryMethodSorted(firstMiles ?? [], mile.method),
        lastMiles,
        mile.method
      ),
    })
  );

  return (
    <>
      <HeaderSecondary $mb='1.2rem'>
        {t('estimated_delivery_cost')}
      </HeaderSecondary>
      {cheapestDeliveryOptions.map((mile, i) => (
        <React.Fragment key={mile.method}>
          <Tabs theme='gray'>
            <TabList>
              <Tab
                fullWidth={isTablet}
                tabIndex={i.toString()}
                theme={mile.method === 'expedited' ? 'red' : 'blue'}>
                <DeliveryTabTitle method={mile.method} />
              </Tab>
            </TabList>
            <MarginContainer
              $mt='24px'
              $mb='24px'>
              <TabPanelFlexContainer>
                <TabPanel>
                  <OptionsContainer
                    $isOneOption={mile.deliveryOptions.length === 1}>
                    {mile.deliveryOptions
                      .slice(0, 4)
                      .map((deliveryOption, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <SelectOption key={index}>
                          <ParcelDeliveryOption
                            deliveryOption={deliveryOption}
                          />
                        </SelectOption>
                      ))}
                    {cheapestDeliveryOptions.length !== 1 && (
                      <Button
                        size='large'
                        appearance='link'
                        contentStyle='thin'
                        onClick={handleClick}>
                        {t('see_more_options')}
                      </Button>
                    )}
                  </OptionsContainer>
                </TabPanel>
              </TabPanelFlexContainer>
            </MarginContainer>
          </Tabs>
        </React.Fragment>
      ))}
    </>
  );
};

export default observer(EstimatedDeliveryCostMobile);
