import styled, { css } from 'styled-components';
import {
  FontSizes,
  HeaderTertiary,
} from '../../../common/typography/typography.styles';
import TruncatedTextWithTooltip from '../../../common/truncated-text-with-tooltip/truncated-text-with-tooltip.component';

const CardInteractionStyle = css`
  background-color: var(--hover-color);
  border-color: var(--hover-color);

  &::before {
    opacity: 1;
  }
`;

interface CardProps {
  $routeName?: string;
}

export const Card = styled.div<CardProps>`
  --hover-color: #f5f6f7;
  --border-radius: 1.2rem;
  --animation-duration: 0.3s;

  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #eaecee;
  border-radius: var(--border-radius);
  transition-property: background-color, border-color;
  transition-duration: var(--animation-duration);
  min-height: ${({ $routeName }) =>
    $routeName === 'drafts' ? '22rem' : '29.1rem'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 1px 4px 0px #05236b1f;
    opacity: 0;
    transition: opacity var(--animation-duration);
    pointer-events: none;
    border-radius: var(--border-radius);
  }

  &:focus-visible {
    ${CardInteractionStyle};
    outline: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${CardInteractionStyle};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
  font-size: ${FontSizes.normal};
`;
export const HeaderLeft = styled.div``;
export const HeaderRight = styled.div`
  position: relative;
`;

export const CommonButtonStyles = css`
  display: flex;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  transition: color 0.2s;
  color: #2c363f;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const MoreDropdownContainer = styled.div`
  position: relative;
  display: flex;
  @media screen and (max-width: 649px) {
    display: none;
  }
`;

export const MoreSidebarContainer = styled.div`
  @media screen and (min-width: 650px) {
    display: none;
  }
`;

export const MoreButton = styled.button`
  ${CommonButtonStyles}
  width: 1.5em;
  height: 1.5em;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.colors.moreButton.colorHover};
    }
  }

  &:focus {
    color: ${({ theme }) => theme.colors.moreButton.colorFocus};
  }

  &.ant-dropdown-open {
    color: ${({ theme }) => theme.colors.moreButton.colorHover};
  }
`;

interface FavoriteButtonProps {
  $isFavorite?: boolean;
}

export const FavoriteButton = styled.button<FavoriteButtonProps>`
  ${CommonButtonStyles}
  position: relative;
  flex-shrink: 0;
  outline: none;
  transition: color 0.3s;
  color: ${({ $isFavorite, theme }) =>
    $isFavorite
      ? theme.colors.favoriteButton.colorActive
      : theme.colors.favoriteButton.color};
  width: 1.5em;
  height: 1.5em;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 50%;
    background-color: #b7b9bd;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover,
  &:focus-visible {
    &::before {
      opacity: 1;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    isolation: isolate;
  }
`;

interface TrackingNumberProps {
  $noMargin?: boolean;
}

export const TrackingNumber = styled.div<TrackingNumberProps>`
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ $noMargin }) =>
    !$noMargin &&
    css`
      margin-bottom: 8px;
    `}
  ${HeaderTertiary} {
    margin-top: 2px;
  }
`;

export const InfoItemLine = styled.div`
  position: absolute;
  width: 2px;
  height: 34px;
  background-color: var(--inactive-color);
  top: 18.5px;
  left: 7px;
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  min-width: 0;
  padding-left: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 2px;
    width: 12px;
    height: 12px;
    border: 2.5px solid ${({ theme }) => theme.colors.namedColors.primary};
    border-radius: 50%;
  }

  &:first-child {
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 6px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.namedColors.primary};
    }
  }

  &:not(:last-child) {
    padding-bottom: 12px;
  }

  &:last-child {
    &::before {
      border-color: var(--inactive-color);
    }
  }
`;

interface InfoProps {
  $isActive?: boolean;
}

export const Info = styled.div<InfoProps>`
  --active-color: #005fad;
  --inactive-color: #b7b9bd;

  ${({ $isActive }) =>
    $isActive &&
    css`
      ${InfoItem}:last-child::before {
        border-color: var(--active-color);
      }

      ${InfoItemLine} {
        background-color: var(--active-color);
      }
    `}
`;

export const InfoAbout = styled.div`
  min-width: 0;
  display: grid;
`;

export const Items = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: ${FontSizes.small};
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  letter-spacing: 0.5px;
  min-width: 0;
  padding-top: 12px;
`;

export const ItemsIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 1.34em;
  height: 1.34em;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ItemsList = styled(TruncatedTextWithTooltip)``;

export const Footer = styled.div`
  padding-top: 12px;
  margin-top: 16px;
  border-top: 1px solid #dcdde0;
`;

export const PaidWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding-top: 8px;
  padding-bottom: 12px;
`;
export const PaidLabel = styled.div`
  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #75777b;
`;
export const PaidPrice = styled.div`
  font-size: ${FontSizes.normal};
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: 0.5px;
  color: #2c363f;
`;
