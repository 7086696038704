import { client } from '../config';
import { Language } from '../models/language';
import { isMeest } from '../utils/client/client.utils';

export const localizations: Language[] = isMeest(client)
  ? [
      { code: 'en', languageLabel: 'English' },
      { code: 'uk', languageLabel: 'Українська' },
      { code: 'ru', languageLabel: 'Русский' },
      // { code: 'pl', languageLabel: 'Polska' },
    ]
  : [{ code: 'en', languageLabel: 'English' }];

export const localizationsMap = localizations.reduce<{
  [key: string]: Language;
}>((acc, curr) => {
  acc[curr.code] = curr;
  return acc;
}, {});
