import { TextBody } from '../typography/typography.styles';
import { ToastAppearance } from './types';
import { TipIcon, StyledTip } from './toasts.styles';
import { ReactComponent as ErrorIcon } from '../../assets/error-tip.svg';
import { ReactComponent as SuccessIcon } from '../../assets/success.svg';
import { ReactComponent as InfoIcon } from '../../assets/info-tip.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning-tip.svg';

const getIcon = (appearance: ToastAppearance) =>
  ({
    error: <ErrorIcon />,
    success: <SuccessIcon />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
  })[appearance];

const getClassName = (appearance: ToastAppearance) =>
  ({
    info: 'toastInfoCustom',
    error: 'toastErrorCustom',
    warning: 'toastWarningCustom',
    success: 'toastSuccessCustom',
  })[appearance];

interface Props {
  appearance?: ToastAppearance;
  title?: string;
  description?: string | React.ReactNode;
  icon?: React.ReactNode;
}

const Tip = ({ appearance = 'info', title, description, icon }: Props) => (
  <StyledTip className={`tip ${getClassName(appearance)}`}>
    <TipIcon>{icon || getIcon(appearance)}</TipIcon>
    {title ? (
      <TextBody
        $fontWeight={400}
        $color='#000000'>
        {title}
      </TextBody>
    ) : (
      <span>{description}</span>
    )}
  </StyledTip>
);

export default Tip;
