import { FlexContainer } from '../../../../../common/flex-container/flex-container.styles';
import { TrackingItem } from '../../../../../models/parcelDeliveryMiles';
import { formatDate } from '../../../../../utils/generic/generic.utils';
import { ParcelHistoryProgress } from './parcel-history.helpers';
import {
  TrackingDetailsDate,
  TrackingDetailsDesc,
} from './parcel-history.styles';

interface Props {
  trackingItem: TrackingItem;
  progress: ParcelHistoryProgress;
}

const ParcelTrackingItem = ({ trackingItem, progress }: Props) => (
  <FlexContainer
    $mb='4px'
    $align='top'
    $gap='1.2rem'>
    <TrackingDetailsDesc $colorType={progress}>
      {trackingItem.text}
    </TrackingDetailsDesc>
    <TrackingDetailsDate $colorType={progress}>
      {formatDate(trackingItem.created_at, true, ', ')}
    </TrackingDetailsDate>
  </FlexContainer>
);

export default ParcelTrackingItem;
