import styled, { css, DefaultTheme } from 'styled-components';
import { SliderArrowTheme } from './slider-arrow.component';

export const SliderWrapper = styled.div``;

export const CardsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  gap: 24px;
  margin-top: 32px;

  @media (max-width: 625px) {
    margin-top: 24px;
    gap: 12px;
  }
`;

export const DividerWrapper = styled.div`
  display: none;

  @media (max-width: 625px) {
    display: flex;
    padding-right: 12px;
    justify-content: center;
    margin-top: 8px;
  }
`;

interface Props {
  $isActive: boolean;
}

export const DividerPagination = styled.span<Props>`
  background-color: ${({ $isActive, theme }) =>
    $isActive
      ? theme.colors.namedColors.primary
      : theme.colors.baseColors.grey50};
  width: 100%;
  height: 2px;
  border-radius: 2px;
`;

interface SliderItemContainerProps {
  $index: number;
  $currentSlide: number;
}

export const SliderItemContainer = styled.div<SliderItemContainerProps>`
  min-width: calc(100% / 3 - 16px);
  translate: ${({ $currentSlide }) =>
    `calc(${-100 * $currentSlide}% - 2.4rem * ${$currentSlide})`};

  width: calc(100%);
  height: auto;
  transition: translate 0.8s ease-in-out;

  @media (max-width: 625px) {
    min-width: 322px;
    width: 100%;
    height: auto;
    translate: ${({ $currentSlide }) => `${-333 * $currentSlide}px`};
  }
`;

export const SliderArrowContainer = styled.div`
  display: flex;
  gap: 12px;
`;

interface ArrowProps {
  $direction?: 'left' | 'right';
  $sliderArrowTheme?: SliderArrowTheme;
  $disabled?: boolean;
}

const getTheme = (
  sliderArrowTheme: SliderArrowTheme,
  disabled: boolean,
  theme: DefaultTheme
) =>
  ({
    white: css`
      background-color: ${disabled ? '#F1F2F4' : '#fff'};
      color: ${disabled
        ? theme.colors.baseColors.grey65
        : theme.colors.baseColors.grey100};
    `,
    grey: css`
      background-color: #f1f2f4;
      color: ${disabled
        ? theme.colors.baseColors.grey65
        : theme.colors.baseColors.grey100};
    `,
    black: css`
      background-color: ${disabled
        ? theme.colors.textLabel.color
        : theme.colors.baseColors.grey150};
      color: ${disabled
        ? theme.colors.textLabel.color
        : theme.colors.namedColors.white};
    `,
  })[sliderArrowTheme];

export const Arrow = styled.button<ArrowProps>`
  display: flex;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 8px;
  min-width: 36px;
  max-height: 36px;
  font-weight: 500;
  transition-property: border-color, background-color, color, outline-color;
  transition-duration: 0.2s;
  border: 0;
  transform: ${({ $direction = 'left' }) =>
    $direction === 'left' ? 'rotate(0)' : 'rotate(180deg)'};
  ${({ $sliderArrowTheme = 'grey', $disabled = false, theme }) =>
    getTheme($sliderArrowTheme, $disabled, theme)};

  &:hover {
    background-color: ${({ $disabled, theme }) =>
      $disabled ? '' : theme.colors.namedColors.primary};
    color: ${({ $disabled, theme }) =>
      $disabled ? '' : theme.colors.namedColors.white};
  }

  &:active {
    background-color: ${({ $disabled, theme }) =>
      $disabled ? '' : theme.colors.namedColors.primary};
    color: ${({ $disabled, theme }) =>
      $disabled ? '' : theme.colors.namedColors.white};
  }
`;
