import styled from 'styled-components';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';
import { ServicesSubSectionValue as Val } from '../../services/services-subsection/services-subsection.styles';

export const MarginContainer = styled(BaseMarginComponent('div'))``;

export const Value = styled(Val)<{ insuranceAmount?: number }>`
  & span {
    display: block;
    color: ${({ theme }) => theme.colors.baseColors.green100};
    font-size: inherit;
  }
`;

export const Heading = styled.span`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;
