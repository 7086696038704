import { useTranslation } from 'react-i18next';
import { Section, SectionTitle, SubSection } from '../details-sidebar.styles';
import { Shipment } from '../../../../models/parcelDeliveryMiles';
import DeliveryDetail from './delivery-detail.component';

interface Props {
  createdDate: string;
  deliveryDate: string;
  senderAddress: string;
  recipientAddress: string;
  shipment: Shipment;
}

const DeliveryDetails = ({
  createdDate,
  deliveryDate,
  senderAddress,
  recipientAddress,
  shipment,
}: Props) => {
  const { t } = useTranslation();

  const firstMileTrackingNumber = shipment.tracking_numbers.first_mile[0];
  const lastMileTrackingNumber = shipment.tracking_numbers.last_mile[0];

  return (
    <Section>
      <SectionTitle>{t('delivery_details')}</SectionTitle>
      <SubSection>
        <DeliveryDetail
          title={t('from')}
          trackingNumber={firstMileTrackingNumber}
          subjectAddress={senderAddress}
          date={createdDate}
          subject={shipment.sender!}
        />
      </SubSection>
      <SubSection>
        <DeliveryDetail
          title={t('to')}
          trackingNumber={lastMileTrackingNumber}
          subjectAddress={recipientAddress}
          date={deliveryDate}
          subject={shipment.recipient!}
        />
      </SubSection>
    </Section>
  );
};

export default DeliveryDetails;
