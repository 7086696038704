import styled from 'styled-components';

export interface ParcelStepLeftContainerProps {
  $isLastStep?: boolean;
}

export const ParcelStepLeftContainer = styled.div<ParcelStepLeftContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 65rem;
  width: 100%;
`;
