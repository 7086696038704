import { Trans, useTranslation } from 'react-i18next';
import StatusPage from '../../common/status-page/status-page.component';
import MeestBoxImage from '../../assets/meest-box-big-image.png';
import PolonezBoxImage from '../../assets/polonez-box-big-image.png';
import { client } from '../../config';

const getImage = () => {
  const imgs = {
    meest: MeestBoxImage,
    polonez: PolonezBoxImage,
  };
  return imgs[client];
};

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <StatusPage
      title={t('page_not_found')}
      body={<Trans i18nKey='page_you_are_looking_not_exist' />}
      imgSrc={getImage()}
    />
  );
};

export default NotFound;
