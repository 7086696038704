import styled from 'styled-components';
import { ToastStyles } from './app/common/toasts/toasts.styles';

export const AppContainer = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${ToastStyles};

  @media (max-width: 768px) {
    overflow-y: auto;
  }
`;
