import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  feedbackCategories,
  FeedbackCategory,
} from '../routes/leave-feedback/config';
import { FeedbackData } from '../models/feedBackModel';
import agent from '../api/agent';

export default class FeedbackStore {
  rate: number = 5;
  comment: string | null = null;
  categories: FeedbackCategory[] = feedbackCategories;
  selectedCategory: FeedbackCategory['value'] | null = null;
  shipmentId: number | null = null;
  send_from: string = 'from_settings';
  limit: number | null = 0;
  isLoadingLimit: boolean = false;
  isFeedbackInProgress: boolean = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'FeedbackStoreSession',
      properties: ['limit', 'isFeedbackInProgress'],
      storage: window.sessionStorage,
    });
  }

  setRate = (rate: number) => {
    this.rate = rate;
  };

  setComment = (comment: string | null) => {
    this.comment = comment;
  };

  setCategories = (categories: FeedbackCategory[]) => {
    this.categories = categories;
  };

  setSelectedCategory = (categories: FeedbackCategory['value'] | null) => {
    this.selectedCategory = categories;
  };

  setShipmentId = (shipmentId: number | null) => {
    this.shipmentId = shipmentId;
  };

  setSendFrom = (send_from: string) => {
    this.send_from = send_from;
  };

  submitFeedback = async (feedbackData: FeedbackData) => {
    try {
      const response = await agent.Feedback.submitFeedback(feedbackData);
      return response;
    } catch (error) {
      console.error('Feedback submission error:', error);
      throw error;
    }
  };

  getLimit = async () => {
    this.isLoadingLimit = true;
    try {
      const limitData = await agent.Feedback.limitFeedback();
      const limitValue = limitData ? limitData.feedback_count : null;
      runInAction(() => {
        this.limit = limitValue;
      });
      return limitValue;
    } catch (error) {
      console.error('Feedback limit error:', error);
      throw error;
    } finally {
      runInAction(() => {
        this.isLoadingLimit = false;
      });
    }
  };

  resetFeedback = () => {
    this.isFeedbackInProgress = false;
    this.rate = 5;
    this.comment = null;
    this.selectedCategory = null;
    this.shipmentId = null;
  };

  setIsFeedbackInProgress = (value: boolean) => {
    this.isFeedbackInProgress = value;
  };
}
