import styled from 'styled-components';
import { theme } from '../../styles/themes';

export const ClusterMarkerView = styled.div`
  width: 4rem;
  height: 4rem;
  padding: 0.8rem 0.3rem;
  gap: 1rem;
  border-radius: 1rem;
  border: 0.2rem solid ${theme.colors?.namedColors.white};
  background: ${theme.colors?.namedColors.primary};
  box-shadow: 0px 0px 0px 5px ${theme.colors?.clusterMarker.boxShadowColor};
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  color: ${theme.colors?.namedColors.white};
`;
