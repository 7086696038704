import { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import {
  ActionButtons,
  SignatureContent,
  Wrapper,
} from './signature-field.styles';
import Button from '../button/button.component';
import { meest } from '../../styles/meest';

interface Props {
  signature?: string;
  onConfirm?: (signature: FormData, signatureUrl: string) => void;
  onClear?: () => void;
}

const SignatureField = ({ signature, onConfirm, onClear }: Props) => {
  const [isConfirmed, setIsConfirmed] = useState(!!signature);
  const [isEmpty, setIsEmpty] = useState(true);
  const canvasRef = useRef<SignatureCanvas | null>(null);

  const { t } = useTranslation();

  const handleClear = () => {
    if (!canvasRef?.current) return;

    canvasRef.current.clear();
    setIsEmpty(true);
    onClear?.();
  };

  const handleConfirm = () => {
    if (!canvasRef?.current) return;

    setIsConfirmed(true);
    canvasRef.current.getCanvas().toBlob((blob) => {
      if (!blob) return;

      const file = new File([blob], 'signature.jpeg', { type: 'image/jpeg' });
      const formData = new FormData();
      formData.append('signature', file);

      const signatureDataUrl = canvasRef.current?.getCanvas().toDataURL();
      if (!signatureDataUrl) return;

      onConfirm?.(formData, signatureDataUrl);
    });
  };

  const handleReEnter = () => {
    setIsConfirmed(false);
    handleClear();
  };

  useEffect(() => {
    if (!canvasRef?.current || !signature) return;

    setIsEmpty(false);
    canvasRef.current.fromDataURL(signature);
  }, [signature]);

  return (
    <Wrapper $isDisabled={isConfirmed}>
      <SignatureContent>
        <SignatureCanvas
          onBegin={() => setIsEmpty(false)}
          clearOnResize={false}
          ref={canvasRef}
          penColor='#000'
          canvasProps={{ className: 'canvas' }}
        />
        {isConfirmed ? (
          <Button
            theme={meest}
            onClick={handleReEnter}
            appearance='text'>
            {t('re-enter_signature')}
          </Button>
        ) : (
          <ActionButtons>
            <Button
              disabled={isEmpty}
              onClick={handleClear}
              appearance='secondary'>
              {t('retry')}
            </Button>
            <Button
              disabled={isEmpty}
              onClick={handleConfirm}>
              {t('confirm')}
            </Button>
          </ActionButtons>
        )}
      </SignatureContent>
    </Wrapper>
  );
};

export default SignatureField;
