import { observer } from 'mobx-react-lite';
import ReCaptcha from 'react-google-recaptcha';
import { useStore } from '../../stores/store';
import { envConfig } from '../../config';

interface Props {
  onChange: (value: string | null) => void;
  onExpired: () => void;
}

const ReCAPTCHA = ({ onChange, onExpired }: Props) => {
  const {
    localizationsStore: { selectedLocalization },
  } = useStore();

  return (
    <ReCaptcha
      key={selectedLocalization.code}
      hl={selectedLocalization.code}
      onExpired={onExpired}
      onChange={onChange}
      sitekey={envConfig.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
    />
  );
};

export default observer(ReCAPTCHA);
