import { observer } from 'mobx-react-lite';
import { PudoListItemContainer } from './pudo-list-item.styles';
import CustomSkeleton from '../../../common/skeletons/custom-skeleton-component';

interface Props {
  withDetails?: boolean; // max weight and distance
  onChoose?: () => void;
}

const PudoListItemSkeleton = ({ withDetails, onChoose }: Props) => (
  <PudoListItemContainer
    $withDetails={withDetails}
    onClick={() => {
      onChoose && onChoose();
    }}>
    <CustomSkeleton height='2.2rem' />

    <CustomSkeleton height='2.4rem' />

    <CustomSkeleton height='1.6rem' />

    <CustomSkeleton height='2rem' />

    <CustomSkeleton height='2.4rem' />
  </PudoListItemContainer>
);

export default observer(PudoListItemSkeleton);
