/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useId } from 'react';
import { CustomRadio, Input, Label, Text } from './radio.styles';

interface Props {
  text: string;
  checked: boolean;
  name: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Radio = ({ checked, name, value, text, onChange }: Props) => {
  const id = useId();

  return (
    <Label htmlFor={id}>
      <Input
        id={id}
        name={name}
        value={value}
        type='radio'
        checked={checked}
        onChange={onChange}
      />
      <CustomRadio />
      <Text>{text}</Text>
    </Label>
  );
};

export default Radio;
