import { observer } from 'mobx-react-lite';
import {
  DeliveryDescriptionContainer,
  DeliveryImgContainer,
  DeliveryImg,
  ParcelDeliveryOptionContainer,
  DeliveryPriceContainer,
} from './parcel-delivery-option.styles';
import {
  FirstMile,
  LastMile,
  ShipmentPrice,
} from '../../../../../models/parcelDeliveryMiles';
import { getFormattedPrice } from '../../../../../utils/price/get-formatted-price';
import { useStore } from '../../../../../stores/store';

interface Props {
  deliveryOption: {
    firstMile: FirstMile | undefined;
    lastMile: LastMile | undefined;
    price: ShipmentPrice;
  };
}

const ParcelDeliveryOption = ({ deliveryOption }: Props) => {
  const {
    userStore: { getCurrencySymbol },
  } = useStore();

  const { firstMile, lastMile, price } = deliveryOption;

  const firstMileImgUrl = firstMile!.image;
  const lastMileImgUrl = lastMile!.image;

  return (
    <ParcelDeliveryOptionContainer>
      <DeliveryDescriptionContainer>
        <DeliveryImgContainer>
          <DeliveryImg $imgUrl={firstMileImgUrl} />
        </DeliveryImgContainer>
        {firstMile!.carrier.code !== lastMile!.carrier.code && (
          <>
            +
            <DeliveryImgContainer>
              <DeliveryImg $imgUrl={lastMileImgUrl} />
            </DeliveryImgContainer>
          </>
        )}
      </DeliveryDescriptionContainer>
      <DeliveryPriceContainer>
        {getFormattedPrice(
          price.total_price,
          getCurrencySymbol(price.currency)
        )}
      </DeliveryPriceContainer>
    </ParcelDeliveryOptionContainer>
  );
};

export default observer(ParcelDeliveryOption);
