import styled, { css, keyframes } from 'styled-components';
import { CloseButtonContainer } from '../close-button/close-button.styles';

interface Props {
  $isOpened: boolean;
  $height?: string;
  $backgroundColor?: string;
}

const createSlideFromBottomAnimation = ($height: string) => keyframes`
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-${$height});
    }
`;

const DEFAULT_HEIGHT = '50rem';

export const BottomSidebarContainer = styled.div<Props>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'white'};
  padding: 2.6rem 2.4rem 4rem 2.4rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: auto;
`;

export const DragLine = styled.span`
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: 50%;
  border-radius: 15px;
  transform: translateX(-50%);
  display: inline-block;
  height: 0.5rem;
  width: 12%;
  background-color: ${({ theme }) => theme.colors.dragLine.backgroundColor};
`;

export const Container = styled.div`
  position: fixed;
  z-index: 1119000;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100dvh;
`;

export const Content = styled.div<Props>`
  position: fixed;
  z-index: 1119000;
  display: grid;
  gap: 20px;
  align-content: end;
  height: 100%;
  width: 100vw;
  -webkit-overflow-scrolling: auto !important;

  ${({ $height = DEFAULT_HEIGHT }) => css`
    height: ${$height};
    bottom: -${$height};
  `}

  left: 0;
  transition: transform 0.4s ease-out;

  ${({ $isOpened, $height = DEFAULT_HEIGHT }) =>
    $isOpened &&
    css`
      animation: ${createSlideFromBottomAnimation($height)} 0.4s;
      transform: translateY(-${$height});
    `}

  ${CloseButtonContainer} {
    justify-self: center;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.passwordValidationMarker.backgroundColor};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.placeholderText.color};
    }
  }
`;
