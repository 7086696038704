import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Container } from '../common/container.styles';
import {
  CountriesHeader,
  CountriesListContainer,
  CountriesSection,
  CountriesSubHeader,
  CountryCard,
  DeliveryLimitationContainer,
  DeliveryLimitationIcon,
  TooltipSubtitle,
  TooltipTitle,
  LimitationTitle,
  ExpandButton,
  BackgroundImage,
} from './countries-list.styles';
import { ReactComponent as DimensionsLimitIcon } from '../../../assets/main-page/countries-list/dimensions_icon.svg';
import { ReactComponent as WeightLimitIcon } from '../../../assets/main-page/countries-list/weight_icon.svg';
import { ReactComponent as CostLimitIcon } from '../../../assets/main-page/countries-list/cost_icon.svg';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../../common/tooltip/tooltip.component';
import { useStore } from '../../../stores/store';
import Sidebar from '../../../common/sidebar-right/sidebar.component';
import { AvailableCountriesList, CountryInfo } from './countries.config';
import { useMediaQuery } from '../../../hooks/use-media-query.hook';
import countriesListWebp2x from '../../../assets/main-page/countries-list/countries-list@2x.webp';
import countriesList2x from '../../../assets/main-page/countries-list/countries-list@2x.png';
import { CountriesResponse } from '../../../models/countriesModel';

const CountriesList = () => {
  const {
    navStore: { toggleSidebarByName, closeAllRightSidebars },
    localizationsStore: {
      languageCode,
      availableCountries,
      countryLimit,
      setCountryLimit,
    },
  } = useStore();

  const { t } = useTranslation();

  const [countryData, setCountryData] = useState<any>([]);
  const [showAllCountries, setShowAllCountries] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (availableCountries) {
      const sortedCountries = availableCountries.toSorted((a, b) =>
        a.countryDetails.localized_name.localeCompare(
          b.countryDetails.localized_name
        )
      );
      setCountryData(sortedCountries);
    }
  }, [availableCountries]);

  const handleLimitIconClick = (code: string) => {
    toggleSidebarByName('delivery_country_limit');
    setCountryLimit(code);
  };

  const toggleShowAllCountries = () => {
    setShowAllCountries(!showAllCountries);
  };

  const getVisibleCountries = () => {
    if (showAllCountries) {
      return AvailableCountriesList;
    }
    return AvailableCountriesList.slice(0, isMobile ? 8 : 32);
  };

  const getCountryName = (country: CountryInfo) => {
    switch (languageCode) {
      case 'uk':
        return country.countryNameUkr;
      case 'ru':
        return country.countryNameRu;
      default:
        return country.countryName;
    }
  };

  return (
    <CountriesSection>
      <Container>
        <CountriesSubHeader>{t('global_delivery')}</CountriesSubHeader>
        <CountriesHeader>{t('delivery_countries')}</CountriesHeader>
        <CountriesListContainer>
          {countryData?.map((country: CountriesResponse) => (
            <CountryCard
              key={country.country_code}
              onClick={() => handleLimitIconClick(country.country_code)}>
              <FlexContainer
                $gap='16px'
                as='span'>
                <img
                  width={34}
                  height={24}
                  decoding='async'
                  loading='lazy'
                  alt=''
                  src={country.flag_image_svg}
                />
                <span>{`${country.countryDetails.localized_name}`}</span>
              </FlexContainer>
            </CountryCard>
          ))}
          <Sidebar
            name='delivery_country_limit'
            withBlurredBackground
            sidebarWidth='48rem'
            header={`${t('delivery_restrictions')}: ${t(`${countryLimit?.name}`)}`}
            onClose={closeAllRightSidebars}>
            <div>
              {countryLimit?.maxDimensions && (
                <DeliveryLimitationContainer>
                  <DeliveryLimitationIcon>
                    <DimensionsLimitIcon />
                  </DeliveryLimitationIcon>
                  <LimitationTitle>
                    <b>{t('max_parcel_size')}: </b>
                    {countryLimit?.maxDimensions}
                    {t('cm')}
                  </LimitationTitle>
                </DeliveryLimitationContainer>
              )}
              {countryLimit?.maxWeight && (
                <DeliveryLimitationContainer>
                  <DeliveryLimitationIcon>
                    <WeightLimitIcon />
                  </DeliveryLimitationIcon>
                  <LimitationTitle>
                    <b>{t('max_parcel_weight')}: </b>
                    {countryLimit?.maxWeight}
                    {t('kg')}
                  </LimitationTitle>
                </DeliveryLimitationContainer>
              )}
              {countryLimit?.maxCost && (
                <DeliveryLimitationContainer>
                  <DeliveryLimitationIcon>
                    <CostLimitIcon />
                  </DeliveryLimitationIcon>
                  <LimitationTitle>
                    <b>{t('max_parcel_cost')}: </b>
                    {countryLimit?.maxCost}
                  </LimitationTitle>
                </DeliveryLimitationContainer>
              )}
            </div>
          </Sidebar>
        </CountriesListContainer>

        <CountriesSubHeader>{t('coming_soon')}</CountriesSubHeader>
        <CountriesListContainer>
          {getVisibleCountries().map((country: CountryInfo) => (
            <Tooltip
              key={country.countryCode}
              placement='top'>
              <TooltipTrigger useFlex={false}>
                <TooltipContent maxWidth='100%'>
                  <TooltipTitle>{t('coming_soon')}</TooltipTitle>
                  <TooltipSubtitle>
                    {t('currently_working_on_page')}
                  </TooltipSubtitle>
                </TooltipContent>
                <CountryCard key={country.countryCode}>
                  <FlexContainer
                    $gap='16px'
                    as='span'>
                    <img
                      width={34}
                      height={24}
                      decoding='async'
                      loading='lazy'
                      alt={country.countryCode}
                      src={country.imgSrc}
                    />
                    <span>{getCountryName(country)}</span>
                  </FlexContainer>
                </CountryCard>
              </TooltipTrigger>
            </Tooltip>
          ))}
        </CountriesListContainer>
        <ExpandButton onClick={toggleShowAllCountries}>
          {showAllCountries ? t('see_less_countries') : t('see_more_countries')}
        </ExpandButton>
      </Container>
      <picture>
        <source
          width={2432}
          height={2096}
          srcSet={countriesListWebp2x}
          type='image/webp'
        />
        <BackgroundImage
          width={2432}
          height={2096}
          src={countriesList2x}
          decoding='async'
          loading='lazy'
          alt=''
        />
      </picture>
    </CountriesSection>
  );
};

export default observer(CountriesList);
