import styled from 'styled-components';
import { ServicePageContainer } from '../../features/services/services-page-container/services-page-container.styles';

export const SecuritySettingsContainer = styled(ServicePageContainer)`
  form .inputs {
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 2.4rem;

    @media screen and (min-width: 650px) {
      max-width: 45rem;
    }
  }
`;
