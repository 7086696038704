import { observer } from 'mobx-react-lite';
import { Container, SelectOption } from './parcel-delivery-options-tabs.styles';
import { WithSelectionCallback } from '../../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import { useStore } from '../../../stores/store';
import ParcelDeliveryOption from '../parcel-delivery-option/parcel-delivery-option.component';
import { LastMile } from '../../../models/parcelDeliveryMiles';
import { sortLastMilesByPrice } from '../../../utils/parcel-creation/parcel-creation-utils';

const ParcelReceivingOptionsTabs = ({
  onSelect,
}: WithSelectionCallback<LastMile>) => {
  const {
    parcelCreationStore: { lastMilesForSelectedFirstMile },
  } = useStore();

  return (
    <Container>
      {sortLastMilesByPrice(lastMilesForSelectedFirstMile).map(
        (lastMile, index) => (
          <SelectOption
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => onSelect(lastMile)}>
            <ParcelDeliveryOption
              deliveryOption={lastMile}
              isPriceResponsive={false}
              showDurationInCard={false}
            />
          </SelectOption>
        )
      )}
    </Container>
  );
};

export default observer(ParcelReceivingOptionsTabs);
