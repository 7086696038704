import styled, { css } from 'styled-components';
import {
  FontSizes,
  HeaderSecondary,
  TextBody,
  TextServicesData,
} from '../../../common/typography/typography.styles';
import { SidebarHeader } from '../../../common/sidebar-right/sidebar.styles';
import { ServicesSubsectionAppearance } from './services-subsection.component';

export type Padding = 'Top' | 'Bottom' | 'Left' | 'Right';

export type ServicesSubSectionContainerProps = {
  [key in `$padding${Padding}`]?: string;
};

// eslint-disable-next-line
export const ServicesSubSectionHeader = styled(HeaderSecondary).attrs({$size: 'medium'})<{$isEditable?: boolean;}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: var(--services-subsection-gap);
  ${({ $isEditable }) =>
    $isEditable &&
    css`
      min-height: 32px;
    `};
`;

export type Props = {
  $appearance?: ServicesSubsectionAppearance;
} & ServicesSubSectionContainerProps;

export const ServicesSubSectionContainer = styled.div<Props>`
  --default-padding-top: 1.6rem;
  --default-padding-bottom: 1.6rem;
  --default-padding-left: 1.6rem;
  --default-padding-right: 1.6rem;

  position: relative;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-radius: 1.2rem;
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  padding-top: ${(props) =>
    props['$paddingTop'] || 'var(--default-padding-top)'};
  padding-bottom: ${(props) =>
    props['$paddingBottom'] || 'var(--default-padding-bottom)'};
  padding-left: ${(props) =>
    props['$paddingLeft'] || 'var(--default-padding-left)'};
  padding-right: ${(props) =>
    props['$paddingRight'] || 'var(--default-padding-right)'};

  ${SidebarHeader} > ${ServicesSubSectionHeader} {
    margin-bottom: 0;
  }

  form {
    display: grid;
    width: 100%;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    }

    .buttons {
      display: flex;
      flex-direction: column-reverse;
      gap: 1.6rem;
      width: 100%;
    }
  }

  @media screen and (min-width: 751px) {
    --default-padding-top: 2.4rem;
    --default-padding-bottom: 3.2rem;
    --default-padding-left: 2.4rem;
    --default-padding-right: 2.4rem;

    form {
      .inputs {
        flex-direction: row;
        gap: 2.4rem;
      }

      .buttons {
        flex-direction: row;
        gap: 2.4rem;
        width: 34rem;
        margin-left: auto;
      }
    }
  }
`;

export const SubSectionDataItem = styled.span`
  display: grid;
  justify-content: left;
  gap: 5rem;
  align-items: center;

  @media screen and (min-width: 751px) {
    grid-template-columns: 15rem 1fr;
  }

  & > ${TextServicesData}:nth-child(2) {
    word-break: break-word;
  }

  @media (max-width: 750px) {
    gap: 0.8rem;
    align-items: start;
  }
`;

export const SubsectionDataItems = styled.div`
  display: grid;
  gap: 2rem;
`;

export const ServicesSubSectionMainContent = styled.div`
  display: grid;
  gap: 16px;
`;

export const ServicesSubSectionTitle = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  @media (min-width: 768px) {
    position: relative;
    top: 2px;
  }
`;

export const ServicesSubSectionNote = styled.span``;

export const ServicesSubSectionValue = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-size: ${FontSizes.medium};
  line-height: 1.375;
  font-weight: 500;
  letter-spacing: 0.5px;
  word-break: break-word;

  & ${ServicesSubSectionNote} {
    display: block;
    font-size: ${FontSizes.normal};
    color: ${({ theme }) => theme.colors.baseColors.grey150};
    margin-top: 0.4rem;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const ServicesSubSectionRow = styled.div`
  --services-subsection-row-title: 200px;

  display: grid;
  justify-content: start;
  gap: 0.4rem;
  align-items: start;

  @media (min-width: 768px) {
    grid-template-columns: var(--services-subsection-row-title) 1fr;
    gap: 1.2rem;
  }
`;
