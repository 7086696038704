import styled from 'styled-components';
import { VisuallyHiddenStyles } from '../visually-hidden/visually-hidden.styles';

export type CheckboxWrapperProps = {
  $marginBottom?: string;
};
export const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  display: flex;
  align-items: stretch;
  gap: 8px;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || 0};
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
`;

export const CheckboxContent = styled.span``;

export const CheckboxContainer = styled.label`
  position: relative;
  padding-top: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    ${VisuallyHiddenStyles}
  }

  ${CheckboxContent} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.baseColors.grey100};
    border-radius: 2px;
    transition-property: border-color, box-shadow, background-color;
    transition-duration: 0.2s;
  }

  ${CheckboxContent}:active {
    outline: none;
    border-color: ${({ theme }) => theme.colors.сheckbox.borderColorFocus};
    box-shadow: 0 0 0 3px #89caff;
  }

  input ~ ${CheckboxContent} svg {
    display: none;
  }

  input:focus ~ ${CheckboxContent} {
    outline: none;
    border-color: ${({ theme }) => theme.colors.сheckbox.borderColorFocus};
    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.сheckbox.shadowColor};
  }

  input:checked ~ ${CheckboxContent} {
    background-color: ${({ theme }) =>
      theme.colors.сheckbox.backgroundColorChecked};
    border-color: ${({ theme }) => theme.colors.сheckbox.borderColorChecked};
  }

  input:checked ~ ${CheckboxContent} svg {
    display: block;
  }
`;
