import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from 'react';

export type FooterDisplayOptions = 'full' | 'mini' | 'hidden';
interface FooterContextProps {
  footerDisplayOption: FooterDisplayOptions;
  setFooterDisplayOption: React.Dispatch<
    React.SetStateAction<FooterDisplayOptions>
  >;
}

const FooterContext = createContext<FooterContextProps | undefined>(undefined);

export const FooterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [footerDisplayOption, setFooterDisplayOption] =
    useState<FooterDisplayOptions>('full');
  const value = useMemo(
    () => ({
      footerDisplayOption,
      setFooterDisplayOption,
    }),
    [footerDisplayOption]
  );

  return (
    <FooterContext.Provider value={value}>{children}</FooterContext.Provider>
  );
};

export const useFooter = () => {
  const context = useContext(FooterContext);
  if (!context) {
    throw new Error('useFooter must be used within a FooterProvider');
  }
  return context;
};
