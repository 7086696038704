import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FiltersHeading,
  FiltersList,
  FilterSection,
  FilterSectionTitle,
  FilterOptionsBlock,
  FilterOption,
  FilterButtonsBlock,
} from '../../../common/filters/filters.styles';
import Button from '../../../common/button/button.component';
import { Container } from './parcel-list-filters.styles';
import BottomSidebar from '../../../common/sidebar-bottom/bottom-sidebar.component';
import {
  directionItemsMobile,
  IDirectionItem,
  IStatusItem,
  PARCEL_LIST_SIDEBARS,
  statusItemsMobile,
} from '../../../routes/parcel-list/config';
import { useStore } from '../../../stores/store';
import { ShipmentQueryFilter } from '../../../stores/parcelListStore';
import { theme } from '../../../styles/themes';

type FilterItem = {
  direction: IDirectionItem | null;
  status: IStatusItem | null;
};

interface Props {
  handleFilterParcels: (
    filters: (ShipmentQueryFilter | undefined)[],
    shouldShowLoading: boolean
  ) => void;
}

const ParcelListFilters = ({ handleFilterParcels }: Props) => {
  const {
    parcelListStore: {
      selectedDirection,
      selectedStatus,
      setDirection,
      setStatus,
      defaultDirection,
    },
    navStore: { toggleSidebarByName },
  } = useStore();

  const { t } = useTranslation();

  const initialFilters = {
    direction: selectedDirection,
    status: selectedStatus,
  };
  const hasInitialFiltersValue = Object.values(initialFilters).some(
    (item) => !!item?.value
  );
  const [filter, setFilter] = useState<FilterItem>(initialFilters);
  const hasValue = Object.values(filter).some((item) => !!item?.value);

  const handleApply = () => {
    toggleSidebarByName(PARCEL_LIST_SIDEBARS.filters);
    setDirection(filter?.direction || null);
    setStatus(filter?.status || null);
    const newFilter = hasValue
      ? (
          Object.values(filter).filter(Boolean) as (
            | IDirectionItem
            | IStatusItem
          )[]
        ).map(({ value }) => (value !== 'all' ? value : undefined))
      : [];

    const shouldShowLoading = newFilter.length === 0;

    handleFilterParcels(
      [defaultDirection.value, ...newFilter],
      shouldShowLoading
    );
  };

  return (
    <Container>
      <BottomSidebar
        height='95dvh'
        name={PARCEL_LIST_SIDEBARS.filters}
        withBlurredBackground
        backgroundColor={theme.colors.baseColors.blue0}>
        <FiltersHeading>{t('filters')}</FiltersHeading>
        <FiltersList>
          <FilterSection>
            <FilterSectionTitle>{t('direction')}</FilterSectionTitle>
            <FilterOptionsBlock $withLastChildSpan2Cols>
              {directionItemsMobile.map((item) => (
                <FilterOption
                  $isSelected={item.value === filter?.direction?.value}
                  onClick={() => setFilter({ ...filter, direction: item })}
                  key={item.value}>
                  {t(item.label)}
                </FilterOption>
              ))}
            </FilterOptionsBlock>
          </FilterSection>
          <FilterSection>
            <FilterSectionTitle>{t('quick_filters')}</FilterSectionTitle>
            <FilterOptionsBlock>
              {statusItemsMobile.map((item) => (
                <FilterOption
                  $isSelected={item.value === filter?.status?.value}
                  onClick={() => setFilter({ ...filter, status: item })}
                  key={item.value}>
                  {t(item.label)}
                </FilterOption>
              ))}
            </FilterOptionsBlock>
          </FilterSection>
        </FiltersList>
        <FilterButtonsBlock>
          <Button
            disabled={!hasInitialFiltersValue && !hasValue}
            onClick={(e) => {
              e.stopPropagation();
              handleApply();
            }}>
            {t('apply')}
          </Button>
          <Button
            disabled={!hasValue}
            appearance='text'
            onClick={(e) => {
              e.stopPropagation();
              setFilter({ status: null, direction: null });
            }}>
            {t('clear_all')}
          </Button>
        </FilterButtonsBlock>
      </BottomSidebar>
    </Container>
  );
};

export default ParcelListFilters;
