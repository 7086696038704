import { t } from 'i18next';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ModalTextBody } from '../../../common/modal-dialog/modal-dialog.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
}

const DeleteItemDialog = ({ isOpen = false, onClose, onApprove }: Props) => (
  <ModalDialog
    isDanger
    approveBtnContent={t('delete')}
    cancelBtnContent={t('cancel')}
    isOpen={isOpen}
    onApprove={onApprove}
    onClose={onClose}>
    <HeaderSecondary>{t('delete_item')}</HeaderSecondary>
    <ModalTextBody $textAlign='center'>
      {t('the_action_cannot_be_canceled')}
    </ModalTextBody>
  </ModalDialog>
);

export default DeleteItemDialog;
