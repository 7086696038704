import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
  ServicesSubSectionRow,
  ServicesSubSectionMainContent,
  ServicesSubSectionTitle,
  ServicesSubSectionValue,
  ServicesSubSectionNote,
} from '../../services/services-subsection/services-subsection.styles';
import { useStore } from '../../../stores/store';
import { Recipient } from '../../../models/parcelDeliveryMiles';
import { getSubjectDetailsSummary } from '../../../utils/parcel-creation/parcel-creation-utils';

const ReceiverDetailsSummary = () => {
  const {
    parcelCreationStore: { shipment },
  } = useStore();

  const { t } = useTranslation();

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    phone: { phone_number: phoneNumber = '' } = {},
    email = '',
    carrier: {
      pudo_info: pudoInfo,
      delivery_service: { service_type: serviceType = '' } = {},
      branch_uuid: branchUUID,
    } = {},
    country_name: countryName = '',
    address: {
      note,
      full_address: fullAddress,
      post_code: postCode,
      region,
      city,
      building,
      street,
      apartment,
      section,
      buzz_code: buzzCode,
    } = {},
  } = shipment?.recipient as Recipient;

  const [recipientCarrier, setRecipientCarrier] = useState<string | null>('');
  const recipientFullName = `${firstName} ${lastName}`;

  useEffect(() => {
    if (branchUUID) {
      setRecipientCarrier(pudoInfo!.name);
    } else {
      setRecipientCarrier(
        getSubjectDetailsSummary({
          building,
          street,
          apartment,
          section,
          buzzCode,
          city,
        })
      );
    }
  }, [
    apartment,
    branchUUID,
    building,
    buzzCode,
    city,
    fullAddress,
    postCode,
    section,
    serviceType,
    street,
    pudoInfo,
  ]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('receiver')}</ServicesSubSectionHeader>
      <ServicesSubSectionMainContent>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('name')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{recipientFullName}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('phone_number')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>+{phoneNumber}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('email')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{email}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>
            {t('delivery_details')}
          </ServicesSubSectionTitle>
          <ServicesSubSectionValue>
            {recipientCarrier}
            <ServicesSubSectionNote>{`${countryName}${region ? `, ${region}` : ''}`}</ServicesSubSectionNote>
          </ServicesSubSectionValue>
        </ServicesSubSectionRow>
        {note && (
          <ServicesSubSectionRow>
            <ServicesSubSectionTitle>{t('note')}</ServicesSubSectionTitle>
            <ServicesSubSectionValue>{note}</ServicesSubSectionValue>
          </ServicesSubSectionRow>
        )}
      </ServicesSubSectionMainContent>
    </ServicesSubSectionContainer>
  );
};

export default observer(ReceiverDetailsSummary);
