import { useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';

import { ReactComponent as ProhibitedIcon } from '../../../assets/prohibited-icon.svg';
import { ProhibitedItems } from '../../../models/prohibitedItems';
import InfoSelectWithSidebar from '../../../common/info-select-with-sidebar/info-select-with-sidebar.component';
import {
  ButtonsBlock,
  NoResultsTextBox,
  ProhibitedItemOption,
} from './parcel-prohibited-items-sidebar.styles';
import ProhibitedListItem from '../prohibited-list-item/prohibited-list-item.component';
import Button from '../../../common/button/button.component';
import { theme } from '../../../styles/themes';

const ParcelProhibitedItemsWithSidebar = () => {
  const {
    navStore: { closeAllRightSidebars },
    parcelCreationStore: { isLoadingShipmentApi, getProhibitedItems },
    localizationsStore: { isLanguageChanging },
    userStore: { user },
  } = useStore();

  const { t } = useTranslation();

  const [prohibitedItemsList, setProhibitedItemsList] = useState<
    ProhibitedItems[]
  >([]);

  const handleGetProhibitedItems = useCallback(async () => {
    const prohibitedItems: ProhibitedItems[] | undefined =
      await getProhibitedItems();
    if (prohibitedItems && prohibitedItems.length > 0) {
      setProhibitedItemsList(prohibitedItems);
    }
  }, [getProhibitedItems]);

  useEffect(() => {
    handleGetProhibitedItems();
  }, [handleGetProhibitedItems, user?.lang]);

  return (
    <InfoSelectWithSidebar
      bodyBackground={theme.colors.namedColors.white}
      sidebarName='prohibited_items'
      placeholder={t('list_of_prohibited_goods')}
      sidebarTitle={t('list_of_prohibited_goods')}
      theme='yellow'
      isLoading={isLoadingShipmentApi}
      icon={<ProhibitedIcon />}>
      {prohibitedItemsList.length > 0
        ? prohibitedItemsList.map((item) => (
            <ProhibitedItemOption key={item.code}>
              <ProhibitedListItem prohibitedItem={item} />
            </ProhibitedItemOption>
          ))
        : !isLoadingShipmentApi && (
            <NoResultsTextBox>
              <span>{t('no_results')}</span>
              <span>{t('no_search_results')}</span>
            </NoResultsTextBox>
          )}
      <ButtonsBlock>
        <Button
          key='Continue'
          onClick={closeAllRightSidebars}>
          {t('continue')}
        </Button>
      </ButtonsBlock>
    </InfoSelectWithSidebar>
  );
};

export default observer(ParcelProhibitedItemsWithSidebar);
