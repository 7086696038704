import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  TextContainer,
  ServicesGuestAvatar,
  ServicesGuestInfoContainer,
  ServicesUserName,
} from './services-guest-info.styles';
import { ReactComponent as UserIcon } from '../../../assets/user-icon.svg';
import { TextServicesContact } from '../../../common/typography/typography.styles';

const ServicesGuestInfo = () => {
  const { t } = useTranslation();

  return (
    <ServicesGuestInfoContainer>
      <ServicesGuestAvatar>
        <UserIcon />
      </ServicesGuestAvatar>
      <TextContainer>
        <ServicesUserName>{t('welcome_guest')}</ServicesUserName>
        <TextServicesContact>
          {t('register_to_unlock_all_features')}
        </TextServicesContact>
      </TextContainer>
    </ServicesGuestInfoContainer>
  );
};

export default observer(ServicesGuestInfo);
