import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useLayoutEffect } from 'react';
import { AuthContainer, AuthWrapper, ViewMode } from './authorization.styles';
import AuthTabs from '../../features/authentication/auth-tabbed-container/auth-tabbed-container.component';
import SignUpEmailSent from '../../features/authentication/sign-up/sign-up-email-sent.component';
import { useStore } from '../../stores/store';
import PasswordRestore from '../../features/authentication/password-restore/password-restore.component';
import RestoreEmailSent from '../../features/authentication/password-restore/restore-email-sent.component';
import ConfirmPhoneNumber from '../../features/authentication/sign-up/confirm-phone-number.component';
import WelcomeOldAccount from '../../features/authentication/welcome-old-account/welcome-old-account.component';
import DataMigrationAgreement from '../../features/authentication/data-migration-agreement/data-migration-agreement.component';
import AddPhoneNumber from '../../features/authentication/phone-verification/add-phone.component';
import WelcomeSocialAccount from '../../features/authentication/welcome-social-account/welcome-social-account.component';
import { AuthScreen } from '../../stores/navStore';

interface Props {
  viewMode?: ViewMode;
}

const centeredScreens: AuthScreen[] = [
  'data-migration-agreement',
  'welcome-old-account',
  'welcome-social-account',
];

const Authorization = ({ viewMode }: Props) => {
  const {
    navStore: { authCurrentScreen, setAuthCurrentScreen },
  } = useStore();

  useEffect(() => {
    setAuthCurrentScreen('auth-tabs');
    // eslint-disable-next-line
  }, []);

  const mapper: Record<AuthScreen, JSX.Element> = {
    'auth-tabs': <AuthTabs />,
    'sign-up-email-sent': <SignUpEmailSent />,
    'password-reset-form': <PasswordRestore />,
    'password-reset-email-sent': <RestoreEmailSent />,
    'confirm-phone-number': <ConfirmPhoneNumber />,
    'add-phone-number': <AddPhoneNumber />,
    'welcome-old-account': <WelcomeOldAccount />,
    'data-migration-agreement': <DataMigrationAgreement />,
    'welcome-social-account': <WelcomeSocialAccount />,
  };

  const element = mapper[authCurrentScreen];

  const Wrapper = viewMode === 'sidebar' ? Fragment : AuthWrapper;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [authCurrentScreen]);

  return (
    <Wrapper>
      <AuthContainer
        $viewMode={viewMode}
        $isCentered={centeredScreens.includes(authCurrentScreen)}>
        {element}
      </AuthContainer>
    </Wrapper>
  );
};

export default observer(Authorization);
