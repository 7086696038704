import styled from 'styled-components';
import { FontSizes, Link } from '../../../common/typography/typography.styles';
import { SectionDescription } from '../common/typography.styles';
import { CloseButtonContainer } from '../../../common/close-button/close-button.styles';

export const Container = styled.div`
  position: fixed;
  z-index: 1119000;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  padding: 4rem 2.4rem;
  box-shadow: 0px 0px 10px 0px #0439641c;
  @media screen and (min-width: 655px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 3.2rem 2.4rem;
    gap: 2.4rem;
  }
`;
export const CloseIconContainer = styled.div`
  --size: 2.2rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: 654px) {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  @media screen and (min-width: 655px) {
    width: var(--size);

    ${CloseButtonContainer} {
      padding: 0;
      width: var(--size);
      height: var(--size);
    }
  }
`;

export const CookiesIconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  flex-shrink: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 655px) {
    flex-direction: row;
    width: 100%;
  }
`;
export const Text = styled(SectionDescription)`
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;
  text-align: center;
  font-size: ${FontSizes.normal};
  font-weight: 400;
  @media screen and (min-width: 655px) {
    margin: 0 1.6rem 0 1.2rem;
    text-align: left;
  }
`;
export const AcceptButton = styled.div`
  max-height: 4rem;
  flex-shrink: 0;
  margin-left: auto;
  @media screen and (max-width: 654px) {
    width: 100%;
  }
`;

export const PrivacyPolicy = styled(Link)`
  color: ${({ theme }) => theme.colors.baseColors.blue80};
  font-size: inherit;

  &:hover,
  &:active {
    border-bottom-color: ${({ theme }) => theme.colors.baseColors.blue80};
  }
`;
