import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { v4 } from 'uuid';
import { ToastOptions, toast } from 'react-toastify';
import { makePersistable } from 'mobx-persist-store';
import React from 'react';
import { store } from './store';
import { ApiResponse } from '../models/apiResponse';
import { ReactComponent as SuccessIcon } from '../assets/success.svg';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';
import { ReactComponent as ErrorIconMissingPhoneNumber } from '../assets/error-icon.svg';
import { SocialLoginType } from './userStore';
import ToastMessage, { ToastContent } from '../common/toasts/toast-message';
import { normalizeToToastContent } from '../common/toasts/utils';

const ACCEPTED_ALL_COOKIES_KEY = 'acceptedAllCookies';

export default class CommonStore {
  serverError: ApiResponse | null = null;

  deviceUuid: string | null = localStorage.getItem('device_uuid');

  meestToken: string | null = localStorage.getItem('meest_token');

  refreshToken: string | null = localStorage.getItem('refresh_token');

  isGuestParcelCreation: boolean | null =
    sessionStorage.getItem('is_guest_user')?.toLowerCase() === 'true';

  isSocialLogIn: boolean | null =
    sessionStorage.getItem('is_social_log_in')?.toLowerCase() === 'true';

  appLoaded: boolean = false;

  isOffline: boolean = false;

  acceptedAllCookies: string | null = localStorage.getItem(
    ACCEPTED_ALL_COOKIES_KEY
  );

  toastInfoOptions: ToastOptions = { className: 'toastInfoCustom' };

  toastSuccessOptions: ToastOptions = {
    className: 'toastSuccessCustom',
    icon: <SuccessIcon />,
  };

  toastErrorOptions: ToastOptions = {
    className: 'toastErrorCustom',
    icon: <ErrorIcon />,
  };

  toastErrorMissingPhoneNumberOptions: ToastOptions = {
    className: 'toastErrorCustom ErrorMissingPhoneNumber',
    icon: <ErrorIconMissingPhoneNumber />,
  };

  // data of user which is currently being signed up (required for resending email)
  currentAuthData: { email: string; password: string | undefined } | null =
    null;

  socialLoginType: SocialLoginType | undefined = undefined;

  firebaseUid: string | undefined = undefined;

  firebaseToken: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CommonStore',
      properties: ['currentAuthData', 'isOffline'],
      storage: window.localStorage,
    });
    makePersistable(this, {
      name: 'CommonStoreSession',
      properties: ['firebaseToken', 'firebaseUid', 'socialLoginType'],
      storage: window.sessionStorage,
    });
    reaction(
      () => this.deviceUuid,
      (deviceUuid) => {
        if (deviceUuid) localStorage.setItem('device_uuid', deviceUuid);
        else localStorage.removeItem('device_uuid');
      }
    );

    reaction(
      () => this.refreshToken,
      (refreshToken) => {
        if (refreshToken) localStorage.setItem('refresh_token', refreshToken);
        else localStorage.removeItem('refresh_token');
      }
    );

    reaction(
      () => this.isSocialLogIn,
      (isSocialLogIn) => {
        if (isSocialLogIn)
          sessionStorage.setItem('is_social_log_in', isSocialLogIn.toString());
        else sessionStorage.removeItem('is_social_log_in');
      }
    );

    reaction(
      () => this.isGuestParcelCreation,
      (isGuestUser) => {
        if (isGuestUser)
          sessionStorage.setItem('is_guest_user', isGuestUser.toString());
        else {
          sessionStorage.removeItem('is_guest_user');
          this.setMeestToken(null);
          this.setRefreshToken(null);
        }
      }
    );

    reaction(
      () => this.meestToken,
      async (meestToken) => {
        if (meestToken) {
          localStorage.setItem('meest_token', meestToken);
        } else {
          localStorage.removeItem('meest_token');
        }
      }
    );
    reaction(
      () => this.acceptedAllCookies,
      (acceptedAllCookies) => {
        if (acceptedAllCookies)
          localStorage.setItem(ACCEPTED_ALL_COOKIES_KEY, acceptedAllCookies);
        else localStorage.removeItem(ACCEPTED_ALL_COOKIES_KEY);
      }
    );
  }

  get getDeviceUuid() {
    if (!this.deviceUuid) {
      runInAction(() => this.setDeviceUuid(v4()));
    }

    return this.deviceUuid!;
  }

  setSocialLoginType = (type?: 'apple' | 'google') => {
    this.socialLoginType = type;
  };

  setFirebaseUid = (value?: string) => {
    this.firebaseUid = value;
  };

  setFirebaseToken = (value?: string) => {
    this.firebaseToken = value;
  };

  setServerError = (error: ApiResponse) => {
    this.serverError = error;
  };

  setDeviceUuid = (deviceUuid: string | null) => {
    this.deviceUuid = deviceUuid;
  };

  setMeestToken = (token: string | null) => {
    this.meestToken = token;
  };

  setRefreshToken = (token: string | null) => {
    this.refreshToken = token;
  };

  setAppLoaded = async (loaded: boolean) => {
    this.appLoaded = loaded;
  };

  setCurrentAuthData = (email: string, password?: string) => {
    this.currentAuthData = { email, password };
  };

  setAcceptedAllCookies = (isAcceptedAllCookies: string | null) => {
    this.acceptedAllCookies = isAcceptedAllCookies;
  };

  setToastErrorOptions = (newOptions: ToastOptions) => {
    this.toastSuccessOptions = newOptions;
  };

  setIsSocialLogin = (isSocialLogIn: boolean | null) => {
    this.isSocialLogIn = isSocialLogIn;
  };

  toastInfo = (message: React.ReactNode) => {
    toast(message, this.toastInfoOptions);
  };

  toastSuccess = (message: string | ToastContent | React.ReactNode) => {
    const contentToRender = React.isValidElement(message) ? (
      message
    ) : (
      <ToastMessage content={normalizeToToastContent(message)} />
    );

    toast.success(contentToRender, this.toastSuccessOptions);
  };

  toastError = (message: string | ToastContent | React.ReactNode) => {
    const contentToRender = React.isValidElement(message) ? (
      message
    ) : (
      <ToastMessage content={normalizeToToastContent(message)} />
    );

    toast.error(contentToRender, this.toastErrorOptions);
  };

  toastErrorMissingPhoneNumber = (message: React.ReactNode) => {
    toast.error(message, this.toastErrorMissingPhoneNumberOptions);
  };

  toastOffline = () => {
    toast.error(
      <>
        You are offline. <br />
        Please check your internet connection
      </>,
      this.toastErrorOptions
    );
  };

  setIsOffline = (isOffline: boolean) => {
    this.isOffline = isOffline;
  };

  setIsGuestParcelCreation = (isGuestUser: boolean) => {
    this.isGuestParcelCreation = isGuestUser;
  };

  resetAppState = () => {
    this.setMeestToken(null);
    this.setRefreshToken(null);
    this.setDeviceUuid(null);
    this.setIsSocialLogin(null);
    this.setIsGuestParcelCreation(false);
    this.setFirebaseToken(undefined);
    this.setFirebaseUid(undefined);
    this.setSocialLoginType(undefined);
    store.navStore.resetNavStore();

    this.currentAuthData = null;
    store.userStore.user = null;
  };
}
