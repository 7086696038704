import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../features/services/services-subsection/services-subsection.styles';
import { useStore } from '../../stores/store';
import AgentDropoffInstructions from '../../features/parcel-creation/agent-dropoff-instructions/agent-dropoff-instructions.component';
import Tip from '../../common/toasts/tip.component';
import { ReactComponent as HouseIcon } from '../../assets/house-icon.svg';
import PudoListItem from '../../features/parcel-creation/pudo-list-item/pudo-list-item.component';
import { PudoWrapper } from './styles';

const ParcelDeliveryInstructions = () => {
  const {
    parcelCreationStore: { selectedSenderPudo },
  } = useStore();

  const { t } = useTranslation();

  if (!selectedSenderPudo) return;

  return (
    <>
      <ServicesSubSectionContainer>
        <ServicesSubSectionHeader>
          {t('agent_instructions_drop_off_address')}
        </ServicesSubSectionHeader>
        <PudoWrapper>
          <PudoListItem pudo={selectedSenderPudo} />
        </PudoWrapper>
        <Tip
          appearance='info'
          title={t('agent_instructions_tips')}
          icon={<HouseIcon />}
        />
      </ServicesSubSectionContainer>
      <AgentDropoffInstructions />
    </>
  );
};

export default observer(ParcelDeliveryInstructions);
