import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';

export const ServicesGuestInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  margin-bottom: 2.4rem;

  svg {
    cursor: pointer;
  }
`;

export const ServicesGuestAvatar = styled.span`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.baseColors.blue80};
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.namedColors.white};

  svg {
    width: 2.4em;
    height: 2.7em;
    path {
      fill: ${({ theme }) => theme.colors.namedColors.white};
    }
  }
`;

export const ServicesUserName = styled.span`
  width: 100%;
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  text-align: left;
`;

export const TextContainer = styled.span`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;
