import { t } from 'i18next';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ModalTextBody } from '../../../common/modal-dialog/modal-dialog.styles';
import { MarginContainer } from '../../../common/base-margin/base-margin.component';
import { ReactComponent as MeestLocationIcon } from '../../../assets/location-icon.svg';
import { ReactComponent as PolonezLocationIcon } from '../../../assets/polonez-location-icon.svg';
import { client } from '../../../config';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
}

const getIcon = () => {
  const icons = {
    meest: <MeestLocationIcon />,
    polonez: <PolonezLocationIcon />,
  };
  return icons[client];
};

const AllowLocationAccessDialog = ({
  isOpen = false,
  onClose,
  onApprove,
}: Props) => (
  <ModalDialog
    isDanger={false}
    approveBtnContent={t('allow')}
    cancelBtnContent={t('deny')}
    isOpen={isOpen}
    onApprove={onApprove}
    onClose={onClose}>
    <MarginContainer $mb='2.4rem'>{getIcon()}</MarginContainer>
    <HeaderSecondary>{t('allow_location_access')}</HeaderSecondary>
    <ModalTextBody>{t('service_needs_location')}</ModalTextBody>
  </ModalDialog>
);

export default AllowLocationAccessDialog;
