import { ParcelDimensions } from '../../models/shipmentCountryInfo';

export enum Measure {
  KG_CM = 1,
  LB_IN = 2,
}

export const WEIGHT_UNITS = {
  kg: 'kg',
  lb: 'lb',
};

export const LENGTH_UNITS = {
  cm: 'cm',
  in: 'in',
};

export const DISTANCE_UNITS = {
  km: 'km',
  mi: 'mi',
};

export const getMeasureUnits = (measure: Measure = Measure.KG_CM) =>
  ({
    [Measure.KG_CM]: {
      weight: WEIGHT_UNITS.kg,
      length: LENGTH_UNITS.cm,
      distance: DISTANCE_UNITS.km,
    },
    [Measure.LB_IN]: {
      weight: WEIGHT_UNITS.lb,
      length: LENGTH_UNITS.in,
      distance: DISTANCE_UNITS.mi,
    },
  })[measure];

export const convertKgToLb = (kgValue: number) => {
  const conversionFactor = 2.20462;
  return parseFloat((kgValue * conversionFactor).toFixed(1));
};

export const convertCmToInches = (cmValue: number) => {
  const conversionFactor = 0.393701;
  return parseFloat((cmValue * conversionFactor).toFixed(1));
};

export const convertLbToKg = (lbValue: number) => {
  const conversionFactor = 2.20462;
  const kgValue = lbValue / conversionFactor;
  return parseFloat(kgValue.toFixed(1));
};

export const convertInchesToCm = (inchesValue: number) => {
  const conversionFactor = 0.393701;
  const cmValue = inchesValue / conversionFactor;
  return parseFloat(cmValue.toFixed(1));
};

export const convertKmToMiles = (kmValue: number) => {
  const conversionFactor = 0.62137;
  return parseFloat((kmValue * conversionFactor).toFixed(2));
};

export const convertMilesToKm = (milesValue: number) => {
  const conversionFactor = 1.60934;
  return parseFloat((milesValue * conversionFactor).toFixed(2));
};

export const convertDimensionsToMetric = (dimensions: ParcelDimensions) => ({
  weight: convertKgToLb(dimensions.weight || 0),
  size_x: convertCmToInches(dimensions.size_x || 0),
  size_y: convertCmToInches(dimensions.size_y || 0),
  size_z: convertCmToInches(dimensions.size_z || 0),
});

export const convertDimensionsToImperial = (dimensions: ParcelDimensions) => ({
  weight: convertLbToKg(dimensions.weight || 0),
  size_x: convertInchesToCm(dimensions.size_x || 0),
  size_y: convertInchesToCm(dimensions.size_y || 0),
  size_z: convertInchesToCm(dimensions.size_z || 0),
});

export const convertDimensions = (
  dimensions: ParcelDimensions,
  measure: Measure
) =>
  measure === Measure.KG_CM
    ? convertDimensionsToMetric(dimensions)
    : convertDimensionsToImperial(dimensions);
