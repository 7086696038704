import styled from 'styled-components';
import {
  ParcelListRowHeader,
  List as ParcelList,
  Grid as ParcelGrid,
  HeaderCell as ParcelHeaderCell,
  ListViewContainer as ParcelListViewContainer,
  FiltersMobile as ParcelFiltersMobile,
  ListSkeleton,
} from '../parcel-list/parcel-list.styles';

export const FiltersMobile = styled(ParcelFiltersMobile)`
  grid-template-columns: 1fr;
`;

export const Grid = styled(ParcelGrid)`
  @media screen and (min-width: 650px) {
    display: none;
  }
`;

export const ViewModeDivider = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.baseColors.grey50};
`;

export const Filters = styled.div`
  margin-bottom: 12px;

  @media screen and (min-width: 650px) {
    margin-bottom: 32px;
    display: flex;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  background: ${({ theme }) => theme.colors.namedColors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};
  }
`;

export const List = styled(ParcelList)`
  display: none;

  @media screen and (min-width: 650px) {
    display: flex;
  }
`;

export const ReceiverListRowHeader = styled(ParcelListRowHeader)`
  grid-template-columns: repeat(5, 1fr);
`;

export const HeaderCell = styled(ParcelHeaderCell)`
  &:first-child {
    padding-left: 8px;
  }
`;

export const ListViewContainer = styled(ParcelListViewContainer)`
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const ReceiversListSkeleton = styled(ListSkeleton)`
  display: none;

  @media screen and (min-width: 650px) {
    display: flex;
  }
`;
