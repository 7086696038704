import styled, { css } from 'styled-components';
import { MarginContainer } from '../base-margin/base-margin.component';

export interface FlexContainerProps {
  children: React.ReactNode;
  $align?: React.CSSProperties['alignItems'];
  $justify?: React.CSSProperties['justifyContent'];
  $direction?: React.CSSProperties['flexDirection'];
  $gap?: string;
  $width?: string;
  $wrap?: 'wrap' | 'nowrap';
}

export const FlexContainer = styled(MarginContainer)<FlexContainerProps>`
  width: ${({ $width = 'auto' }) => $width};
  display: flex;
  align-items: ${({ $align = 'center' }) => $align};
  justify-content: ${({ $justify = 'space-between' }) => $justify};
  flex-direction: ${({ $direction = 'row' }) => $direction};
  flex-wrap: ${({ $wrap = 'nowrap' }) => $wrap};
  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${$gap};
    `};
`;
