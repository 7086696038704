import React from 'react';
import { ClusterMarkerView } from './cluster-markers.styles';

interface ClusterMarkerProps {
  count: number;
}

export const ClusterMarker: React.FC<ClusterMarkerProps> = ({ count }) => (
  <ClusterMarkerView>{count}</ClusterMarkerView>
);
