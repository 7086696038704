import { I18TranslateFunc } from '../../../../i18n';
import { PudoItem, WorkingTimePartlySeparated } from '../../../models/pudoItem';

export function formatStringPascalCase(words: string[]): string {
  const wordsFormatted = words.map(
    (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  );

  const wordsCombined = wordsFormatted.join(' ');
  return wordsCombined;
}

export function getTodaysWorkingHours(pudo: PudoItem) {
  const currentDayNum = new Date().getDay() + 1; // + 1 here to correspond with backend values (1 - Sunday, 7 - Saturday)
  const currentDayWorkingTime = pudo.working_time.filter(
    (wt) => wt.day_number === currentDayNum
  )[0];

  return currentDayWorkingTime?.open_time && currentDayWorkingTime?.close_time
    ? `${currentDayWorkingTime.open_time} - ${currentDayWorkingTime.close_time}`
    : '';
}

export function getAddressFormatted(pudo: PudoItem) {
  const streetFormatted = formatStringPascalCase(
    pudo.address.street.split(' ')
  );
  return [streetFormatted, pudo.address.building].filter(Boolean).join(', ');
}

export function decodeHtmlEntities(encodedString: string) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.textContent;
}

// HARDCODE. WILL BE REMOVE AFTER BACKEND ADDS IT ON THEIR SIDE
const getWorkingHoursTranslations = (locale: string, dayName: string) => {
  const mapper: Record<string, any> = {
    en: {
      Monday: 'Mon',
      Tuesday: 'Tue',
      Wednesday: 'Wed',
      Thursday: 'Thu',
      Friday: 'Fri',
      Saturday: 'Sat',
      Sunday: 'Sun',
    },
    uk: {
      Monday: 'Пн',
      Tuesday: 'Вт',
      Wednesday: 'Ср',
      Thursday: 'Чт',
      Friday: 'Пт',
      Saturday: 'Сб',
      Sunday: 'Нд',
    },
    ru: {
      Monday: 'Пн',
      Tuesday: 'Вт',
      Wednesday: 'Ср',
      Thursday: 'Чт',
      Friday: 'Пт',
      Saturday: 'Сб',
      Sunday: 'Вс',
    },
  };
  return mapper[locale][dayName];
};

export function parseWorkingHoursSummary(
  workingTime: WorkingTimePartlySeparated[],
  t: I18TranslateFunc,
  locale: string
) {
  const parsedSummary: {
    daysRange: string;
    timeRange: string;
    isClosed: boolean;
  }[] = [];
  [...workingTime]
    .sort((a, b) => {
      if (a.operating_hours.closed === b.operating_hours.closed) return 0;
      return a.operating_hours.closed ? 1 : -1;
    })
    .forEach((wt) => {
      let daysRange = '';
      let timeRange = '';

      const beginDayName = getWorkingHoursTranslations(
        locale,
        wt.begin.day_name
      );
      const endDayName = getWorkingHoursTranslations(locale, wt.end.day_name);
      if (wt.one_day_period) {
        daysRange = beginDayName;
      } else {
        daysRange = `${beginDayName}-${endDayName}`;
      }

      if (wt.operating_hours?.open_time && wt.operating_hours?.close_time) {
        timeRange = `${wt.operating_hours.open_time} - ${wt.operating_hours.close_time}`;
      } else if (wt.operating_hours.closed) {
        timeRange = t('closed');
      } else if (wt.operating_hours.open_24_hours_indicator) {
        timeRange = '00:01 - 23:59';
      }

      parsedSummary.push({
        daysRange,
        timeRange,
        isClosed: wt.operating_hours.closed,
      });
    });

  return parsedSummary;
}
