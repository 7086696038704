import styled from 'styled-components';
import { ServicesSubSectionContainer } from '../../services/services-subsection/services-subsection.styles';
import { TextBody as BodyText } from '../../../common/typography/typography.styles';
import { MenuItems } from '../../../common/menu-item/menu-item.styles';

export const ParcelParticipantDetailsContainer = styled(
  ServicesSubSectionContainer
)`
  form {
    .inputs {
      flex-direction: column;
      gap: 2rem;
    }
  }
`;

export const FormFieldContainer = styled.div`
  position: relative;
`;

export const FormFieldIcon = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }

  button {
    padding: 0;
    color: ${({ theme }) => theme.colors.baseColors.grey100};
    &:hover,
    &:focus-visible {
      color: ${({ theme }) => theme.colors.namedColors.primary};
    }
  }
`;

export const MenuItemsReceivers = styled(MenuItems)`
  gap: 12px;
  padding-right: 6px;

  & button {
    border-radius: 12px;
    border: 1px solid
      ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
    background: ${({ theme }) => theme.colors.namedColors.white};
    padding: 16px;
    gap: 0;
  }

  & > div {
    padding: 0;
  }
`;

export const TextBody = styled(BodyText)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  text-align: center;
`;

export const EmptyReceiversListContainer = styled.div`
  margin: auto 0;
  display: grid;
  justify-items: center;
  gap: 32px;
  text-align: center;
`;

export const EmptyReceiversListIconWrapper = styled.div`
  max-width: 145px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
