import { css, styled } from 'styled-components';

export const Footer = styled.div`
  display: grid;
  align-items: start;
  gap: 2.4rem;
  grid-template-columns: 1fr auto;
`;

export const FooterDownload = styled.div`
  display: grid;
  gap: 1.2rem;
`;

export const FooterStores = styled.div`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
`;

export const FooterTitle = styled.p`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.baseColors.blue150};
  font-size: 1.4rem;
  line-height: 1.42;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

export const Steps = styled.ul`
  display: grid;
  gap: 1.6rem;
  padding: 0;
  margin: 0;
`;

export const Step = styled.li`
  --step-icon-size: 20px;

  display: grid;
  gap: 0.8rem;
  grid-template-columns: var(--step-icon-size) 1fr;
`;

export const StepContent = styled.div`
  display: grid;
  gap: 0.4rem;
`;

export const Content = styled.div`
  display: grid;
  gap: 2.4rem;

  @media screen and (min-width: 768px) {
    gap: 4rem;
  }
`;

export const StepDecoration = styled.div<{ $isLast: boolean }>`
  --decoration-primary-color: ${({ theme }) =>
    theme.colors.namedColors.primary};

  display: grid;
  justify-items: center;
  grid-template-rows: var(--step-icon-size) 1fr;
  gap: 6px;
  color: var(--decoration-primary-color);
  margin-top: 0.28em;

  svg {
    width: var(--step-icon-size);
    height: var(--step-icon-size);
  }

  ${({ $isLast }) =>
    !$isLast &&
    css`
      &::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: var(--decoration-primary-color);
      }
    `}
`;

export const StoreLink = styled.a<{ $type: 'google' | 'apple' }>`
  display: flex;
`;

export const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.baseColors.blue0};
  color: ${({ theme }) => theme.colors.baseColors.blue0};
  border-radius: 1rem;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;
