import { useState, useRef, useEffect } from 'react';

export const useDetectSticky = () => {
  const [isSticky, setIsSticky] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(e.intersectionRatio < 1);
      },
      { threshold: 1, rootMargin: '-1px 0px 0px 0px' }
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  });

  return { isSticky, ref };
};
