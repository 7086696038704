import styled, { css } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';

export const Container = styled.div`
  display: flex;
  overflow: hidden;
`;

interface TextProps {
  $fontSize: string;
  $color: string;
  $isTruncated: boolean;
  $fontWeight: number;
}

export const Text = styled.p<TextProps>`
  font-family: Montserrat, 'Open Sans', sans-serif;
  font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
  font-weight: ${({ $fontWeight = 500 }) => $fontWeight};
  line-height: 2rem;
  min-width: 0;
  display: inline-block;
  white-space: nowrap;
  color: ${({ $color }) => $color};

  ${({ $isTruncated }) =>
    $isTruncated &&
    css`
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;
