import styled from 'styled-components';
import { VisuallyHiddenStyles } from '../visually-hidden/visually-hidden.styles';
import { FontSizes } from '../typography/typography.styles';

export const CustomRadio = styled.span`
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 1.6em;
  height: 1.6em;
  border: 1px solid #dcdde0;
  background-color: #fff;
  transition: border-color 0.2s;
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.radio.colorActive};
    opacity: 0;
    transition: opacity 0.3s;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover ${CustomRadio} {
    border-color: ${({ theme }) => theme.colors.radio.colorActive};
  }
`;

export const Input = styled.input`
  ${VisuallyHiddenStyles}

  &:checked ~ ${CustomRadio} {
    border-color: ${({ theme }) => theme.colors.radio.colorActive};

    &::before {
      opacity: 1;
    }
  }
`;

export const Text = styled.span`
  font-size: ${FontSizes.normal};
  line-height: 1.42;
  letter-spacing: 0.5px;
  font-weight: 400;
`;
