import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Sidebar from '../../../common/sidebar-right/sidebar.component';
import {
  Body,
  Section,
  SectionTitle,
  SectionsList,
  SubSection,
  ParcelContentTitle,
  Value,
  ActionButtonContainer,
  SubSectionList,
  SubSectionRow,
} from './details-sidebar.styles';
import {
  FontSizes,
  TextBody,
  TextLabel,
} from '../../../common/typography/typography.styles';
import {
  getLengthConfig,
  getWeightConfig,
} from '../../parcel-creation/parcel-details-dimensions/dimensionsConfig';
import { useStore } from '../../../stores/store';
import { ICardActionItem } from '../../../routes/parcel-list/config';
import DeliveryStatus from './components/delivery-status.component';
import NameValueSection from './components/name-value-section';
import DeliveryDetails from './components/delivery-details.component';
import Spinner from '../../../common/spinner/spinner.component';
import PaymentButton from '../payment-button/payment-button.component';
import PrintLabelButton from '../print-label-button/print-label-button.component';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import Tip from '../../../common/toasts/tip.component';
import { SidebarName } from '../../../types/sidebar.types';
import EditDraftButton from '../ edit-draft-button/edit-draft-button.component';
import ParcelTaxesInfo from '../../parcel-creation/parcel-taxes-info/parcel-taxes-info';

const formatPromoCodeText = (text: string, promoCode: string) => {
  const regex = new RegExp(`(${promoCode})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part) => (
    <React.Fragment key={part}>
      {regex.test(part) ? <strong>{part}</strong> : part}
    </React.Fragment>
  ));
};

interface Props {
  name: SidebarName;
  createdDate: string;
  deliveryDate: string;
  senderAddress: string;
  recipientAddress: string;
  menuItems: ICardActionItem[];
  isLoadingParcel?: boolean;
  onToggleFavorite: (isFavorite: boolean) => void;
  isGuestUser?: boolean;
}

const DetailsSidebar = ({
  name,
  createdDate,
  deliveryDate,
  senderAddress,
  recipientAddress,
  menuItems,
  isLoadingParcel,
  onToggleFavorite,
  isGuestUser,
}: Props) => {
  const {
    userStore: { measures, getCurrencySymbol },
    parcelListStore: { shipment },
    navStore: { closeAllRightSidebars },
  } = useStore();

  const { t } = useTranslation();

  if (isLoadingParcel || !shipment) {
    return (
      <Sidebar
        sidebarWidth='64rem'
        contentWithVerticalScroll
        backgroundColor='#f4f7fc'
        withBlurredBackground
        header={t('parcel_details')}
        name={name}>
        <Spinner />
      </Sidebar>
    );
  }

  const { parcel } = shipment;

  const {
    first_name: senderFirstName = '',
    last_name: senderLastName = '',
    email: senderEmail = '',
    phone: senderPhone,
    contact_phone: contactPhone,
  } = shipment.sender!;

  const {
    first_name: recipientFirstName = '',
    last_name: recipientLastName = '',
    email: recipientEmail = '',
    phone: recipientPhone,
  } = shipment.recipient!;

  const weightConfig = getWeightConfig(measures);
  const lengthConfig = getLengthConfig(measures);
  const parcelWeight = `${parcel?.weight} ${weightConfig.suffix}`;
  const parcelDimensions = `${parcel?.size_z} x ${parcel?.size_x} x ${parcel?.size_y} ${lengthConfig.suffix}`;
  const hasItems = !!shipment?.items?.length;
  const hasSender = !!senderFirstName;
  const hasRecipient = !!recipientFirstName;
  const senderPhoneNumber = senderPhone?.phone_number;
  const senderContactPhoneNumber = contactPhone?.phone_number;
  const recipientPhoneNumber = recipientPhone?.phone_number;
  const senderFullName = `${senderFirstName} ${senderLastName}`;
  const recipientFullName = `${recipientFirstName} ${recipientLastName}`;
  const totalPrice = `${getFormattedPrice(shipment.converted_price?.total_price, getCurrencySymbol(shipment.converted_price?.currency))}`;

  const promoCodes = shipment?.promocodes?.map((item) => item.code);
  const firstPromoCode = promoCodes ? promoCodes[0] : '';

  const formattedPromoText = shipment.markers?.promo
    ? formatPromoCodeText(shipment.markers?.promo, firstPromoCode)
    : undefined;
  return (
    <Sidebar
      sidebarWidth='64rem'
      contentWithVerticalScroll
      backgroundColor='#f4f7fc'
      withBlurredBackground
      header={t('parcel_details')}
      onClose={() => closeAllRightSidebars()}
      name={name}>
      {!isLoadingParcel && (
        <Body>
          <SectionsList>
            <DeliveryStatus
              shipment={shipment}
              menuItems={menuItems}
              onToggleFavorite={onToggleFavorite}
              isGuestUser={isGuestUser}
            />
            {shipment.markers?.tip && (
              <Tip description={shipment.markers?.tip} />
            )}
            <DeliveryDetails
              shipment={shipment}
              createdDate={createdDate}
              deliveryDate={deliveryDate}
              senderAddress={senderAddress}
              recipientAddress={recipientAddress}
            />
            <NameValueSection
              title={t('sender')}
              items={
                !hasSender
                  ? undefined
                  : [
                      {
                        name: t('name'),
                        value: senderFullName,
                      },
                      {
                        name: t('contacts'),
                        value: (
                          <>
                            +{senderPhoneNumber} <br />
                            {senderContactPhoneNumber && (
                              <>
                                +{senderContactPhoneNumber} <br />
                              </>
                            )}
                            {senderEmail}
                          </>
                        ),
                      },
                    ]
              }
            />
            <NameValueSection
              title={t('receiver')}
              items={
                !hasRecipient
                  ? undefined
                  : [
                      {
                        name: t('name'),
                        value: recipientFullName,
                      },
                      {
                        name: t('contacts'),
                        value: (
                          <>
                            <span>+{recipientPhoneNumber}</span>
                            <br />
                            <span>{recipientEmail}</span>
                          </>
                        ),
                      },
                    ]
              }
            />
            <NameValueSection
              title={t('parcel_details')}
              items={[
                {
                  name: t('weight'),
                  value: parcelWeight,
                },
                {
                  name: t('dimensions'),
                  value: parcelDimensions,
                },
              ]}
            />
            <Section>
              <SectionTitle>{t('parcel_content')}</SectionTitle>
              {hasItems ? (
                shipment.items?.map((item) => (
                  <SubSection key={item.id}>
                    <ParcelContentTitle>
                      {item.description_standart}
                    </ParcelContentTitle>
                    <SubSectionList>
                      <SubSectionRow>
                        <TextLabel
                          as='p'
                          $fontSize={FontSizes.small}>
                          {t('quantity')}
                        </TextLabel>
                        <TextBody>{item.number_of_items}</TextBody>
                      </SubSectionRow>
                      <SubSectionRow>
                        <TextLabel
                          as='p'
                          $fontSize={FontSizes.small}>
                          {t('declared_value')}
                        </TextLabel>
                        <TextBody>
                          {getFormattedPrice(
                            item?.value,
                            getCurrencySymbol(
                              shipment.converted_price?.currency
                            )
                          )}
                        </TextBody>
                      </SubSectionRow>
                    </SubSectionList>
                  </SubSection>
                ))
              ) : (
                <Value>{t('no_info')}</Value>
              )}
            </Section>
            <ParcelTaxesInfo
              shipment={shipment}
              hideRules
            />
            <NameValueSection
              title={t('payment_details')}
              items={
                shipment.converted_price?.total_price
                  ? [
                      {
                        name: t('total'),
                        value: totalPrice,
                        valueNote: ((): React.ReactNode => {
                          // if (shipment.flags?.payment_in_process) {
                          //   return <PaymentProcessingText />;
                          // }

                          if (
                            shipment.flags?.can_pay ||
                            shipment.status === 'draft' ||
                            shipment.flags?.payment_in_process
                          ) {
                            return null;
                          }

                          return t('paid');
                        })(),
                      },
                    ]
                  : undefined
              }
              promocode={formattedPromoText}
            />
          </SectionsList>
          {(shipment.flags?.can_receive_label || shipment.flags?.can_pay) && (
            <ActionButtonContainer>
              {shipment.flags?.can_receive_label ? (
                <PrintLabelButton />
              ) : (
                <PaymentButton parcel={shipment} />
              )}
            </ActionButtonContainer>
          )}
          {shipment.status === 'draft' && (
            <ActionButtonContainer>
              <EditDraftButton shipmentId={shipment.id} />
            </ActionButtonContainer>
          )}
        </Body>
      )}
    </Sidebar>
  );
};

export default observer(DetailsSidebar);
