import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Section,
  SectionTitle,
  SectionFields,
  Value,
  Row,
  ValueNote,
  Title,
} from '../details-sidebar.styles';
import Tip from '../../../../common/toasts/tip.component';
import { ReactComponent as PromocodeIcon } from '../../../../assets/promocode-tag.svg';

interface Props {
  title: React.ReactNode;
  items?: {
    name: string;
    value: React.ReactNode;
    valueNote?: React.ReactNode;
  }[];
  promocode?: React.ReactNode;
}

const NameValueSection = ({ title, items, promocode }: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionFields>
        {items ? (
          items.map(({ name, value, valueNote }) => (
            <Row key={name}>
              <Title>{name}</Title>
              <Value>
                {value}
                {valueNote && <ValueNote>{valueNote}</ValueNote>}
              </Value>
            </Row>
          ))
        ) : (
          <Value>{t('no_info')}</Value>
        )}
        {promocode && (
          <Tip
            appearance='success'
            description={promocode}
            icon={<PromocodeIcon />}
          />
        )}
      </SectionFields>
    </Section>
  );
};

export default NameValueSection;
