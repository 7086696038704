import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';
import { ActionButton } from '../parcel-items-management/parcel-items-management.styles';

export const Container = styled.div`
  display: flex;
  margin-top: 2.4rem;
  gap: 12px;
  justify-content: space-between;

  @media (max-width: 650px) {
    margin-top: 1.2rem;
  }
`;

export const Info = styled.div``;

export const Header = styled.span`
  display: inline-block;
  font-size: ${FontSizes.mediumPlus};
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0.05rem;
  margin-bottom: 0.4rem;
`;

export const Props = styled.div``;

export const PropValue = styled.span`
  font-size: ${FontSizes.normal};
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05rem;
`;

export const RemoveButton = styled(ActionButton)`
  color: #e82a53;
`;

export const PromocodeEditFormContainer = styled.div`
  margin-top: 2.4rem;

  @media (max-width: 650px) {
    margin-top: 1.2rem;
  }
`;

export const FormInputs = styled.div`
  margin-bottom: 2.4rem;
`;

export const FormBtnsBlock = styled.div`
  width: 100%;

  @media (min-width: 650px) {
    min-width: 16rem;
    width: fit-content;
    margin-left: auto;
  }
`;
