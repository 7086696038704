import styled from 'styled-components';
import { TertiaryButton } from '../button/button.styles';

export const ButtonWrapper = styled.div`
  display: grid;
  position: absolute;
  bottom: 1.8rem;
  right: 1.8rem;
  gap: 0.8rem;
  ${TertiaryButton} {
    --button-background-color: #fff;
    --button-background-color--disabled: #fff;
    &:disabled {
      cursor: pointer;
    }
  }
`;

export const StyledMapDataLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  font-size: 1rem;
  pointer-events: none;
`;
