import { v4 as uuid } from 'uuid';
import { snakeToCamel } from '../utils/generic/generic.utils';
import { ApiResponse } from './apiResponse';

export interface ManualScreenResponse {
  screen_name: string;
  image_url: string;
  mobile_image_url: string;
  title: string;
  description: string;
  button_text?: string;
}

export interface ManualResponse {
  flags: {
    allowed_skip_button: boolean;
  };
  screens: ManualScreenResponse[];
}

export interface ManualScreen {
  id: string;
  imgUrl: string;
  mobileImgUrl: string;
  title: string;
  description: string;
  buttonText?: string;
  screenName: string;
}

export interface Manual {
  flags: {
    allowedSkipButton: boolean;
  };
  screens: ManualScreen[];
}

export const transformManualResponse = (
  response: ApiResponse<ManualResponse>
): Manual => {
  const { flags, screens } = response.data!;

  return {
    flags: Object.entries(flags).reduce<Manual['flags']>(
      (acc, [key, value]) => ({
        ...acc,
        [snakeToCamel(key)]: value,
      }),
      {
        allowedSkipButton: false,
      }
    ),
    screens: screens.map((screen) => ({
      id: uuid(),
      imgUrl: screen.image_url,
      mobileImgUrl: screen.mobile_image_url,
      title: screen.title,
      description: screen.description,
      buttonText: screen.button_text,
      screenName: screen.screen_name,
    })),
  };
};
