import styled from 'styled-components';
import showPassIcon from '../../assets/pass-show-icon.svg';
import hidePassIcon from '../../assets/pass-hide-icon.svg';

interface PassToggleProps {
  $type: 'show' | 'hide';
}

export const PassVisibilityIcon = styled.button.attrs({
  type: 'button',
})<PassToggleProps>`
  background: none;
  border: none;
  padding: 0;
  position: relative;
  isolation: isolate;
  display: inline-block;
  cursor: pointer;
  width: 1.6em;
  height: 1.6em;
  background: no-repeat center;
  background-image: ${({ $type }) =>
    $type === 'show' ? `url(${showPassIcon})` : `url(${hidePassIcon})`};
  transition: background-image 0.2s;

  &::before {
    --offset: -6px;

    content: '';
    position: absolute;
    top: var(--offset);
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    z-index: -1;
  }
`;
