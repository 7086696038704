import { t } from 'i18next';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import {
  Header,
  HeaderLeft,
  TrackingNumber,
  Info,
  InfoItem,
  InfoAbout,
  InfoItemLine,
} from '../../shipments/parcel-list-card/parcel-list-card.styles';

import Tag from '../../../common/tag/tag.component';
import { HeaderTertiary } from '../../../common/typography/typography.styles';
import { TimeLabel } from '../../parcel-creation/pudo-list-item/pudo-list-item.styles';
import TruncatedTextWithTooltip from '../../../common/truncated-text-with-tooltip/truncated-text-with-tooltip.component';
import {
  getAddress,
  getStatusBadgeConfig,
} from '../../../routes/parcel-list/config';
import { Card } from './parcel-list-options.styles';
import { theme } from '../../../styles/themes';
import { formatDate } from '../../../utils/generic/generic.utils';

interface Props {
  parcel: ParcelListShipment;
  onSelect: (parcel: ParcelListShipment) => void;
}

const ParcelListOption = ({ parcel, onSelect }: Props) => {
  const NO_INFORMATION_TEXT = t('no_information');

  const {
    status,
    tracking_number: trackingNumber,
    recipient,
    sender,
    created_at: createdAt,
    delivery_at: deliveryAt,
  } = parcel;

  const statusConfig = getStatusBadgeConfig(status);
  const senderAddress = getAddress(sender!);
  const recipientAddress = getAddress(recipient!);
  const senderName =
    sender?.first_name && sender?.last_name
      ? `${sender?.first_name} ${sender?.last_name}`
      : NO_INFORMATION_TEXT;
  const recipientName =
    recipient?.first_name && recipient?.last_name
      ? `${recipient?.first_name} ${recipient?.last_name}`
      : NO_INFORMATION_TEXT;
  const createdDate =
    formatDate(createdAt.split(' ')[0]) ?? NO_INFORMATION_TEXT;
  const deliveryDate = deliveryAt
    ? `~${formatDate(deliveryAt.split(' ')[0])}`
    : NO_INFORMATION_TEXT;

  return (
    <Card
      tabIndex={0}
      onClick={() => onSelect(parcel)}>
      <Header>
        <HeaderLeft>
          <TrackingNumber>
            <HeaderTertiary>{trackingNumber}</HeaderTertiary>
          </TrackingNumber>
          <Tag
            size='small'
            type={statusConfig.type || 'semi-filled'}
            color={statusConfig.color}>
            {t(statusConfig.text)}
          </Tag>
        </HeaderLeft>
      </Header>
      <Info $isActive={status === 'received' || status === 'ready_to_pick_up'}>
        <InfoItem>
          <InfoAbout>
            <TruncatedTextWithTooltip color={theme.colors.baseColors.grey150}>
              {senderAddress}
            </TruncatedTextWithTooltip>
            <TruncatedTextWithTooltip textType='label'>
              {senderName}
            </TruncatedTextWithTooltip>
          </InfoAbout>
          <TimeLabel>{createdDate}</TimeLabel>
          <InfoItemLine />
        </InfoItem>
        <InfoItem>
          <InfoAbout>
            <TruncatedTextWithTooltip color={theme.colors.baseColors.grey150}>
              {recipientAddress}
            </TruncatedTextWithTooltip>
            <TruncatedTextWithTooltip textType='label'>
              {recipientName}
            </TruncatedTextWithTooltip>
          </InfoAbout>
          <TimeLabel>{deliveryDate}</TimeLabel>
        </InfoItem>
      </Info>
    </Card>
  );
};

export default ParcelListOption;
