import styled, { css } from 'styled-components';
import { FlexContainer } from '../../../../../common/flex-container/flex-container.styles';

export const Container = styled.div`
  width: 100%;
  padding: 24px 12px;
  border-top: 1px solid #dcdde0;

  @media screen and (min-width: 768px) {
    padding: 12px 24px 24px;
  }
`;

export const TabListContainer = styled(FlexContainer)`
  > *:last-child {
    flex-shrink: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
    gap: 10px;

    & > * {
      width: 100%;
    }
  }
`;

export const OptionsContainer = styled.div<{ $isOneOption: boolean }>`
  display: grid;
  gap: 1.2rem;

  @media screen and (min-width: 400px) and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    & > *:last-child:nth-child(odd) {
      grid-column: span 2;
    }
  }

  ${({ $isOneOption }) =>
    $isOneOption &&
    css`
      grid-template-columns: 1fr;
    `}

  @media screen and (max-width: 767px) {
    & > * {
      padding: 1.2rem;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));

    & > *:last-child {
      width: auto;
      min-width: 156px;
      padding: 1.2rem;
    }

    & > *:not(:last-child) {
      flex-grow: 1;
    }
  }
`;
export const TabPanelFlexContainer = styled(FlexContainer)`
  & > * {
    width: 100%;
  }
`;
export const Footer = styled.div`
  display: grid;
  width: 100%;
  align-items: start;
  padding-top: 16px;
  border-top: 1px solid #dcdde0;
  gap: 2.4rem;

  @media (min-width: 768px) {
    gap: 4rem;
    grid-template-columns: 1fr auto;
  }
`;

export const FooterInfoBlock = styled.div`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  border-radius: 10px;
  background-color: #f4f7fc;

  svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    fill: #008cff;
  }

  @media (max-width: 768px) {
    flex: 1 1 auto;
  }
`;

export const FooterInfoText = styled.div`
  display: grid;
  gap: 0.8rem;
  width: 100%;
  color: #75777b;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const FooterButtons = styled.div`
  display: grid;
  grid-template-columns: auto minmax(180px, 1fr);
  gap: 1.6rem;

  @media screen and (min-width: 768px) {
    gap: 2.4rem;
  }
`;
