import styled, { css } from 'styled-components';
import { TextValidation } from '../../../common/typography/typography.styles';

export const CellContainer = styled.div<{
  $width: string;
  $justify?: string;
  $isLoading?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  justify-content: ${({ $justify = 'start' }) => $justify};
  width: ${({ $width }) => $width};
`;

export const LastCell = styled(CellContainer)<{ $isLoading?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const MoreDropdownCell = styled(CellContainer)``;

const stickyStyles = css`
  position: sticky;
  right: 0;
  top: 0;
  background-color: var(--background-color-hover);
  transition: none;
  z-index: 1;
`;

interface ListRowProps {
  $hasPayButton?: boolean;
}

export const ListRow = styled.div<ListRowProps>`
  --background-color-hover: #f5f6f7;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 12px;
  align-items: center;
  padding-left: 8px;
  min-width: fit-content;

  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-top: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  transition: background-color 0.2s;

  &:focus-visible {
    background-color: var(--background-color-hover);
    outline: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transition: none;
      background-color: var(--background-color-hover);

      ${MoreDropdownCell} {
        ${stickyStyles};
      }

      ${({ $hasPayButton }) =>
        $hasPayButton &&
        css`
          ${LastCell} {
            ${stickyStyles};
            right: 4rem;
          }
        `}
    }
  }
`;

export const TrackingNumberText = styled.span`
  ${TextValidation}
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

export const DropdownContainer = styled.div`
  width: 100%;
  height: 100%;

  & svg {
    width: 100%;
    height: 100%;
  }
`;
