import styled, { css } from 'styled-components';
import { InputSearchWithSidebarContainer } from '../input-search-with-sidebar/input-search-with-sidebar.styles';
import { InputSelect } from '../input-select-with-sidebar/input-select-with-sidebar.styles';
import { SidebarBody } from '../sidebar-right/sidebar.styles';
import { TextInput } from '../typography/typography.styles';
import { Theme } from './types';

const getTheme = (theme: Theme) =>
  ({
    yellow: {
      iconColor: '#E3AC12',
      borderColor: '#FFBE0A',
      backgroundColor: '#FFFAEB',
      arrowHoverColor: '#FFBE0A',
    },
    blue: {
      iconColor: '#0070cc',
      borderColor: '#0070CC',
      backgroundColor: '#E5F4FF',
      arrowHoverColor: '#0070CC',
    },
  })[theme];

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Select = styled(InputSelect)`
  border-radius: 10px;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem 1.6rem !important;
`;

export const ArrowContainer = styled.div`
  display: flex;
  margin-left: auto;
  svg path {
    fill: #2c363f;
    transition: fill 0.2s;
  }
`;

export const SidebarContainer = styled(InputSearchWithSidebarContainer)<{
  $theme: Theme;
  $bodyBackground?: string;
}>`
  ${SidebarBody} {
    background-color: ${({ $bodyBackground = '#f4f7fc' }) => $bodyBackground};
  }

  ${({ $theme }) => {
    const { iconColor, backgroundColor, borderColor, arrowHoverColor } =
      getTheme($theme);

    return css`
      ${Select} {
        border: 1px solid ${borderColor};
        background-color: ${backgroundColor};
        &:hover ${ArrowContainer} svg path {
          fill: ${arrowHoverColor};
        }
      }

      ${IconWrapper} {
        color: ${iconColor};
      }
    `;
  }}
`;

export const InputPlaceholderDark = styled.span`
  ${TextInput};
`;
