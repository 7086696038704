import { useTranslation } from 'react-i18next';
import { AuthMessageScreen } from '../auth-messege-screen/auth-message-screen.styles';
import PhoneFormComponent from './phone-form.component';
import { AddPhoneHeader } from './add-phone.component.styles';

const AddPhoneNumber = () => {
  const { t } = useTranslation();

  return (
    <AuthMessageScreen>
      <AddPhoneHeader>{t('add_your_phone_number')}</AddPhoneHeader>
      <PhoneFormComponent />
    </AuthMessageScreen>
  );
};

export default AddPhoneNumber;
