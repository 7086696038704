import styled from 'styled-components';
import {
  FontSizes,
  TextBody,
} from '../../../common/typography/typography.styles';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';
import Checkbox from '../../../common/checkbox/checkbox.component';
import { CheckboxWrapper } from '../../../common/checkbox/checkbox.styles';

export const MarginContainer = styled(BaseMarginComponent('div'))``;

export const TextValue = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  font-size: ${FontSizes.small};
`;

export const CustomCheckbox = styled(Checkbox)`
  & ${CheckboxWrapper} {
    align-items: start;
  }
`;
