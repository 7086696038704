import { observer } from 'mobx-react-lite';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';
import { useStore } from '../../stores/store';
import {
  BottomSidebarContainer,
  Container,
  Content,
} from './bottom-sidebar.styles';
import { BlurredBackground } from '../blurred-background/blurred-background.styles';
import CloseButton from '../close-button/close-button.component';
import { SidebarName } from '../../types/sidebar.types';

interface Props {
  name: SidebarName;
  height?: string;
  children: any;
  withBlurredBackground?: boolean;
  onClose?: () => void;
  backgroundColor?: string;
}

const BottomSidebar = (
  {
    name,
    children,
    height,
    withBlurredBackground,
    onClose,
    backgroundColor,
  }: Props,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    navStore: {
      isSidebarOpenedByName,
      isSidebarClosingByName,
      toggleSidebarByName,
    },
  } = useStore();

  if (!isSidebarOpenedByName(name) && !isSidebarClosingByName(name))
    return null;

  return ReactDOM.createPortal(
    <Container
      onMouseUp={(event) => event.stopPropagation()}
      onClick={(event) => event.stopPropagation()}>
      <Content
        $height={height}
        $isOpened={isSidebarOpenedByName(name)}>
        <CloseButton
          onClick={(event) => {
            event.stopPropagation();
            toggleSidebarByName(name);
            onClose && onClose();
          }}
        />
        <BottomSidebarContainer
          ref={ref}
          $height={height}
          $isOpened={isSidebarOpenedByName(name)}
          $backgroundColor={backgroundColor}>
          {children}
        </BottomSidebarContainer>
      </Content>
      {withBlurredBackground && <BlurredBackground />}
    </Container>,
    document.querySelector('#dropdown-root')!
  );
};

export default observer(forwardRef(BottomSidebar));
