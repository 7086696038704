import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';

export const NoResultsTextBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;

  span:nth-child(1) {
    font-size: ${FontSizes.mediumPlus};
    font-style: normal;
    font-weight: 700;
    line-height: 2.4rem; /* 133.333% */
    letter-spacing: 0.5px;
    display: inline-block;
  }

  span:nth-child(2) {
    font-size: ${FontSizes.normal};
    font-style: normal;
    font-weight: 400;
    line-height: 2rem; /* 142.857% */
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.baseColors.grey100};
    display: inline-block;
  }
`;

export const ProhibitedItemsSidebarContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

export const SearchAndFilterBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 2.4rem;
`;

export const ProhibitedItemOption = styled.div`
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.baseColors.grey5};

  &:last-child {
    border-bottom: none;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 2.4rem;
`;
