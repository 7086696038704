import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';

export const ProhibitedListItemContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

export const ProhibitedListItemImage = styled.div<{
  $imageUrl: string;
  $iconContent: string;
}>`
  width: 6rem;
  height: 6rem;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;

  &::after {
    content: ${({ $iconContent }) => $iconContent};
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.baseColors.blue150};
    color: ${({ theme }) => theme.colors.namedColors.white};
    font-size: ${FontSizes.small};
    padding: 0.5rem;
  }
`;

export const ProhibitedListItemDescriptionContainer = styled.div`
  flex: 1;
  margin-left: 0.8rem;
`;

export const ProhibitedListItemDescription = styled.span`
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const ProhibitedListItemTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  gap: 0.4rem;
`;
