import styled from 'styled-components';

export const CloseButtonContainer = styled.button<{
  $size?: 'normal' | 'big';
}>`
  border: none;
  background-color: transparent;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s;
  padding: ${({ $size = 'normal' }) => ($size === 'normal' ? '0' : '4px')};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  transition-property: background-color, color;
  transition-duration: 0.2s;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.baseColors.grey100};
  }
`;
