import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ParcelCreationProgressContainer } from './parcel-creation-progress.styles';
import ParcelCreationBottom from '../parcel-creation-bottom/parcel-creation-bottom.component';
import ParcelStepTitle from '../parcel-step-title/parcel-step-title.component';
import { ReactComponent as ParcelStepDots } from '../../../assets/vertical-dots-icon.svg';
import { PaddingContainer } from '../parcel-creation-bottom/parcel-creation-bottom.styles';
import { findStepIndex } from '../../../models/parcelCreationFlowNavigation';

const ParcelCreationProgress = () => {
  const {
    parcelCreationStore: { navigation, isDraftEditing },
  } = useStore();

  const { t } = useTranslation();

  return (
    <ParcelCreationProgressContainer id='parcelProgressDesktop'>
      <ParcelCreationProgressContainer
        $pt='2.4rem'
        $pr='2.4rem'
        $pb='2.4rem'
        $pl='2.4rem'>
        <HeaderSecondary $size='medium'>
          {isDraftEditing ? t('edit_parcel') : t('create_a_parcel')}
        </HeaderSecondary>
        {navigation.map.map((step, i) => (
          <React.Fragment key={step.name}>
            <ParcelStepTitle
              step={step}
              stepIndex={i + 1}
              isActive={step.name === navigation.current?.name}
              isCompleted={
                findStepIndex(navigation, step.name) <
                findStepIndex(navigation, navigation.current!.name)
              }
              isLastStep={step.name === navigation.map.at(-1)?.name}
              tooltip={false}
            />
            {step.name === 'delivery-option' && navigation.map.length === 2 && (
              <PaddingContainer
                key='dots'
                $pl='1.1rem'
                $pt='0.4rem'>
                <ParcelStepDots />
              </PaddingContainer>
            )}
          </React.Fragment>
        ))}
      </ParcelCreationProgressContainer>
      <ParcelCreationBottom />
    </ParcelCreationProgressContainer>
  );
};

export default observer(ParcelCreationProgress);
