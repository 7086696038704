import {
  IStatusHistory,
  ShipmentStatus,
} from '../../../../../models/parcelDeliveryMiles';
import ParcelHistoryItem from './parcel-history-item.component';
import { getStatusProgress } from './parcel-history.helpers';

interface Props {
  statusHistory: IStatusHistory[] | null;
  currentStatus: ShipmentStatus | null;
}

const ParcelHistory = ({ statusHistory, currentStatus }: Props) => {
  if (!statusHistory || !currentStatus) return;

  const historyItemsToDisplay = statusHistory.filter((s) => !!s.created_at);

  if (!historyItemsToDisplay) return;

  return (
    <>
      {historyItemsToDisplay.map(({ status, tracking }) => (
        <ParcelHistoryItem
          key={status}
          status={status}
          isLast={
            statusHistory.findIndex((s) => s.status === status) ===
            statusHistory.length - 1
          }
          progress={getStatusProgress(
            statusHistory.findIndex((s) => s.status === status),
            statusHistory.findIndex((s) => s.status === currentStatus),
            statusHistory.length - 1
          )}
          trackingItems={tracking}
        />
      ))}
    </>
  );
};

export default ParcelHistory;
