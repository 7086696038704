import React from 'react';
import { ReactComponent as CopyIcon } from '../../assets/copy-icon.svg';
import { BadgeLabel, BadgeWrapper } from './badge-button.styles';

interface BadgeButtonProps {
  icon?: React.ReactNode;
  text: string;
  href?: string;
  onClick?: () => void;
}

const BadgeButton: React.FC<BadgeButtonProps> = ({
  icon,
  text,
  href,
  onClick,
}) => (
  <BadgeWrapper
    href={href}
    onClick={onClick}
    target={href ? '_blank' : undefined}
    rel={href ? 'noopener noreferrer' : undefined}>
    {icon}
    <BadgeLabel>
      <span>{text}</span>
      <CopyIcon />
    </BadgeLabel>
  </BadgeWrapper>
);

export default BadgeButton;
