import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import {
  ContainerDesktop,
  CardsWrapper,
  Description,
  ImageWrapper,
  TextWrapper,
  Heading,
  ButtonsWrapper,
  BulletContainer,
  Bullet,
  PaginationContainer,
  ContainerMobile,
  WrapperMobile,
  Header,
} from './manual-modal.styles';
import Button from '../button/button.component';
import { useSliderMethods } from '../../hooks/use-slider-methods.hook';
import { type Manual } from '../../models/manual';
import {
  lockBodyScroll,
  unlockBodyScroll,
} from '../../utils/generic/generic.utils';
import BottomSidebar from '../sidebar-bottom/bottom-sidebar.component';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { dummyItem } from './config';
import Checkbox from '../checkbox/checkbox-plain.component';
import { SidebarName } from '../../types/sidebar.types';
import DesktopManualDialog from './components/desktop-manual-dialog.component';
import { useStore } from '../../stores/store';
import { TModals } from '../modal/modal-context';

interface Props {
  item: Manual;
  modalName: TModals;
  mobileSidebarName: SidebarName;
  isLoading: boolean;
  showDontShowAgainCheckbox?: boolean;
  onCloseDesktop: () => void;
  onCloseMobile: () => void;
  onDontShowAgain?: () => void;
}

const ManualModal = ({
  item,
  modalName,
  mobileSidebarName,
  isLoading,
  showDontShowAgainCheckbox = false,
  onCloseDesktop,
  onCloseMobile,
  onDontShowAgain,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const bottomSidebarRef = useRef<HTMLDivElement | null>(null);
  const [shouldDontShowAgain, setShouldDontShowAgain] = useState(false);
  const swiperRef = useRef<SwiperRef>(null);
  const swiper = swiperRef.current?.swiper;
  const {
    modalStore: { isModalVisible },
    navStore: { showSidebarByName, closeSidebarByName },
  } = useStore();

  const { currentSlide, moveNextSlide, moveToSlide, setCurrentSlide } =
    useSliderMethods({ items: item?.screens || [] }, false);

  useEffect(() => {
    lockBodyScroll();

    return () => {
      unlockBodyScroll();
      closeSidebarByName(mobileSidebarName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentSlide(0);
    swiper?.slideTo(0, 0);
  }, [setCurrentSlide, swiper]);

  useEffect(() => {
    if (isMobile) {
      showSidebarByName(mobileSidebarName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const config = isLoading ? dummyItem : item;
  if (!isModalVisible(modalName) || !config) return null;

  const { screens, flags } = config;

  const handleScrollTop = () => {
    bottomSidebarRef.current?.scroll({ top: 0, behavior: 'smooth' });
  };

  const lastSlide = screens.length - 1;
  const isLastSlide = currentSlide === lastSlide;

  const handleMoveSlide = (onClose: () => void) => () => {
    if (isLastSlide) {
      onClose();
      if (shouldDontShowAgain) {
        onDontShowAgain && onDontShowAgain();
      }
    } else {
      swiper?.slideTo(currentSlide + 1);
      moveNextSlide();
      bottomSidebarRef.current?.scroll({ top: 0, behavior: 'smooth' });
    }
  };

  const handleMoveSlideMobile = handleMoveSlide(onCloseMobile);
  const handleMoveSlideDesktop = handleMoveSlide(onCloseDesktop);

  const handleSkip = () => {
    swiper?.slideTo(lastSlide);

    moveToSlide(lastSlide);
    handleScrollTop();
  };

  const content = screens.map(
    ({ imgUrl, mobileImgUrl, id, title, description }, index) => (
      <SwiperSlide key={id}>
        <CardsWrapper
          $currentSlide={currentSlide}
          $index={index}>
          <Header>
            <ImageWrapper>
              {isLoading ? (
                <Skeleton
                  borderRadius={10}
                  width={isMobile ? 600 : 460}
                  height={isMobile ? 300 : 320}
                />
              ) : (
                <img
                  width={isMobile ? 600 : 460}
                  height={isMobile ? 300 : 320}
                  src={isMobile ? mobileImgUrl : imgUrl}
                  alt=''
                />
              )}
            </ImageWrapper>
            <TextWrapper>
              {isLoading ? (
                <Skeleton
                  borderRadius={5}
                  height={30}
                />
              ) : (
                <Heading>{title}</Heading>
              )}
              {isLoading ? (
                <Skeleton
                  borderRadius={5}
                  count={3}
                />
              ) : (
                <Description>{description}</Description>
              )}
              {isLastSlide && showDontShowAgainCheckbox && (
                <Checkbox
                  isChecked={shouldDontShowAgain}
                  onChange={(event) =>
                    setShouldDontShowAgain(event.target.checked)
                  }
                  name='manual-dont-show-again'>
                  {t('manual_checkbox_dont_show_again')}
                </Checkbox>
              )}
            </TextWrapper>
          </Header>
          <PaginationContainer>
            <BulletContainer>
              {screens.map((_, idx) => {
                const key = idx;
                return isLoading ? (
                  <Skeleton
                    key={key}
                    circle
                    width='var(--bullet-size)'
                    height='var(--bullet-size)'
                  />
                ) : (
                  <Bullet
                    key={key}
                    $isActive={idx === currentSlide}
                  />
                );
              })}
            </BulletContainer>
            {!isMobile && (
              <ButtonsWrapper>
                {!isMobile ? (
                  <>
                    {flags?.allowedSkipButton &&
                      (isLoading ? (
                        <Skeleton
                          borderRadius={10}
                          width={66}
                          height={40}
                        />
                      ) : (
                        <Button
                          onClick={isLastSlide ? onCloseDesktop : handleSkip}
                          appearance='tertiary'>
                          {t('skip')}
                        </Button>
                      ))}
                    {isLoading ? (
                      <Skeleton
                        borderRadius={10}
                        width={160}
                        height={40}
                      />
                    ) : (
                      <Button
                        className='next-button'
                        onClick={handleMoveSlideDesktop}>
                        {isLastSlide ? t('done') : t('next')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <Button onClick={handleMoveSlideMobile}>
                        {isLastSlide ? t('done') : t('next')}
                      </Button>
                    )}
                    {flags?.allowedSkipButton &&
                      (isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <Button
                          className='next-button'
                          onClick={isLastSlide ? onCloseMobile : handleSkip}
                          appearance='tertiary'>
                          {t('skip')}
                        </Button>
                      ))}
                  </>
                )}
              </ButtonsWrapper>
            )}
          </PaginationContainer>
        </CardsWrapper>
      </SwiperSlide>
    )
  );

  return (
    <>
      {!isMobile && (
        <ContainerDesktop>
          <DesktopManualDialog
            onClose={onCloseDesktop}
            isOpen={isModalVisible(modalName)}
            content={content}
          />
        </ContainerDesktop>
      )}
      <ContainerMobile>
        {isMobile && (
          <BottomSidebar
            ref={bottomSidebarRef}
            name={mobileSidebarName}
            height='95dvh'
            withBlurredBackground
            onClose={onCloseMobile}>
            <WrapperMobile>
              <Swiper
                className='swiper_custom'
                ref={swiperRef}
                onSlideChange={({ activeIndex }) =>
                  setCurrentSlide(activeIndex)
                }
                initialSlide={currentSlide}
                spaceBetween={50}
                slidesPerView={1}>
                {content}
              </Swiper>
              <ButtonsWrapper>
                {!isMobile ? (
                  <>
                    {flags?.allowedSkipButton &&
                      (isLoading ? (
                        <Skeleton
                          borderRadius={10}
                          width={66}
                          height={40}
                        />
                      ) : (
                        <Button
                          onClick={isLastSlide ? onCloseDesktop : handleSkip}
                          appearance='tertiary'>
                          {t('skip')}
                        </Button>
                      ))}
                    {isLoading ? (
                      <Skeleton
                        borderRadius={10}
                        width={160}
                        height={40}
                      />
                    ) : (
                      <Button
                        className='next-button'
                        onClick={handleMoveSlideDesktop}>
                        {isLastSlide ? t('done') : t('next')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <Skeleton height={40} />
                    ) : (
                      <Button onClick={handleMoveSlideMobile}>
                        {isLastSlide ? t('done') : t('next')}
                      </Button>
                    )}
                    {flags?.allowedSkipButton &&
                      (isLoading ? (
                        <Skeleton height={40} />
                      ) : (
                        <Button
                          className='next-button'
                          onClick={isLastSlide ? onCloseMobile : handleSkip}
                          appearance='tertiary'>
                          {t('skip')}
                        </Button>
                      ))}
                  </>
                )}
              </ButtonsWrapper>
            </WrapperMobile>
          </BottomSidebar>
        )}
      </ContainerMobile>
    </>
  );
};

export default ManualModal;
