import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
  ServicesSubSectionRow,
  ServicesSubSectionMainContent,
  ServicesSubSectionTitle,
  ServicesSubSectionValue,
  ServicesSubSectionNote,
} from '../../services/services-subsection/services-subsection.styles';
import { useStore } from '../../../stores/store';
import { Sender } from '../../../models/parcelDeliveryMiles';
import { getSubjectDetailsSummary } from '../../../utils/parcel-creation/parcel-creation-utils';

const SenderDetailsSummary = () => {
  const {
    parcelCreationStore: { shipment },
  } = useStore();

  const { t } = useTranslation();

  const {
    first_name: firstName = '',
    last_name: lastName = '',
    phone: { phone_number: phoneNumber = '' } = {},
    contact_phone: contactPhone,
    email = '',
    carrier: {
      pudo_info: pudoInfo,
      delivery_service: { service_type: serviceType = '' } = {},
      branch_uuid: branchUUID,
    } = {},
    country_name: countryName = '',
    address: {
      note,
      full_address: fullAddress,
      post_code: postCode,
      region,
      city,
      building,
      street,
      apartment,
      section,
      buzz_code: buzzCode,
    } = {},
  } = shipment?.sender as Sender;

  const senderFullName = `${firstName} ${lastName}`;
  const contactPhoneNumber = contactPhone?.phone_number || '';

  const [senderCarrier, setSenderCarrier] = useState<string | null>('');

  useEffect(() => {
    if (branchUUID) {
      setSenderCarrier(pudoInfo!.name);
    } else {
      setSenderCarrier(
        getSubjectDetailsSummary({
          building,
          street,
          apartment,
          section,
          buzzCode,
          city,
        })
      );
    }
  }, [
    apartment,
    branchUUID,
    building,
    buzzCode,
    city,
    fullAddress,
    postCode,
    section,
    serviceType,
    street,
    pudoInfo,
  ]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('sender')}</ServicesSubSectionHeader>
      <ServicesSubSectionMainContent>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('name')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{senderFullName}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('phone_number')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>+{phoneNumber}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        {contactPhoneNumber && (
          <ServicesSubSectionRow>
            <ServicesSubSectionTitle>
              {t('additional_phone_number')}
            </ServicesSubSectionTitle>
            <ServicesSubSectionValue>
              +{contactPhoneNumber}
            </ServicesSubSectionValue>
          </ServicesSubSectionRow>
        )}
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('email')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{email}</ServicesSubSectionValue>
        </ServicesSubSectionRow>

        {branchUUID ? (
          <ServicesSubSectionRow>
            <ServicesSubSectionTitle>
              {t('pick_up_details')}
            </ServicesSubSectionTitle>
            <ServicesSubSectionValue>
              {senderCarrier}
              <ServicesSubSectionNote>{`${countryName}${region ? `, ${region}` : ''}`}</ServicesSubSectionNote>
            </ServicesSubSectionValue>
          </ServicesSubSectionRow>
        ) : (
          <>
            <ServicesSubSectionRow>
              <ServicesSubSectionTitle>
                {t('pick_up_details')}
              </ServicesSubSectionTitle>
              <ServicesSubSectionValue>
                {senderCarrier}
                <ServicesSubSectionNote>{`${countryName}${region ? `, ${region}` : ''}`}</ServicesSubSectionNote>
              </ServicesSubSectionValue>
            </ServicesSubSectionRow>

            {note && (
              <ServicesSubSectionRow>
                <ServicesSubSectionTitle>{t('note')}</ServicesSubSectionTitle>
                <ServicesSubSectionValue>{note}</ServicesSubSectionValue>
              </ServicesSubSectionRow>
            )}
          </>
        )}
      </ServicesSubSectionMainContent>
    </ServicesSubSectionContainer>
  );
};

export default observer(SenderDetailsSummary);
