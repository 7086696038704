import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
  ServicesSubSectionRow,
  ServicesSubSectionMainContent,
  ServicesSubSectionTitle,
  ServicesSubSectionValue,
} from '../../services/services-subsection/services-subsection.styles';
import { useStore } from '../../../stores/store';
import {
  getLengthConfig,
  getWeightConfig,
} from '../parcel-details-dimensions/dimensionsConfig';

const ParcelDetailsSummary = () => {
  const {
    userStore: { measures },
    parcelCreationStore: { shipment },
  } = useStore();

  const { t } = useTranslation();

  const { sender, recipient, parcel } = shipment!;

  const weightConfig = getWeightConfig(measures);
  const lengthConfig = getLengthConfig(measures);

  const senderAddress =
    `${sender?.country_name?.toLowerCase()}, ${sender?.address?.post_code}${(sender
      ?.address?.city
      ? `, ${sender?.address?.city.toLowerCase()}`
      : ''
    ).toLowerCase()}`.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());

  const receiverAddress =
    `${recipient?.country_name?.toLowerCase()}, ${recipient?.address?.post_code}${(recipient
      ?.address?.city
      ? `, ${recipient?.address?.city.toLowerCase()}`
      : ''
    ).toLowerCase()}`.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());

  const parcelWeight = `${parcel?.weight} ${t(weightConfig.suffix)}`;
  const parcelDimensions = `${parcel?.size_z} x ${parcel?.size_x} x ${parcel?.size_y} ${t(lengthConfig.suffix)}`;

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('parcel_details')}</ServicesSubSectionHeader>
      <ServicesSubSectionMainContent>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('from')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{senderAddress}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('to')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{receiverAddress}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('weight')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{parcelWeight}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>{t('dimensions')}</ServicesSubSectionTitle>
          <ServicesSubSectionValue>{parcelDimensions}</ServicesSubSectionValue>
        </ServicesSubSectionRow>
      </ServicesSubSectionMainContent>
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelDetailsSummary);
