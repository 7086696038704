import React from 'react';
import { useTranslation } from 'react-i18next';
import { IDataInputNames } from '../../widget-calculator-form.component';
import {
  DataInputLabelButton,
  MeasureInputField,
} from '../../widget-calculator.styles';
import FormFieldText from '../../../../../common/form-field-text/form-field-text.component';

type InputName = Exclude<
  IDataInputNames,
  'departure_country' | 'destination_country'
>;

interface LengthConfig {
  suffix: string;
}

interface WeightConfig {
  suffix: string;
  maxWeight: number;
}

type InputConfig = {
  [key in InputName]: {
    label: string;
    ref: React.MutableRefObject<HTMLInputElement | null>;
    config: LengthConfig | WeightConfig;
  };
};

interface Props {
  inputsToShow: any;
  name: InputName;
  weightConfig: WeightConfig;
  lengthConfig: LengthConfig;
  refs: {
    inputWeightRef: React.MutableRefObject<HTMLInputElement | null>;
    inputLengthRef: React.MutableRefObject<HTMLInputElement | null>;
    inputWidthRef: React.MutableRefObject<HTMLInputElement | null>;
    inputHeightRef: React.MutableRefObject<HTMLInputElement | null>;
  };
  touched: any;
  errors: any;
  onDataInputLabelClick: (inputName: IDataInputNames) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (inputName: IDataInputNames) => void;
  onDataInputClose: (inputName: IDataInputNames) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const MeasureInput = ({
  inputsToShow,
  name,
  weightConfig,
  lengthConfig,
  refs,
  touched,
  errors,
  onDataInputLabelClick,
  onChange,
  onFocus,
  onDataInputClose,
  onBlur,
}: Props) => {
  const { t } = useTranslation();
  const { inputWeightRef, inputLengthRef, inputWidthRef, inputHeightRef } =
    refs;

  const inputConfig: InputConfig = {
    weight: {
      label: 'weight',
      ref: inputWeightRef,
      config: weightConfig,
    },
    size_z: {
      label: 'length',
      ref: inputLengthRef,
      config: lengthConfig,
    },
    size_x: {
      label: 'width',
      ref: inputWidthRef,
      config: lengthConfig,
    },
    size_y: {
      label: 'height',
      ref: inputHeightRef,
      config: lengthConfig,
    },
  };

  const { label, ref, config } = inputConfig[name];

  const isInputValid = !(touched[name] && errors[name]);
  const isLabelRed = !!errors[name] && !inputsToShow[name];

  return (
    <MeasureInputField $isFieldValid={isInputValid}>
      <DataInputLabelButton
        $isInvalid={isLabelRed}
        onClick={() => onDataInputLabelClick(name)}
        onFocus={() => onDataInputLabelClick(name)}>
        {t(label)}
      </DataInputLabelButton>
      <FormFieldText
        $mb='0.5rem'
        $borderNone
        validationMethod='always'
        type='number'
        isHidden={!inputsToShow[name]}
        isLabelHidden
        showValidationError={false}
        ref={ref}
        label={label}
        name={name}
        infoText={config.suffix}
        onChange={onChange}
        onBlur={(e) => {
          if (!e.target.value) onDataInputClose(name);
          onBlur(e);
        }}
        onFocus={() => onFocus(name)}
      />
    </MeasureInputField>
  );
};

export default MeasureInput;
