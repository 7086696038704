import { useTranslation } from 'react-i18next';
import Button from '../../../common/button/button.component';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';

interface Props {
  shipmentId: number;
}

const EditDraftButton = ({ shipmentId }: Props) => {
  const { t } = useTranslation();
  const handleCreateParcel = useStartParcelCreation();

  return (
    <Button onClick={() => handleCreateParcel(undefined, shipmentId)}>
      {t('edit')}
    </Button>
  );
};

export default EditDraftButton;
