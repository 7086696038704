import styled from 'styled-components';
import {
  FontSizes,
  TextBody,
} from '../../../common/typography/typography.styles';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';

export const MarginContainer = styled(BaseMarginComponent('div'))``;

export const List = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
    padding-bottom: 1.6rem;
  }
`;

export const Items = styled.div`
  display: grid;
  gap: 0.8rem;
`;

export const ItemName = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-size: ${FontSizes.mediumPlus};
  line-height: 1.33;
  margin-bottom: 1.2rem;
`;

export const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  gap: 1.2rem;
`;
