import { TFunction } from 'i18next/typescript/t';
import Tag from '../../../common/tag/tag.component';
import { Tags } from '../../../models/prohibitedItems';

export function getProhibitedTags(tags: Tags | undefined, t: TFunction) {
  if (!tags) return null;

  if (tags.prohibited) {
    return (
      <Tag
        size='small'
        color='red'>
        {t('prohibited_for_delivery')}
      </Tag>
    );
  }

  return Object.entries(tags).map(([key, isProhibited]) => {
    if (isProhibited === null) return null;

    return isProhibited ? (
      <Tag
        size='small'
        color='red'
        key={key}>
        {t(`prohibited_by_${key}`)}
      </Tag>
    ) : (
      <Tag
        size='small'
        color='green'
        key={key}>
        {t(`allowed_by_${key}`)}
      </Tag>
    );
  });
}
