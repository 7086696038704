import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Advantages from '../../features/main/advantages/advantages.component';
import WidgetCalculator from '../../features/main/widget-calculator/widget-calculator.component';
import Instructions from '../../features/main/instructions/instructions.component';
import Banner from '../../features/main/banner/banner.component';
import TopDirections from '../../features/main/top-directions/top-directions.component';
import DownloadApp from '../../features/main/download-app/download-app.component';
import Partners from '../../features/main/partners/partners.component';
import AuthenticationComponent from '../../features/main/authentication/authentication.component';
import Cookies from '../../features/main/cookies/cookies.component';
import CountriesList from '../../features/main/countries-list/countries-list';
import useLogoutUnverifiedUser from '../../hooks/use-logout-unverified-user.hook';
import BackToTop from '../../features/main/back-to-top/back-to-top.component';
import { SearchParam } from '../../constants/route';

const Main = () => {
  useLogoutUnverifiedUser();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has(SearchParam.NAVIGATE_TO)) {
      searchParams.delete(SearchParam.NAVIGATE_TO);
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <>
      <WidgetCalculator />
      <Advantages />
      <Banner />
      <Instructions />
      <DownloadApp />
      {/* <Services /> - for now disabled until marketing will provide required info */}
      <CountriesList />
      <TopDirections />
      {/* <News /> - for now disabled until marketing will provide required info */}
      <Partners />
      <Cookies />
      <BackToTop />
      <AuthenticationComponent />
    </>
  );
};

export default Main;
