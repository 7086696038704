export const sideValidatorMax =
  (limit: number | undefined) =>
  (value: string = '') => {
    if (!limit) return false;
    return Number(value) <= limit;
  };

export const sideValidatorMin =
  (limit: number) =>
  (value: string = '') => {
    if (!limit) return false;
    return parseFloat(value) >= limit;
  };
