import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
  ServicesSubSectionMainContent,
  ServicesSubSectionTitle,
  ServicesSubSectionValue,
} from '../../services/services-subsection/services-subsection.styles';
import {
  ItemName,
  ItemRow,
  Items,
  List,
} from './parcel-content-summary.styles';
import { useStore } from '../../../stores/store';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';

const ParcelContentSummary = () => {
  const {
    parcelCreationStore: { shipment },
    userStore: { getCurrencySymbol },
  } = useStore();
  const { t } = useTranslation();

  if (shipment?.items?.length === 0) return null;

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('parcel_content')}</ServicesSubSectionHeader>
      <ServicesSubSectionMainContent>
        {shipment!.items!.map((item) => (
          <List key={item.id}>
            <ItemName>{item.description_standart}</ItemName>
            <Items>
              <ItemRow>
                <ServicesSubSectionTitle>
                  {t('quantity')}
                </ServicesSubSectionTitle>
                <ServicesSubSectionValue>
                  {item.number_of_items}
                </ServicesSubSectionValue>
              </ItemRow>
              <ItemRow>
                <ServicesSubSectionTitle>
                  {t('declared_value')}
                </ServicesSubSectionTitle>
                <ServicesSubSectionValue>
                  {getFormattedPrice(
                    item?.value,
                    getCurrencySymbol(shipment?.converted_price?.currency)
                  )}
                </ServicesSubSectionValue>
              </ItemRow>
            </Items>
          </List>
        ))}
      </ServicesSubSectionMainContent>
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelContentSummary);
