import styled, { css } from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';
import { ServicesMenuItemAppearance } from './config';

const pressedStyles = css`
  color: var(--color-active);
  background-color: var(--background-color-active);
`;

interface Props {
  $isSelected?: boolean;
  $isDisabled?: boolean;
  $appearance?: ServicesMenuItemAppearance;
}

const getColorVariables = (appearance: ServicesMenuItemAppearance) =>
  ({
    default: css`
      --color: ${({ theme }) => theme.colors.baseColors.grey150};
      --color-hover: ${({ theme }) => theme.colors.namedColors.primary};
      --color-active: ${({ theme }) => theme.colors.namedColors.primary};
      --background-color-active: ${({ theme }) =>
        theme.colors.baseColors.blue0};
      --color-disabled: ${({ theme }) => theme.colors.baseColors.grey100};
      --background-color-disabled: ${({ theme }) =>
        theme.colors.namedColors.white};
    `,
    logout: css`
      --color: ${({ theme }) => theme.colors.baseColors.red100};
      --color-hover: ${({ theme }) =>
        theme.colors.namedColors.secondaryPrimary};
      --color-active: ${({ theme }) => theme.colors.namedColors.primary};
      --background-color-active: ${({ theme }) =>
        theme.colors.baseColors.blue0};
      --color-disabled: ${({ theme }) => theme.colors.baseColors.grey100};
      --background-color-disabled: ${({ theme }) =>
        theme.colors.namedColors.white};
    `,
  })[appearance];

export const ServicesMenuItemContainer = styled.button<Props>`
  ${({ $appearance = 'default' }) => getColorVariables($appearance)}

  text-decoration: none;
  border: none;
  background: none;
  font: inherit;
  font-size: ${FontSizes.medium};
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;
  color: var(--color);
  transition-property: background-color, color;
  transition-duration: 0.2s;
  outline-color: transparent;
  border-radius: 10px;
  font-weight: 500;
  line-height: 1.42;

  @media screen and (min-width: 768px) {
    padding: 0.8rem 1.2rem;
    font-size: ${FontSizes.normal};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--color-hover);
    }
  }

  &:focus-visible {
    color: var(--color-hover);
  }

  &:active {
    ${pressedStyles};
  }

  ${({ $isSelected }) => $isSelected && pressedStyles}
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: var(--color-disabled);
      background-color: var(--background-color-disabled);
      pointer-events: none;

      svg {
        fill: #96999e;
      }
    `}
    svg {
    --size: 1.25em;

    width: var(--size);
    height: var(--size);
    flex-shrink: 0;

    @media screen and (min-width: 768px) {
      --size: 1.43em;
    }
  }
`;
