import styled from 'styled-components';
import { HeaderBtn } from '../header-btn/header-btn.styles';

export const Container = styled.div`
  @media screen and (min-width: 650px) {
    ${HeaderBtn} {
      display: none;
    }
  }
`;

export const ActionsDesktop = styled.div`
  display: flex;
  gap: 16px;

  @media screen and (max-width: 649px) {
    display: none;
  }
`;
