import React, { forwardRef, Ref, useEffect, useId } from 'react';
import { MarginProps } from '../base-margin/base-margin.component';
import { FieldContainer, InputContainer } from '../input/input.styles';
import FieldLabel from '../input/field-label.component';
import TextArea from '../textarea/textarea.component';
import { useFormField } from '../../hooks/use-form-field.hook';
import { TValidationMethod } from '../../utils/forms/forms.utils';

interface Props {
  isOptional?: boolean;
  name: string;
  placeholder?: string;
  label: string;
  isHidden?: boolean;
  isLabelHidden?: boolean;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  hint?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  disabled?: boolean;
  validationMethod?: TValidationMethod;
  showValidationError?: boolean;
  optionalText?: string;
  labelEndElement?: React.ReactNode;
  autoComplete?: string;
  disableBlur?: boolean;
}

const FormFieldTextArea = (
  {
    name,
    placeholder,
    label,
    inputMode,
    isHidden,
    isLabelHidden,
    hint,
    maxLength,
    disabled = false,
    onChange,
    onFocus,
    onBlur,
    showValidationError = true,
    isOptional,
    optionalText = 'Optional',
    labelEndElement,
    autoComplete,
    disableBlur,
    validationMethod = 'default',
  }: Props & MarginProps,
  ref: Ref<HTMLTextAreaElement>
) => {
  const id = useId();
  const inputId = name + id;

  const {
    field,
    formikContext,
    meta,
    isInvalid,
    helpers: { setTouched },
  } = useFormField({
    name,
    validationMethod,
  });

  useEffect(() => {
    if (meta.touched && meta.value === null) {
      setTouched(false, false);
    }
  }, [meta.touched, meta.value, setTouched]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event);
    onChange && onChange(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onFocus && onFocus(event);
    setTouched(false);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    formikContext.setFieldValue(name, event.target.value);
    if (disableBlur) return;
    field.onBlur(event);
    onBlur && onBlur(event);
  };

  return (
    <FieldContainer $isHidden={isHidden}>
      <InputContainer>
        <FieldLabel
          isHidden={isLabelHidden}
          isOptional={isOptional}
          optionalText={optionalText}
          labelEndElement={labelEndElement}
          hint={hint}
          inputId={inputId}>
          {label}
        </FieldLabel>

        <TextArea
          autoComplete={autoComplete}
          ref={ref}
          id={inputId}
          placeholder={placeholder}
          required={!isOptional}
          inputMode={inputMode}
          name={name}
          disabled={disabled}
          value={field.value ?? ''}
          isInvalid={isInvalid}
          maxLength={maxLength}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          validationMessage={
            showValidationError && isInvalid ? meta.error : undefined
          }
        />
      </InputContainer>
    </FieldContainer>
  );
};

export default forwardRef(FormFieldTextArea);
