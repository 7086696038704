import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import FormFieldPhone, {
  getCountryCallingCodeWrapper,
} from '../../../common/form-field-phone/form-field-phone.component';
import { AuthorizationForm } from '../auth-tabbed-container/auth-tabbed-container.styles';
import { Buttons } from '../sign-up/sign-up.styles';
import { AppRoute } from '../../../constants/route';
import { removeCountryCallingCodeFromPhone } from '../../../routes/personal-data/contact-details-form.component';
import { generateLocaleRoute } from '../../../utils/uri/uri.utils';
import { theme } from '../../../styles/themes';

const PhoneForm = () => {
  const {
    localizationsStore: { selectedCountry },
    navStore: { setAuthCurrentScreen },
    userStore: { user, isRequestingPhoneCode, updatePhone },
  } = useStore();

  const navigate = useNavigate();

  const initialValues = {
    phone:
      removeCountryCallingCodeFromPhone(
        user?.phoneForVerification,
        selectedCountry?.country_code
      ) || '',
  };

  const { t } = useTranslation();
  const countryCode = selectedCountry?.country_code || '';
  const countryCallingCode = getCountryCallingCodeWrapper(countryCode);

  const validationSchema = Yup.object({
    phone: Yup.string()
      .test('len', t('wrong_phone_number_format'), (value) =>
        isValidPhoneNumber(`+${countryCallingCode}${value}`)
      )
      .matches(/^\d+$/, t('wrong_phone_number_format'))
      .required(t('this_field_cannot_be_empty')),
  });

  const handleFormSubmit = async (phoneNumber: string) => {
    const phoneWithCountryCallingCode = countryCallingCode + phoneNumber;

    const phone = {
      country_code: countryCode.toUpperCase(),
      phone_number: phoneWithCountryCallingCode,
    };

    const success = await updatePhone(phone);
    success && setAuthCurrentScreen('confirm-phone-number');
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(formValues) => handleFormSubmit(formValues.phone)}>
      {({ handleSubmit, isValid, dirty }) => (
        <AuthorizationForm
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}>
          <FormFieldPhone
            $mb='0'
            placeholder='(555) 000 - 0000'
            codeFieldName={t('phone_country_code')}
            phoneFieldName='phone'
            label={t('your_phone_number')}
            labelColor={theme.colors.baseColors.grey100}
            defaultCountryCode={countryCode}
            isDropdownDisabled
          />
          <Buttons $gap='1.6rem'>
            <Button
              isLoading={isRequestingPhoneCode}
              size='medium'
              type='submit'
              disabled={!dirty || !isValid}>
              {t('next')}
            </Button>
            <Button
              appearance='tertiary'
              onClick={() => {
                setAuthCurrentScreen('auth-tabs');
                navigate(generateLocaleRoute(AppRoute.AUTH));
              }}>
              {t('back')}
            </Button>
          </Buttons>
        </AuthorizationForm>
      )}
    </Formik>
  );
};

export default observer(PhoneForm);
