import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  FormBtnsBlock,
  FormInputs,
  PromocodeEditFormContainer,
} from './promocode-summary-form.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import Button from '../../../common/button/button.component';

const PromocodeSummaryForm = () => {
  const { t } = useTranslation();
  const { handleSubmit, dirty, isValid, isSubmitting } = useFormikContext();

  return (
    <PromocodeEditFormContainer>
      <form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
        <FormInputs>
          <FormFieldText
            $mb='0'
            name='promocode_add'
            label='promocode_add'
          />
        </FormInputs>

        <FormBtnsBlock>
          <Button
            type='submit'
            disabled={!dirty || !isValid}
            isLoading={isSubmitting}>
            {t('apply')}
          </Button>
        </FormBtnsBlock>
      </form>
    </PromocodeEditFormContainer>
  );
};

export default PromocodeSummaryForm;
