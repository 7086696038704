import styled from 'styled-components';
import { ScrollableContent } from '../form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';
import { BottomSidebarContainer } from '../sidebar-bottom/bottom-sidebar.styles';
import { TextBody } from '../typography/typography.styles';

export const ContainerDesktop = styled.div`
  display: block;
  position: absolute;
  @media (max-width: 649px) {
    display: none;
  }
`;

export const ContainerMobile = styled.div`
  display: none;

  ${BottomSidebarContainer} {
    background-color: ${({ theme }) => theme.colors.baseColors.blue0};
  }

  ${ScrollableContent} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.baseColors.blue0};
    border-radius: 0;
    margin: 0;
    gap: 0;
  }

  @media (max-width: 649px) {
    display: block;
  }
`;

export const WrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  .swiper_custom {
    width: 100%;
  }
`;

interface Props {
  $currentSlide: number;
  $index: number;
}

export const CardsWrapper = styled.div<Props>`
  --container-gap: 2.4rem;
  --bullet-size: 8px;

  display: ${({ $index, $currentSlide }) =>
    $index === $currentSlide ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  padding: 0;
  gap: var(--container-gap);
  min-width: 460px;
  max-width: 460px;
  width: 100%;

  @media (max-width: 649px) {
    display: ${({ $index, $currentSlide }) =>
      $index === $currentSlide ? 'flex' : 'none'};
    flex-direction: column;
    padding: 0;
    min-width: 100%;
    max-width: 343px;

    justify-content: space-between;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  min-height: 43.4rem;
  max-height: 43.4rem;

  @media screen and (min-width: 650px) {
    gap: var(--container-gap);
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;

  @media screen and (min-width: 650px) {
    height: 320px;
  }

  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (max-width: 649px) {
    gap: 8px;
  }
`;

export const Heading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

export const Description = styled(TextBody)`
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;

  @media screen and (min-width: 650px) {
    .next-button {
      min-width: 160px;
    }
  }

  @media (max-width: 649px) {
    flex-direction: column;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--container-gap);

  @media (max-width: 649px) {
    flex-direction: column;
  }
`;

export const BulletContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  padding: 4px 6px;
`;

interface BulletProps {
  $isActive: boolean;
}

export const Bullet = styled.span<BulletProps>`
  display: block;
  border-radius: 100%;
  width: var(--bullet-size);
  height: var(--bullet-size);
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.namedColors.primary : '#f1f2f4'};
`;
