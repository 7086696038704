import React, { MouseEventHandler } from 'react';
import { Container, MenuItemInner, Suffix, Prefix } from './menu-item.styles';

export type MenuItemSize = 'small' | 'medium';
export type MenuItemContentWeight = 400 | 500 | 700;

interface Props {
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  children: React.ReactNode;
  isSelected?: boolean;
  isDisabled?: boolean;
  size?: MenuItemSize;
  contentWeight?: MenuItemContentWeight;
  showBackgroundOnSelected?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: MouseEventHandler<HTMLButtonElement>;
}

const MenuItem = ({
  isSelected,
  suffix,
  prefix,
  children,
  isDisabled,
  size = 'medium',
  contentWeight = 400,
  showBackgroundOnSelected = true,
  onClick,
  onMouseUp,
}: Props) => (
  <Container>
    <MenuItemInner
      type='button'
      $size={size}
      $showBackgroundOnSelected={showBackgroundOnSelected}
      $contentWeight={contentWeight}
      $isSelected={isSelected}
      $hasPrefix={!!prefix}
      $hasSuffix={!!suffix}
      aria-disabled={isDisabled}
      $isDisabled={isDisabled}
      className='flag-emoji-font'
      onClick={onClick}
      onMouseUp={onMouseUp}>
      {prefix && <Prefix>{prefix}</Prefix>}
      {children}
      {suffix && <Suffix>{suffix}</Suffix>}
    </MenuItemInner>
  </Container>
);

export default MenuItem;
