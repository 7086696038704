import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import ManualModal from '../../common/manual-modal/manual-modal.component';
import { Modals } from '../../constants/modals';

const OnboardingManual = ({ onClose }: { onClose: () => void }) => {
  const {
    userStore: {
      onboardingManual,
      isOnboardingManualLoading,
      confirmOnboardingManual,
    },
  } = useStore();

  const handleClose = () => {
    onClose();
    confirmOnboardingManual();
  };

  return (
    <ManualModal
      isLoading={isOnboardingManualLoading}
      modalName={Modals.ONBOARDING_MANUAL}
      mobileSidebarName={Modals.ONBOARDING_MANUAL}
      item={onboardingManual!}
      onCloseDesktop={handleClose}
      onCloseMobile={handleClose}
    />
  );
};

export default observer(OnboardingManual);
