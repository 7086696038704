import Skeleton from 'react-loading-skeleton';
import { SkeletonContainer } from '../../routes/parcel-list/parcel-list.styles';

interface Props {
  height?: string;
  width?: string;
  pb?: string;
  display?: 'block' | 'grid' | 'inline-block';
}

const CustomSkeleton = ({
  height,
  width = '100%',
  pb = '0',
  display = 'block',
}: Props) => (
  <SkeletonContainer
    $display={display}
    $pb={pb}
    $width={width}>
    <Skeleton
      width='100%'
      max-height={height}
      height={height}
      borderRadius={10}
    />
  </SkeletonContainer>
);

export default CustomSkeleton;
