import { css } from 'styled-components';
import { ButtonAppearance, ButtonContentStyle, ButtonSize } from './types';

export const getContentStyle = (contentStyle: ButtonContentStyle = 'normal') =>
  ({
    thin: '500',
    normal: '700',
  })[contentStyle];

export const getIconSize = (size: 'large' | 'default' | 'mini') =>
  ({
    large: {
      iconSize: '1.5em',
    },
    mini: {
      iconSize: '1.33em',
    },
    default: {
      iconSize: '1.14em',
    },
  })[size];

const getCommonProperties = (size: ButtonSize) =>
  ({
    mini: {
      borderRadius: '5px',
      gap: '4px',
      fontSize: '1.2rem',
      fontWeight: '500',
      minHeight: '24px',
      ...getIconSize('mini'),
    },
    small: {
      borderRadius: '10px',
      gap: '8px',
      fontSize: '1.4rem',
      fontWeight: '500',
      minHeight: '32px',
      ...getIconSize('default'),
    },
    medium: {
      borderRadius: '10px',
      gap: '8px',
      fontSize: '1.4rem',
      fontWeight: '700',
      minHeight: '40px',
      ...getIconSize('default'),
    },
    large: {
      borderRadius: '10px',
      gap: '8px',
      fontSize: '1.6rem',
      fontWeight: '700',
      minHeight: '48px',
      ...getIconSize('large'),
    },
  })[size];

export const getSizePaddings = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  const paddings =
    appearance === 'text'
      ? {
          mini: {
            minHeight: '24px',
            pl: '3px',
            pr: '3px',
            pt: '5px',
            pb: '5px',
          },
          small: {
            minHeight: '28px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          medium: {
            minHeight: '24px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          large: {
            minHeight: '32px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
        }
      : {
          mini: {
            pl: '7px',
            pr: '7px',
            pt: '3px',
            pb: '3px',
          },
          small: {
            pl: '15px',
            pr: '15px',
            pt: '5px',
            pb: '5px',
          },
          medium: {
            pl: '15px',
            pr: '15px',
            pt: '11px',
            pb: '11px',
          },
          large: {
            pl: '15px',
            pr: '15px',
            pt: '13px',
            pb: '13px',
          },
        };

  return paddings[size];
};

export const getLoadingSizePaddings = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  const paddings =
    appearance === 'text'
      ? {
          mini: {
            minHeight: '24px',
            pl: '3px',
            pr: '3px',
            pt: '5px',
            pb: '5px',
          },
          small: {
            minHeight: '28px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          medium: {
            minHeight: '24px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          large: {
            minHeight: '32px',
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
        }
      : {
          mini: {
            pl: '7px',
            pr: '7px',
            pt: '7px',
            pb: '7px',
            iconSize: '0.66em',
          },
          small: {
            pl: '15px',
            pr: '15px',
            pt: '7px',
            pb: '7px',
          },
          medium: {
            pl: '15px',
            pr: '15px',
            pt: '11px',
            pb: '11px',
          },
          large: {
            pl: '11px',
            pr: '11px',
            pt: '11px',
            pb: '11px',
          },
        };

  return paddings[size];
};

export const getSizeConfig = (
  size: ButtonSize = 'medium',
  appearance?: ButtonAppearance,
  isLoading: boolean = false
) =>
  ({
    mini: {
      ...getCommonProperties('mini'),
      ...(isLoading
        ? getLoadingSizePaddings('mini', appearance)
        : getSizePaddings('mini', appearance)),
    },
    small: {
      ...getCommonProperties('small'),
      ...(isLoading
        ? getLoadingSizePaddings('small', appearance)
        : getSizePaddings('small', appearance)),
    },
    medium: {
      ...getCommonProperties('medium'),
      ...(isLoading
        ? getLoadingSizePaddings('medium', appearance)
        : getSizePaddings('medium', appearance)),
    },
    large: {
      ...getCommonProperties('large'),
      ...(isLoading
        ? getLoadingSizePaddings('large', appearance)
        : getSizePaddings('large', appearance)),
    },
  })[size];

export const getIconSizePaddings = (
  size: ButtonSize,
  appearance?: ButtonAppearance
) => {
  const paddings =
    appearance === 'text'
      ? {
          default: {
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
        }
      : {
          mini: {
            pl: '3px',
            pr: '3px',
            pt: '3px',
            pb: '3px',
          },
          small: {
            pl: '7px',
            pr: '7px',
            pt: '7px',
            pb: '7px',
          },
          medium: {
            pl: '11px',
            pr: '11px',
            pt: '11px',
            pb: '11px',
          },
          large: {
            pl: '11px',
            pr: '11px',
            pt: '11px',
            pb: '11px',
          },
        };

  return paddings[size] || paddings['default'];
};

export const getIconSizeConfig = (
  size: ButtonSize = 'medium',
  appearance?: ButtonAppearance
) =>
  ({
    mini: {
      ...getCommonProperties('mini'),
      ...getIconSizePaddings('mini', appearance),
    },
    small: {
      ...getCommonProperties('small'),
      ...getIconSizePaddings('small', appearance),
    },
    medium: {
      ...getCommonProperties('medium'),
      ...getIconSizePaddings('medium', appearance),
    },
    large: {
      ...getCommonProperties('large'),
      ...getIconSizePaddings('large', appearance),
    },
  })[size];

export const getBadgeSizes = (size: ButtonSize) =>
  ({
    large: css`
      top: 6px;
      right: 6px;
      width: 1.6rem;
      height: 1.6rem;
    `,
    medium: css`
      top: 8px;
      right: 10px;
      width: 0.8rem;
      height: 0.8rem;
    `,
    small: css`
      top: 5px;
      right: 5px;
      width: 0.8rem;
      height: 0.8rem;
    `,
    mini: css`
      top: 2px;
      right: 2px;
      width: 0.8rem;
      height: 0.8rem;
    `,
  })[size];
