import { observer } from 'mobx-react-lite';
import { WithSelectionCallback } from '../../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import { useStore } from '../../../stores/store';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import { ScrollableContent } from '../../../common/form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';
import ParcelListOption from './parcel-list-option.component';

const ParcelListOptions = ({
  onSelect,
}: WithSelectionCallback<ParcelListShipment>) => {
  const {
    parcelListStore: { parcels },
  } = useStore();

  return (
    <ScrollableContent
      $heightOfAboveContent='17.9rem'
      $heightOfAboveContentMobile='13.6rem'>
      {parcels?.map((parcel) => (
        <ParcelListOption
          key={parcel.id}
          parcel={parcel}
          onSelect={onSelect}
        />
      ))}
    </ScrollableContent>
  );
};

export default observer(ParcelListOptions);
