import styled from 'styled-components';

export const FormFields = styled.div`
  display: grid;
  gap: var(--inputs-gap, 16px);

  @media screen and (min-width: 751px) {
    gap: var(--inputs-gap, 20px);
  }
`;

export const AccountSettingsForm = styled.form`
  display: grid;
  gap: 24px;
`;
