import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import {
  CalculateButton,
  DataInputField,
  DataInputFields,
  DataInputFieldsContainer,
  DataInputLabelButton,
  DimensionFlexContainer,
  DividerPipe,
  MessagesContainer,
  ParcelContainer,
  ParcelTab,
  ParcelTabContent,
} from './widget-calculator.styles';
import { ReactComponent as ShipmentsIcon } from '../../../assets/shipments-icon.svg';
import { useStore } from '../../../stores/store';
import {
  getLengthConfig,
  getWeightConfig,
} from '../../parcel-creation/parcel-details-dimensions/dimensionsConfig';
import {
  DeliveryOptionsRequest,
  ParcelDimensions,
  ShipmentCountryInfo,
} from '../../../models/shipmentCountryInfo';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import { getShipmentCountriesByFilter } from '../../../utils/parcel-creation/parcel-creation-utils';
import InputCountryFormField from './components/input-country-form-field/input-country-form-field.component';
import EstimatedDeliveryCost from './components/estimated-delivery-cost/estimated-delivery-cost.component';
import Button from '../../../common/button/button.component';
import ValidationHandler from './components/validation-handler/validation-handler.component';
import MeasureInput from './components/measure-input/measure-input.component';
import { loadPlacesLibs } from '../../../utils/google-places/google-places.utils';
import { getMaxValidation, getMinValidation } from './helpers';
import { useInputsRef } from './use-inputs-ref';
import { getCountryZip } from '../../../utils/countries/countries.utils';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import VolumetricWeightMessage from './components/volumetric-weight-message/volumetric-weight-message.component';
import { VALIDATION_RULE_DIGITS_WITH_DOT } from '../../../constants/validation';
import { createValidationRuleWithRequired } from '../../../utils/forms/forms.utils';

interface FormikEffectFieldProps {
  defaultValues?: FormValues;
}

const FormikEffectField = ({ defaultValues }: FormikEffectFieldProps) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const {
    localizationsStore: { selectedLocalization },
    widgetCalculatorStore: {
      setParcelDimensions,
      countryDeparture,
      countryDestination,
    },
  } = useStore();

  useEffect(() => {
    const numericValues: ParcelDimensions = Object.entries(values).reduce(
      (acc, [key, value]) => {
        if (key === 'departure_country' || key === 'destination_country') {
          return acc;
        }

        acc[key as keyof ParcelDimensions] =
          typeof value === 'string' ? parseFloat(value) || 0 : value;
        return acc;
      },
      {} as ParcelDimensions
    );

    setParcelDimensions(numericValues);
  }, [setParcelDimensions, values]);

  useEffect(() => {
    setFieldValue(
      'departure_country',
      countryDeparture?.countryDetails.localized_name
    );
  }, [countryDeparture, selectedLocalization, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      'destination_country',
      countryDestination?.countryDetails.localized_name
    );
  }, [countryDestination, setFieldValue]);

  useEffect(() => {
    if (defaultValues) {
      setFieldValue('weight', defaultValues?.weight);
      setFieldValue('size_x', defaultValues.size_x);
      setFieldValue('size_y', defaultValues.size_y);
      setFieldValue('size_z', defaultValues.size_z);
    }
  }, [defaultValues, setFieldValue]);

  return null;
};

export type IDataInputNames =
  | 'departure_country'
  | 'destination_country'
  | 'weight'
  | 'size_z'
  | 'size_x'
  | 'size_y';

export interface FormValues {
  departure_country: string;
  destination_country: string;
  weight: string;
  size_z: string;
  size_x: string;
  size_y: string;
}

const DEFAULT_INPUTS_TO_SHOW = {
  departure_country: true,
  destination_country: false,
  weight: false,
  size_z: false,
  size_x: false,
  size_y: false,
};

interface Props {
  defaultValues?: FormValues;
  isFrameMode?: boolean;
}

const WidgetCalculatorForm = ({ defaultValues, isFrameMode }: Props) => {
  const {
    userStore: { measures },
    localizationsStore: { selectedCountry },
    widgetCalculatorStore: {
      countryDeparture,
      countryDestination,
      deliveryLimitations,
      isCalculating,
      isLoadingCountries,
      loadShipmentCountriesAndSetDeparture,
      makeShipmentCalculation,
      setIsCalculating,
      getDeliveryLimitations,
      setDestinationCountry,
      showEstimatedDeliveryCost,
      setShowEstimatedDeliveryCost,
      findShipmentCountry,
    },
  } = useStore();

  const [inputsToShow, setInputsToShow] = useState(DEFAULT_INPUTS_TO_SHOW);
  const [activeField, setActiveField] = useState<IDataInputNames | null>(null);
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const { inputWeightRef, inputLengthRef, inputWidthRef, inputHeightRef } =
    useInputsRef({ inputsToShow });
  const [destinationOptions, setDestinationOptions] = useState<
    ShipmentCountryInfo[] | undefined
  >([]);

  const [calculateButtonClicked, setCalculateButtonClicked] = useState(false);

  const { t } = useTranslation();

  const weightConfig = getWeightConfig(measures);
  const lengthConfig = getLengthConfig(measures);

  const ignoreDimensionsValidation = !calculateButtonClicked;

  const validationSchema = Yup.object({
    departure_country: Yup.string().required('fields_required'),
    destination_country: Yup.string().required('fields_required'),
    weight: createValidationRuleWithRequired(VALIDATION_RULE_DIGITS_WITH_DOT)
      .test(
        'value-is-big',
        t('max_weight_with_value', {
          value: deliveryLimitations?.maxWeight,
          unit: weightConfig.suffix,
        }),
        (value, context) =>
          getMaxValidation(
            value,
            context,
            deliveryLimitations?.maxWeight,
            ignoreDimensionsValidation
          )
      )
      .test('value-is-low', 'weight_value_too_low', (value, context) =>
        getMinValidation(
          value,
          context,
          ignoreDimensionsValidation,
          deliveryLimitations?.minWeight
        )
      ),
    size_x: createValidationRuleWithRequired(VALIDATION_RULE_DIGITS_WITH_DOT)
      .test('value-is-big', 'width_value_too_big', (value, context) =>
        getMaxValidation(
          value,
          context,
          deliveryLimitations?.maxSide,
          ignoreDimensionsValidation
        )
      )
      .test('value-is-low', 'width_value_too_low', (value, context) =>
        getMinValidation(value, context, ignoreDimensionsValidation)
      ),
    size_y: createValidationRuleWithRequired(VALIDATION_RULE_DIGITS_WITH_DOT)
      .test('value-is-big', 'height_value_too_big', (value, context) =>
        getMaxValidation(
          value,
          context,
          deliveryLimitations?.maxSide,
          ignoreDimensionsValidation
        )
      )
      .test('value-is-low', 'height_value_too_low', (value, context) =>
        getMinValidation(value, context, ignoreDimensionsValidation)
      ),
    size_z: createValidationRuleWithRequired(VALIDATION_RULE_DIGITS_WITH_DOT)
      .test('value-is-big', 'length_value_too_big', (value, context) =>
        getMaxValidation(
          value,
          context,
          deliveryLimitations?.maxSide,
          ignoreDimensionsValidation
        )
      )
      .test('value-is-low', 'length_value_too_low', (value, context) =>
        getMinValidation(value, context, ignoreDimensionsValidation)
      ),
  });

  const initialValues = {
    departure_country: '',
    destination_country: '',
    weight: '',
    size_x: '',
    size_y: '',
    size_z: '',
  };

  useLayoutEffect(() => {
    loadShipmentCountriesAndSetDeparture(true);
  }, [loadShipmentCountriesAndSetDeparture, selectedCountry]);

  const getDestinationOptions = useCallback(
    async (filter?: string) => {
      if (!countryDeparture?.destinationCountries) return [];

      return Promise.resolve(
        getShipmentCountriesByFilter(
          countryDeparture?.destinationCountries ?? [],
          filter
        )
      );
    },
    [countryDeparture]
  );

  const handleDataInputLabelButtonClick = (inputName: IDataInputNames) => {
    if (inputName === 'destination_country') {
      getDestinationOptions().then(setDestinationOptions);
    }
    setInputsToShow({ ...inputsToShow, [inputName]: true });
  };

  const handleDataInputClose = (inputName?: keyof FormValues) => {
    if (inputName) {
      setInputsToShow({ ...inputsToShow, [inputName]: false });
    } else {
      setInputsToShow(DEFAULT_INPUTS_TO_SHOW);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
    setFieldTouched: (
      field: string,
      isTouched?: boolean,
      shouldValidate?: boolean
    ) => void
  ) => {
    const { name, value } = e.target;
    let newValue = value.replace(/,/g, '.').replace(/[^0-9.,]/g, '');
    // Ensure only one decimal point and one digit after the decimal point
    const match = newValue.match(/^(\d*)(\.?)(\d?)/);
    newValue = match ? `${match[1]}${match[2]}${match[3]}` : '';

    setFieldTouched(name, true, false);
    setFieldValue(name, newValue);
  };

  const handleSubmitInFrame = (calculatorState: DeliveryOptionsRequest) => {
    const params = new URL(window.location.href).searchParams;
    const origin = params.get('origin');

    if (!origin) {
      throw new Error('Please, add origin!');
    }

    window.parent.postMessage(calculatorState, origin);
  };

  const handleFormSubmit = async (formValues: FormValues) => {
    if (!countryDeparture || !countryDestination) return;

    const {
      departure_country: departureCountry,
      destination_country: destinationCountry,
      ...dimensions
    } = formValues;

    const calculatorState = {
      departure_country: countryDeparture.countryCode,
      departure_zip: countryDeparture.zipCode!,
      destination_country: countryDestination.countryCode,
      destination_zip: countryDestination.zipCode!,
      weight: parseFloat(dimensions.weight),
      size_x: parseFloat(dimensions.size_x),
      size_y: parseFloat(dimensions.size_y),
      size_z: parseFloat(dimensions.size_z),
    };

    if (isFrameMode) {
      handleSubmitInFrame(calculatorState);
      return;
    }

    setIsCalculating(true);

    await loadPlacesLibs();

    const success = await makeShipmentCalculation(calculatorState);

    if (success) {
      setShowEstimatedDeliveryCost(true);
    }
  };

  useEffect(() => {
    if (defaultValues) {
      setInputsToShow({
        departure_country: true,
        destination_country: true,
        weight: true,
        size_x: true,
        size_y: true,
        size_z: true,
      });

      if (!countryDestination) {
        const defaultDestination = findShipmentCountry(
          defaultValues.destination_country
        );

        setDestinationCountry(defaultDestination);
      }

      if (countryDeparture && countryDestination) {
        handleFormSubmit(defaultValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultValues,
    countryDeparture?.countryCode,
    countryDestination?.countryCode,
    setInputsToShow,
  ]);

  useEffect(() => {
    setInputsToShow(DEFAULT_INPUTS_TO_SHOW);
  }, [selectedCountry]);

  return (
    <>
      <ParcelTab>
        <ParcelTabContent>
          <ShipmentsIcon />
        </ParcelTabContent>
        <ParcelTabContent>{t('parcel')}</ParcelTabContent>
      </ParcelTab>

      <ParcelContainer>
        <Formik
          enableReinitialize={false}
          innerRef={formikRef}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          initialValues={initialValues}>
          {({
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => {
            const commonProps = {
              touched,
              errors,
              inputsToShow,
              weightConfig,
              lengthConfig,
              refs: {
                inputWeightRef,
                inputLengthRef,
                inputHeightRef,
                inputWidthRef,
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                handleInputChange(e, setFieldValue, setFieldTouched);
                setCalculateButtonClicked(false);
              },
              onFocus: setActiveField,
              onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
                const { name, value } = event.target;
                if (value.trim() === '' || Number.isNaN(Number(value))) return;

                setFieldValue(name, String(Number(value)));
              },
              onDataInputClose: handleDataInputClose,
              onDataInputLabelClick: handleDataInputLabelButtonClick,
            };

            return (
              <>
                <form
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  autoComplete='off'
                  style={{ width: '100%' }}>
                  <FormikEffectField defaultValues={defaultValues} />
                  <DataInputFields>
                    <FlexContainer
                      $width='100%'
                      $direction='column'
                      $align='start'>
                      <DataInputFieldsContainer $width='100%'>
                        <DataInputField
                          $isDisabled
                          $minWidth='228'>
                          <DataInputLabelButton>
                            {t('from')}
                          </DataInputLabelButton>
                          <FormFieldText
                            $mb='0.5rem'
                            $borderNone
                            validationMethod='always'
                            label='from'
                            name='departure_country'
                            isLabelHidden
                            type='text'
                            showValidationError={false}
                            disabled
                          />
                        </DataInputField>

                        <DividerPipe />

                        <DataInputField $minWidth='228'>
                          <InputCountryFormField
                            onSelect={(option) => {
                              setShowEstimatedDeliveryCost(false);
                              setDestinationCountry({
                                ...option,
                                zipCode: getCountryZip(option.countryCode),
                              });
                              getDeliveryLimitations();
                            }}
                            onDataInputLabelButtonClick={
                              handleDataInputLabelButtonClick
                            }
                            showInput={inputsToShow.destination_country}
                            toggleInput={(value: boolean) =>
                              setInputsToShow({
                                ...inputsToShow,
                                destination_country: value,
                              })
                            }
                            searchOptions={destinationOptions}
                            setSearchOptions={setDestinationOptions}
                            getSearchOptions={getDestinationOptions}
                            label='to'
                            name='destination_country'
                            onFocus={() =>
                              setActiveField('destination_country')
                            }
                            onBlur={() => setActiveField(null)}
                            disabled={!countryDeparture}
                          />
                        </DataInputField>

                        <DividerPipe />

                        <DimensionFlexContainer $justify='left'>
                          <MeasureInput
                            name='weight'
                            {...commonProps}
                            onChange={() => {
                              setShowEstimatedDeliveryCost(false);
                              setCalculateButtonClicked(false);
                            }}
                          />

                          <DividerPipe />

                          <MeasureInput
                            name='size_z'
                            {...commonProps}
                            onChange={() => {
                              setShowEstimatedDeliveryCost(false);
                              setCalculateButtonClicked(false);
                            }}
                          />
                        </DimensionFlexContainer>

                        <DividerPipe />
                        <DimensionFlexContainer>
                          <MeasureInput
                            name='size_x'
                            {...commonProps}
                            onChange={() => {
                              setShowEstimatedDeliveryCost(false);
                              setCalculateButtonClicked(false);
                            }}
                          />

                          <DividerPipe />

                          <MeasureInput
                            name='size_y'
                            {...commonProps}
                            onChange={() => {
                              setShowEstimatedDeliveryCost(false);
                              setCalculateButtonClicked(false);
                            }}
                          />
                        </DimensionFlexContainer>
                      </DataInputFieldsContainer>

                      <MessagesContainer
                        $direction='column'
                        $align='start'
                        $justify='start'>
                        <ValidationHandler
                          setShowEstimatedDeliveryCost={
                            setShowEstimatedDeliveryCost
                          }
                          handleDataInputClose={handleDataInputClose}
                          activeField={activeField}
                        />
                        {!showEstimatedDeliveryCost && (
                          <VolumetricWeightMessage />
                        )}
                      </MessagesContainer>
                    </FlexContainer>

                    <CalculateButton>
                      <Button
                        isLoading={isCalculating || isLoadingCountries}
                        size='large'
                        type='submit'
                        onClick={() => setCalculateButtonClicked(true)}>
                        {t('calculator_calculate')}
                      </Button>
                    </CalculateButton>
                  </DataInputFields>
                </form>
                {showEstimatedDeliveryCost && (
                  <EstimatedDeliveryCost
                    onClear={() => {
                      setInputsToShow(DEFAULT_INPUTS_TO_SHOW);
                    }}
                  />
                )}
              </>
            );
          }}
        </Formik>
      </ParcelContainer>
    </>
  );
};

export default observer(WidgetCalculatorForm);
