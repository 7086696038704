import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DropdownIcon,
  DropdownItem,
} from '../../../common/dropdown/dropdown.styles';
import {
  MoreButton,
  MoreDropdownContainer,
} from '../parcel-list-card/parcel-list-card.styles';
import { ReactComponent as MoreIcon } from '../../../assets/more.svg';
import { ICardActionItem } from '../../../routes/parcel-list/config';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../../../common/popover/popover.component';

interface Props {
  width?: string;
  items: ICardActionItem[];
  isInPortal?: boolean;
  strategy?: 'fixed' | 'absolute';
}

const MoreDropdown = ({
  items,
  width = 'fit-content',
  isInPortal = true,
  strategy = 'absolute',
}: Props) => {
  const { t } = useTranslation();

  return (
    <MoreDropdownContainer
      onClick={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
      onMouseUp={(event) => event.stopPropagation()}>
      <Popover
        strategy={strategy}
        placement='bottom-end'>
        <PopoverTrigger asChild>
          <MoreButton>
            <MoreIcon />
          </MoreButton>
        </PopoverTrigger>
        <PopoverContent isInPortal={isInPortal}>
          <Dropdown $width={width}>
            {items.map(({ value, icon, label, onClick }) => (
              <PopoverClose key={value}>
                <DropdownItem
                  $fontWeight={500}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClick();
                  }}>
                  <DropdownIcon>{icon}</DropdownIcon>
                  {t(label)}
                </DropdownItem>
              </PopoverClose>
            ))}
          </Dropdown>
        </PopoverContent>
      </Popover>
    </MoreDropdownContainer>
  );
};

export default MoreDropdown;
