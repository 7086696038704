import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Container } from '../common/container.styles';
import { Section } from '../common/section.styles';
import { SectionTitle } from '../common/typography.styles';
import {
  Header,
  SendParcel,
  ReceiveParcel,
  InstructionsCards,
  InstructionsCardImage,
  TabsContainer,
  InstructionsTextInfo,
} from './instructions.styles';
import { useStore } from '../../../stores/store';
import { Card, CardDescription, CardTitle } from '../common/card.styles';
import Button from '../../../common/button/button.component';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '../../../common/tabs/tabs.component';
import { client } from '../../../config';
import { meest } from '../../../styles/meest';

const theme = client === 'polonez' ? 'polonez' : null;

const Instructions = () => {
  const {
    marketingStore: { fetchInstructions, instructions },
  } = useStore();
  const handleStartParcelCreation = useStartParcelCreation();

  const { t } = useTranslation();

  useEffect(() => {
    fetchInstructions();
  }, [fetchInstructions]);

  const hasInstructionsSender = !!instructions?.send.items.length;
  const hasInstructionsReceiver = !!instructions?.receive.items.length;

  return (
    <Container>
      <Section>
        <TabsContainer>
          <Tabs
            size='medium'
            contentWeight={700}>
            <Header>
              <SectionTitle>{t('how_does_it_work')}</SectionTitle>
              <TabList>
                <Tab {...(theme && { theme })}>{t('send_parcel')}</Tab>
                <Tab {...(theme && { theme })}>{t('receive_parcel')}</Tab>
              </TabList>
            </Header>
            <TabPanel>
              {hasInstructionsSender && (
                <SendParcel>
                  <InstructionsCards
                    $length={instructions.send.items.length + 1}>
                    {instructions?.send.items.map(
                      ({ id, title, description, icon: Icon }) => (
                        <Card key={id}>
                          <InstructionsCardImage>
                            <Icon />
                          </InstructionsCardImage>
                          <InstructionsTextInfo>
                            <CardTitle>{t(title)}</CardTitle>
                            <CardDescription>{t(description)}</CardDescription>
                          </InstructionsTextInfo>
                        </Card>
                      )
                    )}
                    <Button
                      size='large'
                      theme={meest}
                      onClick={() => {
                        handleStartParcelCreation();
                      }}>
                      {t('create_online')}
                    </Button>
                  </InstructionsCards>
                </SendParcel>
              )}
            </TabPanel>

            <TabPanel>
              {hasInstructionsReceiver && (
                <ReceiveParcel>
                  <InstructionsCards
                    $length={instructions.receive.items.length + 1}>
                    {instructions?.receive.items.map(
                      ({ id, title, description, icon: Icon }) => (
                        <Card key={id}>
                          <InstructionsCardImage>
                            <Icon />
                          </InstructionsCardImage>
                          <InstructionsTextInfo>
                            <CardTitle>{t(title)}</CardTitle>
                            <CardDescription>{t(description)}</CardDescription>
                          </InstructionsTextInfo>
                        </Card>
                      )
                    )}
                    <Button
                      size='large'
                      theme={meest}
                      onClick={() => {
                        handleStartParcelCreation();
                      }}>
                      {t('create_online')}
                    </Button>
                  </InstructionsCards>
                </ReceiveParcel>
              )}
            </TabPanel>
          </Tabs>
        </TabsContainer>
      </Section>
    </Container>
  );
};

export default observer(Instructions);
