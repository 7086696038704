import { useState, useEffect } from 'react';

export const useTimeout = (initialTimeout: number) => {
  const [timeoutValue, setTimeoutValue] = useState(initialTimeout);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (timeoutValue > 0) {
      timeout = setTimeout(() => {
        setTimeoutValue(timeoutValue - 1);
      }, 1000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeoutValue, setTimeoutValue]);

  const resetTimeout = (newTimeout?: number) => {
    setTimeoutValue(newTimeout !== undefined ? newTimeout : initialTimeout);
  };

  return { resetTimeout, timeoutValue, setTimeoutValue };
};
