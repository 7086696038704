import { Placement } from '@floating-ui/react';
import { Hint } from '../form-field-text/form-field-text.styles';
import {
  TooltipTrigger,
  TooltipContent,
  Tooltip,
} from '../tooltip/tooltip.component';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';

interface Props {
  text: React.ReactNode;
  contentMaxWidth?: string;
  placement?: Placement;
}

const HintTooltip = ({ text, placement = 'right', contentMaxWidth }: Props) => (
  <Tooltip placement={placement}>
    <TooltipTrigger
      style={{ width: '1.6rem' }}
      asChild>
      <Hint>
        <InfoIcon />
      </Hint>
    </TooltipTrigger>
    <TooltipContent maxWidth={contentMaxWidth}>{text}</TooltipContent>
  </Tooltip>
);

export default HintTooltip;
