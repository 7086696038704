import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import ServicesSubsection, {
  SubSectionData,
} from '../../features/services/services-subsection/services-subsection.component';
import { SecuritySettingsContainer } from './security-settings.styles';
import SecuritySettingsEditForm from './security-settings-form.component';
import DeleteAccount from '../delete-account/delete-account.component';

const SecuritySettings = () => {
  const {
    userStore: { user },
    commonStore: { isSocialLogIn },
  } = useStore();
  const { t } = useTranslation();

  if (!user) return null;

  const securitySettingsSectionData: SubSectionData[] = [
    {
      label: t('your_password'),
      value: '●●●●●●●●●●●',
    },
  ];

  return (
    <SecuritySettingsContainer>
      {!isSocialLogIn && (
        <ServicesSubsection
          title={t('security_settings')}
          data={securitySettingsSectionData}
          EditForm={SecuritySettingsEditForm}
        />
      )}
      <DeleteAccount />
    </SecuritySettingsContainer>
  );
};

export default observer(SecuritySettings);
