import { MessageBus } from '../../models/apiResponse';

export type BusMessageHandler = {
  selector: (msg: MessageBus) => boolean;
  handler: (msg: MessageBus) => void;
};

export function handleBusMessages(
  busMessages: MessageBus[] | undefined,
  messageHandlers: BusMessageHandler[],
  shouldSort?: boolean
) {
  if (!busMessages) return;

  const busMessagesToProcess = shouldSort
    ? [...(busMessages ?? [])].sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    : busMessages;

  messageHandlers.forEach(({ selector, handler }) => {
    const msg = busMessagesToProcess.filter(selector)?.at(0);
    if (!msg) return;
    handler(msg);
  });
}
