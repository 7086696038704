import { ManualScreen, Manual } from '../../models/manual';

export const dummyScreens: ManualScreen[] = [
  {
    id: '1',
    imgUrl: '',
    title: '',
    description: '',
    buttonText: '',
    screenName: '',
    mobileImgUrl: '',
  },
  {
    id: '2',
    imgUrl: '',
    title: '',
    description: '',
    buttonText: '',
    screenName: '',
    mobileImgUrl: '',
  },
];

export const dummyItem: Manual = {
  flags: {
    allowedSkipButton: true,
  },
  screens: dummyScreens,
};
