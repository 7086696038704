import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import {
  DataInputLabelButton,
  MenuItemsContainer,
} from '../../widget-calculator.styles';
import FormFieldText from '../../../../../common/form-field-text/form-field-text.component';
import { MenuItems } from '../../../../../common/menu-item/menu-item.styles';
import {
  FormValues,
  IDataInputNames,
} from '../../widget-calculator-form.component';
import { ShipmentCountryInfo } from '../../../../../models/shipmentCountryInfo';
import { useStore } from '../../../../../stores/store';
import MenuItem from '../../../../../common/menu-item/menu-item.component';
import { IShipmentCountry } from '../../../../../stores/parcelCreationFlowStore';

interface Props {
  onFocus: () => void;
  onBlur: () => void;
  onDataInputLabelButtonClick: (inputName: IDataInputNames) => void;
  showInput: boolean;
  toggleInput: (arg: boolean) => void;
  searchOptions: ShipmentCountryInfo[] | undefined;
  setSearchOptions: (arg: ShipmentCountryInfo[] | undefined) => void;
  getSearchOptions: (
    filter?: string
  ) => Promise<ShipmentCountryInfo[] | undefined>;
  label: string;
  name: IDataInputNames;
  onSelect?: (option: IShipmentCountry) => void;
  disabled?: boolean;
}

const InputCountryFormField = ({
  onFocus,
  onBlur,
  onDataInputLabelButtonClick,
  showInput,
  toggleInput,
  searchOptions,
  setSearchOptions,
  getSearchOptions,
  onSelect,
  label,
  name,
  disabled,
}: Props) => {
  const {
    localizationsStore: { selectedLocalization },
  } = useStore();
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const dropdownRef = useRef<HTMLInputElement | null>(null);

  const { setFieldValue, setFieldTouched, errors } =
    useFormikContext<FormValues>();

  const [disableBlur, setDisableBlur] = useState(false);

  // TODO: change to custom hook to handle click outside of dropdown
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSearchOptions(false);
        if (!inputRef.current?.value) {
          toggleInput(false);
        }
        setDisableBlur(false);
      } else {
        setDisableBlur(true);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, toggleInput]);

  useEffect(() => {
    showInput ? inputRef?.current?.focus() : inputRef?.current?.blur();
  }, [showInput]);

  const modifyListOfDisplayedOptions = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setFieldTouched(name, true);

    if (value) {
      setSearchOptions((await getSearchOptions(value)) ?? []);
    } else {
      getSearchOptions().then(setSearchOptions);
    }
  };

  const handleSearchOptionSelected = (option: IShipmentCountry) => {
    setFieldValue(name, option.countryDetails.localized_name);
    setShowSearchOptions(false);
    onSelect?.(option);
  };

  useEffect(() => {
    getSearchOptions().then(setSearchOptions);
  }, [selectedLocalization, getSearchOptions, setSearchOptions]);

  return (
    <>
      <DataInputLabelButton
        $isInvalid={!!errors[name]}
        disabled={disabled}
        onClick={() => onDataInputLabelButtonClick(name)}>
        {t(label)}
      </DataInputLabelButton>

      <FormFieldText
        disabled={disabled}
        $mb='0.5rem'
        $borderNone
        ref={inputRef}
        validationMethod='always'
        label={t(label)}
        name={name}
        isHidden={!showInput}
        isLabelHidden
        onChange={modifyListOfDisplayedOptions}
        type='text'
        showValidationError={false}
        onFocus={() => {
          onFocus();
          setShowSearchOptions(true);
        }}
        onBlur={onBlur}
        disableBlur={disableBlur}
      />
      {showSearchOptions && searchOptions && searchOptions.length > 0 && (
        <MenuItemsContainer ref={dropdownRef}>
          <MenuItems>
            {searchOptions.map((option) => (
              <MenuItem
                key={option?.countryCode}
                onClick={() => handleSearchOptionSelected(option)}>
                {option.countryDetails.localized_name}
              </MenuItem>
            ))}
          </MenuItems>
        </MenuItemsContainer>
      )}
    </>
  );
};

export default observer(InputCountryFormField);
