import { ToastContainer } from 'react-toastify';
import { ReactComponent as CrossIcon } from '../../assets/xcross-icon.svg';
import { ToastCloseButton } from './toasts.styles';

const Toast = () => (
  <ToastContainer
    position='bottom-center'
    className='toastContainerCustom'
    hideProgressBar
    autoClose={5000}
    closeOnClick
    closeButton={({ closeToast, ariaLabel }) => (
      <ToastCloseButton
        aria-label={ariaLabel}
        type='button'
        onClick={closeToast}>
        <CrossIcon />
      </ToastCloseButton>
    )}
  />
);

export default Toast;
