import Button, { ButtonProps } from '../button/button.component';
import { SocialButtonAppearance } from '../button/types';

interface Props extends Omit<ButtonProps, 'icon' | 'appearance'> {
  appearance: SocialButtonAppearance;
}

const SocialButton = ({ appearance, ...delegated }: Props) => (
  <Button
    appearance={appearance}
    {...delegated}
    icon={{
      glyph: appearance,
    }}
  />
);

export default SocialButton;
