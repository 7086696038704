import styled, { css } from 'styled-components';
import {
  FontSizes,
  HeaderTertiary,
  LinkFooter,
} from '../../common/typography/typography.styles';
import { Container, Input } from '../../common/select/select.styles';

export const FooterContainer = styled.div`
  width: 100%;
  @media (max-width: 900px) {
    margin-top: 0;
  }
`;

export const FooterTopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 4.8rem;
  justify-items: left;
  padding: 3.2rem 2.4rem 3.6rem 2.4rem;
  max-width: 144rem;
  margin: 0 auto;

  ${Container} {
    margin-bottom: 1.2rem;
  }

  @media (max-width: 900px) {
    padding: 3.2rem 2.4rem;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    row-gap: 3.2rem;
    ${Container} {
      margin-bottom: 0%;
    }
  }

  @media (max-width: 580px) {
    grid-template-columns: 1fr;
  }
`;

export const FooterContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.namedColors.white};

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.baseColors.grey10};
  }
`;

export const FooterColumn = styled.div`
  width: 100%;

  ${HeaderTertiary} {
    margin-bottom: 8px;
  }

  ${LinkFooter} {
    display: block;
    margin-bottom: 1.2rem;

    &:last-child {
      margin-bottom: 0%;
    }
  }

  ${Input} {
    width: fit-content;
  }
`;

export const FooterColumnTitle = styled.h3`
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.colors.footerColumnTitle.color};
  font-size: ${FontSizes.normal};
  font-weight: 500;
`;

export const FooterBottomContainer = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 0.8rem 2.4rem;
  min-height: 4.8rem;
  max-width: 144rem;
  margin: 0 auto;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 2.4rem;
    padding: 2.4rem;
  }
`;

export const SocialLinksContainer = styled.span<{ $isHidden?: boolean }>`
  display: flex;
  gap: 1.6rem;
  min-width: 14rem;
  margin-right: 8.6rem;

  a {
    display: block;
    width: 24px;
    height: 24px;
    padding: 0.24rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 900px) {
    margin-right: 0;
    min-width: auto;
    a {
      width: 40px;
      height: 40px;
      padding: 0.72rem;
    }

    ${({ $isHidden }) =>
      $isHidden &&
      css`
        display: none;
      `}
  }
`;

interface StoreLinkProps {
  type: 'google' | 'apple';
}

export const StoreLink = styled.a<StoreLinkProps>`
  display: flex;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 651px) {
    width: ${({ type }) => (type === 'google' ? '10.8rem' : '9.6rem')};
    height: 3.2rem;
  }
`;

export const AppLinksContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  span {
    font-size: ${FontSizes.small};
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
  }

  @media (min-width: 900px) {
    justify-self: flex-end;
  }
`;

export const ChangeCountrySelectTitle = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-size: ${FontSizes.normal};
  line-height: 2rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.2rem;
  word-break: break-word;
`;

export const BecomeAnAgentLink = styled.a`
  color: ${({ theme }) => theme.colors.namedColors.primary};
  padding: 0.8rem 0.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  text-decoration-line: underline;
  transition-property: border-color, background-color, color, outline-color;
  transition-duration: 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.namedColors.secondaryPrimary};
  }

  &:active {
    color: ${({ theme }) => theme.colors.namedColors.main};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.namedColors.secondaryPrimary};
    outline: none;
  }

  @media (max-width: 900px) {
    display: block;
    margin-top: 1.2rem;
  }
`;
