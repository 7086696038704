import styled from 'styled-components';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';

export const PriceInput = styled.div`
  width: 100%;
`;

export const MarginContainer = styled(BaseMarginComponent('div'))`
  button {
    max-width: 160px;
  }
  button:not(:last-of-type) {
    margin-right: 2.4rem;
  }
`;

export const Header = styled(FlexContainer)`
  min-height: 32px;
  align-items: center;
`;

export const CustomHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 0 !important;
`;

export const Content = styled.div`
  --grid-container-gap: 0.4rem;

  display: grid;
  gap: 1.6rem;

  @media (min-width: 768px) {
    --grid-container-gap: 1.6rem;

    gap: 2.2rem;
  }
`;
