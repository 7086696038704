import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import ParcelParticipantPersonalForm from '../../features/parcel-creation/parcel-participant-personal/parcel-participant-personal.component';
import ParcelParticipantContactForm from '../../features/parcel-creation/parcel-participant-contact/parcel-participant-contact.component';
import ParcelParticipantPudoForm from '../../features/parcel-creation/parcel-participant-pudo/parcel-participant-pudo.component';
import DeliveryDetailsForm, {
  UserRole,
} from '../../features/parcel-creation/delivery-details-form/delivery-details-form.component';
import { removeCountryCallingCodeFromPhone } from '../personal-data/contact-details-form.component';
import CallCourierForm from '../../features/parcel-creation/call-courier-form/call-courier-form.component';
import { DEFAULT_COUNTRY } from '../../stores/localizationsStore';
import { pudoServiceTypes } from '../../utils/parcel-creation/parcel-creation-utils';

const ParcelSender = () => {
  const {
    parcelCreationStore: {
      setSenderAdditionalPhone,
      selectedSenderPudo,
      setSenderFirstName,
      setSenderLastName,
      setSenderPhone,
      setSenderPhoneCountryCode,
      setSenderEmail,
      setSenderPudo,
      shipment,
      countryDeparture,
      contactDetailsSchema,
      setSenderAdditionalPhoneCountryCode,
      useAdditionalPhone,
    },
    commonStore: { isGuestParcelCreation },
    userStore: { user },
  } = useStore();

  const { t } = useTranslation();

  useEffect(() => {
    if (!shipment) return;

    if (!isGuestParcelCreation) {
      setSenderPhone(user?.phone?.phone_number ?? '');
    }

    if (!shipment?.sender?.first_name) {
      setSenderFirstName(user?.first_name ?? '');
    }
    if (!shipment?.sender?.last_name) {
      setSenderLastName(user?.last_name ?? '');
    }
    if (!shipment?.sender?.email) {
      setSenderEmail(user?.email ?? '');
    }
  }, [
    shipment?.sender,
    user,
    setSenderFirstName,
    setSenderLastName,
    setSenderPhone,
    setSenderEmail,
    shipment,
    setSenderAdditionalPhone,
    setSenderAdditionalPhoneCountryCode,
    isGuestParcelCreation,
  ]);

  if (!shipment) return null;

  const serviceType = shipment.sender!.carrier.delivery_service.service_type;

  const initialValuesContactForm = {
    phone:
      removeCountryCallingCodeFromPhone(
        user?.phone?.phone_number,
        user?.phone?.country_code
      ) ??
      removeCountryCallingCodeFromPhone(
        shipment?.sender?.phone?.phone_number,
        shipment?.sender?.phone?.country_code
      ) ??
      '',
    phone_country_code:
      user?.phone?.country_code.toUpperCase() ??
      countryDeparture?.countryCode ??
      DEFAULT_COUNTRY.country_code,
    email: shipment.sender?.email ?? user?.email ?? '',
    ...(!useAdditionalPhone
      ? {}
      : {
          contact_phone_number: shipment.sender?.contact_phone?.phone_number
            ? removeCountryCallingCodeFromPhone(
                shipment.sender?.contact_phone?.phone_number ?? '',
                shipment.sender?.contact_phone?.country_code ?? ''
              )
            : '',
          contact_phone_country_code:
            shipment.sender?.contact_phone?.country_code ?? '',
        }),
  };

  return (
    <>
      <ParcelParticipantPersonalForm
        initialValues={{
          first_name: shipment?.sender?.first_name ?? user?.first_name ?? '',
          last_name: shipment?.sender?.last_name ?? user?.last_name ?? '',
        }}
        storeParticipantFirstName={setSenderFirstName}
        storeParticipantLastName={setSenderLastName}
      />

      <ParcelParticipantContactForm
        initialValues={initialValuesContactForm}
        validationSchema={contactDetailsSchema}
        storeParticipantAdditionalPhone={setSenderAdditionalPhone}
        storeParticipantPhone={setSenderPhone}
        storeParticipantPhoneCountryCode={setSenderPhoneCountryCode}
        storeParticipantEmail={setSenderEmail}
        storeParticipantAdditionalPhoneCountryCode={
          setSenderAdditionalPhoneCountryCode
        }
        isDropdownDisabled
        isInputDisabled={!!(user?.email && user?.phone?.phone_number)}
        showAdditionalPhoneNumber={useAdditionalPhone}
        defaultCountryCode={countryDeparture?.countryCode}
      />
      {pudoServiceTypes.includes(serviceType) ? (
        <ParcelParticipantPudoForm
          initialValues={{
            pudo: selectedSenderPudo,
          }}
          role={UserRole.sender}
          header={t('choose_pudo_sender', {
            CarrierName: shipment!.sender!.carrier.delivery_carrier_name,
          })}
          storeRecipientPudo={setSenderPudo}
        />
      ) : (
        <>
          <DeliveryDetailsForm role={UserRole.sender} />
          <CallCourierForm />
        </>
      )}
    </>
  );
};

export default observer(ParcelSender);
