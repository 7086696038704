import styled from 'styled-components';
import { theme } from '../../../styles/themes';

export const SectionTitle = styled.h2`
  font-family: Montserrat, 'Open Sans', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.14;
  letter-spacing: 1px;

  @media screen and (min-width: 650px) {
    font-size: 32px;
    line-height: 1.25;
  }
`;

export const SectionDescription = styled.p`
  font-family: Montserrat, 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: 0.5px;
  color: #2c363f;

  @media screen and (min-width: 650px) {
    font-size: 16px;
    line-height: 1.37;
  }
`;

interface SectionSubtitleProps {
  $color?: string;
}

export const SectionSubtitle = styled.p<SectionSubtitleProps>`
  font-family: Montserrat, 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ $color = theme.colors.namedColors.primary }) => $color};
  text-transform: uppercase;

  @media screen and (min-width: 650px) {
    font-size: 16px;
    line-height: 1.37;
  }
`;
