import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import FormFieldText from '../../common/form-field-text/form-field-text.component';
import Button from '../../common/button/button.component';
import { EditFormProps } from '../../features/services/services-subsection/services-subsection.component';
import PlacesFormFieldSearch from '../../common/form-field-places-search/form-field-places-search.component';
import { AddressDataValues } from '../../models/addressDataValues';
import {
  createValidationRule,
  isInitialValuesEqualCurrentValues,
} from '../../utils/forms/forms.utils';
import CountriesFormFieldSearch from '../../common/form-field-countries-search/form-field-countries-search.component';
import useNavigateNewLocale from '../../hooks/use-navigate-to-new-locale.hook';
import { AccountSettingsForm } from '../../common/form/form.styles';
import {
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_LATIN_WITH_NUMBERS,
} from '../../constants/validation';
import { getSetting } from '../../utils/countries/countries.utils';

const validationSchema = Yup.object({
  country: Yup.string().required('this_field_cannot_be_empty!'),
  full_address: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  region: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  city: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  post_code: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  street: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  building: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  apartment: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  section: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_NUMBERS
  ).notRequired(),
  buzz_code: createValidationRule(
    VALIDATION_RULE_IS_NOT_CYRILLIC
  ).notRequired(),
  note: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).notRequired(),
});

type IFormValues = AddressDataValues & { full_address: string };

const MyAddressEditForm = ({ onCancel }: EditFormProps) => {
  const {
    userStore: { user, updateAddress },
    localizationsStore: { findCountryByCode },
    placesSearchStore: { setUserPlaceDetails, setPlaceDetails },
    commonStore: { toastSuccess },
  } = useStore();

  const { t } = useTranslation();
  const navigateToNewLocale = useNavigateNewLocale();

  useEffect(() => {
    // On the first run init place values in PlacesSearchStore based on current user.
    // Then they will be updated based on response from Places API
    setUserPlaceDetails();

    // Clear PlacesSearchStore when form is closed
    return () => {
      setPlaceDetails(null);
    };
  }, [setPlaceDetails, setUserPlaceDetails]);

  const handleFormSubmit = async (
    formValues: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    navigateToNewLocale({
      countryCode: formValues.country.toLocaleLowerCase(),
    });
    const success = await updateAddress(formValues);
    success && toastSuccess(t('data_updated_successfully'));
    onCancel && onCancel();
    formikHelpers.resetForm();
  };

  const [selectedCountry, setSelectedCountry] = useState(
    () => findCountryByCode(user!.country)!
  );

  const additionalFieldsSetting = getSetting(
    selectedCountry?.settings || [],
    'account_settings',
    'additional-fields'
  );

  const shouldShowAdditionalFields = additionalFieldsSetting === 'show';

  const initialValues = useMemo(
    () =>
      ({
        country: selectedCountry.country_code,
        full_address: user?.address?.full_address || '',
        region: user?.address?.region || '',
        city: user?.address?.city || '',
        post_code: user?.address?.post_code || '',
        street: user?.address?.street || '',
        building: user?.address?.building || '',
        section: user?.address?.section || '',
        apartment: user?.address?.apartment || '',
        buzz_code: user?.address?.buzz_code || '',
        note: user?.address?.note || '',
      }) as IFormValues,
    [
      selectedCountry.country_code,
      user?.address?.apartment,
      user?.address?.building,
      user?.address?.buzz_code,
      user?.address?.city,
      user?.address?.full_address,
      user?.address?.note,
      user?.address?.post_code,
      user?.address?.region,
      user?.address?.section,
      user?.address?.street,
    ]
  );

  return (
    <Formik
      validateOnMount
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}>
      {({
        handleSubmit,
        isValid,
        values,
        isSubmitting,
        setValues,
        validateField,
      }) => (
        <AccountSettingsForm
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <div className='inputs'>
            <CountriesFormFieldSearch
              initialCountry={selectedCountry}
              name='country'
              label={t('country')}
              formikValidateField={validateField}
              onCountrySelected={(c) => {
                const newCountry = findCountryByCode(c);
                if (selectedCountry.country_code !== newCountry?.country_code) {
                  // Resets places search store in case country was changed and therefore initial values and as a result whole form state is reset.
                  setPlaceDetails(null);
                  setValues({
                    country: '',
                    full_address: '',
                    region: '',
                    city: '',
                    post_code: '',
                    street: '',
                    building: '',
                    section: '',
                    apartment: '',
                    buzz_code: '',
                    note: '',
                  });
                }
                setSelectedCountry(newCountry!);
              }}
            />
            <PlacesFormFieldSearch
              selectedCountryCode={selectedCountry?.country_code}
              name='full_address'
              placeholder=''
              label={t('address')}
              formikValidateField={validateField}
              setValues={({ zipCode: post_code, address, ...props }) => {
                setValues({
                  ...values,
                  ...props,
                  full_address: address,
                  post_code,
                  country: selectedCountry?.country_code,
                });
              }}
            />

            <FormFieldText
              maxLength={100}
              name='region'
              placeholder=''
              label='province/state/region'
            />

            <FormFieldText
              maxLength={100}
              name='city'
              placeholder=''
              label='city'
            />

            <FormFieldText
              maxLength={10}
              name='post_code'
              placeholder=''
              label={t(
                shouldShowAdditionalFields
                  ? 'zip-code'
                  : 'create_parcel_zip_code'
              )}
            />

            <FormFieldText
              maxLength={100}
              name='street'
              placeholder=''
              label='street'
            />

            <FormFieldText
              maxLength={10}
              name='building'
              label='building'
            />

            <FormFieldText
              maxLength={10}
              name='apartment'
              label='apartment'
              isOptional
            />

            {shouldShowAdditionalFields && (
              <>
                <FormFieldText
                  maxLength={10}
                  name='section'
                  label='section'
                  isOptional
                />

                <FormFieldText
                  name='buzz_code'
                  label='buzz_code'
                  isOptional
                />
              </>
            )}

            <FormFieldText
              name='note'
              placeholder=''
              label='note'
              isOptional
            />
          </div>
          <div className='buttons'>
            <Button
              appearance='secondary'
              disabled={isSubmitting}
              onClick={() => onCancel && onCancel()}>
              {t('cancel')}
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={
                !isValid ||
                isInitialValuesEqualCurrentValues(initialValues, values)
              }
              type='submit'>
              {t('save_changes')}
            </Button>
          </div>
        </AccountSettingsForm>
      )}
    </Formik>
  );
};

export default observer(MyAddressEditForm);
