import styled, { css } from 'styled-components';
import { TextBody } from '../../../common/typography/typography.styles';
import { ServicesSubSectionContainer } from '../../services/services-subsection/services-subsection.styles';

const listStyles = css`
  padding: 0;
  margin: 0;
  padding-left: 20px;
`;

export const Messages = styled.div`
  display: grid;
  gap: 1.2rem;
  margin-top: 1.2rem;

  @media screen and (min-width: 650px) {
    margin-top: 2.4rem;
  }

  &:empty {
    display: none;
  }
`;

export const DimensionsTooltipList = styled.ul`
  ${listStyles}
`;

export const VolumetricWeightWarningText = styled(TextBody).attrs({
  as: 'div',
})`
  color: #e82a53;
  font-weight: 400;
  ul {
    ${listStyles};
    padding-top: 10px;
  }
`;

export const Container = styled(ServicesSubSectionContainer)`
  form {
    display: grid;
    gap: 1.6rem;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      > *:first-child {
        grid-column: 1 / -1;
      }
      gap: 2rem 2.4rem;
    }
  }
`;
