import { client, WLClient } from '../config';
import { meest } from './meest';
import { polonez } from './polonez';

const themes: Record<WLClient, any> = {
  meest,
  polonez,
};

export const theme = themes[client];
