import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import StarRating from '../star-rating/star-rating.component';
import { useStore } from '../../../stores/store';
import { generateMyRoute } from '../../../utils/uri/uri.utils';
import { AppRoute } from '../../../constants/route';
import { SERVICE_LEAVE_FEEDBACK } from '../../../routes/leave-feedback/config';
import { useModal } from '../../../common/modal';
import { Modals } from '../../../constants/modals';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  feedbackShipmentId: number | null;
}

const LeaveFeedbackDialog = ({
  isOpen = false,
  onClose,
  feedbackShipmentId,
}: Props) => {
  const {
    feedbackStore: {
      rate,
      selectedCategory,
      shipmentId,
      comment,
      setRate,
      setShipmentId,
      submitFeedback,
      getLimit,
      resetFeedback,
      setSendFrom,
    },
    commonStore: { toastSuccess, isGuestParcelCreation },
    navStore: { setSelectedService },
  } = useStore();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal } = useModal();

  useEffect(() => {
    if (feedbackShipmentId) {
      setShipmentId(feedbackShipmentId);
    }
  }, [feedbackShipmentId, setShipmentId]);

  const handleRateChange = (index: number) => {
    setRate(index + 1);
  };

  const handleApprove = async () => {
    if (rate > 3) {
      const response = await submitFeedback({
        rate,
        categories: selectedCategory ? [selectedCategory] : [],
        shipment_id: shipmentId,
        comment,
        send_from: 'after_created_parcel',
      });

      await getLimit();

      const fullMessage = response?.response_message;

      if (!fullMessage) {
        return;
      }
      const [primaryMessage, ...rest] = fullMessage.split(/(?<=!)\s/);
      const secondaryMessage = rest.join(' ');

      toastSuccess({
        primaryMessage,
        secondaryMessage,
      });

      onClose();
      return;
    }

    setSendFrom('after_created_parcel');

    if (rate <= 3 && isGuestParcelCreation) {
      openModal({
        id: Modals.CREATE_ACCOUNT,
        name: Modals.CREATE_ACCOUNT,
        props: {
          isFeedbackModal: true,
        },
      });

      onClose();
      return;
    }

    navigate(generateMyRoute(AppRoute.LEAVE_FEEDBACK));
    setSelectedService(SERVICE_LEAVE_FEEDBACK);

    onClose();
  };

  const handleModalClose = () => {
    resetFeedback();
    onClose();
  };

  return (
    <ModalDialog
      isDanger={false}
      approveBtnContent={t('leave_feedback_submit')}
      cancelBtnContent={t('leave_feedback_not_now')}
      isOpen={isOpen}
      onApprove={handleApprove}
      onClose={handleModalClose}>
      <HeaderSecondary>{t('leave_feedback_raiting_text')}</HeaderSecondary>
      <StarRating
        name='rate'
        rating={rate}
        onRate={handleRateChange}
      />
    </ModalDialog>
  );
};

export default observer(LeaveFeedbackDialog);
