import Slider from 'rc-slider';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SliderBlock,
  SliderMark,
  SliderMarksBlock,
} from './pudo-filter.styles';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import {
  Measure,
  convertKgToLb,
  convertKmToMiles,
  getMeasureUnits,
} from '../../../utils/parcel-measurements.ts/parcel-measurements-utils';
import {
  FilterButtonsBlock,
  FilterOption,
  FilterOptionsBlock,
  FilterSection,
  FilterSectionTitle,
  FiltersHeading,
  FiltersList,
} from '../../../common/filters/filters.styles';
import { BackToListBtn } from '../pudo-list-item/pudo-list-item.styles';
import { isObjectsEqual } from '../../../utils/generic/generic.utils';

interface Props {
  onClose: () => void;
}

const convertArrayToMeasures = (arr: number[], converter: any) =>
  arr.map((item) => converter(item));

const MIN_DISTANCE = 1;
const MAX_DISTANCE_KM = 100;
const MORE_THAN_WEIGHT = 30;
const distanceKmFilters = [5, 10, 50, MAX_DISTANCE_KM];
const distanceLbFilters = convertArrayToMeasures(
  distanceKmFilters,
  convertKmToMiles
);

const PudoFilterContent = ({ onClose }: Props) => {
  const {
    userStore: { user },
    parcelCreationStore: {
      parcelDimensions,
      pudoFilterTimeWeight,
      pudoFilterDistance,
      setPudoFilterTimeWeight,
      setPudoFilterDistance,
    },
  } = useStore();

  const isLbMeasure = user?.measures === Measure.LB_IN;
  const maxSliderValue = isLbMeasure
    ? convertKmToMiles(MAX_DISTANCE_KM)
    : MAX_DISTANCE_KM;

  const { t } = useTranslation();

  const [selectedFilterTimeWeight, selectFilterTimeWeight] =
    useState(pudoFilterTimeWeight);
  const [selectedFilterDistance, selectFilterDistance] = useState<
    number | undefined
  >(pudoFilterDistance ?? maxSliderValue);

  const isAnyFilterSelected =
    selectedFilterTimeWeight !== undefined ||
    selectedFilterDistance !== undefined;

  const isAnyFilterChanged =
    !isObjectsEqual(selectedFilterTimeWeight, pudoFilterTimeWeight) ||
    selectedFilterDistance !== pudoFilterDistance;

  const applyFilters = () => {
    onClose();

    setPudoFilterTimeWeight(selectedFilterTimeWeight);
    setPudoFilterDistance(selectedFilterDistance);
  };

  const clearFilters = () => {
    selectFilterDistance(undefined);
    selectFilterTimeWeight(undefined);
  };

  const onWeightFilterSelected = (weightFilter: number) => {
    selectFilterTimeWeight({
      ...selectedFilterTimeWeight,
      weight: weightFilter,
    });
  };

  const onWorkingTimeFilterSelected = (workingTimeFilter: {
    open24_7?: boolean;
    open_on_weekends?: boolean;
    open_now?: boolean;
  }) => {
    selectFilterTimeWeight({
      ...selectedFilterTimeWeight,
      ...workingTimeFilter,
    });
  };

  const onDistanceFilterSelected = (distance: number) => {
    selectFilterDistance(distance);
  };

  const weightKmFilters = [
    5,
    10,
    15,
    30,
    parcelDimensions!.weight > 30 ? parcelDimensions!.weight : 31,
  ];
  const weightFilters = isLbMeasure
    ? convertArrayToMeasures(weightKmFilters, convertKgToLb)
    : weightKmFilters;
  const distanceFilters = isLbMeasure ? distanceLbFilters : distanceKmFilters;
  const measureUnits = getMeasureUnits(user?.measures);
  const weightUnit = t(measureUnits.weight);
  const distanceUnit = t(measureUnits.distance);

  return (
    <>
      <BackToListBtn>
        <Button
          fullWidth={false}
          $pl='0'
          $mb='3.2rem'
          icon={{ glyph: 'chevron-left' }}
          appearance='text'
          size='large'
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}>
          {t('back_to_list')}
        </Button>
      </BackToListBtn>
      <FiltersHeading>{t('filters')}</FiltersHeading>
      <FiltersList>
        <FilterSection>
          <FilterSectionTitle>{t('weight')}</FilterSectionTitle>
          <FilterOptionsBlock $withLastChildSpan2Cols>
            {weightFilters.map((w) => {
              const moreThan = isLbMeasure
                ? convertKgToLb(MORE_THAN_WEIGHT)
                : MORE_THAN_WEIGHT;
              return (
                <FilterOption
                  key={w}
                  $isSelected={selectedFilterTimeWeight?.weight === w}
                  // disabled={parcelDimensions!.weight > w}
                  disabled
                  onClick={() => onWeightFilterSelected(w)}>
                  {w <= moreThan
                    ? `${t('up_to')} ${w} ${weightUnit}`
                    : `${t('more_than')} ${moreThan} ${weightUnit}`}
                </FilterOption>
              );
            })}
          </FilterOptionsBlock>
        </FilterSection>
        <FilterSection>
          <FilterSectionTitle>{t('working_hours')}</FilterSectionTitle>
          <FilterOptionsBlock $withLastChildSpan2Cols>
            <FilterOption
              disabled
              onClick={() =>
                onWorkingTimeFilterSelected({
                  open24_7: !selectedFilterTimeWeight?.open24_7,
                })
              }
              $isSelected={selectedFilterTimeWeight?.open24_7}>
              24/7
            </FilterOption>
            <FilterOption
              disabled
              onClick={() =>
                onWorkingTimeFilterSelected({
                  open_now: !selectedFilterTimeWeight?.open_now,
                })
              }
              $isSelected={selectedFilterTimeWeight?.open_now}>
              {t('open_now')}
            </FilterOption>
            <FilterOption
              disabled
              onClick={() =>
                onWorkingTimeFilterSelected({
                  open_on_weekends: !selectedFilterTimeWeight?.open_on_weekends,
                })
              }
              $isSelected={selectedFilterTimeWeight?.open_on_weekends}>
              {t('works_on_weekends')}
            </FilterOption>
          </FilterOptionsBlock>
        </FilterSection>
        <FilterSection>
          <FilterSectionTitle>{t('distance')}</FilterSectionTitle>
          <FilterOptionsBlock>
            {distanceFilters.map((d) => (
              <FilterOption
                key={d}
                onClick={() => onDistanceFilterSelected(d)}
                $isSelected={selectedFilterDistance === d}>
                {t('up_to')} {d} {distanceUnit}
              </FilterOption>
            ))}
          </FilterOptionsBlock>
          <SliderBlock>
            <Slider
              min={0}
              max={maxSliderValue}
              value={selectedFilterDistance}
              onChange={(distance) =>
                onDistanceFilterSelected(distance as number)
              }
            />
            <SliderMarksBlock>
              <SliderMark>
                {MIN_DISTANCE} {distanceUnit}
              </SliderMark>
              <SliderMark>{`${selectedFilterDistance || maxSliderValue} ${distanceUnit}`}</SliderMark>
            </SliderMarksBlock>
          </SliderBlock>
        </FilterSection>
      </FiltersList>
      <FilterButtonsBlock>
        <Button
          type='button'
          disabled={!isAnyFilterChanged}
          onClick={(e) => {
            e.stopPropagation();
            applyFilters();
          }}>
          {t('apply')}
        </Button>
        <Button
          disabled={!isAnyFilterSelected}
          type='button'
          appearance='text'
          onClick={(e) => {
            e.stopPropagation();
            clearFilters();
          }}>
          {t('clear_all')}
        </Button>
      </FilterButtonsBlock>
    </>
  );
};

export default observer(PudoFilterContent);
