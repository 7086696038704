import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import {
  FontSizes,
  HeaderSecondary,
} from '../../../common/typography/typography.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';

export const Wrapper = styled.div`
  padding: 40px 0;

  @media screen and (min-width: 650px) {
    padding: 80px 0;
  }
`;

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  padding: 3.2rem 3.2rem 4rem 3.2rem;
  border-radius: 2rem;
  @media screen and (max-width: 650px) {
    padding: 2.4rem 1.6rem 3.2rem;
  }
`;

export const TopDirectionsItems = styled(FlexContainer)`
  margin-top: 2.4rem;
  gap: 2.4rem;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 650px) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 4.8rem;
  }
`;

export const TopDirectionsHeader = styled(HeaderSecondary)`
  font-size: 2.4rem;
  line-height: 3rem;
  @media screen and (max-width: 500px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
`;

export const TopDirectionsStyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.baseColors.blue100};
  font-size: ${FontSizes.medium};
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 2.2rem;
`;
