import React from 'react';
import { Container } from './tag.styles';
import { client } from '../../config';
import { isMeest } from '../../utils/client/client.utils';

export type TagSize = 'mini' | 'small' | 'medium';
export type TagType = 'filled' | 'semi-filled' | 'text';
export type TagColor =
  | 'blue'
  | 'sky-blue'
  | 'green'
  | 'yellow'
  | 'red'
  | 'grey'
  | 'white'
  | 'polonez';

interface Props {
  size?: TagSize;
  type?: TagType;
  color?: TagColor;
  textColor?: string;
  children: React.ReactNode;
}

const Tag = ({
  size = 'medium',
  type = 'semi-filled',
  color = isMeest(client) ? 'sky-blue' : 'polonez',
  textColor,
  children,
}: Props) => (
  <Container
    $size={size}
    $type={type}
    $textColor={textColor}
    $color={color}>
    {children}
  </Container>
);

export default Tag;
