import styled, { css } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';
import { getEncodedCheckMarkIconSvg } from '../../utils/svg/svg.utils';

interface PopupProps {
  $isOpened?: boolean;
  $width?: string;
  $position?: 'left' | 'right';
}

export const Dropdown = styled.div<PopupProps>`
  display: block;
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  width: ${({ $width = 'fit-content' }) => $width};
  padding: 8px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  z-index: 1000;
  font-family: Montserrat, 'Open Sans', sans-serif;
  min-width: 100%;
`;

export const DropdownIcon = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 1.42em;
  height: 1.42em;
  color: #75777b;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const DropdownItems = styled.div`
  display: grid;
  gap: 4px;
`;

type DropdownItemSize = 'medium' | 'large';

interface ItemProps {
  $isSelected?: boolean;
  $showCheckmark?: boolean;
  $size?: DropdownItemSize;
  $fontWeight?: 400 | 500;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const getSizeConfig = (size: DropdownItemSize) =>
  ({
    medium: css`
      font-size: 1.2rem;
    `,
    large: css`
      font-size: 1.4rem;
    `,
  })[size];

export const DropdownItem = styled.button<ItemProps>`
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  border: none;
  background: none;
  width: 100%;
  font: inherit;
  font-family: 'Noto Color Emoji', Montserrat !important;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  outline: none;
  ${({ $size = 'large' }) => getSizeConfig($size)};
  transition: background-color 0.2s;
  font-weight: ${({ $fontWeight = 400, $isSelected }) =>
    $isSelected ? 500 : $fontWeight};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme.colors.namedColors.primary};

      ${DropdownIcon} {
        color: ${({ theme }) => theme.colors.namedColors.primary};
      }
    `}

  ${({ $isSelected, $showCheckmark }) =>
    $isSelected &&
    $showCheckmark &&
    css`
      --gap: 1.2rem;
      --size: 1.2rem;

      padding-right: calc(var(--gap) + var(--size) + 8px);
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        width: var(--size);
        height: var(--size);
        background-image: ${({ theme }) =>
          `url(${getEncodedCheckMarkIconSvg(theme.colors.namedColors.primary)})`};
        background-size: contain;
        background-repeat: no-repeat;
      }
    `}

  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};
    border-radius: 6px;
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colors.passwordValidationMarker.backgroundColor};
  }
`;
