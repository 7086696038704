import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import {
  ListRow,
  TrackingNumberText,
  CellContainer,
  LastCell,
  MoreDropdownCell,
} from './parcel-list-row.styles';
import Tag from '../../../common/tag/tag.component';
import { FontSizes } from '../../../common/typography/typography.styles';
import { TimeLabel } from '../../parcel-creation/pudo-list-item/pudo-list-item.styles';
import { IParcelListPathValue } from '../../../routes/parcel-list/config';
import TruncatedTextWithTooltip from '../../../common/truncated-text-with-tooltip/truncated-text-with-tooltip.component';
import {
  FavoriteButton,
  TrackingNumber,
} from '../parcel-list-card/parcel-list-card.styles';
import MoreDropdown from '../more-dropdown/more-dropdown.component';
import { useParcelItem } from '../hooks/use-parcel-item.hook';
import { ReactComponent as StarIcon } from '../../../assets/star.svg';
import DetailsSidebar from '../details-sidebar/details-sidebar.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../../common/tooltip/tooltip.component';
import PaymentButton from '../payment-button/payment-button.component';
import { useStore } from '../../../stores/store';

interface Props {
  parcel: ParcelListShipment;
  routeName: IParcelListPathValue['path'];
  onToggleFavorite: (value: boolean) => void;
  onToggleArchive: (value: boolean) => void;
  onCopy: () => void;
  isGuestUser?: boolean;
}

const ParcelListRow = ({
  parcel,
  routeName,
  onToggleFavorite,
  onToggleArchive,
  onCopy,
  isGuestUser,
}: Props) => {
  const {
    parcelListStore: { isLoadingShipmentApi },
  } = useStore();

  const { tracking_number: trackingNumber, is_favorite: isFavorite } = parcel;
  const {
    actions,
    statusConfig,
    favoritesLabel,
    senderAddress,
    recipientAddress,
    senderName,
    recipientName,
    createdDate,
    deliveryDate,
    itemDescriptions,
    sidebarName,
    isLoadingParcel,
    canPay,
    totalPrice,
    handleClick,
    handleKeyDown,
  } = useParcelItem({
    parcel,
    routeName,
    onToggleFavorite,
    onToggleArchive,
    onCopy,
  });

  const { t } = useTranslation();

  const isNoPayButton = !canPay;
  const moreDropdownRef = useRef<HTMLDivElement | null>(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!moreDropdownRef.current) return;

    const observer = new IntersectionObserver(
      ([e]) => {
        if (!e.isIntersecting) {
          setCount((prev) => prev + 1);
        }
      },
      { threshold: 1 }
    );
    observer.observe(moreDropdownRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <ListRow
        $hasPayButton={!isNoPayButton}
        tabIndex={0}
        onKeyDown={(event) => handleKeyDown(event, parcel.id)}
        onClick={() => handleClick(parcel.id)}>
        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='16rem'>
          <TrackingNumber $noMargin>
            {!isGuestUser && (
              <FavoriteButton
                onClick={(event) => event.stopPropagation()}
                onMouseUp={(event) => {
                  event.stopPropagation();
                  onToggleFavorite(!isFavorite);
                }}
                onKeyDown={(event) => event.stopPropagation()}
                onKeyUp={(event) => {
                  if (event.key === ' ' || event.key === 'Enter') {
                    onToggleFavorite(!isFavorite);
                  }
                }}
                $isFavorite={isFavorite}
                aria-label={t(favoritesLabel)}>
                <Tooltip placement='bottom-start'>
                  <TooltipTrigger>
                    <StarIcon />
                  </TooltipTrigger>
                  <TooltipContent>{t(favoritesLabel)}</TooltipContent>
                </Tooltip>
              </FavoriteButton>
            )}
            <TrackingNumberText>{trackingNumber}</TrackingNumberText>
          </TrackingNumber>
        </CellContainer>

        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='12rem'>
          <TimeLabel>{createdDate}</TimeLabel>
        </CellContainer>

        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='12rem'>
          <TimeLabel>{deliveryDate}</TimeLabel>
        </CellContainer>

        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='16rem'>
          <Tag
            size='small'
            type={statusConfig.type || 'semi-filled'}
            color={statusConfig.color}>
            {t(statusConfig.text)}
          </Tag>
        </CellContainer>
        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='24rem'>
          <TruncatedTextWithTooltip
            textType='label'
            fontSize={FontSizes.small}>
            {itemDescriptions}
          </TruncatedTextWithTooltip>
        </CellContainer>
        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='16rem'>
          <TruncatedTextWithTooltip
            textType='label'
            fontSize={FontSizes.small}>
            {senderAddress}
          </TruncatedTextWithTooltip>
        </CellContainer>
        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='16rem'>
          <TruncatedTextWithTooltip
            textType='label'
            fontSize={FontSizes.small}>
            {recipientAddress}
          </TruncatedTextWithTooltip>
        </CellContainer>
        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='16rem'>
          <TruncatedTextWithTooltip
            textType='label'
            fontSize={FontSizes.small}>
            {senderName}
          </TruncatedTextWithTooltip>
        </CellContainer>
        <CellContainer
          $isLoading={isLoadingShipmentApi}
          $width='16rem'>
          <TruncatedTextWithTooltip
            textType='label'
            fontSize={FontSizes.small}>
            {recipientName}
          </TruncatedTextWithTooltip>
        </CellContainer>
        <LastCell
          $isLoading={isLoadingShipmentApi}
          $width='17.2rem'>
          {!isNoPayButton && (
            <PaymentButton
              contentStyle='thin'
              size='mini'
              parcel={parcel}
            />
          )}

          {parcel.status === 'paid' &&
            !canPay &&
            !parcel.flags?.payment_in_process && (
              <TruncatedTextWithTooltip
                textType='label'
                fontSize={FontSizes.small}>
                {`${t('paid')} ${totalPrice}`}
              </TruncatedTextWithTooltip>
            )}
        </LastCell>
        <MoreDropdownCell
          ref={moreDropdownRef}
          $width='4rem'>
          <MoreDropdown
            strategy='fixed'
            isInPortal={false}
            key={`${count}-${parcel.id}`}
            items={actions}
          />
        </MoreDropdownCell>
      </ListRow>
      <DetailsSidebar
        name={sidebarName}
        senderAddress={senderAddress}
        recipientAddress={recipientAddress}
        createdDate={createdDate}
        deliveryDate={deliveryDate}
        onToggleFavorite={onToggleFavorite}
        menuItems={actions}
        isLoadingParcel={isLoadingParcel}
      />
    </>
  );
};

export default ParcelListRow;
