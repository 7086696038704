import styled, { css, DefaultTheme } from 'styled-components';
import { BaseMarginComponent } from '../base-margin/base-margin.component';

enum FontSizes {
  small = '1.2rem',
  normal = '1.4rem',
  medium = '1.6rem',
  mediumPlus = '1.8rem',
  mediumBig = '2.4rem',
  big = '2.8rem',
  huge = '4rem',
}

type HeadingSize =
  | 'small'
  | 'normal'
  | 'medium'
  | 'mediumPlus'
  | 'mediumBig'
  | 'big'
  | 'huge';

// enum Colors {
//   blue = '#005FAD', // btn background
//   blue2 = '#0070CC', // tab bottom border
//   blue3 = '#0061AF', // links
//   blue4 = '#008CFF', // checbox checked,
//   blue5 = '#0261af', // widget calculator background,
//   blue6 = '#0070cc', // widget calculator parcel tab content,
//   blueLight3 = '#B3DDFF',
//   blueLight4 = '#F4F7FC',
//   blueLight5 = '#CCE8FF',
//   blueDisabled = '#C1DBF0', // disabled button
//   gray = '#5A6673', // normal text
//   gray2 = '#75777B', // tab title, checkbox unchecked border
//   gray3 = '#96999E', // nav text, nav btn active
//   gray4 = '#B7B9BD', // nav btn hover
//   gray5 = '#B7B9BD', // Parcle step title inactive
//   grayLight = '#7B8085', // label text, checkbox descr. text
//   grayLight2 = '#96999E', // nav icons
//   grayLight3 = '#DCDDE0', // input borders
//   grayLight4 = '#E0E3E7', // placeholder text
//   grayLight5 = '#EAECEE', // password validation marker background
//   grayLight6 = '#F5F6F7', // nav background
//   grayLight7 = '#F1F2F4',
//   grayDark = '#334150', // headings
//   grayDark2 = '#2C363F', // text in inputs, sidebar title, footer header3
//   grayDark3 = '#2C2C2C', // text copyright
//   grayDark4 = '#3C3C434D',
//   grayDark5 = '#2C363F',
//   white = '#FFFFFF',
//   red = '#E82A53',
//   redTransparent = '#ed5576e2',
//   lightRed = '#FE6975',
//   green = '#57C278',
//   greenTransparent = '#68c886e3',
//   greenLight = '#47CE72',
//   yellow = '#FFCB45',
//   yellow2 = '#FFBE0A',
//   yellow3 = '#FFFAEB',
// }

const HeaderMain = styled.h1`
  font-size: ${FontSizes.big};
  font-weight: 700;
  line-height: 3.2rem;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.headerMain.color};
`;

const getHeadingSize = (size: HeadingSize) =>
  ({
    small: css`
      font-size: ${FontSizes.small};
    `,
    normal: css`
      font-size: ${FontSizes.normal};
    `,
    medium: css`
      font-size: ${FontSizes.medium};
      line-height: 1.375;
    `,
    mediumPlus: css`
      font-size: ${FontSizes.mediumPlus};
      line-height: 1.333;
    `,
    mediumBig: css`
      font-size: ${FontSizes.mediumBig};
      line-height: 1.25;
    `,
    big: css`
      font-size: ${FontSizes.big};
    `,
    huge: css`
      font-size: ${FontSizes.huge};
    `,
  })[size];

const HeaderSecondary = styled.h2<{
  $size?: HeadingSize;
  $mb?: string;
}>`
  margin-bottom: ${({ $mb = '0' }) => $mb};
  line-height: 2.4rem;
  font-weight: 700;
  ${({ $size = 'mediumPlus' }) => getHeadingSize($size)}
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

interface Header3Props {
  $color?: 'faded';
}

const HeaderTertiary = styled.h3<Header3Props>`
  font-size: ${FontSizes.normal};
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ $color, theme }) =>
    $color ? theme.colors.baseColors.grey100 : theme.colors.baseColors.grey150};
`;

const HeaderFourth = styled.h3<Header3Props>`
  font-size: ${FontSizes.mediumBig};
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  color: ${({ color, theme }) =>
    color ? theme.colors.baseColors.grey100 : theme.colors.baseColors.grey150};
`;

interface TextBodyProps {
  $fontSize?: FontSizes;
  $color?: string;
  $fontWeight?: 400 | 500 | 700 | 'inherit';
  $textAlign?: string;
}

const TextBodyParagraph = styled.span`
  display: block;
  margin-top: 8px;
`;

const TextBody = styled(BaseMarginComponent('p'))<TextBodyProps>`
  font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
  font-weight: ${({ $fontWeight = 500 }) => $fontWeight};
  line-height: 2rem;
  color: ${({ $color, theme }) => $color || theme.colors.normalText.color};
  text-align: ${({ $textAlign = 'left' }) => $textAlign};
  word-wrap: break-word;
  letter-spacing: 0.5px;

  ${TextBodyParagraph} {
    color: ${({ theme }) => theme.colors.baseColors.grey150};
  }
`;

const TextResendCode = styled.p`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

interface TextLabelProps {
  $fontSize?: FontSizes;
  $color?: string;
}

const TextLabel = styled.label<TextLabelProps>`
  display: block;
  font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
  font-weight: 500;
  line-height: 2rem;
  color: ${({ $color, theme }) => $color || theme.colors.textLabel.color};
  letter-spacing: 0.5px;
`;

const TextInput = css`
  font-size: ${FontSizes.normal};
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-family: Montserrat, 'Open Sans', sans-serif;
`;
const TextInputPlaceholder = css`
  font-size: ${FontSizes.normal};
  font-weight: 400;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  letter-spacing: 0.5px;
  font-family: Montserrat, 'Open Sans', sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonText = css`
  font-size: ${FontSizes.normal};
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.namedColors.white};
`;

const ButtonTextThin = css`
  font-size: ${FontSizes.normal};
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.namedColors.white};
`;

const TextCheckbox = styled.span`
  font-size: ${FontSizes.small};
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

const TextValidation = css`
  font-size: ${FontSizes.small};
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.textLabel.color};
`;

const TextSearchOption = styled.span`
  font-size: ${FontSizes.medium};
  font-weight: 400;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

interface CaptionProps {
  $color?: 'red' | 'green';
}

const getTextPopupColor = (
  $color: CaptionProps['$color'],
  theme: DefaultTheme
) =>
  ({
    red: theme.colors.baseColors.red100,
    green: theme.colors.textPopupColorSuccess.color,
    default: theme.colors.baseColors.grey150,
  })[$color || 'default'];

const TextPopup = styled.span<CaptionProps>`
  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.6rem;
  color: ${({ $color, theme }) => getTextPopupColor($color, theme)};
`;

interface TextMenuProps {
  $color: 'normal' | 'active' | 'nav';
}

const getTextMenuColor = (
  $color: TextMenuProps['$color'],
  theme: DefaultTheme
) =>
  ({
    normal: theme.colors.baseColors.grey150,
    active: theme.colors.baseColors.blue150,
    nav: theme.colors.baseColors.grey100,
  })[$color];

const TextMenu = styled.span<TextMenuProps>`
  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ $color, theme }) => getTextMenuColor($color, theme)};
`;

interface TextServicesMenuProps {
  $color?: 'gray' | 'red';
}

const TextServicesMenu = styled.span<TextServicesMenuProps>`
  font-size: ${FontSizes.normal};
  font-weight: 500;
  line-height: 2rem; /* 142.857% */
  letter-spacing: 0.5px;
  color: ${({ $color = 'red', theme }) =>
    $color === 'red'
      ? theme.colors.baseColors.red100
      : theme.colors.baseColors.grey150};
  white-space: nowrap;
`;

interface TextServicesDataProps {
  $type: 'label' | 'data';
}

const getTypography = (
  label: TextServicesDataProps['$type'],
  theme: DefaultTheme
) =>
  ({
    label: {
      color: theme.colors.baseColors.grey100,
    },
    data: {
      color: theme.colors.baseColors.grey150,
    },
  })[label];

const TextServicesData = styled.span<TextServicesDataProps>`
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
  font-size: ${FontSizes.medium};
  ${({ $type, theme }) => {
    const { color } = getTypography($type, theme);

    return css`
      color: ${color};
    `;
  }};
`;

const TextServicesContact = styled.span`
  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  display: block;
`;

const TextCopyright = styled.span`
  font-size: ${FontSizes.small};
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  order: 1;

  @media (min-width: 900px) {
    justify-self: flex-start;
    order: 0;
    width: max-content;
  }
`;

const TextParcelDeliveryOptionTabTitle = css`
  font-size: ${FontSizes.normal};
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
`;

interface LinkProps {
  $bold?: boolean;
  $fontSize?: string;
  $color?: string;
  $fontWeight?: string;
}

const Link = styled.a<LinkProps>`
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
  font: inherit;
  padding: 0;
  font-size: ${({ $fontSize = FontSizes.normal }) => $fontSize};
  font-weight: ${({ $fontWeight, $bold }) =>
    $bold ? 600 : $fontWeight || 500};
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ $color, theme }) => $color || theme.colors.link.color};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s;

  &:hover,
  &:active {
    border-bottom-color: ${({ theme }) => theme.colors.link.color};
  }
`;

interface Props {
  underline?: 'hover' | 'default';
}

const LinkSmall = styled.a<Props>`
  &:link,
  &:visited {
    font-size: ${FontSizes.small};
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.link.color};
    text-decoration: none;
    transition: border-bottom-color 0.2s;
    border-bottom: 1px solid
      ${({ underline = 'default', theme }) =>
        underline === 'default' ? theme.colors.link.color : 'transparent'};
  }

  &:hover,
  &:active {
    border-bottom-color: ${({ underline = 'default', theme }) =>
      underline === 'default' ? 'transparent' : theme.colors.link.color};
  }
`;

const LinkFooter = styled.a`
  &:link,
  &:visited {
    font-size: ${FontSizes.normal};
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.baseColors.grey150};
    text-decoration: none;
    /* border-bottom: 1px solid transparent;
    transition: all .2s; */
  }

  &:hover,
  &:active {
    /* border-bottom: 1px solid ${({ theme }) =>
      theme.colors.textCopyright.color}; */
  }
`;

export {
  HeaderMain,
  HeaderSecondary,
  HeaderTertiary,
  HeaderFourth,
  TextBody,
  TextBodyParagraph,
  TextResendCode,
  TextLabel,
  TextCheckbox,
  TextInput,
  TextInputPlaceholder,
  TextValidation,
  TextPopup,
  ButtonText,
  ButtonTextThin,
  TextMenu,
  TextServicesMenu,
  TextServicesContact,
  TextServicesData,
  TextCopyright,
  TextSearchOption,
  TextParcelDeliveryOptionTabTitle,
  Link,
  LinkSmall,
  LinkFooter,
  FontSizes,
};
