import { forwardRef, Ref } from 'react';
import { InfoTextContainer } from '../input/input.styles';
import { MarginProps } from '../base-margin/base-margin.component';
import { TValidationMethod } from '../../utils/forms/forms.utils';
import FormField from '../input/form-field.component';
import { useFormField } from '../../hooks/use-form-field.hook';

export type MaxLength = 10 | 50 | 100 | 255;

export const MAX_CHAR_LENGTH = 255;

interface Props {
  isOptional?: boolean;
  name: string;
  placeholder?: string;
  label: string;
  isLabelHidden?: boolean;
  labelEndElement?: React.ReactNode;
  type?: React.HTMLInputTypeAttribute;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  hint?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: MaxLength;
  disabled?: boolean;
  validationMethod?: TValidationMethod;
  infoText?: string; // can control whether to display a text (e.g. $ sign) in the right side of an input
  $borderNone?: boolean;
  showValidationError?: boolean;
  prefix?: {
    element: React.ReactNode;
    isClickable?: boolean;
  };
  suffix?: {
    element: React.ReactNode;
    isClickable?: boolean;
  };
  disableBlur?: boolean;
  isHidden?: boolean;
  showSkeleton?: boolean;
}

const FormFieldText = (
  {
    name,
    placeholder,
    label,
    type = 'text',
    $borderNone,
    infoText,
    inputMode = 'text',
    isHidden,
    isLabelHidden,
    hint,
    maxLength = MAX_CHAR_LENGTH,
    disabled = false,
    validationMethod = 'default',
    onChange,
    onFocus,
    onBlur,
    showValidationError = true,
    isOptional,
    labelEndElement,
    prefix,
    suffix,
    disableBlur,
    showSkeleton,
  }: Props & MarginProps,
  ref: Ref<HTMLInputElement>
) => {
  const { field, formikContext, meta, setIsTyping, isInvalid, t } =
    useFormField({
      name,
      validationMethod,
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTyping(true);
    field.onChange(event);
    onChange && onChange(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    formikContext.setFieldValue(name, event.target.value.trim());
    if (disableBlur) return;
    field.onBlur(event);
    onBlur && onBlur(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus && onFocus(event);
  };

  return (
    <FormField
      {...field}
      ref={ref}
      type={type}
      placeholder={placeholder ? t(placeholder) : undefined}
      inputMode={inputMode}
      value={field.value ?? ''}
      disabled={disabled}
      name={name}
      isOptional={isOptional}
      optionalText={t('optional')}
      hint={hint}
      label={t(label)}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      errorMessage={showValidationError && isInvalid ? t(meta.error!) : ''}
      isHidden={isHidden}
      isLabelHidden={isLabelHidden}
      maxLength={maxLength}
      labelEndElement={labelEndElement}
      prefix={prefix}
      suffix={{
        element: (
          <>
            {suffix?.element}
            {infoText && <InfoTextContainer>{t(infoText)}</InfoTextContainer>}
          </>
        ),
        isClickable: suffix?.isClickable,
      }}
      showSkeleton={showSkeleton}
    />
  );
};

export default forwardRef(FormFieldText);
