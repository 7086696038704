export type WLClient = 'meest' | 'polonez';

const clients: Record<WLClient, WLClient> = {
  meest: 'meest',
  polonez: 'polonez',
};

const getClient = (): WLClient => {
  if (process.env.REACT_APP_CLIENT) {
    return process.env.REACT_APP_CLIENT as WLClient;
  }

  const url = window.location.origin;

  const client = Object.keys(clients).find((key) => url.includes(key)) as
    | WLClient
    | undefined;

  if (!client) {
    throw new Error('Client not found');
  }

  return client;
};

export const client = getClient();

const getClientEnvConfig = (wlClient: WLClient) => {
  const upperCaseClient = wlClient.toUpperCase();
  return {
    REACT_APP_API_URL: process.env[`REACT_APP_${upperCaseClient}_API_URL`],
    REACT_APP_FIREBASE_API_KEY:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_API_KEY`],
    REACT_APP_FIREBASE_AUTH_DOMAIN:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_AUTH_DOMAIN`],
    REACT_APP_FIREBASE_PROJECT_ID:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_PROJECT_ID`],
    REACT_APP_FIREBASE_STORAGE_BUCKET:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_STORAGE_BUCKET`],
    REACT_APP_FIREBASE_MSG_SENDER_ID:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_MSG_SENDER_ID`],
    REACT_APP_FIREBASE_APP_ID:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_APP_ID`],
    REACT_APP_FIREBASE_MEASUREMENT_ID:
      process.env[`REACT_APP_${upperCaseClient}_FIREBASE_MEASUREMENT_ID`],
    REACT_APP_RECAPTCHA_SITE_KEY:
      process.env[`REACT_APP_${upperCaseClient}_RECAPTCHA_SITE_KEY`],
    REACT_APP_ENVIRONMENT:
      process.env[`REACT_APP_${upperCaseClient}_ENVIRONMENT`],
    REACT_APP_ANALYTICS_DISABLED:
      process.env[`REACT_APP_${upperCaseClient}_ANALYTICS_DISABLED`],
    POEDITOR_API_KEY: process.env[`POEDITOR_${upperCaseClient}_API_KEY`],
    POEDITOR_PROJECT_ID: process.env[`POEDITOR_${upperCaseClient}_PROJECT_ID`],
    REACT_APP_GOOGLE_MAP_API_KEY:
      process.env[`REACT_APP_${upperCaseClient}_GOOGLE_MAP_API_KEY`],
  };
};

export const envConfig = getClientEnvConfig(client);
