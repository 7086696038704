import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect, useCallback } from 'react';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../features/services/services-subsection/services-subsection.styles';
import { CONFIRM_NUMBER_DIGITS_AMOUNT } from '../../features/authentication/sign-up/confirm-phone-number.component';
import OtpInput from '../../common/otp-input/otp-input.component';
import { useStore } from '../../stores/store';
import { theme } from '../../styles/themes';

const ParcelVerificationPhone = () => {
  const { t } = useTranslation();
  const {
    parcelCreationStore: {
      shipment,
      setPhoneVerificationCode,
      isPhoneCodeValid,
      sendVerificationMessage,
    },
  } = useStore();

  const handleSendVerification = useCallback(async () => {
    try {
      await sendVerificationMessage({
        country_code: shipment!.sender!.phone!.country_code!,
        phone_number: shipment!.sender!.phone!.phone_number!,
      });
    } catch (error) {
      console.error(error);
    }
  }, [sendVerificationMessage, shipment]);

  useEffect(() => {
    handleSendVerification();
  }, [handleSendVerification]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>
        {t('confirm_the_phone_number')}
      </ServicesSubSectionHeader>
      <OtpInput
        handleSendAgain={handleSendVerification}
        textAlign='left'
        isInvalid={!isPhoneCodeValid}
        numOfDigits={CONFIRM_NUMBER_DIGITS_AMOUNT}
        onChange={(val: string) => setPhoneVerificationCode(val)}
        titleColor={theme.colors.baseColors.grey100}
        title={t('enter_code_from_sms')}
      />
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelVerificationPhone);
