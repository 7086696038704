import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Container } from './back-to-top.styles';
import { ReactComponent as ArrowDownIcon } from '../../../assets/arrow-down-blue.svg';
import { useStore } from '../../../stores/store';

const BackToTop = () => {
  const {
    navStore: { openedRightSidebars },
  } = useStore();

  const previousScrollY = useRef(window.scrollY);
  const isClicked = useRef(false);
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) {
        setShowBackToTop(false);
        isClicked.current = false;
      } else if (currentScrollY < previousScrollY.current) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }

      previousScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [openedRightSidebars]);

  const scrollToTop = () => {
    if (isClicked.current) return;
    isClicked.current = true;
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Container
      type='button'
      onClick={scrollToTop}
      onTouchStart={scrollToTop}
      $showBackToTop={showBackToTop && openedRightSidebars.length === 0}>
      <ArrowDownIcon />
    </Container>
  );
};

export default observer(BackToTop);
