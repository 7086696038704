import styled, { css } from 'styled-components';
import {
  FontSizes,
  HeaderTertiary,
  HeaderSecondary,
} from '../typography/typography.styles';

export const Filters = styled.div``;

export const FiltersHeading = styled(HeaderSecondary)`
  text-align: center;
  margin-left: 0 !important;
  margin-bottom: 14px !important;
`;

export const FiltersList = styled.div`
  display: grid;
  gap: 12px;
`;

export const FilterSection = styled.div`
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  padding: 2.4rem 2.4rem 3.2rem 2.4rem;
  border-radius: 1.2rem;
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
`;

export const FilterSectionTitle = styled(HeaderTertiary)`
  margin-bottom: 12px;
`;

interface FilterOptionProps {
  $isSelected?: boolean;
}

export const FilterOption = styled.button<FilterOptionProps>`
  font: inherit;
  background: none;
  font-size: ${FontSizes.medium};
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  border-radius: 1rem;
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  transition-property: color, border-color, background-color;
  transition-duration: 0.2s;

  @media (hover: hover) and (pointer: fine) {
    &:hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.namedColors.main};
      border-color: ${({ theme }) => theme.colors.namedColors.main};
    }
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      color: ${({ theme }) => theme.colors.namedColors.primary};
      border-color: ${({ theme }) => theme.colors.namedColors.primary};
    `}

  &:disabled {
    color: ${({ theme }) => theme.colors.baseColors.grey80};
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};
    cursor: not-allowed;
  }
`;

interface FilterOptionsBlockProps {
  $withLastChildSpan2Cols?: boolean;
}

export const FilterOptionsBlock = styled.div<FilterOptionsBlockProps>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;

  ${({ $withLastChildSpan2Cols }) =>
    $withLastChildSpan2Cols &&
    css`
      ${FilterOption}:last-child {
        grid-column: span 2;
      }
    `}
`;

export const FilterButtonsBlock = styled.div`
  margin-top: 2.4rem;
  display: grid;
  gap: 1.6rem;
`;
