import { useField, useFormikContext } from 'formik';
import {
  getCountries,
  getCountryCallingCode,
  CountryCode,
} from 'libphonenumber-js/max';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontSizes } from '../typography/typography.styles';
import {
  FieldContainer,
  InputContainer,
  LabelTooltipContainer,
} from '../form-field-text/form-field-text.styles';
import { FieldPhoneContainer } from './form-field-phone.styles';
import PhoneCodeDropdown, {
  CountryOption,
} from './phone-code-dropdown/phone-code-dropdown.component';
import { countryInfoList } from '../../assets/data/countryInfo';
import { MarginProps } from '../base-margin/base-margin.component';
import {
  TValidationMethod,
  getIsFieldInvalid,
} from '../../utils/forms/forms.utils';
import FieldLabel from '../input/field-label.component';
import InputMessage from '../input-message/input-message.component';
import HintTooltip from '../hint-tooltip/hint-tooltip.component';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';

interface Props {
  phoneFieldName: string;
  codeFieldName: string;
  placeholder: string;
  label?: string;
  labelColor?: string;
  isDropdownDisabled?: boolean;
  isInputDisabled?: boolean;
  validationMethod?: TValidationMethod;
  defaultCountryCode?: string;
  tooltipText?: string;
}

export const getCountryName = (code: string) =>
  countryInfoList.find(
    (info) => info.countryCode.toLocaleLowerCase() === code.toLocaleLowerCase()
  )?.countryName;

export const getCountryCode = (name: string) =>
  countryInfoList.find(
    (info) => info.countryName.toLocaleLowerCase() === name.toLocaleLowerCase()
  )?.countryCode;

export const getCountryCallingCodeWrapper = (countryCode: string) =>
  getCountryCallingCode(countryCode.toLocaleUpperCase() as CountryCode);

const countryOptions = getCountries().map((countryCode) => ({
  countryName: getCountryName(countryCode) ?? '',
  unicodeFlag: getUnicodeFlagIcon(countryCode) ?? '',
  callingCode: getCountryCallingCodeWrapper(countryCode) ?? '',
  countryCode: countryCode ?? '',
}));

const FormFieldPhone = ({
  phoneFieldName,
  codeFieldName,
  placeholder,
  label,
  labelColor,
  $mb,
  isDropdownDisabled = false,
  isInputDisabled = false,
  validationMethod = 'default',
  defaultCountryCode,
  tooltipText,
}: Props & MarginProps) => {
  const [field, meta] = useField(phoneFieldName); // phone without country calling code part
  const [codeField] = useField(codeFieldName); // country code (e.g. US, AL)
  const { setFieldValue } = useFormikContext();
  const isMobile = useIsMobile();

  const { t } = useTranslation();

  useEffect(() => {
    if (defaultCountryCode && !codeField.value) {
      setFieldValue(codeFieldName, defaultCountryCode);
    }
  }, [defaultCountryCode, codeField.value, codeFieldName, setFieldValue]);

  const onCountryCodeSelected = (country: CountryOption) => {
    setFieldValue(codeFieldName, country.countryCode);
  };
  const [isTyping, setIsTyping] = useState(false);

  const isInvalid = getIsFieldInvalid({ validationMethod, meta, isTyping });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(field.name, event.target.value.replace(/[^0-9]+/g, ''));
    setIsTyping(true);
  };

  return (
    <FieldContainer $mb={$mb ?? '2.4rem'}>
      <InputContainer
        $fontSize={FontSizes.normal}
        $padding='1rem 1.2rem'
        $error={isInvalid}
        $disabled={isInputDisabled}>
        <LabelTooltipContainer>
          <FieldLabel
            inputId={phoneFieldName}
            isOptional={!label}
            labelColor={labelColor}
            optionalText='Optional'>
            {label}
          </FieldLabel>
          {tooltipText && (
            <HintTooltip
              contentMaxWidth={isMobile ? '20rem' : '30rem'}
              placement='top'
              text={tooltipText}
            />
          )}
        </LabelTooltipContainer>

        <FieldPhoneContainer>
          <PhoneCodeDropdown
            selectedCountryCode={codeField.value}
            onSelected={onCountryCodeSelected}
            items={countryOptions}
            isDropdownDisabled={isDropdownDisabled}
          />
          <input
            type='text'
            placeholder={placeholder}
            required
            inputMode='tel'
            {...field}
            formNoValidate
            autoComplete='false'
            onChange={handleChange}
            disabled={isInputDisabled}
          />
        </FieldPhoneContainer>
      </InputContainer>

      {isInvalid && (
        <InputMessage type='error'>{t(meta.error || '')}</InputMessage>
      )}
    </FieldContainer>
  );
};

export default FormFieldPhone;
