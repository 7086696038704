import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { InputSearchWithSidebarContainer } from '../../../common/input-search-with-sidebar/input-search-with-sidebar.styles';
import { InputContainer } from '../../../common/form-field-text/form-field-text.styles';
import {
  ArrowIconContainer,
  InputSelect,
} from '../../../common/input-select-with-sidebar/input-select-with-sidebar.styles';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';
import {
  HeaderContent,
  InputPlaceholderGrayDark,
  SidebarContentWrapper,
  DateContainer,
  TimeContainer,
  RadioWrapper,
} from './call-courier-with-sidebar.styles';
import Sidebar from '../../../common/sidebar-right/sidebar.component';
import { useStore } from '../../../stores/store';
import Spinner from '../../../common/spinner/spinner.component';
import Button from '../../../common/button/button.component';
import { HeaderDivider } from '../../header/header-user/header-user.styles';
import { TextLabel } from '../../../common/typography/typography.styles';
import CustomRadioButton from '../../../common/radio-button/custom-radio-button.component';
import {
  isCourierPickupEmpty,
  formatDisplayDate,
  formatDisplayTime,
  getNearestDate,
} from './helpers';
import { TimeSlot } from '../../../models/pickupWindowsModels';

const CallCourierWithSidebar = () => {
  const {
    navStore: { toggleSidebarByName },
    parcelCreationStore: {
      setCourierPickupValues,
      shipment,
      dateInfo,
      isLoadingApiCallCourier,
    },
  } = useStore();

  const { t } = useTranslation();

  const shipmentPickupInfo = shipment?.sender?.courier_pickup;

  const [sidebarPickupInfo, setSidebarPickupInfo] =
    useState(shipmentPickupInfo); // Defines what is selected in the opened sidebar

  const dates =
    dateInfo?.map(({ date }) => ({
      original: date,
      display: formatDisplayDate(date, t),
    })) || [];

  const timeSlots =
    dateInfo?.find((parsed) => parsed.date === sidebarPickupInfo?.date)
      ?.time_slots || [];

  useEffect(() => {
    if (!isCourierPickupEmpty(shipmentPickupInfo) || !dateInfo) return;

    const nearestDate = getNearestDate(dateInfo, t);

    if (!nearestDate) return;

    const info = {
      date: nearestDate.date,
      time_from: nearestDate.time_slots[0].from,
      time_to: nearestDate.time_slots[0].to,
    };

    setSidebarPickupInfo(info);

    setCourierPickupValues(info);
  }, [dateInfo, shipmentPickupInfo, t, setCourierPickupValues]);

  const handleDateChange = (date: string) => {
    if (!dateInfo) return;

    setSidebarPickupInfo((prev) => ({ ...prev, date }));
  };

  const handleTimeChange = ({ from, to }: TimeSlot) => {
    setSidebarPickupInfo((prev) => ({
      ...prev,
      time_from: from,
      time_to: to,
    }));
  };

  const handleChooseClick = () => {
    if (sidebarPickupInfo) {
      setCourierPickupValues(sidebarPickupInfo);
    }

    toggleSidebarByName('choose_date_time');
  };

  const displayDate = shipmentPickupInfo
    ? formatDisplayDate(shipmentPickupInfo.date!, t)
    : '';
  const displayTime = shipmentPickupInfo
    ? formatDisplayTime(
        shipmentPickupInfo.time_from!,
        shipmentPickupInfo.time_to!
      )
    : '';

  return (
    <InputSearchWithSidebarContainer $mb='0'>
      <div>
        <TextLabel>{t('create_parcel_courier_courier_arrival')}</TextLabel>
        <InputContainer
          $error={false}
          $maxInputHeight='20rem'>
          <InputSelect
            tabIndex={0}
            onClick={() => toggleSidebarByName('choose_date_time')}>
            <ArrowIconContainer>
              <ArrowRightIcon />
            </ArrowIconContainer>
            {shipmentPickupInfo && (
              <InputPlaceholderGrayDark>
                {displayDate} {displayTime}
              </InputPlaceholderGrayDark>
            )}
          </InputSelect>
        </InputContainer>
      </div>

      <Sidebar
        name='choose_date_time'
        sidebarWidth='48rem'
        withBlurredBackground
        onClose={() => setSidebarPickupInfo(shipmentPickupInfo)}
        header={t('create_parcel_courier_date_time')}>
        {isLoadingApiCallCourier ? (
          <Spinner />
        ) : (
          <SidebarContentWrapper>
            <DateContainer>
              <HeaderContent>{t('create_parcel_courier_date')}</HeaderContent>
              <RadioWrapper>
                {dates.map((date) => (
                  <CustomRadioButton<string>
                    key={date.original}
                    name='courierDate'
                    value={date.original}
                    label={date.display}
                    isSelected={date.original === sidebarPickupInfo?.date}
                    onSelect={handleDateChange}
                  />
                ))}
              </RadioWrapper>
            </DateContainer>

            <HeaderDivider />

            <TimeContainer>
              <HeaderContent>{t('create_parcel_courier_time')}</HeaderContent>
              <RadioWrapper>
                {timeSlots.map((slot) => (
                  <CustomRadioButton<TimeSlot>
                    key={slot.from}
                    name='courierTime'
                    value={slot}
                    label={formatDisplayTime(slot.from, slot.to)}
                    isSelected={
                      slot.from === sidebarPickupInfo?.time_from &&
                      slot.to === sidebarPickupInfo.time_to
                    }
                    onSelect={handleTimeChange}
                  />
                ))}
              </RadioWrapper>
              <Button
                type='submit'
                $mt='2.4rem'
                onClick={handleChooseClick}>
                {t('choose')}
              </Button>
            </TimeContainer>
          </SidebarContentWrapper>
        )}
      </Sidebar>
    </InputSearchWithSidebarContainer>
  );
};

export default observer(CallCourierWithSidebar);
