import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import agent from '../api/agent';
import { MarketingBanners } from '../models/marketingStoreModels';
import { Advantages } from '../models/advantagesModels';
import { Partners } from '../models/PartnersModels';
import { ReactComponent as FastDeliveryIcon } from '../assets/main-page/advantages/fast-delivery.svg';
import { ReactComponent as ConvenientTarrifsIcon } from '../assets/main-page/advantages/convenient-tarrifs.svg';
import { ReactComponent as AddressDeliveryIcon } from '../assets/main-page/advantages/address-delivery.svg';
import { ReactComponent as TrackingParcelIcon } from '../assets/main-page/advantages/tracking-parcel.svg';
import { ReactComponent as BringParcelIcon } from '../assets/main-page/instructions/bring-your-parcel.svg';
import { ReactComponent as CreateParcelIcon } from '../assets/main-page/instructions/create-parcel.svg';
import { ReactComponent as ParcelIcon } from '../assets/main-page/instructions/parcel.svg';
import { ReactComponent as PhoneIcon } from '../assets/main-page/instructions/phone_and_number.svg';
import { ReactComponent as PayForDeliveryIcon } from '../assets/main-page/instructions/pay-for-delivery.svg';
import { ReactComponent as SMSPushIcon } from '../assets/main-page/instructions/sms-push.svg';
import { ReactComponent as FedexIcon } from '../assets/main-page/partners/fedex.svg';
import { ReactComponent as CanadapostIcon } from '../assets/main-page/partners/canadapost.svg';
import { ReactComponent as CanparIcon } from '../assets/main-page/partners/canpar.svg';
import { ReactComponent as MeestPostIcon } from '../assets/main-page/partners/meest-post.svg';
import { ReactComponent as MeestShoppingIcon } from '../assets/main-page/partners/meest-shopping.svg';
import { ReactComponent as PurolatorIcon } from '../assets/main-page/partners/purolator.svg';
import { ReactComponent as RosanIcon } from '../assets/main-page/partners/rosan.svg';
import { ReactComponent as USPSIcon } from '../assets/main-page/partners/usps.svg';
import { ReactComponent as UPSIcon } from '../assets/main-page/partners/ups.svg';
import newsImageSrc from '../assets/main-page/news/image.jpg';
import { Instructions } from '../models/instructionsModels';
import { INews } from '../models/newsModels';
import { ServicesModel } from '../models/servicesModel';
import { FooterLinks } from '../models/footerLinksModel';
// import { ReactComponent as DeliveryServiceOf } from '../assets/main-page/services/delivery-service.svg';
// import { ReactComponent as DeliveryToUkraine } from '../assets/main-page/services/delivery-to-ukraine.svg';
// import { ReactComponent as DeliveryFromUkraine } from '../assets/main-page/services/delivery-from-ukraine.svg';
import { TopDirections } from '../models/topDirectionsModels';
import { store } from './store';
import { handleError } from '../utils/generic/generic.utils';

const defaultText = 'main_page_advantages_default_text';

const items = [
  {
    id: 'fast-delivery',
    title: 'main_page_advantages_fast_delivery',
    text: 'main_page_advantages_fast_delivery_excerpt',
    icon: FastDeliveryIcon,
  },
  {
    id: 'convenient-tarrifs',
    title: 'main_page_advantages_convenient_tariffs',
    text: 'main_page_advantages_convenient_tariffs_excerpt',
    icon: ConvenientTarrifsIcon,
  },
  {
    id: 'address-delivery',
    title: 'main_page_advantages_address_delivery',
    text: 'main_page_advantages_address_delivery_excerpt',
    icon: AddressDeliveryIcon,
  },
  {
    id: 'tracking-parcel',
    title: 'main_page_advantages_tracking_parcel',
    text: 'main_page_advantages_tracking_parcel_excerpt',
    icon: TrackingParcelIcon,
  },
];

// strings here are ids to translation
const instructions = {
  send: {
    items: [
      {
        id: 'create-parcel',
        title: 'create',
        icon: CreateParcelIcon,
        description: 'a_parcel_in_the_mobile_app_or_on_the_website',
      },
      {
        id: 'pay-for-delivery',
        title: 'pay',
        icon: PayForDeliveryIcon,
        description: 'for_the_delivery',
      },
      {
        id: 'pack-the-parcel',
        title: 'pack',
        icon: ParcelIcon,
        description: 'the_parcel_in_a_box_or_bag',
      },
      {
        id: 'bring-your-parcel',
        title: 'drop_off',
        icon: BringParcelIcon,
        description: 'the_packed_parcel_to_the_post_office_operator',
      },
    ],
  },
  receive: {
    items: [
      {
        id: 'get-sms-notification',
        title: 'sms_message',
        icon: SMSPushIcon,
        description: 'receive_a_notification_about_parcel',
      },
      {
        id: 'provide-phone-number',
        title: 'provide',
        icon: PhoneIcon,
        description: 'your_phone_number_or_parcel_number',
      },
      {
        id: 'pick-up',
        title: 'pick_up',
        icon: BringParcelIcon,
        description: 'your_parcel_via_qr_code',
      },
    ],
  },
};

const topDirectionsItems = [
  {
    name: 'Ukraine',
    directions: ['Ukraine → Canada', 'Ukraine → USA'],
    directionsUk: ['Україна → Канада', 'Україна → США'],
    directionsRu: ['Украина → Канада', 'Украина → США'],
  },
  {
    name: 'United States of America',
    directions: ['USA → Ukraine', 'USA → Canada'],
    directionsUk: ['США → Україна', 'США → Канада'],
    directionsRu: ['США → Украина', 'США → Канада'],
  },
  {
    name: 'Canada',
    directions: ['Canada → Ukraine'],
    directionsUk: ['Канада → Україна'],
    directionsRu: ['Канада → Украина'],
  },
];

export const PartnersSlides = [
  {
    name: 'Ukraine',
    icons: [RosanIcon, MeestPostIcon, MeestShoppingIcon],
  },
  {
    name: 'United States of America',
    icons: [UPSIcon, USPSIcon, FedexIcon],
  },
  {
    name: 'Canada',
    icons: [PurolatorIcon, CanadapostIcon, UPSIcon, CanparIcon, UPSIcon],
  },
];

const news = [
  {
    id: '1',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '2',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '3',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '4',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
  {
    id: '5',
    imgSrc: newsImageSrc,
    title: 'Delivery to Ukraine',
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus. ',
    date: 'dd.mm.yy',
    readingTime: '~Reading time',
    url: '#',
  },
];
// const servicesArray = [
//   {
//     title: '1Delivery to Ukraine',
//     text: '1Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryToUkraine,
//   },
//   {
//     title: 'Delivery from Ukraine',
//     text: '2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryFromUkraine,
//   },
//   {
//     title: 'Delivery service of goods from online stores in Europe and the USA',
//     text: '3Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryServiceOf,
//   },
//   {
//     title: '4Delivery to Ukraine',
//     text: '4Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryToUkraine,
//   },
//   {
//     title: '5Delivery from Ukraine',
//     text: '5Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryFromUkraine,
//   },
//   {
//     title:
//       '6Delivery service of goods from online stores in Europe and the USA',
//     text: '6Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et libero quis lacus pulvinar vehicula egestas ut lacus.',
//     icon: DeliveryServiceOf,
//   },
// ];

const commonDownloadLinks = {
  appStore: 'https://apps.apple.com/app/id6480413214',
  playMarket: 'https://play.google.com/store/apps/details?id=com.example.app',
};

const defaultFooterLinks = {
  countryCode: 'default',
  aboutUs: '',
  privacyPolicy: '',
  termOfUse: '',
  deliveryRules: '',
  internationalShipping: '',
  allServices: '',
  contactCenter: '',
  customerSupport: '',
  faq: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  becomeAgentLink: '',
  appStore: commonDownloadLinks.appStore,
  playMarket: commonDownloadLinks.playMarket,
};

const hardcodedFooterLinks = [
  {
    countryCode: 'us',
    aboutUs: 'https://us.meest.com/about-us-in-us',
    privacyPolicy: 'https://us.meest.com/privacy-policy-us',
    termOfUse: 'https://us.meest.com/services/service/compliance',
    deliveryRules: 'https://us.meest.com/important-information-compliance',
    internationalShipping:
      'https://us.meest.com/services/service/from-us-to-international',
    allServices: 'https://us.meest.com/services',
    contactCenter: 'https://us.meest.com/contact-center',
    customerSupport: 'https://us.meest.com/contact-center',
    faq: 'https://us.meest.com/faq',
    facebook: 'https://www.facebook.com/meestamerica/',
    instagram: 'https://www.instagram.com/meestusa/',
    linkedin: 'https://www.linkedin.com/company/meestmerica/',
    twitter: '',
    becomeAgentLink: 'https://us.meest.com/become-an-agent-us',
    appStore: commonDownloadLinks.appStore,
    playMarket: commonDownloadLinks.playMarket,
  },
  {
    countryCode: 'ca',
    aboutUs: 'https://ca.meest.com/about-us-in-canada',
    privacyPolicy: 'https://ca.meest.com/privacy-policy-canada',
    termOfUse:
      'https://www.mymeest.ca/StaticContent/Terms.htm?_gl=1*16q4d83*_gcl_au*MTg5MTExMDgwOC4xNjkxMDY5NDE0&_ga=2.40303015.449870188.1693816429-1297318442.1674658910',
    deliveryRules: 'https://ca.meest.com/conditions-of-carriage',
    internationalShipping:
      'https://ca.meest.com/services/service/parcels-delivery-from-canada',
    allServices: 'https://ca.meest.com/services',
    contactCenter: 'https://ca.meest.com/contact-center',
    customerSupport: 'https://ca.meest.com/contact-center',
    faq: 'https://ca.meest.com/faq',
    facebook: 'https://www.facebook.com/MeestCanada',
    instagram: 'https://www.instagram.com/meestcanada/',
    linkedin: '',
    twitter: '',
    becomeAgentLink: 'https://ca.meest.com/become-an-agent',
    appStore: commonDownloadLinks.appStore,
    playMarket: commonDownloadLinks.playMarket,
  },
  {
    countryCode: 'ua',
    aboutUs: 'https://ua.meest.com/pro-kompaniu',
    privacyPolicy:
      'https://ua.meest.com/polityka-konfidentsiynosti-meest-ukrayina',
    termOfUse:
      'https://ua.meest.com/storage/pdf/standartni_umovi_servisu.pdf?_t=1711016159',
    deliveryRules:
      'https://ua.meest.com/storage/images/tariffs/pdf/table/Вимоги%20до%20упакування.pdf?_t=1693487196',
    internationalShipping:
      'https://ua.meest.com/services/mizhnarodna-dostavka/mezhdunarodnaya-dostavка',
    allServices: 'https://ua.meest.com/services/international-delivery',
    contactCenter: 'https://ua.meest.com/contact-center',
    customerSupport: 'https://ua.meest.com/contact-center',
    faq: 'https://ua.meest.com/faq',
    facebook: 'https://www.facebook.com/MeestPoshta',
    instagram: 'https://www.instagram.com/meest_poshta/',
    linkedin: '',
    twitter: '',
    becomeAgentLink: '',
    appStore: commonDownloadLinks.appStore,
    playMarket: commonDownloadLinks.playMarket,
  },
  { ...defaultFooterLinks },
];

export default class MarketingStore {
  banners: MarketingBanners[] | undefined = undefined;
  advantages: Advantages | undefined = undefined;
  instructions: Instructions | undefined = undefined;
  partners: Partners[] | undefined = undefined;
  topDirections: TopDirections[] | undefined = undefined;
  news: INews[] | undefined = undefined;
  services: ServicesModel[] | undefined = undefined;
  footerLinks: FooterLinks | undefined = undefined;

  isLoadingBannersApi: boolean = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'MarketingStore',
      properties: ['isLoadingBannersApi'],
      storage: window.localStorage,
    });
  }

  fetchNews = async () => {
    try {
      runInAction(() => {
        // @ts-ignore
        this.news = news;
      });
    } catch (error) {
      handleError(error);
    }
  };

  fetchInstructions = async () => {
    try {
      runInAction(() => {
        // @ts-ignore
        this.instructions = instructions;
      });
      return defaultText;
    } catch (error) {
      handleError(error);
    }
  };

  fetchAdvantages = async () => {
    try {
      runInAction(() => {
        this.advantages = {
          items,
          defaultText,
        };
      });
      return defaultText;
    } catch (error) {
      handleError(error);
    }
  };

  fetchBanners = async () => {
    try {
      this.isLoadingBannersApi = true;

      const { selectedCountry } = store.localizationsStore;
      if (selectedCountry) {
        const banners = await agent.Banners.banners(
          selectedCountry.country_code
        );

        runInAction(() => {
          this.banners = banners;
        });
        return this.banners;
      }
    } catch (error) {
      handleError(error);
    } finally {
      runInAction(() => {
        this.isLoadingBannersApi = false;
      });
    }
  };

  fetchPartners = async () => {
    if (this.partners) return;

    try {
      runInAction(() => {
        this.partners = PartnersSlides;

        return this.partners;
      });
    } catch (error) {
      handleError(error);
    }
  };

  getAllBanners = async () => this.fetchBanners();

  fetchTopDirections = async () => {
    try {
      runInAction(() => {
        this.topDirections = topDirectionsItems;
      });
      return topDirectionsItems;
    } catch (error) {
      handleError(error);
    }
  };

  fetchServices = async () => {
    try {
      this.isLoadingBannersApi = true;
      runInAction(() => {
        // this.services = servicesArray;
        this.services = undefined;
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingBannersApi = false;
    }
  };

  getAllServices = async () => this.fetchServices();

  fetchFooterLinks = async (countryCode: string) => {
    try {
      const foundFooterLinks =
        hardcodedFooterLinks.find(
          (i) => i.countryCode === countryCode.toLocaleLowerCase()
        ) || defaultFooterLinks;

      runInAction(() => {
        this.footerLinks = foundFooterLinks;
      });
    } catch (error) {
      handleError(error);
    }
  };
}
