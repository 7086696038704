import { CourierPickup } from '../../../models/parcelDeliveryMiles';
import { formatDate } from '../../../utils/generic/generic.utils';

export const getDayOfWeek = (
  dateString: string,
  t: (key: string) => string
): string => {
  const date = new Date(dateString);
  const dayOfWeek = date.getUTCDay(); // 0 (Sun) to 6 (Sat)

  const translations = [
    'common_day_short_sun',
    'common_day_short_mon',
    'common_day_short_tue',
    'common_day_short_wed',
    'common_day_short_thu',
    'common_day_short_fri',
    'common.day_short_sat',
  ];

  return t(translations[dayOfWeek]);
};

export const formatDisplayDate = (
  dateString: string,
  t: (key: string) => string
): string => {
  const formattedDate = formatDate(dateString);
  const dayOfWeek = getDayOfWeek(dateString, t);
  return `${dayOfWeek} ${formattedDate}`;
};

export const formatDisplayTime = (from: string, to: string): string =>
  `${from} - ${to}`;

export const getNearestDate = (
  dateInfo: { date: string; time_slots: { from: string; to: string }[] }[],
  t: (key: string) => string
): {
  formattedDate: string;
  date: string;
  time_slots: { from: string; to: string }[];
} | null => {
  const today = new Date();
  const nearestDate = dateInfo
    .map((parsed) => new Date(parsed.date))
    .filter((date) => date >= today)
    .sort((a, b) => a.getTime() - b.getTime())[0];

  if (!nearestDate) return null;

  const dateString = nearestDate.toISOString().split('T')[0];
  const formattedDate = formatDisplayDate(dateString, t);
  const timeSlots =
    dateInfo.find((d) => d.date === dateString)?.time_slots || [];
  return { formattedDate, date: dateString, time_slots: timeSlots };
};

export function isCourierPickupEmpty(pickupInfo: CourierPickup | undefined) {
  return (
    !pickupInfo ||
    (!pickupInfo.date &&
      !pickupInfo.time_from &&
      !pickupInfo.time_to &&
      !pickupInfo.timezone)
  );
}
