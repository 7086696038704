import { createContext, useContext } from 'react';
import UserStore from './userStore';
import NavStore from './navStore';
import CommonStore from './commonStore';
import NotificationsStore from './notificationsStore';
import LocalizationsStore from './localizationsStore';
import PlacesSearchStore from './placesSearchStore';
import ParcelCreationFlowStore from './parcelCreationFlowStore';
import ParcelListStore from './parcelListStore';
import MarketingStore from './marketingStore';
import ModalStore from './modalStore';
import WidgetCalculatorStore from './widgetCalculatorStore';
import FeedbackStore from './feedbackStore';

interface Store {
  userStore: UserStore;
  navStore: NavStore;
  commonStore: CommonStore;
  notificationsStore: NotificationsStore;
  localizationsStore: LocalizationsStore;
  placesSearchStore: PlacesSearchStore;
  parcelCreationStore: ParcelCreationFlowStore;
  parcelListStore: ParcelListStore;
  marketingStore: MarketingStore;
  modalStore: ModalStore;
  widgetCalculatorStore: WidgetCalculatorStore;
  feedbackStore: FeedbackStore;
}

export const store: Store = {
  userStore: new UserStore(),
  navStore: new NavStore(),
  commonStore: new CommonStore(),
  notificationsStore: new NotificationsStore(),
  localizationsStore: new LocalizationsStore(),
  placesSearchStore: new PlacesSearchStore(),
  parcelCreationStore: new ParcelCreationFlowStore(),
  parcelListStore: new ParcelListStore(),
  marketingStore: new MarketingStore(),
  modalStore: new ModalStore(),
  widgetCalculatorStore: new WidgetCalculatorStore(),
  feedbackStore: new FeedbackStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
