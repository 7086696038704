import { makeAutoObservable } from 'mobx';
import { IModal, TModalProps, TModals } from '../common/modal/modal-context';

export default class ModalStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentModal: IModal | null = null;
  modalsQueue: IModal[] = [];
  pausedModal: IModal | null = null;

  open = (modal: IModal) => {
    if (this.currentModal) {
      this.modalsQueue.push(modal);
      return;
    }
    this.currentModal = modal;
  };

  close = (id: string) => {
    this.currentModal = null;

    if (this.pausedModal) {
      this.currentModal = this.pausedModal;
      this.pausedModal = null;
      return;
    }

    if (this.modalsQueue.length !== 0) {
      this.currentModal = this.modalsQueue.shift()!;
    }
  };

  removeFromQueue = (id: TModals) => {
    this.modalsQueue = this.modalsQueue.filter((modal) => modal.id !== id);
  };

  update = (id: string, props: TModalProps) => {
    if (this.currentModal?.id !== id) return;

    const modal = this.currentModal;
    if (!modal) return;
    modal.props = {
      ...modal.props,
      ...props,
    };
  };

  openUrgent = (modal: IModal) => {
    if (this.currentModal) {
      this.pausedModal = this.currentModal;
    }

    this.currentModal = modal;
  };

  isModalVisible = (id: string) => !!(this.currentModal?.id === id);
}
