import { NavLink } from 'react-router-dom';
import { ReactComponent as PackageIcon } from '../../../assets/package-icon.svg';
import { ReactComponent as UserIcon } from '../../../assets/user-icon.svg';
import { ReactComponent as ShipmentsIcon } from '../../../assets/shipments-icon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/settings-icon.svg';
import { ReactComponent as PudoIcon } from '../../../assets/pudo-icon.svg';
import { ReactComponent as BonusesIcon } from '../../../assets/bonuses-icon.svg';
import { ReactComponent as LikeIcon } from '../../../assets/thumbsup-icon.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/logout-icon.svg';
import { ReactComponent as BoxOpen } from '../../../assets/box-open.svg';
import { ReactComponent as Tememplate } from '../../../assets/tememplate.svg';
import { ReactComponent as ArchiveDrawer } from '../../../assets/archive-drawer.svg';
import { ReactComponent as ShieldHalved } from '../../../assets/shield-halved.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/credit-card.svg';
import { ReactComponent as AddressBookIcon } from '../../../assets/address-book.svg';
import { ReactComponent as PromocodeIcon } from '../../../assets/promocode.svg';
import { ReactComponent as HeartIcon } from '../../../assets/heart.svg';
import { ReactComponent as Users } from '../../../assets/users-icon.svg';
import { ServicesMenuItemContainer } from './services-menu-item.styles';
import { ServicesMenuIcon, ServicesMenuItemAppearance } from './config';

const getIcon = (icon: ServicesMenuIcon) =>
  ({
    package: <PackageIcon />,
    user: <UserIcon />,
    shipments: <ShipmentsIcon />,
    accountsettings: <SettingsIcon />,
    pudo: <PudoIcon />,
    bonuses: <BonusesIcon />,
    leavefeedback: <LikeIcon />,
    logout: <LogoutIcon />,
    parcellist: <PackageIcon />,
    drafts: <BoxOpen />,
    templates: <Tememplate />,
    archive: <ArchiveDrawer />,
    personaldata: <UserIcon />,
    securitysettings: <ShieldHalved />,
    paymentdetails: <CreditCardIcon />,
    receivers: <AddressBookIcon />,
    item1: <HeartIcon />,
    item2: <PromocodeIcon />,
    item3: <Users />,
    item4: <HeartIcon />,
  })[icon];

interface Props {
  children: string;
  as?: keyof JSX.IntrinsicElements;
  appearance?: ServicesMenuItemAppearance;
  isSelected?: boolean;
  isDisabled?: boolean;
  icon?: ServicesMenuIcon;
  to?: string;
  onClick?: () => void;
}

const ServicesMenuItem = ({
  children,
  as,
  icon,
  appearance,
  isSelected,
  isDisabled,
  to,
  onClick,
}: Props) => {
  const commonProps = {
    $isSelected: isSelected,
    $isDisabled: isDisabled,
    $appearance: appearance,
    as: to ? NavLink : as,
    onClick,
    children: (
      <>
        {icon && getIcon(icon)}
        {children}
      </>
    ),
  };

  if (to) {
    return (
      <ServicesMenuItemContainer
        {...commonProps}
        to={to}
      />
    );
  }

  return (
    <ServicesMenuItemContainer
      {...commonProps}
      disabled={isDisabled}
    />
  );
};

export default ServicesMenuItem;
