import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { VolumetricWeightWarning } from '../../widget-calculator.styles';
import { ReactComponent as VolumetricWeightWarningIcon } from '../../../../../assets/warning-tip.svg';
import { FormValues } from '../../widget-calculator-form.component';
import { useStore } from '../../../../../stores/store';
import { calculateVolumetricWeight } from '../../../../parcel-creation/parcel-details-dimensions/dimensionsConfig';

const VolumetricWeightMessage = () => {
  const {
    userStore: { measures },
    widgetCalculatorStore: { deliveryLimitations },
  } = useStore();
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const [volumetricWeight, setVolumetricWeight] = useState(0);

  useEffect(() => {
    setVolumetricWeight(
      calculateVolumetricWeight({
        width: Number(values.size_x),
        height: Number(values.size_y),
        length: Number(values.size_z),
        measure: measures,
      })
    );
  }, [measures, values.size_x, values.size_y, values.size_z]);

  if (
    !deliveryLimitations?.maxVolWeight ||
    volumetricWeight <= deliveryLimitations?.maxVolWeight
  ) {
    return null;
  }

  return (
    <VolumetricWeightWarning>
      <VolumetricWeightWarningIcon />
      {t('create_parcel_volumetric_tip')}
    </VolumetricWeightWarning>
  );
};

export default VolumetricWeightMessage;
