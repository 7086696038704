import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { HeaderMain } from '../../../common/typography/typography.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import Checkbox from '../../../common/checkbox/checkbox.component';
import Button from '../../../common/button/button.component';
import FormFieldPassword from '../../../common/form-field-password/form-field-password.component';
import { useStore } from '../../../stores/store';
import {
  EMAIL_REGEX,
  VALIDATION_RULE_IS_NOT_CYRILLIC,
} from '../../../constants/validation';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import Separator from '../../../common/separator/separator.component';
import { SocialButtons } from '../sign-in/sign-in.styles.component';
import SocialButton from '../../../common/social-button/social-button.component';
import { SocialButtonAppearance } from '../../../common/button/types';
import { exhaustiveGuard } from '../../../utils/generic/generic.utils';
import { AuthorizationForm } from '../auth-tabbed-container/auth-tabbed-container.styles';
import TermsOfUseTranslation from '../../../common/terms-of-use-translation/terms-of-use-translation.component';

const initialValues: SignUpFormValues = {
  email: '',
  password: '',
  confirmPassword: '',
  termsOfUse: false,
  newsSubscription: false,
};

interface SignUpFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
  newsSubscription: boolean;
}

const SignUp = () => {
  const {
    userStore: {
      signUp,
      signUpInProgress,
      googleSignInProgress,
      appleSignInProgress,
      googleLogin,
      appleLogin,
    },
  } = useStore();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
      .matches(EMAIL_REGEX, t('wrong_email_format'))
      .required(t('this_field_cannot_be_empty')),

    password: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&-])(?!.*\s).{8,30}$/,
        t('password_doesnt_match_the_requirements')
      )
      .required(t('this_field_cannot_be_empty')),

    confirmPassword: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC).test(
      'passwords-match',
      t('password_doesnt_match'),
      (value, ctx) => ctx.parent.password === value
    ),

    termsOfUse: Yup.boolean().oneOf([true]),
  });

  const handleFormSubmit = async (
    { email, password, newsSubscription }: any,
    { setFieldError, resetForm }: FormikHelpers<SignUpFormValues>
  ) => {
    const { success, userAlreadyExists } = await signUp(
      email,
      password,
      newsSubscription
    );
    if (!success && userAlreadyExists) {
      setFieldError('email', t('an_account_with_this_email_already_exists'));
    } else {
      resetForm();
    }
  };

  const handleSocialButtonClick = async (
    socialType: SocialButtonAppearance
  ) => {
    switch (socialType) {
      case 'apple':
        await appleLogin();
        break;
      case 'google':
        await googleLogin();
        break;
      default:
        exhaustiveGuard(socialType);
    }
  };

  return (
    <>
      <HeaderMain>{t('lets_create_account')}</HeaderMain>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
        {({ handleSubmit, isValid, dirty, setFieldValue }) => (
          <AuthorizationForm
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <FormFieldText
              name='email'
              placeholder={t('enter_your_email')}
              label={t('email')}
              inputMode='email'
            />

            <FormFieldPassword
              autoComplete='new-password'
              shouldValidatePassword
              name='password'
              placeholder={t('enter_your_password')}
              label={t('create_your_password')}
            />

            <FormFieldPassword
              name='confirmPassword'
              placeholder={t('repeat_your_password')}
              label={t('repeat_the_password')}
            />

            <div>
              <Checkbox
                name='termsOfUse'
                onChecked={setFieldValue}>
                <TermsOfUseTranslation />
              </Checkbox>

              <Checkbox
                name='newsSubscription'
                onChecked={setFieldValue}>
                {t('news_subscription')}
              </Checkbox>
            </div>
            <Button
              size='large'
              isLoading={signUpInProgress}
              disabled={!dirty || !isValid}
              type='submit'
              $pt='1.4rem'
              $pb='1.4rem'
              $pr='1.6rem'
              $pl='1.6rem'>
              {t('create_an_account')}
            </Button>
          </AuthorizationForm>
        )}
      </Formik>

      <Separator content={t('or')} />

      <SocialButtons>
        <SocialButton
          isLoading={googleSignInProgress}
          appearance='google'
          onClick={() => handleSocialButtonClick('google')}
        />
        <SocialButton
          isLoading={appleSignInProgress}
          appearance='apple'
          onClick={() => handleSocialButtonClick('apple')}
        />
      </SocialButtons>
    </>
  );
};

export default observer(SignUp);
