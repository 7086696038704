import styled from 'styled-components';
import { FontSizes } from '../../../../../common/typography/typography.styles';
import { ParcelHistoryProgress } from './parcel-history.helpers';

interface Props {
  $colorType: ParcelHistoryProgress;
}

export const TrackingStatusTitle = styled.div<Props>`
  font-size: ${FontSizes.normal};
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ $colorType, theme }) => {
    if ($colorType === 'completed') {
      return theme.colors.baseColors.grey150;
    }

    if ($colorType === 'active') {
      return theme.colors.namedColors.primary;
    }

    return theme.colors.baseColors.grey80;
  }};
`;

const TrackingDetailsText = styled.span<Props>`
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ $colorType, theme }) => {
    if ($colorType !== 'future') {
      return theme.colors.baseColors.grey150;
    }

    return theme.colors.baseColors.grey80;
  }};
`;

export const TrackingDetailsDesc = styled(TrackingDetailsText)`
  flex: 1;
  word-break: break-word;
`;

export const TrackingDetailsDate = styled(TrackingDetailsText)`
  white-space: nowrap;
`;

interface TrackingContainerProps {
  $borderType: 'colored' | 'grey';
  $hideBorder: boolean;
  $isEmpty: boolean;
}

export const TrackingContainer = styled.div<TrackingContainerProps>`
  border-left: 1px solid
    ${({ $borderType, theme }) =>
      $borderType === 'colored'
        ? theme.colors.namedColors.primary
        : theme.colors.baseColors.grey50};
  border-width: ${({ $hideBorder }) => ($hideBorder ? '0px' : '1px')};
  padding-left: 2.4rem;
  margin-left: 1.2rem;
  height: ${({ $isEmpty }) => ($isEmpty ? '2rem' : 'auto')};
`;
