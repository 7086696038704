import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import ManualModal from '../../../common/manual-modal/manual-modal.component';
import { Modals } from '../../../constants/modals';
import { useModal } from '../../../common/modal';

const ParcelManual = ({ onClose }: { onClose: () => void }) => {
  const {
    parcelListStore: {
      savedManuals,
      activeManualId,
      isManualLoading,
      isDontShowAgainVisible,
      setActiveManualId,
      confirmParcelManual,
    },
    commonStore: { isGuestParcelCreation },
  } = useStore();

  const { openModal } = useModal();

  const showCreateAccountModal = () => {
    if (isGuestParcelCreation)
      setTimeout(
        () =>
          openModal({
            id: Modals.CREATE_ACCOUNT,
            name: Modals.CREATE_ACCOUNT,
          }),
        5000
      );
  };

  const handleClose = () => {
    onClose();
    setActiveManualId(undefined);
    showCreateAccountModal();
  };

  const handleDontShowAgain = () => {
    confirmParcelManual(activeManualId!);
  };

  return (
    <ManualModal
      showDontShowAgainCheckbox={isDontShowAgainVisible}
      isLoading={isManualLoading}
      modalName={Modals.PARCEL_MANUAL}
      mobileSidebarName={Modals.PARCEL_MANUAL}
      item={savedManuals[activeManualId!]}
      onDontShowAgain={handleDontShowAgain}
      onCloseDesktop={handleClose}
      onCloseMobile={handleClose}
    />
  );
};

export default observer(ParcelManual);
