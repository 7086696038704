import styled from 'styled-components';

export const ReceiverName = styled.h3`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
  min-width: 100%;

  @media (max-width: 649.5px) {
    word-break: break-word;
  }
`;
export const ReceiverData = styled.p`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
  min-width: 100%;
`;

export const ReceiverNotes = styled.p`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  min-width: 100%;
`;
