import styled from 'styled-components';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';

export const FlexContainer = styled(BaseMarginComponent('div'))`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > * {
    width: 100%;
    flex: 1 1;
  }
  & > *:not(:last-of-type) {
    margin-right: 2.4rem;
  }
  margin-bottom: 2rem;
  @media (max-width: 650px) {
    flex-direction: column;
    margin-bottom: 0;

    & > * {
      margin-bottom: 1.6rem;
    }
  }
`;

export const FlexContainerMob = styled.div`
  display: flex;

  & > * {
    width: 50%;
  }
  & > *:not(:last-of-type) {
    margin-right: 2rem;
  }
`;
