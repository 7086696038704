import React from 'react';
import { ReactComponent as StarIcon } from '../../../assets/star.svg';
import { ReactComponent as EmptyStarIcon } from '../../../assets/empty-star.svg';
import { StarButton, StarContainer } from './star-rating.styles';

interface StarRatingProps {
  rating: number;
  onRate: (index: number) => void;
  name: string;
}

const StarRating: React.FC<StarRatingProps> = ({ rating, onRate, name }) => (
  <StarContainer>
    {Array.from({ length: 5 }).map((_, index) => (
      <StarButton
        name={name}
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        type='button'
        onClick={() => onRate(index)}
        aria-label={`Rate ${index + 1} out of 5`}>
        {index < rating ? <StarIcon /> : <EmptyStarIcon />}
      </StarButton>
    ))}
  </StarContainer>
);

export default StarRating;
