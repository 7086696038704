import styled from 'styled-components';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';
import { TextLabel } from '../../../common/typography/typography.styles';

export const FieldContainer = styled(BaseMarginComponent('div'))`
  width: 100%;

  ${TextLabel} {
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
  }
`;
