import { NavigateFunction } from 'react-router-dom';
import { ShippingType } from '../../stores/parcelCreationFlowStore';
import { router } from '../../../Router';
import { AppRoute } from '../../constants/route';
import { store } from '../../stores/store';

export const getLastPathSegment = (pathname: string): string => {
  const pathSegments = pathname.split('/').filter(Boolean); // Split and filter out empty strings
  return pathSegments[pathSegments.length - 1]?.split('?')[0] || '';
};

export const clearSearchParams = () => {
  const url = new URL(window.location.href);

  url.search = '';

  window.history.pushState({}, document.title, url.toString());
};

export const constructLocalePath = (
  localizationCode: string,
  countryCode: string
) =>
  `/${countryCode.toLocaleLowerCase()}-${localizationCode.toLocaleLowerCase()}`;

const navigateToNewLocale = (
  navigate: NavigateFunction,
  currentPath: string,
  entity: string,
  type: 'country' | 'lang',
  withPageReload?: boolean
) => {
  const pathParts = currentPath.split('/').filter(Boolean);

  if (!pathParts?.at(0)) return;

  const localePart = pathParts[0];
  if (localePart.split('-').length !== 2) return;
  const [countryCode, languageCode] = localePart.split('-');

  const isCountryType = type === 'country';
  const newLocalePart = `${isCountryType ? entity : countryCode}-${isCountryType ? languageCode : entity}`;

  const newUrl = `/${[newLocalePart, ...pathParts.slice(1)].join('/')}`;

  withPageReload ? (window.location.href = newUrl) : navigate(newUrl);
};

export const navigateToNewLanguage = (
  navigate: NavigateFunction,
  currentPath: string,
  newLanguageCode: string,
  withPageReload?: boolean
) => {
  navigateToNewLocale(
    navigate,
    currentPath,
    newLanguageCode,
    'lang',
    withPageReload
  );
};

export const navigateToNewCountry = (
  navigate: NavigateFunction,
  currentPath: string,
  newCountryCode: string,
  withPageReload?: boolean
) => {
  navigateToNewLocale(
    navigate,
    currentPath,
    newCountryCode,
    'country',
    withPageReload
  );
};

export const changeShippingTypeInUrl = (newType: ShippingType) => {
  const pathParts = window.location.pathname.split('/').filter(Boolean);
  const oldType = newType === 'international' ? 'domestic' : 'international';
  const shippingTypeIndex = pathParts.findIndex((p) => p === oldType);
  if (shippingTypeIndex !== -1) {
    pathParts[shippingTypeIndex] = newType;
  }
  const newUrl = `/${pathParts.join('/')}`;
  router.navigate(newUrl);
};

export const generateLocaleRoute = (
  subRoute?: string,
  locale: string = store.localizationsStore.localeParam
) => [locale, AppRoute.PORTAL, subRoute].filter(Boolean).join('/');

export const generateMyRoute = (subRoute?: string) =>
  generateLocaleRoute([AppRoute.MY, subRoute].filter(Boolean).join('/'));

export const generateStartParcelCreationRoute = () =>
  generateLocaleRoute(
    `${AppRoute.INTERNATIONAL}/${AppRoute.PARCEL_CREATE}/${AppRoute.PARCEL_CREATION_PARCEL_DETAILS}`
  );

export const generateLeaveFeedbackRoute = () =>
  generateLocaleRoute(`${AppRoute.MY}/${AppRoute.LEAVE_FEEDBACK}`);

type QueryParams<T> = {
  [K in keyof T]: string | (string | undefined)[] | number | undefined;
};

export function buildQueryParams<T>(obj: QueryParams<T>): string {
  return Object.entries(obj)
    .filter(
      ([key, value]) =>
        Object.prototype.hasOwnProperty.call(obj, key) && value !== undefined
    )
    .map(([key, value]) => {
      const val = (Array.isArray(value) ? value.join(',') : value) as string;
      return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
    })
    .join('&');
}

export const removeLanguagePrefix = (path: string) =>
  path.replace(/^\/[a-z]{2}(-[a-z]{2})?\//, '/');
