import styled from 'styled-components';
import { ServicesSubSectionContainer } from '../../services/services-subsection/services-subsection.styles';

export const TaxesSectionContainer = styled(ServicesSubSectionContainer)`
  &:has(> :nth-child(3)) {
    // Apply margin-top to the last child only if there are more than 2 children
    > :last-child {
      margin-top: 2.4rem;
      @media screen and (min-width: 650px) {
        margin-top: 2rem;
      }
    }
  }
`;
