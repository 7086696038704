import styled, { css } from 'styled-components';
import {
  HeaderMain,
  TextBody,
} from '../../common/typography/typography.styles';

export const AuthWrapper = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.namedColors.white};

  @media screen and (min-width: 650px) {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.6rem;
  }
`;

export type ViewMode = 'page' | 'sidebar';

interface Props {
  $viewMode?: ViewMode;
  $isCentered?: boolean;
}

export const AuthContainer = styled.div<Props>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  padding: 4rem;
  border-radius: 2rem;
  min-height: auto;
  @media screen and (min-width: 650px) {
    max-width: 52.9rem;
  }

  ${({ $viewMode }) =>
    $viewMode === 'page' &&
    css`
      @media screen and (min-width: 650px) {
        border: 1px solid
          ${({ theme }) =>
            theme.colors.passwordValidationMarker.backgroundColor};
      }
    `}

  ${({ $isCentered }) =>
    !$isCentered &&
    css`
      @media (min-height: 800px) {
        min-height: 60rem;
      }
    `}

  ${HeaderMain} {
    text-align: center;
  }

  ${TextBody} {
    color: ${({ theme }) => theme.colors.baseColors.grey150};
  }

  @media (max-width: 650px) {
    flex-grow: 1;
    padding: 2.4rem 1.6rem;
    margin-top: 0;
    border-radius: 0;
  }
`;

export const AuthButtonsHolder = styled.div`
  display: grid;
  gap: 1.6rem;
`;
