import styled, { css } from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal';

export const BlurredBackgroundColorStyles = css`
  background-color: #314c8144;
  backdrop-filter: blur(1px);
`;

export const BlurredBackground = styled.div`
  position: fixed;
  height: 100dvh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 8000;
  ${BlurredBackgroundColorStyles}
`;

export const ModalBlurredBackground = styled(BaseModalBackground)`
  z-index: 1119001;

  ${BlurredBackgroundColorStyles};
`;

export const BlurredMenuOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
`;
