import { useField, useFormikContext } from 'formik';
import { useId, useState } from 'react';
import { TextLabel } from '../typography/typography.styles';
import { MarginProps } from '../base-margin/base-margin.component';
import Select, { IDropdownItem } from '../select/select.component';
import { FieldContainer } from './form-field-select.styles';

interface Props {
  name: string;
  placeholder: string;
  label?: string;
  items: IDropdownItem<string>[];
}

const FormFieldSelect = ({
  name,
  placeholder,
  label,
  items,
}: Props & MarginProps) => {
  const [, meta] = useField(name);
  const [value, setValue] = useState(() =>
    items.find((item) => item.value === meta.initialValue)
  );
  const { setFieldValue } = useFormikContext();
  const id = useId();
  const inputId = name + id;
  const handleChange = (item: IDropdownItem<string>) => {
    setValue(item);
    setFieldValue(name, item.value);
  };

  return (
    <FieldContainer>
      <TextLabel htmlFor={inputId}>{label}</TextLabel>
      <Select
        shouldMatchWidthOfInput
        onChange={handleChange}
        name={name}
        placeholder={placeholder}
        items={items}
        value={value}
      />
    </FieldContainer>
  );
};

export default FormFieldSelect;
