import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';
import { ReactComponent as MeestMapCenterIcon } from '../../assets/meest-map-center-icon.svg';
import { ReactComponent as PolonezMapCenterIcon } from '../../assets/polonez-map-center-icon.svg';
import { MapCenterIconHolder } from './map-center-marker.styles';
import { mapConfig } from './map.config';
import { client } from '../../config';

const getMapCenterIcon = () => {
  const svgs = {
    meest: <MeestMapCenterIcon />,
    polonez: <PolonezMapCenterIcon />,
  };

  return svgs[client] || null;
};

interface Props {
  hidden?: boolean;
  mapDragging?: boolean;
  mapLoading?: boolean;
}

export const MapCenterMarker = ({ hidden, mapDragging, mapLoading }: Props) => {
  const [centerPinPosition, setCenterPinPosition] = useState<
    google.maps.LatLng | undefined
  >();
  const map = useMap(mapConfig.id);

  useEffect(() => {
    if (!map) return;

    const centerChangedListener = map.addListener('center_changed', () => {
      setCenterPinPosition(map.getCenter());
    });

    return () => {
      google.maps.event.removeListener(centerChangedListener);
    };
  }, [map]);

  if (hidden) return;

  return (
    <AdvancedMarker
      zIndex={1000}
      position={centerPinPosition}>
      <MapCenterIconHolder
        $mapDragging={mapDragging}
        $mapLoading={mapLoading}>
        {getMapCenterIcon()}
      </MapCenterIconHolder>
    </AdvancedMarker>
  );
};
