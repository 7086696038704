import { Formik, FormikProps, useFormikContext } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  FormFieldContainer,
  FormFieldIcon,
  ParcelParticipantDetailsContainer,
} from './parcel-participant-personal.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import { useStore } from '../../../stores/store';
import IconButton from '../../../common/icon-button/icon-button';
import { IPersonalDataFormValues } from '../../../routes/parcel-receiver/parcel-receiver.component';
import { ServicesSubSectionHeader } from '../../services/services-subsection/services-subsection.styles';
import { parcelParticipantDataSchema } from '../../../utils/forms/schemas.utils';

interface Props {
  updateFirstName: (firstName: string) => void;
  updateLastName: (lastName: string) => void;
}

export const senderPersonalDataValidationSchema = Yup.object(
  parcelParticipantDataSchema
);

const PersonalDataFormChangeHandler = ({
  updateFirstName,
  updateLastName,
}: Props) => {
  // Grab values and submitForm from context
  const { values } = useFormikContext<IPersonalDataFormValues>();
  const { first_name: firstName, last_name: lastName } = values;
  useEffect(() => {
    updateFirstName(firstName);
    updateLastName(lastName);

    // eslint-disable-next-line
  }, [values]);
  return null;
};

interface ParcelSenderPersonalProps {
  initialValues: IPersonalDataFormValues;
  storeParticipantFirstName: (firstName: string) => void;
  storeParticipantLastName: (lastName: string) => void;
  isReceiver?: boolean;
  innerRef?: React.Ref<FormikProps<IPersonalDataFormValues>>;
}

const ParcelParticipantPersonalForm = ({
  isReceiver = false,
  initialValues,
  storeParticipantFirstName,
  storeParticipantLastName,
  innerRef,
}: ParcelSenderPersonalProps) => {
  const {
    navStore: { toggleSidebarByName },
    commonStore: { isGuestParcelCreation },
  } = useStore();

  const { t } = useTranslation();

  return (
    <ParcelParticipantDetailsContainer>
      <ServicesSubSectionHeader>
        {isReceiver ? t('receiver_information') : t('user_data')}
      </ServicesSubSectionHeader>
      <Formik
        validationSchema={senderPersonalDataValidationSchema}
        initialValues={initialValues}
        innerRef={innerRef}
        onSubmit={() => {}}>
        {({ handleSubmit }) => (
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <div className='inputs'>
              <FormFieldContainer>
                <FormFieldText
                  maxLength={50}
                  name='first_name'
                  label='first_name'
                  suffix={{
                    isClickable: true,
                    element: isReceiver && !isGuestParcelCreation && (
                      <FormFieldIcon>
                        <IconButton
                          appearance='text'
                          aria-label='address-book'
                          icon={{ glyph: 'address-book' }}
                          onClick={() =>
                            toggleSidebarByName('receiver_first_name')
                          }
                        />
                      </FormFieldIcon>
                    ),
                  }}
                />
              </FormFieldContainer>

              <FormFieldText
                maxLength={50}
                name='last_name'
                placeholder=''
                label={t('last_name')}
              />

              <PersonalDataFormChangeHandler
                updateFirstName={storeParticipantFirstName}
                updateLastName={storeParticipantLastName}
              />
            </div>
          </form>
        )}
      </Formik>
    </ParcelParticipantDetailsContainer>
  );
};

export default ParcelParticipantPersonalForm;
