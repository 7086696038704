import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import SignatureField from '../../../common/signature-field/signature-field.component';
import { useStore } from '../../../stores/store';
import { isSignatureSupported } from '../../../utils/parcel-creation/parcel-creation-utils';
import { Title } from './signature-details-summary.styles';

const SignatureDetailsSummary = () => {
  const {
    parcelCreationStore: {
      submitSignature,
      setSignature,
      fetchSignature,
      signature,
      countryDeparture,
    },
  } = useStore();

  const { t } = useTranslation();

  useEffect(() => {
    fetchSignature();
  }, [fetchSignature]);

  if (!isSignatureSupported(countryDeparture?.countryCode || '')) {
    return null;
  }

  const handleConfirm = async (
    newSignature: FormData,
    signatureUrl: string
  ) => {
    await submitSignature(newSignature);
    setSignature(signatureUrl);
  };

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>{t('signature')}</ServicesSubSectionHeader>
      <Title>{t('adding_signature_required_step')}</Title>
      <SignatureField
        signature={signature}
        onConfirm={handleConfirm}
        onClear={() => setSignature('')}
      />
    </ServicesSubSectionContainer>
  );
};

export default observer(SignatureDetailsSummary);
