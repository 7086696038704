import { useFormikContext } from 'formik';
import { useState } from 'react';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { FieldContainer } from './parcel-number-select.styles';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import ParcelListOptions from '../parcel-list-options/parcel-list-options.component';
import InputSelectWithSidebar from '../../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import { MarginProps } from '../../../common/base-margin/base-margin.component';
import { useStore } from '../../../stores/store';

interface Props {
  name: string;
  placeholder: string;
}

const ParcelNumberSelect = ({ name, placeholder }: Props & MarginProps) => {
  const {
    feedbackStore: { setShipmentId },
  } = useStore();

  const [selectedParcel, setSelectedParcel] = useState<ParcelListShipment>();
  const { setFieldValue } = useFormikContext();

  const handleSelect = (parcel: ParcelListShipment) => {
    setSelectedParcel(parcel);
    setFieldValue(name, parcel.id);
    setShipmentId(parcel.id);
  };

  return (
    <FieldContainer>
      <InputSelectWithSidebar<ParcelListShipment>
        name='feedback_parcel_number'
        onSelectHandler={(parcel) => {
          if (parcel) {
            handleSelect(parcel);
          }
        }}
        selectedFromOutsideItem={selectedParcel}
        renderSelectedItem={(parcel) => <span>{parcel.tracking_number}</span>}
        placeholder={placeholder}
        label={t('leave_feedback_parcel_number')}
        isOptional
        sidebarTitle={t('my_parcels')}
        SidebarContent={ParcelListOptions}
        error={t('field_is_required')}
        errorValidationPredicate={(parcel) => parcel === undefined}
        infoMessage={t('could_be_paid_only_by_cash_at_the_agents_office')}
        maxInputHeight='100%'
        shouldSetTouchedOnClose={false}
      />
    </FieldContainer>
  );
};

export default observer(ParcelNumberSelect);
