import styled from 'styled-components';

export const BadgeWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.2rem;
  gap: 0.8rem;
  border-radius: 3.2rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.baseColors.grey50};
  transition:
    background-color 0.3s,
    color 0.2s;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.namedColors.primary};

  svg {
    transition: color 0.2s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
    color: ${({ theme }) => theme.colors.namedColors.white};
  }

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const BadgeLabel = styled.span`
  color: ${({ theme }) => theme.colors.namedColors.primary};
  display: flex;
  gap: 0.4rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  transition: color 0.2s;

  ${BadgeWrapper}:hover & {
    color: ${({ theme }) => theme.colors.namedColors.white};
  }

  @media (max-width: 650px) {
    width: 100%;

    justify-content: space-between;
  }
`;
