import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import FormFieldPassword from '../../common/form-field-password/form-field-password.component';
import Button from '../../common/button/button.component';
import { EditFormProps } from '../../features/services/services-subsection/services-subsection.component';

interface IFormValues {
  password: string;
  confirmPassword: string;
}

const initialValues: IFormValues = {
  password: '',
  confirmPassword: '',
};

const SecuritySettingsEditForm = ({ onCancel }: EditFormProps) => {
  const {
    userStore: { isLoadingUser, changePassword },
    commonStore: { toastSuccess },
  } = useStore();

  const { t } = useTranslation();

  const handleFormSubmit = async (
    { password }: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    const success = await changePassword(password);
    success && toastSuccess(t('data_updated_successfully'));
    onCancel && onCancel();
    formikHelpers.resetForm();
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&])(?!.*\s).{8,30}$/,
        t('password_is_incorrect')
      )
      .required(t('password_is_required')),

    confirmPassword: Yup.string().test(
      'passwords-match',
      t('passwords_must_match'),
      (value, ctx) => ctx.parent.password === value
    ),
  });

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
      {({ handleSubmit, isValid, isSubmitting, dirty }) => (
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <div className='inputs'>
            <FormFieldPassword
              autoComplete='new-password'
              shouldValidatePassword
              name='password'
              placeholder={t('enter_your_password')}
              label={t('new_password')}
            />

            <FormFieldPassword
              name='confirmPassword'
              placeholder={t('repeat_your_password')}
              label={t('repeat_the_password')}
            />
          </div>

          <div className='buttons'>
            <Button
              appearance='secondary'
              disabled={isSubmitting}
              onClick={() => onCancel && onCancel()}>
              {t('cancel')}
            </Button>
            <Button
              isLoading={isLoadingUser}
              disabled={!dirty || !isValid}
              type='submit'>
              {t('save_changes')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default observer(SecuritySettingsEditForm);
