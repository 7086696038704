import currencySymbolsData from '../assets/data/currency-symbols-map';

export interface Currency {
  value: string;
  label: string;
  symbol: string;
  prefixPlace?: boolean;
}

export const currencies: Currency[] = [
  {
    value: 'USD',
    label: 'USD',
    symbol: currencySymbolsData['USD'],
    prefixPlace: true,
  },
  {
    value: 'CAD',
    label: 'CAD',
    symbol: currencySymbolsData['CAD'],
    prefixPlace: true,
  },
  { value: 'UAH', label: 'UAH', symbol: currencySymbolsData['UAH'] },
];

export const currenciesMap = currencies.reduce<{ [key: string]: Currency }>(
  (acc, curr) => {
    acc[curr.value] = curr;
    return acc;
  },
  {}
);

export const currencySymbolsMap = currencies.reduce<{ [key: string]: string }>(
  (acc, curr) => {
    acc[curr.value] = curr.symbol;
    return acc;
  },
  {}
);
