import { AppRoute } from '../../../constants/route';

export type ServicesMenuItemAppearance = 'default' | 'logout';
export type ServicesMenuIcon =
  | 'package'
  | 'user'
  | 'shipments'
  | 'accountsettings'
  | 'pudo'
  | 'bonuses'
  | 'leavefeedback'
  | 'logout'
  | 'parcellist'
  | 'drafts'
  | 'templates'
  | 'archive'
  | 'personaldata'
  | 'securitysettings'
  | 'paymentdetails'
  | 'receivers';

export type ServicesMenuItemId = 'shipments' | 'services' | 'accountsettings';

export type ServicesSubMenuItemId =
  | 'parcellist'
  | 'drafts'
  | 'archive'
  | 'receivers'
  | 'personaldata'
  | 'securitysettings'
  | 'leavefeedback';

export interface MenuItem {
  id: ServicesMenuItemId;
  title: string;
  items: MenuSubItem[];
}

export interface MenuSubItem {
  id: ServicesSubMenuItemId;
  title: string;
  icon: ServicesMenuIcon;
  route: string;
}

export type Menu = {
  [key in ServicesMenuItemId]?: Omit<MenuItem, 'items'> & {
    items: { [subKey in ServicesSubMenuItemId]?: MenuSubItem };
  };
};

export type ServiceId = `${ServicesMenuItemId}:${ServicesSubMenuItemId}`;

const menu: Menu = {
  shipments: {
    id: 'shipments',
    title: 'shipments',
    items: {
      parcellist: {
        id: 'parcellist',
        title: 'parcel_list',
        icon: 'shipments',
        route: AppRoute.PARCEL_LIST,
      },
      drafts: {
        title: 'drafts',
        id: 'drafts',
        icon: 'drafts',
        route: AppRoute.DRAFTS,
      },
      archive: {
        title: 'archive',
        id: 'archive',
        icon: 'archive',
        route: AppRoute.ARCHIVE,
      },
    },
  },
  services: {
    id: 'services',
    title: 'services',
    items: {
      receivers: {
        title: 'my_receivers',
        id: 'receivers',
        icon: 'receivers',
        route: AppRoute.RECEIVERS,
      },
    },
  },
  accountsettings: {
    id: 'accountsettings',
    title: 'account_settings',
    items: {
      personaldata: {
        title: 'user_data',
        id: 'personaldata',
        icon: 'personaldata',
        route: AppRoute.PERSONAL_DATA,
      },
      securitysettings: {
        title: 'security_settings',
        id: 'securitysettings',
        icon: 'securitysettings',
        route: AppRoute.SECURITY_SETTINGS,
      },
      leavefeedback: {
        title: 'leave_feedback_title',
        id: 'leavefeedback',
        icon: 'leavefeedback',
        route: AppRoute.LEAVE_FEEDBACK,
      },
    },
  },
};

const accountCardMenu: Menu = {
  shipments: {
    id: menu.shipments!.id,
    title: menu.shipments!.title,
    items: {
      parcellist: menu.shipments!.items.parcellist,
    },
  },
  accountsettings: {
    id: menu.accountsettings!.id,
    title: menu.accountsettings!.title,
    items: {
      personaldata: menu.accountsettings!.items.personaldata,
      securitysettings: menu.accountsettings!.items.securitysettings,
    },
  },
};

function convertToMenuItemArray(menuToConvert: Menu): MenuItem[] {
  return Object.entries(menuToConvert)
    .filter(([_, menuItem]) => menuItem !== undefined)
    .map(([_, menuItem]) => {
      const itemsArray: MenuSubItem[] = Object.values(menuItem.items).filter(
        (subItem): subItem is MenuSubItem => subItem !== undefined
      );
      return {
        id: menuItem.id,
        title: menuItem.title,
        items: itemsArray,
      };
    })
    .filter((item): item is MenuItem => item !== null);
}

export const fullMenuItems = convertToMenuItemArray(menu);
export const accountCardMenuItems = convertToMenuItemArray(accountCardMenu);
