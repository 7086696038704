import { countryInfoList } from '../../assets/data/countryInfo';
import {
  CountrySetting,
  SettingCategory,
  SettingName,
} from '../../models/countriesModel';
import { IShipmentCountry } from '../../stores/parcelCreationFlowStore';

export const getCountryZip = (countryCode: string) =>
  countryInfoList.find((country) => country.countryCode === countryCode)
    ?.defaultZIP;

export const getSetting = (
  settings: CountrySetting[],
  category: SettingCategory,
  name: SettingName
): string | undefined =>
  settings.find((s) => s.category === category && s.name === name)?.value;

export const getSettingReceiverCountry = (
  country?: IShipmentCountry | null
): string | undefined =>
  getSetting(country?.settings ?? [], 'delivery_options', 'receiver_country');
