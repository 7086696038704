import styled from 'styled-components';
import { SectionSubtitle, SectionTitle } from '../common/typography.styles';
import { TextBody } from '../../../common/typography/typography.styles';
import { Section } from '../common/section.styles';
import { MarginContainer } from '../../../common/base-margin/base-margin.component';

export const CountryCard = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-radius: 16px;
  padding: 1.2rem 2.4rem;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  border: none;
  outline: 1px solid transparent;
  font-family: inherit;
  text-align: left;

  &:nth-child(4n) {
    margin-right: 0;
  }

  &:focus-visible {
    background-color: #cce8ff;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #cce8ff;
    }
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 60% 0;
  mix-blend-mode: lighten;
  z-index: -1;

  @media (max-width: 585px) {
    object-position: 0 100%;
  }
`;

export const CountriesSection = styled(Section)`
  position: relative;
  padding: 60px 24px 80px;
  background-color: #005fad;
  isolation: isolate;

  @media (max-width: 585px) {
    padding: 40px 0;
  }
`;

export const CountriesHeader = styled(SectionTitle)`
  color: ${({ theme }) => theme.colors.namedColors.white};
  margin: 8px 0 32px;

  @media (max-width: 585px) {
    margin-bottom: 24px;
  }
`;

export const CountriesSubHeader = styled(SectionSubtitle)`
  color: ${({ theme }) => theme.colors.namedColors.white};
  text-transform: uppercase;

  &:nth-child(2n) {
    margin-bottom: 24px;

    @media (max-width: 585px) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
`;

export const CountriesListContainer = styled.div`
  --cols: 4;

  display: grid;
  gap: 24px 40px;
  margin: 32px 0 24px 0;
  grid-template-columns: repeat(var(--cols), 1fr);

  @media (max-width: 1021px) {
    --cols: 3;
  }

  @media (max-width: 767px) {
    --cols: 2;
    gap: 12px 24px;
  }

  @media (max-width: 585px) {
    --cols: 1;
    gap: 12px;
    margin: 12px 0 16px 0;
  }
`;

export const CountryMarginContainer = styled(MarginContainer)`
  display: flex;
  width: 34px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
`;

export const TooltipTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const TooltipSubtitle = styled.div`
  font-size: 12px;
`;

export const DeliveryLimitationContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #f5f6f7;
`;

export const DeliveryLimitationIcon = styled.div`
  width: 44px;
  height: 44px;
  background-color: #f4f7fc;
  border-radius: 10px;
`;

export const LimitationTitle = styled(TextBody)`
  margin-left: 8px;
  color: #2c363f;
  font-weight: 400;
`;

export const BaseButton = styled.button`
  padding: 0;
  font-family: inherit;
  background: none;
  border: none;
  cursor: pointer;
`;

export const CountryRestrictions = styled(BaseButton)`
  position: relative;
  isolation: isolate;
  display: flex;

  &::before {
    --offset: -4px;

    content: '';
    position: absolute;
    top: var(--offset);
    left: var(--offset);
    right: var(--offset);
    bottom: var(--offset);
    z-index: -1;
  }
`;

export const ExpandButton = styled(BaseButton)`
  width: 100%;
  padding: 14px 0;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #ffffff;
`;
