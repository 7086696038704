import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  MoreButton,
  MoreSidebarContainer,
} from '../parcel-list-card/parcel-list-card.styles';
import { ReactComponent as MoreIcon } from '../../../assets/more.svg';
import { MenuItems } from '../../../common/menu-item/menu-item.styles';
import {
  ICardActionItem,
  PARCEL_LIST_SIDEBARS,
} from '../../../routes/parcel-list/config';
import BottomSidebar from '../../../common/sidebar-bottom/bottom-sidebar.component';
import { useStore } from '../../../stores/store';
import MenuItem from '../../../common/menu-item/menu-item.component';
import { SidebarName } from '../../../types/sidebar.types';

interface Props {
  items: ICardActionItem[];
  id: number;
}

const MoreDropdown = ({ items, id }: Props) => {
  const {
    navStore: { toggleSidebarByName },
  } = useStore();

  const { t } = useTranslation();

  const sidebarId: SidebarName = `${PARCEL_LIST_SIDEBARS.card_more}_${id}`;

  return (
    <MoreSidebarContainer>
      <MoreButton
        onMouseUp={(event) => event.stopPropagation()}
        onClick={(event) => {
          event.stopPropagation();
          toggleSidebarByName(sidebarId);
        }}>
        <MoreIcon />
      </MoreButton>
      <BottomSidebar
        height='350px'
        name={sidebarId}
        withBlurredBackground>
        <MenuItems>
          {items.map((item) => (
            <MenuItem
              key={item.value}
              prefix={item.icon}
              onMouseUp={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
                toggleSidebarByName(sidebarId);
                item.onClick();
              }}>
              {t(item.label)}
            </MenuItem>
          ))}
        </MenuItems>
      </BottomSidebar>
    </MoreSidebarContainer>
  );
};

export default observer(MoreDropdown);
