import styled, { css } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';
import { Input } from '../select/select.styles';

export const PaginationContainer = styled.div`
  display: none;
  gap: 12px;
  padding: 12px 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};

  @media screen and (min-width: 650px) {
    display: grid;
    justify-content: center;
  }

  @media screen and (min-width: 650px) and (max-width: 1024px) {
    text-align: center;
    grid-template-areas:
      'pagination'
      'items-to-show'
      'pages';
  }

  @media screen and (min-width: 650px) {
    grid-template-areas:
      'pagination pagination'
      'pages items-to-show';
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'pages pagination items-to-show';
  }
`;

export const Pages = styled.div`
  font-size: ${FontSizes.small};
  letter-spacing: 0.5px;
  padding-top: 2px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  grid-area: pages;

  @media screen and (min-width: 768px) {
    width: 160px;
  }
`;

export const Pagination = styled.div`
  display: flex;
  justify-self: center;
  grid-area: pagination;
`;

interface Props {
  $currentPage: boolean;
}

export const PagesItem = styled.div<Props>`
  font-size: ${FontSizes.small};
  padding: 0.17em 0.5em;
  cursor: pointer;

  ${({ $currentPage }) =>
    $currentPage
      ? css`
          color: ${({ theme }) => theme.colors.namedColors.primary};
          font-weight: 500;
          border: 1px solid ${({ theme }) => theme.colors.namedColors.primary};
          border-radius: 6px;
        `
      : css`
          font-weight: 400;
        `};
`;
export const ItemsAmountToShow = styled.div`
  display: flex;
  gap: 12px;
  grid-area: items-to-show;
  margin-left: auto;

  & > span {
    padding-top: 2px;
    font-size: ${FontSizes.small};
    letter-spacing: 0.5px;
    line-height: 1.33;
    color: ${({ theme }) => theme.colors.baseColors.grey100};
  }

  ${Input} {
    padding: 0.145em 0.57em;
    gap: 4px;
    max-height: 20px;
    border-radius: 6px;
    color: ${({ theme }) => theme.colors.baseColors.grey100};
  }
`;

interface IDisabledArrowPage {
  $currentPage: boolean;
}

export const PaginationArrowContainer = styled.div<IDisabledArrowPage>`
  font-size: ${FontSizes.small};
  padding: 2px 6px;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: ${({ $currentPage }) => ($currentPage ? 'pointer' : 'default')};

  svg {
    display: block;
    width: 1.08em;
    height: 1em;
    color: ${({ $currentPage, theme }) =>
      $currentPage
        ? theme.colors.baseColors.grey150
        : theme.colors.baseColors.grey65};
  }
`;

export const PaginationArrowContainerReverse = styled(
  PaginationArrowContainer
)<IDisabledArrowPage>`
  transform: rotate(-180deg);
`;
