import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import { Modals } from '../../../constants/modals';
import LeaveFeedbackDialog from '../../leave-feedback/leave-feedback-dialog/leave-feedback-dialog.component';

const LeaveFeedbackModal = ({ onClose }: { onClose: () => void }) => {
  const {
    parcelListStore: { setActiveManualId, feedbackShipmentId },
    modalStore: { isModalVisible },
  } = useStore();

  const handleClose = () => {
    onClose();
    setActiveManualId(undefined);
  };

  return (
    <LeaveFeedbackDialog
      isOpen={isModalVisible(Modals.LEAVE_FEEDBACK)}
      onClose={handleClose}
      feedbackShipmentId={feedbackShipmentId}
    />
  );
};

export default observer(LeaveFeedbackModal);
