export interface CountryInfo {
  countryCode: string;
  countryName: string;
  countryNameUkr?: string;
  countryNameRu?: string;
  imgSrc: string;
}

export interface CountryLimit {
  name: string;
  maxDimensions: string;
  maxWeight: number;
  maxCost: number | string | null;
}

export interface PriorityCountriesInfo {
  [key: string]: CountryLimit;
}

export const PriorityCountries: PriorityCountriesInfo = {
  UA: {
    name: 'Ukraine',
    maxDimensions: '70x42x40',
    maxWeight: 30,
    maxCost: null,
  },
  US: {
    name: 'United States of America',
    maxDimensions: '120x60x60',
    maxWeight: 30,
    maxCost: '800$',
  },
  CA: {
    name: 'Canada',
    maxDimensions: '120x60x60',
    maxWeight: 30,
    maxCost: '150 CAD',
  },
};

export const AvailableCountriesList = [
  {
    countryCode: 'AL',
    countryName: 'Albania',
    imgSrc: '/download/flags/svg/Albania.svg',
    countryNameUkr: 'Албанія',
    countryNameRu: 'Албания',
  },
  {
    countryCode: 'AR',
    countryName: 'Argentina',
    imgSrc: '/download/flags/svg/Argentina.svg',
    countryNameUkr: 'Аргентина',
    countryNameRu: 'Аргентина',
  },
  {
    countryCode: 'AM',
    countryName: 'Armenia',
    imgSrc: '/download/flags/svg/Armenia.svg',
    countryNameUkr: 'Вірменія',
    countryNameRu: 'Армения',
  },
  {
    countryCode: 'AU',
    countryName: 'Australia',
    imgSrc: '/download/flags/svg/Australia.svg',
    countryNameUkr: 'Австралія',
    countryNameRu: 'Австралия',
  },
  {
    countryCode: 'AT',
    countryName: 'Austria',
    imgSrc: '/download/flags/svg/Austria.svg',
    countryNameUkr: 'Австрія',
    countryNameRu: 'Австрия',
  },
  {
    countryCode: 'AZ',
    countryName: 'Azerbaijan',
    imgSrc: '/download/flags/svg/Azerbaijan.svg',
    countryNameUkr: 'Азербайджан',
    countryNameRu: 'Азербайджан',
  },
  {
    countryCode: 'BE',
    countryName: 'Belgium',
    imgSrc: '/download/flags/svg/Belgium.svg',
    countryNameUkr: 'Бельгія',
    countryNameRu: 'Бельгия',
  },
  {
    countryCode: 'BA',
    countryName: 'Bosnia and Herzegovina',
    imgSrc: '/download/flags/svg/Bosnia.svg',
    countryNameUkr: 'Боснія і Герцеговина',
    countryNameRu: 'Босния и Герцеговина',
  },
  {
    countryCode: 'BR',
    countryName: 'Brazil',
    imgSrc: '/download/flags/svg/Brazil.svg',
    countryNameUkr: 'Бразилія',
    countryNameRu: 'Бразилия',
  },
  {
    countryCode: 'BG',
    countryName: 'Bulgaria',
    imgSrc: '/download/flags/svg/Bulgaria.svg',
    countryNameUkr: 'Болгарія',
    countryNameRu: 'Болгария',
  },
  {
    countryCode: 'CL',
    countryName: 'Chile',
    imgSrc: '/download/flags/svg/Chile.svg',
    countryNameUkr: 'Чилі',
    countryNameRu: 'Чили',
  },
  {
    countryCode: 'CN',
    countryName: 'China',
    imgSrc: '/download/flags/svg/China.svg',
    countryNameUkr: 'Китай',
    countryNameRu: 'Китай',
  },
  {
    countryCode: 'CO',
    countryName: 'Colombia',
    imgSrc: '/download/flags/svg/Colombia.svg',
    countryNameUkr: 'Колумбія',
    countryNameRu: 'Колумбия',
  },
  {
    countryCode: 'CR',
    countryName: 'Costa Rica',
    imgSrc: '/download/flags/svg/Costa-Rica.svg',
    countryNameUkr: 'Коста-Ріка',
    countryNameRu: 'Коста-Рика',
  },
  {
    countryCode: 'HR',
    countryName: 'Croatia',
    imgSrc: '/download/flags/svg/Croatia.svg',
    countryNameUkr: 'Хорватія',
    countryNameRu: 'Хорватия',
  },
  {
    countryCode: 'CY',
    countryName: 'Cyprus',
    imgSrc: '/download/flags/svg/Cyprus.svg',
    countryNameUkr: 'Кіпр',
    countryNameRu: 'Кипр',
  },
  {
    countryCode: 'CZ',
    countryName: 'Czechia',
    imgSrc: '/download/flags/svg/Czechia.svg',
    countryNameUkr: 'Чехія',
    countryNameRu: 'Чехия',
  },
  {
    countryCode: 'DK',
    countryName: 'Denmark',
    imgSrc: '/download/flags/svg/Denmark.svg',
    countryNameUkr: 'Данія',
    countryNameRu: 'Дания',
  },
  {
    countryCode: 'EG',
    countryName: 'Egypt',
    imgSrc: '/download/flags/svg/Egypt.svg',
    countryNameUkr: 'Єгипет',
    countryNameRu: 'Египет',
  },
  {
    countryCode: 'EE',
    countryName: 'Estonia',
    imgSrc: '/download/flags/svg/Estonia.svg',
    countryNameUkr: 'Естонія',
    countryNameRu: 'Эстония',
  },
  {
    countryCode: 'ET',
    countryName: 'Ethiopia',
    imgSrc: '/download/flags/svg/Ethiopia.svg',
    countryNameUkr: 'Ефіопія',
    countryNameRu: 'Эфиопия',
  },
  {
    countryCode: 'FI',
    countryName: 'Finland',
    imgSrc: '/download/flags/svg/Finland.svg',
    countryNameUkr: 'Фінляндія',
    countryNameRu: 'Финляндия',
  },
  {
    countryCode: 'FR',
    countryName: 'France',
    imgSrc: '/download/flags/svg/France.svg',
    countryNameUkr: 'Франція',
    countryNameRu: 'Франция',
  },
  {
    countryCode: 'GE',
    countryName: 'Georgia',
    imgSrc: '/download/flags/svg/Georgia.svg',
    countryNameUkr: 'Грузія',
    countryNameRu: 'Грузия',
  },
  {
    countryCode: 'DE',
    countryName: 'Germany',
    imgSrc: '/download/flags/svg/Germany.svg',
    countryNameUkr: 'Німеччина',
    countryNameRu: 'Германия',
  },
  {
    countryCode: 'GR',
    countryName: 'Greece',
    imgSrc: '/download/flags/svg/Greece.svg',
    countryNameUkr: 'Греція',
    countryNameRu: 'Греция',
  },
  {
    countryCode: 'HK',
    countryName: 'Hong Kong',
    imgSrc: '/download/flags/svg/Hong-Kong.svg',
    countryNameUkr: 'Гонконг',
    countryNameRu: 'Гонконг',
  },
  {
    countryCode: 'HU',
    countryName: 'Hungary',
    imgSrc: '/download/flags/svg/Hungary.svg',
    countryNameUkr: 'Угорщина',
    countryNameRu: 'Венгрия',
  },
  {
    countryCode: 'IS',
    countryName: 'Iceland',
    imgSrc: '/download/flags/svg/Iceland.svg',
    countryNameUkr: 'Ісландія',
    countryNameRu: 'Исландия',
  },
  {
    countryCode: 'IN',
    countryName: 'India',
    imgSrc: '/download/flags/svg/India.svg',
    countryNameUkr: 'Індія',
    countryNameRu: 'Индия',
  },
  {
    countryCode: 'ID',
    countryName: 'Indonesia',
    imgSrc: '/download/flags/svg/Indonesia.svg',
    countryNameUkr: 'Індонезія',
    countryNameRu: 'Индонезия',
  },
  {
    countryCode: 'IL',
    countryName: 'Israel',
    imgSrc: '/download/flags/svg/Israel.svg',
    countryNameUkr: 'Ізраїль',
    countryNameRu: 'Израиль',
  },
  {
    countryCode: 'IT',
    countryName: 'Italy',
    imgSrc: '/download/flags/svg/Italy.svg',
    countryNameUkr: 'Італія',
    countryNameRu: 'Италия',
  },
  {
    countryCode: 'JP',
    countryName: 'Japan',
    imgSrc: '/download/flags/svg/Japan.svg',
    countryNameUkr: 'Японія',
    countryNameRu: 'Япония',
  },
  {
    countryCode: 'KZ',
    countryName: 'Kazakhstan',
    imgSrc: '/download/flags/svg/Kazakhstan.svg',
    countryNameUkr: 'Казахстан',
    countryNameRu: 'Казахстан',
  },
  {
    countryCode: 'KE',
    countryName: 'Kenya',
    imgSrc: '/download/flags/svg/Kenya.svg',
    countryNameUkr: 'Кенія',
    countryNameRu: 'Кения',
  },
  {
    countryCode: 'KW',
    countryName: 'Kuwait',
    imgSrc: '/download/flags/svg/Kuwait.svg',
    countryNameUkr: 'Кувейт',
    countryNameRu: 'Кувейт',
  },
  {
    countryCode: 'KG',
    countryName: 'Kyrgyzstan',
    imgSrc: '/download/flags/svg/Kyrgyzstan.svg',
    countryNameUkr: 'Киргизстан',
    countryNameRu: 'Кыргызстан',
  },
  {
    countryCode: 'LV',
    countryName: 'Latvia',
    imgSrc: '/download/flags/svg/Latvia.svg',
    countryNameUkr: 'Латвія',
    countryNameRu: 'Латвия',
  },
  {
    countryCode: 'LT',
    countryName: 'Lithuania',
    imgSrc: '/download/flags/svg/Lithuania.svg',
    countryNameUkr: 'Литва',
    countryNameRu: 'Литва',
  },
  {
    countryCode: 'LU',
    countryName: 'Luxembourg',
    imgSrc: '/download/flags/svg/Luxembourg.svg',
    countryNameUkr: 'Люксембург',
    countryNameRu: 'Люксембург',
  },
  {
    countryCode: 'MT',
    countryName: 'Malta',
    imgSrc: '/download/flags/svg/Malta.svg',
    countryNameUkr: 'Мальта',
    countryNameRu: 'Мальта',
  },
  {
    countryCode: 'MX',
    countryName: 'Mexico',
    imgSrc: '/download/flags/svg/Mexico.svg',
    countryNameUkr: 'Мексика',
    countryNameRu: 'Мексика',
  },
  {
    countryCode: 'MD',
    countryName: 'Moldova',
    imgSrc: '/download/flags/svg/Moldova.svg',
    countryNameUkr: 'Молдова',
    countryNameRu: 'Молдавия',
  },
  {
    countryCode: 'ME',
    countryName: 'Montenegro',
    imgSrc: '/download/flags/svg/Montenegro.svg',
    countryNameUkr: 'Чорногорія',
    countryNameRu: 'Черногория',
  },
  {
    countryCode: 'NL',
    countryName: 'Netherlands',
    imgSrc: '/download/flags/svg/Netherlands.svg',
    countryNameUkr: 'Нідерланди',
    countryNameRu: 'Нидерланды',
  },
  {
    countryCode: 'NZ',
    countryName: 'New Zealand',
    imgSrc: '/download/flags/svg/New-Zealand.svg',
    countryNameUkr: 'Нова Зеландія',
    countryNameRu: 'Новая Зеландия',
  },
  {
    countryCode: 'NG',
    countryName: 'Nigeria',
    imgSrc: '/download/flags/svg/Nigeria.svg',
    countryNameUkr: 'Нігерія',
    countryNameRu: 'Нигерия',
  },
  {
    countryCode: 'NO',
    countryName: 'Norway',
    imgSrc: '/download/flags/svg/Norway.svg',
    countryNameUkr: 'Норвегія',
    countryNameRu: 'Норвегия',
  },
  {
    countryCode: 'PK',
    countryName: 'Pakistan',
    imgSrc: '/download/flags/svg/Pakistan.svg',
    countryNameUkr: 'Пакистан',
    countryNameRu: 'Пакистан',
  },
  {
    countryCode: 'PE',
    countryName: 'Peru',
    imgSrc: '/download/flags/svg/Peru.svg',
    countryNameUkr: 'Перу',
    countryNameRu: 'Перу',
  },
  {
    countryCode: 'PL',
    countryName: 'Poland',
    imgSrc: '/download/flags/svg/Poland.svg',
    countryNameUkr: 'Польща',
    countryNameRu: 'Польша',
  },
  {
    countryCode: 'PT',
    countryName: 'Portugal',
    imgSrc: '/download/flags/svg/Portugal.svg',
    countryNameUkr: 'Португалія',
    countryNameRu: 'Португалия',
  },
  {
    countryCode: 'QA',
    countryName: 'Qatar',
    imgSrc: '/download/flags/svg/Qatar.svg',
    countryNameUkr: 'Катар',
    countryNameRu: 'Катар',
  },
  {
    countryCode: 'RO',
    countryName: 'Romania',
    imgSrc: '/download/flags/svg/Romania.svg',
    countryNameUkr: 'Румунія',
    countryNameRu: 'Румыния',
  },
  {
    countryCode: 'SA',
    countryName: 'Saudi Arabia',
    imgSrc: '/download/flags/svg/Saudi-Arabia.svg',
    countryNameUkr: 'Саудівська Аравія',
    countryNameRu: 'Саудовская Аравия',
  },
  {
    countryCode: 'RS',
    countryName: 'Serbia',
    imgSrc: '/download/flags/svg/Serbia.svg',
    countryNameUkr: 'Сербія',
    countryNameRu: 'Сербия',
  },
  {
    countryCode: 'SG',
    countryName: 'Singapore',
    imgSrc: '/download/flags/svg/Singapore.svg',
    countryNameUkr: 'Сінгапур',
    countryNameRu: 'Сингапур',
  },
  {
    countryCode: 'SK',
    countryName: 'Slovakia',
    imgSrc: '/download/flags/svg/Slovakia.svg',
    countryNameUkr: 'Словаччина',
    countryNameRu: 'Словакия',
  },
  {
    countryCode: 'SI',
    countryName: 'Slovenia',
    imgSrc: '/download/flags/svg/Slovenia.svg',
    countryNameUkr: 'Словенія',
    countryNameRu: 'Словения',
  },
  {
    countryCode: 'ZA',
    countryName: 'South Africa',
    imgSrc: '/download/flags/svg/South-Africa.svg',
    countryNameUkr: 'Південно-Африканська Республіка',
    countryNameRu: 'Южная Африка',
  },
  {
    countryCode: 'ES',
    countryName: 'Spain',
    imgSrc: '/download/flags/svg/Spain.svg',
    countryNameUkr: 'Іспанія',
    countryNameRu: 'Испания',
  },
  {
    countryCode: 'LK',
    countryName: 'Sri Lanka',
    imgSrc: '/download/flags/svg/Sri-Lanka.svg',
    countryNameUkr: 'Шрі-Ланка',
    countryNameRu: 'Шри-Ланка',
  },
  {
    countryCode: 'SE',
    countryName: 'Sweden',
    imgSrc: '/download/flags/svg/Sweden.svg',
    countryNameUkr: 'Швеція',
    countryNameRu: 'Швеция',
  },
  {
    countryCode: 'CH',
    countryName: 'Switzerland',
    imgSrc: '/download/flags/svg/Switzerland.svg',
    countryNameUkr: 'Швейцарія',
    countryNameRu: 'Швейцария',
  },
  {
    countryCode: 'TZ',
    countryName: 'Tanzania',
    imgSrc: '/download/flags/svg/Tanzania.svg',
    countryNameUkr: 'Танзанія',
    countryNameRu: 'Танзания',
  },
  {
    countryCode: 'AE',
    countryName: 'United Arab Emirates',
    imgSrc: '/download/flags/svg/United-Arab-Emirates.svg',
    countryNameUkr: "Об'єднані Арабські Емірати",
    countryNameRu: 'Объединенные Арабские Эмираты',
  },
  {
    countryCode: 'GB',
    countryName: 'United Kingdom',
    imgSrc: '/download/flags/svg/United-Kingdom.svg',
    countryNameUkr: 'Сполучене Королівство Великої Британії',
    countryNameRu: 'Великобритания',
  },
  {
    countryCode: 'UZ',
    countryName: 'Uzbekistan',
    imgSrc: '/download/flags/svg/Uzbekistan.svg',
    countryNameUkr: 'Узбекистан',
    countryNameRu: 'Узбекистан',
  },
];
