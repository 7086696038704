import styled from 'styled-components';

interface SpinnerOverlayProps {
  $isFullScreen?: boolean;
}

export const SpinnerOverlay = styled.div<SpinnerOverlayProps>`
  height: ${({ $isFullScreen }) => ($isFullScreen ? '100dvh' : '100%')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerCircle = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 3px solid ${({ theme }) => theme.colors.namedColors.primary};
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.namedColors.white};
  border-right-color: ${({ theme }) => theme.colors.namedColors.white};
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
