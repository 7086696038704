import styled, { css } from 'styled-components';
import { InputSearchWithSidebarContainer } from '../../../common/input-search-with-sidebar/input-search-with-sidebar.styles';
import { SidebarBody } from '../../../common/sidebar-right/sidebar.styles';
import {
  HeaderSecondary,
  TextBody,
  TextInput,
} from '../../../common/typography/typography.styles';
import { InputSelect } from '../../../common/input-select-with-sidebar/input-select-with-sidebar.styles';
import { InputMessageContainer } from '../../../common/input-message/input-message.styles';

export const ProhibitedItemsWithSidebarContainer = styled(
  InputSearchWithSidebarContainer
)`
  ${SidebarBody} {
    padding: 0;
  }
`;

export const ProhibitedItemsSidebarContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

export const ProhibitedItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 2.4rem;
`;

export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.namedColors.primary};
`;

export const InputSelectProhibitedItems = styled(InputSelect)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.namedColors.primary} !important;
  background-color: #e5f4ff !important;
  display: flex;
  gap: 0.8rem;
`;

export const InputPlaceholderDark = styled.span`
  ${TextInput};
`;

export const Main = styled.div<{ $isEmpty: boolean }>`
  display: grid;
  gap: 8px;

  ${({ $isEmpty }) =>
    $isEmpty &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    `}

  @media screen and (min-width: 768px) {
    gap: 16px;
  }

  ${InputMessageContainer} {
    margin-top: 0;
  }
`;

export const Content = styled.div`
  display: grid;
  gap: 16px;

  @media screen and (min-width: 768px) {
    gap: 20px;
  }
`;

interface TextBlockProps {
  $generalStyle?: 'li';
  $isOnlyNotice?: boolean;
}

export const TextBlock = styled.div<TextBlockProps>`
  ${({ $isOnlyNotice }) =>
    $isOnlyNotice
      ? css`
          margin-bottom: -12px;
          @media screen and (min-width: 768px) {
            margin-bottom: -8px;
          }
        `
      : css`
          display: grid;
          gap: 0.4rem;
        `}
`;

export const TextItems = styled.div<{ $generalStyle?: 'li' }>`
  ${({ $generalStyle }) =>
    $generalStyle &&
    css`
      position: relative;
      padding-left: 15px;
      margin-left: 10px;
      &::before {
        position: absolute;
        top: 9px;
        left: 0;
        content: '•';
        color: currentColor;
        font-size: 30px;
        line-height: 0;
      }
    `}
`;

export type TextItemStyle = 'default' | 'green' | 'red';

interface TextItemProps {
  $style: TextItemStyle;
}

const getTextItemColor = (style: TextItemStyle) =>
  ({
    default: '#2C363F',
    green: '#39A55B',
    red: '#BA2243',
  })[style];

export const TextItem = styled(HeaderSecondary).attrs({
  as: 'span',
  $size: 'medium',
})<TextItemProps>`
  color: ${({ $style }) => getTextItemColor($style)};
  font-weight: 500;
`;

export const TextNotice = styled(TextBody)``;
