import styled, { css } from 'styled-components';
import {
  TextInput,
  TextInputPlaceholder,
  TextLabel,
  FontSizes,
} from '../typography/typography.styles';
import errorIcon from '../../assets/error-icon.svg';
import { BaseMarginComponent } from '../base-margin/base-margin.component';

interface FieldProps {
  $error?: boolean;
  $maxInputHeight?: string;
  $borderNone?: boolean;
  $isDisabled?: boolean;
}

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 6px;
`;

interface HidableContainerProps {
  $isHidden?: boolean;
}

export const HidableContainer = styled(
  BaseMarginComponent('div')
)<HidableContainerProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
`;

export const FieldContainer = styled(HidableContainer)`
  width: 100%;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

interface ISuffixPrefixProps {
  $isClickable?: boolean;
}

const suffixPrefixStyles = css`
  display: flex;
  flex-shrink: 0;
`;

export const InputPrefix = styled.span<ISuffixPrefixProps>`
  ${suffixPrefixStyles};
  pointer-events: ${({ $isClickable = false }) =>
    $isClickable ? 'auto' : 'none'};
`;

export const InputSuffix = styled.span<ISuffixPrefixProps>`
  ${suffixPrefixStyles};
  pointer-events: ${({ $isClickable = false }) =>
    $isClickable ? 'auto' : 'none'};
`;

export const inputFieldStyles = css<FieldProps>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  border: ${({ $borderNone, theme }) =>
    $borderNone ? 'none' : `1px solid ${theme.colors.baseColors.grey50}`};
  border-radius: 10px;
  padding: 1rem 1.6rem;
  outline: none;
  transition: border-color 0.2s;
  max-height: ${({ $maxInputHeight = '4rem' }) => $maxInputHeight};
  ${TextInput}
  font-size: ${FontSizes.medium};
  font-family: 'Noto Color Emoji', Montserrat !important;
  cursor: text;

  @media screen and (min-width: 768px) {
    font-size: ${FontSizes.normal};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: default !important;
      background-color: #f5f6f7;

      ${InputSuffix},
      ${InputPrefix} {
        pointer-events: none;
      }
    `}
`;

export const InputField = styled.label<FieldProps>`
  background-color: #fff;
  ${inputFieldStyles};

  ${({ $error }) =>
    $error &&
    css`
      border-color: ${({ theme }) => theme.colors.input.errorColor};
    `}
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.input.borderColorFocus};
  }

  input {
    display: block;
    width: 100%;
    border: none;
    font: inherit;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background: none;
      color: ${({ theme }) => theme.colors.baseColors.grey150};
      cursor: default !important;
    }

    &::placeholder {
      ${TextInputPlaceholder}
      font-size: ${FontSizes.medium};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: 768px) {
        font-size: ${FontSizes.normal};
      }
    }
  }
`;

interface Props {
  $isDisabled?: boolean;
}

export const ArrowIconContainer = styled.div<Props>`
  display: flex;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  right: 1.6rem;

  svg path {
    transition: fill 0.2s;
  }
`;

export const InputSelect = styled.div<Props>`
  ${inputFieldStyles};
  cursor: pointer;
  padding: 1rem 5.2rem 1rem 1.6rem !important;

  &:focus {
    border-color: ${({ theme }) => theme.colors.baseColors.blue80};
  }

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      pointer-events: none;
    `}
  &:hover ${ArrowIconContainer} {
    svg path {
      fill: ${({ $isDisabled, theme }) =>
        $isDisabled ? theme.colors.baseColors.grey80 : theme.colors.primary};
    }
  }

  @media (max-width: 1100px) {
    padding: 1rem 4.2rem 1rem 1.6rem !important;
  }
`;

export const InputContainer = styled.div<FieldProps>`
  ${InputField},
  ${InputSelect} {
    max-height: ${({ $maxInputHeight = '4rem' }) => $maxInputHeight};
  }

  ${TextLabel} {
    position: relative;
    display: inline-block;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
`;

export const InfoTextContainer = styled.div`
  pointer-events: none;

  ${TextInputPlaceholder}
  span {
    ${TextInputPlaceholder}
  }
`;

export const ErrorIcon = styled.span`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  background: url(${errorIcon}) no-repeat content-box;
`;

export const Hint = styled.div`
  display: flex;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const Note = styled(TextLabel).attrs({ as: 'span' })`
  color: #96999e;
  font-weight: 400;
`;
