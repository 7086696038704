import { IDropdownItem } from '../../common/select/select.component';
import {
  PerPageParcelCount,
  ShipmentQueryFilter,
  ShipmentQueryOrder,
} from '../../stores/parcelListStore';
import { ReactComponent as OrderAscendingIcon } from '../../assets/order-ascending.svg';
import { ReactComponent as OrderDescendingIcon } from '../../assets/order-descending.svg';
import { ReactComponent as DateAscendingIcon } from '../../assets/date-ascending.svg';
import { ReactComponent as DateDescendingIcon } from '../../assets/date-descending.svg';
import {
  ParcelListShipmentRecipient,
  ParcelListShipmentSender,
  ShipmentStatus,
} from '../../models/parcelDeliveryMiles';
import { TagColor, TagType } from '../../common/tag/tag.component';
import { ParcelListSidebarNames } from '../../types/sidebar.types';

export type IStatusItem = IDropdownItem<
  Exclude<ShipmentQueryFilter, 'from_me' | 'to_me'>
>;

export const statusItems: IStatusItem[] = [
  // labels here are keys to translation file, which are later passed to i18.t func in Select component
  {
    label: 'favourites',
    value: 'favorite',
  },
  {
    label: 'in_delivery',
    value: 'in_delivery',
  },
  {
    label: 'ready_to_pick_up',
    value: 'ready_to_pick_up',
  },
  {
    label: 'received',
    value: 'received',
  },
];

export type IDirectionItem = IDropdownItem<
  Extract<ShipmentQueryFilter, 'from_me' | 'to_me' | 'all'>
>;

export type IPerPageItem = IDropdownItem<PerPageParcelCount>;

export const PerPageCountVariants: IPerPageItem[] = [
  {
    label: '12',
    value: 12,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '48',
    value: 48,
  },
  {
    label: '100',
    value: 100,
  },
];

export type IOrderItem = IDropdownItem<ShipmentQueryOrder>;

export const DEFAULT_ORDER_ITEM: IOrderItem = {
  // labels here is a key to translation file, which are later passed to i18.t func
  label: 'creation_date',
  icon: <OrderAscendingIcon />,
  glyph: 'order-ascending',
  value: 'created_at_from_closest',
};

export const orderItems: IOrderItem[] = [
  // labels here are keys to translation file, which are later passed to i18.t func
  DEFAULT_ORDER_ITEM,
  {
    label: 'creation_date',
    icon: <OrderDescendingIcon />,
    glyph: 'order-descending',
    value: 'created_at_from_latest',
  },
  {
    label: 'delivery_date',
    icon: <DateAscendingIcon />,
    glyph: 'date-ascending',
    value: 'delivery_at_from_closest',
  },
  {
    label: 'delivery_date',
    icon: <DateDescendingIcon />,
    glyph: 'date-descending',
    value: 'delivery_at_from_latest',
  },
];

export const orderItemsDrafts: IOrderItem[] = [
  DEFAULT_ORDER_ITEM,
  {
    label: 'creation_date',
    icon: <OrderDescendingIcon />,
    glyph: 'order-descending',
    value: 'created_at_from_latest',
  },
];

export const statusItemsMobile: IStatusItem[] = [
  // labels here are keys to translation file, which are later passed to i18.t func when passed to FilterOption component in parect-list-filters
  {
    label: 'favourites',
    value: 'favorite',
  },
  {
    label: 'in_delivery',
    value: 'in_delivery',
  },
  {
    label: 'received',
    value: 'received',
  },
  {
    label: 'ready_to_pick_up',
    value: 'ready_to_pick_up',
  },
];

export const directionItemsMobile: IDirectionItem[] = [
  // labels here are keys to translation file, which are later passed to i18.t func when passed to FilterOption component in parect-list-filters
  {
    label: 'to_me',
    value: 'to_me',
  },
  {
    label: 'from_me',
    value: 'from_me',
  },
  {
    label: 'all',
    value: 'all',
  },
];

export const PARCEL_LIST_SIDEBARS: Record<string, ParcelListSidebarNames> = {
  order: 'parcel_list_order',
  filters: 'parcel_list_filters',
  card_more: 'parcel_list_card_more',
  details: 'parcel_list_details',
};

export interface ICardActionItem {
  label: string;
  value: string;
  icon?: React.ReactNode;
  onClick: Function;
}

export const getAddress = (
  obj: ParcelListShipmentSender | ParcelListShipmentRecipient
) =>
  ((obj.address!.city && `${obj!.address!.city}, `) || '') +
  obj.address!.country;

export interface StatusBadge {
  color: TagColor;
  type?: TagType;
  text: string;
}

type StatusBadgeConfig = {
  [key in ShipmentStatus]: StatusBadge;
};

export const getStatusBadgeConfig = (status: ShipmentStatus | null) => {
  // text here are keys to translation file, which are later passed to i18.t func
  const statusBadgeConfig: StatusBadgeConfig = {
    in_delivery: { color: 'blue', text: 'in_delivery' },
    ready_to_pick_up: { color: 'green', text: 'ready_to_pick_up' },
    surcharge: { color: 'red', text: 'surcharge' },
    created: { color: 'grey', text: 'created' },
    received: { color: 'grey', type: 'filled', text: 'received' },
    draft: { color: 'white', text: 'draft' },
    paid: { color: 'blue', text: 'paid' },
    sent: { color: 'blue', text: 'sent' },
    custom_clearance: { color: 'blue', text: 'custom_clearance' },
    declined: { color: 'red', text: 'declined' },
    lost: { color: 'grey', type: 'filled', text: 'lost' },
  };

  return statusBadgeConfig[status || 'created'];
};

export const ListRowHeaderValues = [
  // text here are keys to translation file, which are later passed to i18.t func
  { id: 'tracking-number', text: 'tracking_number', width: '16rem' },
  { id: 'creation-date', text: 'creation_date', width: '12rem' },
  { id: 'delivery-date', text: 'delivery_date', width: '12rem' },
  { id: 'status', text: 'status', width: '16rem' },
  { id: 'parcel-content', text: 'parcel_content', width: '24rem' },
  { id: 'from', text: 'from', width: '16rem' },
  { id: 'to', text: 'to', width: '16rem' },
  { id: 'sender', text: 'sender', width: '16rem' },
  { id: 'receiver', text: 'receiver', width: '16rem' },
  { id: 'payment-button', text: 'parcelList_Payment', width: '17.2rem' },
  { id: 'more', text: '', width: '4rem' },
];

export const getFavoriteLabel = (isFavorite: boolean) =>
  // returns key to the translation file, which is later passed to i18.t func
  isFavorite ? 'remove_from_favourites' : 'add_to_favourites';

export interface IParcelListPathValue {
  header: string;
  path: 'parcellist' | 'drafts' | 'archive';
}

export const listPathValues: Record<string, IParcelListPathValue> = {
  // headers here are keys to translation file, which are later passed to i18.t func
  parcellist: {
    header: 'parcel_list',
    path: 'parcellist',
  },
  drafts: {
    header: 'drafts',
    path: 'drafts',
  },
  archive: {
    header: 'archive',
    path: 'archive',
  },
};

export const getOrderItems = (route: string) =>
  ({
    parcellist: orderItems,
    archive: orderItems,
    drafts: orderItemsDrafts,
  })[route];

export const DEFAULT_PAGE = 1;

export const DEFAULT_PER_PAGE = 12;
