import Bowser from 'bowser';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import React from 'react';
import { translate } from '../../../i18n';
import { Address } from '../../models/user';
import { HTML_CLASSES } from '../../constants/html-classes';
import { scrollbarWidth } from '../../hooks/use-init-app.hook';
import { store } from '../../stores/store';
import { ApiResponseStatus } from '../../constants/server';
import {
  FirebaseErrorCodes,
  handleFirebaseError,
} from '../firebase/firebase.utils';
import { Geocode } from '../../models/pudoItem';

export function getCurrentTimezoneOffset(): string {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.abs(offset / 60);
  const minutes = Math.abs(offset % 60);
  const sign = offset <= 0 ? '+' : '-';

  return `${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

interface CopyToClipboardProps {
  text: string;
  successMessage: string;
  errorMessage?: string;
}

export const copyToClipboardFallback = ({
  text,
  successMessage,
  errorMessage = translate('unable_to_copy_text_to_clipboard.'),
}: CopyToClipboardProps) => {
  const { toastSuccess, toastError } = store.commonStore;
  const textArea = document.createElement('textarea');
  Object.assign(textArea, {
    value: text,
    position: 'fixed',
    opacity: '0',
    pointerEvents: 'none',
    zIndex: '-1',
    left: '-9999px',
  });

  document.body.appendChild(textArea);
  textArea.select();
  try {
    document.execCommand('copy');
    toastSuccess(successMessage);
  } catch (err) {
    toastError(errorMessage);
  }
  document.body.removeChild(textArea);
};

export const copyToClipboard = async ({
  text,
  successMessage,
  errorMessage = translate('unable_to_copy_text_to_clipboard.'),
}: CopyToClipboardProps) => {
  const { toastSuccess, toastError } = store.commonStore;
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      toastSuccess(successMessage);
    } else {
      copyToClipboardFallback({ text, successMessage });
    }
  } catch (error) {
    toastError(errorMessage);
    console.error('Failed to copy text: ', error);
  }
};

export const formatDate = (
  dateTime: string | null,
  includeTime?: boolean,
  dateTimeSeparator: string = ' '
) => {
  if (!dateTime) return;

  const dateTimeSplitted = dateTime.split(' ');
  const date = dateTimeSplitted[0].split('-').reverse().join('.');
  const time = convertToBrowserTimezone(dateTime);
  return includeTime && dateTimeSplitted.length > 1
    ? [date, time].join(dateTimeSeparator)
    : date;
};

function convertToBrowserTimezone(receivedDateTime: string): string {
  // Replace the space with 'T' to make it ISO-compatible
  const isoDateTime = `${receivedDateTime.replace(' ', 'T')}Z`;

  // Create a Date object (treated as UTC because of the 'Z')
  const utcDate = new Date(isoDateTime);

  // Convert to browser's local timezone using toLocaleString
  return utcDate.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
  });
}

export const exhaustiveGuard = (value: never) => {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(value)}`
  );
};

export const getFullUserAddress = (address: Address) => {
  const addressParts: Array<keyof Address> = [
    'city',
    'building',
    'street',
    'apartment',
    'section',
    'buzz_code',
  ];

  return addressParts
    .map((part) => address?.[part] ?? '')
    .filter(Boolean)
    .join(', ');
};

let scrollPosition = 0;

export const lockBodyScroll = () => {
  scrollPosition = window.scrollY;

  document.body.classList.add(HTML_CLASSES.noScroll);

  if (scrollbarWidth > 0) {
    document.body.style.paddingRight = `${scrollbarWidth}px`;
  }

  window.scrollTo(0, scrollPosition);
};

export const unlockBodyScroll = () => {
  document.body.classList.remove(HTML_CLASSES.noScroll);
  document.body.style.paddingRight = '0';

  window.scrollTo(0, scrollPosition);
};

export const createLinkToFile = ({
  data,
  fileName,
  shouldDownloadImmediately = true,
}: {
  data: string;
  fileName: string;
  shouldDownloadImmediately?: boolean;
}) => {
  const link = document.createElement('a');
  Object.assign(link, {
    href: data,
    target: !shouldDownloadImmediately ? '_self' : '_blank',
    ...(!shouldDownloadImmediately && { download: fileName }),
  });
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const printBlob = async (
  blob: Blob | null | undefined,
  fileName: string
) => {
  if (!blob) {
    console.error('No blob provided for printing');
    return;
  }
  const data = window.URL.createObjectURL(blob);
  const parser = Bowser.getParser(navigator.userAgent);

  if (parser.getPlatformType() === 'mobile') {
    const file = new File([blob], fileName, {
      type: blob.type,
    });
    const shareData = {
      files: [file],
      ...(parser.getOS().name === 'Android' && {
        title: fileName,
      }),
    };
    if (!navigator.share || !navigator.canShare(shareData)) {
      createLinkToFile({ data, fileName, shouldDownloadImmediately: false });
    } else {
      navigator.share(shareData).catch((err) => {
        if (err.name === 'AbortError') {
          return;
        }
        throw new Error('something_went_wrong_try_again');
      });
    }
  } else {
    createLinkToFile({
      data,
      fileName,
      shouldDownloadImmediately: parser.getBrowserName() !== 'Firefox',
    });
  }
};

export const snakeToCamel = (value: string) =>
  value.replace(/(_\w)/g, (match) => match[1].toUpperCase());

// GPT generated
export function copyValues<T extends object>(target: T, source: Partial<T>): T {
  Object.keys(source).forEach((key) => {
    const typedKey = key as keyof T;

    // Check if both target and source values for the key are objects and not null
    const sourceValue = source[typedKey];
    const targetValue = target[typedKey];

    if (
      typeof sourceValue === 'object' &&
      sourceValue !== null &&
      typeof targetValue === 'object' &&
      targetValue !== null
    ) {
      // Recursively copy nested objects
      copyValues(targetValue, sourceValue);
    } else {
      // Otherwise, just assign the value
      target[typedKey] = sourceValue as T[keyof T];
    }
  });

  return target;
}

// Shallow comparison
export function isObjectsEqual(
  objectA: object | null | undefined,
  objectB: object | null | undefined,
  sourceOfKeys: object | null = null
) {
  if (!objectA && !objectB) return true;
  if (!objectA) return false;
  if (!objectB) return false;
  const keys = Object.keys(sourceOfKeys ?? objectA);

  return keys.every((key) => (objectA as any)[key] === (objectB as any)[key]);
}

export function isAnyValueEmpty(object: object) {
  return Object.values(object).some((value) => !value);
}

async function handleAxiosErrors(
  error: AxiosError,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
): Promise<string | React.ReactNode> {
  if (error?.name === 'CanceledError') {
    console.error('agent: Request aborted');
    return;
  }

  let toastMessage: string = '';

  let errorData = error.response?.data as any;

  if (errorData instanceof Blob) {
    errorData = JSON.parse(await errorData.text());
  }

  switch (error.response?.status) {
    case ApiResponseStatus.VALIDATION_ERROR:
      if (errorData.data) {
        toastMessage = errorData.data.message;
      } else {
        toastMessage = errorData.message;
      }
      break;

    case ApiResponseStatus.SERVER_ERROR:
      toastMessage = translate('server_error_description');
      break;

    default:
      toastMessage = errorData.message;
      break;
  }

  if (!toastMessage) {
    toastMessage = error.message;
  }

  return customFormatter
    ? customFormatter(toastMessage, error.response?.status)
    : toastMessage;
}

function handleStringError(
  error: string,
  customFormatter?: (error: string) => React.ReactNode
) {
  return customFormatter ? customFormatter(error) : error;
}

async function getToastMessage(
  error: unknown,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
): Promise<string | React.ReactNode> {
  if (React.isValidElement(error)) return error;

  if (error instanceof AxiosError) {
    if (error.response?.status === ApiResponseStatus.UNAUTHORIZED) {
      return;
    }

    return handleAxiosErrors(error, customFormatter);
  }

  if (error instanceof FirebaseError) {
    return handleFirebaseError(error, customFormatter);
  }

  return handleStringError(error?.toString() ?? '', customFormatter);
}

export async function handleError(
  error: unknown,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
) {
  const toastMessage = await getToastMessage(error, customFormatter);

  if (!toastMessage) {
    return;
  }

  store.commonStore.toastError(toastMessage);
}

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export function removeDuplicates<T, K>(
  items: T[] | undefined,
  idSelector: (item: T) => K
) {
  if (!items) return undefined;
  const seenIds = new Set<K>();
  return items.filter((item) => {
    if (seenIds.has(idSelector(item))) {
      return false;
    }
    seenIds.add(idSelector(item));
    return true;
  });
}

export function constructGoogleMapsUrl(
  destination: Geocode,
  origin?: Geocode,
  travelMode = 'driving'
) {
  if (origin) {
    return `https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}&destination=${destination.latitude},${destination.longitude}&travelmode=${travelMode}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${destination.latitude},${destination.longitude}`;
}
