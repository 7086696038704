import styled from 'styled-components';

interface Props {
  $layout?: 'row' | 'column';
  $gap?: string;
}

export const ParcelDetailsSearchInputsContainer = styled.div<Props>`
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100%;
  gap: ${({ $gap = '2.4rem' }) => $gap};
  flex-direction: ${({ $layout = 'row' }) => $layout};
`;
