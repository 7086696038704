import { ReactComponent as GoogleIcon } from '../../assets/google-icon.svg';
import { ReactComponent as AppleIcon } from '../../assets/apple-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus-icon.svg';
import { ReactComponent as ListIcon } from '../../assets/list.svg';
import { ReactComponent as GridIcon } from '../../assets/grid.svg';
import { ReactComponent as FilterIcon } from '../../assets/filter-icon.svg';
import { ReactComponent as OrderAscendingIcon } from '../../assets/order-ascending.svg';
import { ReactComponent as OrderDescendingIcon } from '../../assets/order-descending.svg';
import { ReactComponent as DateAscendingIcon } from '../../assets/date-ascending.svg';
import { ReactComponent as DateDescendingIcon } from '../../assets/date-descending.svg';
import { ReactComponent as ChevronLeft } from '../../assets/chevron-left-icon.svg';
import { ReactComponent as ChevronRight } from '../../assets/chevron-right-icon.svg';
import { ReactComponent as XCrossIcon } from '../../assets/xcross-icon.svg';
import { ReactComponent as BarcodeIcon } from '../../assets/barcode.svg';
import { ReactComponent as QrcodeIcon } from '../../assets/qrcode.svg';
import { ReactComponent as AddressBook } from '../../assets/address-book.svg';
import { ReactComponent as LoginIcon } from '../../assets/login-icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/arrow-left.svg';
import { ReactComponent as LocationCrossIcon } from '../../assets/location-cross-icon.svg';
import { ReactComponent as MapIcon } from '../../assets/map.svg';

export type ButtonIconGlyph =
  | 'plus'
  | 'minus'
  | 'google'
  | 'apple'
  | 'list'
  | 'grid'
  | 'filter'
  | 'order-ascending'
  | 'order-descending'
  | 'date-ascending'
  | 'date-descending'
  | 'chevron-left'
  | 'chevron-right'
  | 'x-cross'
  | 'bar-code'
  | 'qr-code'
  | 'address-book'
  | 'login'
  | 'arrow-left'
  | 'location-cross'
  | 'map';

type Icon = {
  [key in ButtonIconGlyph]: React.ReactNode;
};

export const icons: Icon = {
  plus: <PlusIcon />,
  minus: <MinusIcon />,
  google: <GoogleIcon />,
  apple: <AppleIcon />,
  list: <ListIcon />,
  grid: <GridIcon />,
  filter: <FilterIcon />,
  login: <LoginIcon />,
  map: <MapIcon />,
  'order-ascending': <OrderAscendingIcon />,
  'order-descending': <OrderDescendingIcon />,
  'date-ascending': <DateAscendingIcon />,
  'date-descending': <DateDescendingIcon />,
  'chevron-left': <ChevronLeft />,
  'chevron-right': <ChevronRight />,
  'x-cross': <XCrossIcon />,
  'bar-code': <BarcodeIcon />,
  'qr-code': <QrcodeIcon />,
  'address-book': <AddressBook />,
  'arrow-left': <ArrowLeftIcon />,
  'location-cross': <LocationCrossIcon />,
};

export const getIcon = (icon: ButtonIconGlyph) => icons[icon];
