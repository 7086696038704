import styled from 'styled-components';

export const Container = styled.div`
  --padding: 16px;

  padding-left: var(--padding);
  padding-right: var(--padding);
  margin: 0 auto;
  max-width: 144rem;
  width: 100%;

  @media screen and (min-width: 650px) {
    --padding: 24px;
  }
`;
