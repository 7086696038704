import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from '../stores/store';
import { getLastPathSegment } from '../utils/uri/uri.utils';
import { AppRoute } from '../constants/route';

// Resets isGuestParcelCreation to false when leaving guest parcel creation (when visiting main page (/portal) or auth (/auth))
export const useGuestShippingModeReset = () => {
  const location = useLocation();
  const {
    parcelCreationStore: { setGuestRecaptchaToken },
    commonStore: { setIsGuestParcelCreation },
  } = useStore();

  useEffect(() => {
    const lastPathSegment = getLastPathSegment(location.pathname);
    const guestShippingProhibitedRoutes = [AppRoute.AUTH, AppRoute.PORTAL];
    if (guestShippingProhibitedRoutes.includes(lastPathSegment)) {
      setIsGuestParcelCreation(false);
      setGuestRecaptchaToken(null);
    }
  }, [setIsGuestParcelCreation, setGuestRecaptchaToken, location]);
};
