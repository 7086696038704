import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import {
  ParcelListShipment,
  Shipment,
} from '../../../models/parcelDeliveryMiles';
import Button from '../../../common/button/button.component';
import { ButtonContentStyle, ButtonSize } from '../../../common/button/types';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { handlePaymentRedirect } from '../../../utils/payments/payments.utils';

interface Props {
  parcel: ParcelListShipment | Shipment;
  size?: ButtonSize;
  contentStyle?: ButtonContentStyle;
}

const PaymentButton = ({ parcel, size = 'medium', contentStyle }: Props) => {
  const {
    parcelListStore: {
      fetchingPaymentLinkId,
      updateParcelPaymentStatus,
      getPaymentLink,
    },
    userStore: { getCurrencySymbol },
  } = useStore();
  const { t } = useTranslation();

  // eslint-disable-next-line
  const { converted_price, flags } = parcel;
  if (!flags?.can_pay) return null;

  const handlePayClick = async (id: number) => {
    const link = await getPaymentLink(id)!;
    handlePaymentRedirect(link);
  };
  const totalPrice = `${getFormattedPrice(converted_price?.total_price, getCurrencySymbol(converted_price?.currency))}`;

  return (
    <Button
      isLoading={fetchingPaymentLinkId === parcel.id}
      size={size}
      contentStyle={contentStyle}
      onMouseUp={(event) => {
        event.stopPropagation();
      }}
      onKeyDown={(event) => {
        event.stopPropagation();
      }}
      onClick={(event) => {
        event.stopPropagation();
        handlePayClick(parcel.id);
      }}>
      {t('pay')} {totalPrice}
    </Button>
  );
};

export default observer(PaymentButton);
