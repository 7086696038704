import styled, { css } from 'styled-components';
import { generateMediaQueryStyles } from '../../utils/styles/styles-utils';
import { BREAKPOINTS } from '../../constants/breakpoints';

export interface MarginProps {
  $mr?: string;
  $ml?: string;
  $mt?: string;
  $mb?: string;
  $mrMobile?: string;
  $mlMobile?: string;
  $mtMobile?: string;
  $mbMobile?: string;
  $mrTablet?: string;
  $mlTablet?: string;
  $mtTablet?: string;
  $mbTablet?: string;
  $mrDesktop?: string;
  $mlDesktop?: string;
  $mtDesktop?: string;
  $mbDesktop?: string;
}

export const BaseMarginComponent = <T extends keyof JSX.IntrinsicElements>(
  tagName: T
) => styled(tagName)<MarginProps & React.ComponentProps<T>>`
  ${({ $mr, $ml, $mt, $mb }) => css`
    ${$mr && `margin-right: ${$mr};`}
    ${$ml && `margin-left: ${$ml};`}
      ${$mt && `margin-top: ${$mt};`}
      ${$mb && `margin-bottom: ${$mb};`}
  `}

  ${({ $mrMobile, $mlMobile, $mtMobile, $mbMobile }) => css`
    ${$mrMobile &&
    generateMediaQueryStyles(
      'margin-right',
      $mrMobile,
      BREAKPOINTS.mobile.css
    )};
    ${$mlMobile &&
    generateMediaQueryStyles('margin-left', $mlMobile, BREAKPOINTS.mobile.css)};
    ${$mtMobile &&
    generateMediaQueryStyles('margin-top', $mtMobile, BREAKPOINTS.mobile.css)};
    ${$mbMobile &&
    generateMediaQueryStyles(
      'margin-bottom',
      $mbMobile,
      BREAKPOINTS.mobile.css
    )};
  `}

    ${({ $mrTablet, $mlTablet, $mtTablet, $mbTablet }) => css`
    ${$mrTablet &&
    generateMediaQueryStyles(
      'margin-right',
      $mrTablet,
      BREAKPOINTS.tablet.css
    )};
    ${$mlTablet &&
    generateMediaQueryStyles('margin-left', $mlTablet, BREAKPOINTS.tablet.css)};
    ${$mtTablet &&
    generateMediaQueryStyles('margin-top', $mtTablet, BREAKPOINTS.tablet.css)};
    ${$mbTablet &&
    generateMediaQueryStyles(
      'margin-bottom',
      $mbTablet,
      BREAKPOINTS.tablet.css
    )};
  `}

    ${({ $mrDesktop, $mlDesktop, $mtDesktop, $mbDesktop }) => css`
    ${$mrDesktop &&
    generateMediaQueryStyles(
      'margin-right',
      $mrDesktop,
      BREAKPOINTS.desktop.css
    )};
    ${$mlDesktop &&
    generateMediaQueryStyles(
      'margin-left',
      $mlDesktop,
      BREAKPOINTS.desktop.css
    )};
    ${$mtDesktop &&
    generateMediaQueryStyles(
      'margin-top',
      $mtDesktop,
      BREAKPOINTS.desktop.css
    )};
    ${$mbDesktop &&
    generateMediaQueryStyles(
      'margin-bottom',
      $mbDesktop,
      BREAKPOINTS.desktop.css
    )};
  `}
`;

export const MarginContainer = styled(BaseMarginComponent('div'))``;
