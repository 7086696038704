import { Receiver } from '../../../../models/user';
import { getFullUserAddress } from '../../../../utils/generic/generic.utils';
import {
  ReceiverData,
  ReceiverName,
  ReceiverNotes,
} from './receiver-card.styles';

interface Props {
  receiver: Receiver;
}

const ReceiverCard = ({ receiver }: Props) => (
  <>
    <ReceiverName>
      {receiver?.first_name} {receiver?.last_name}
    </ReceiverName>
    {receiver?.phone?.phone_number && (
      <ReceiverData>{receiver?.phone?.phone_number}</ReceiverData>
    )}
    {receiver?.email && <ReceiverData>{receiver?.email}</ReceiverData>}
    {receiver?.address && (
      <ReceiverData>{getFullUserAddress(receiver?.address)}</ReceiverData>
    )}
    {receiver?.address?.note && (
      <ReceiverNotes>{receiver?.address?.note}</ReceiverNotes>
    )}
  </>
);

export default ReceiverCard;
