import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import {
  AppLinksContainer,
  FooterBottomContainer,
  SocialLinksContainer,
  StoreLink,
} from './footer.styles';
import { ReactComponent as FbIcon } from '../../assets/facebook-footer.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter-footer.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin-footer.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram-footer.svg';
import { ReactComponent as GoogleMarketIcon } from '../../assets/google-play-store.svg';
import { ReactComponent as AppleStoreIcon } from '../../assets/apple-store.svg';
import { TextCopyright } from '../../common/typography/typography.styles';
import { VisuallyHidden } from '../../common/visually-hidden/visually-hidden.styles';
import { useStore } from '../../stores/store';
import { AppRoute } from '../../constants/route';

const FooterBottom = () => {
  const {
    marketingStore: { footerLinks },
  } = useStore();
  const location = useLocation();
  const isRootRoute = location.pathname === AppRoute.ROOT;

  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <FooterBottomContainer>
      <SocialLinksContainer $isHidden={isRootRoute}>
        {footerLinks?.facebook && (
          <a
            href={footerLinks.facebook}
            target='_blank'
            rel='noreferrer'>
            <FbIcon />
            <VisuallyHidden>{t('Facebook link')}</VisuallyHidden>
          </a>
        )}
        {footerLinks?.twitter && (
          <a
            href={footerLinks.twitter}
            target='_blank'
            rel='noreferrer'>
            <TwitterIcon />
            <VisuallyHidden>{t('Twitter link')}</VisuallyHidden>
          </a>
        )}
        {footerLinks?.linkedin && (
          <a
            href={footerLinks.linkedin}
            target='_blank'
            rel='noreferrer'>
            <LinkedInIcon />
            <VisuallyHidden>{t('LinkEdin link')}</VisuallyHidden>
          </a>
        )}
        {footerLinks?.instagram && (
          <a
            href={footerLinks?.instagram}
            target='_blank'
            rel='noreferrer'>
            <InstagramIcon />
            <VisuallyHidden>{t('instagram link')}</VisuallyHidden>
          </a>
        )}
      </SocialLinksContainer>
      <TextCopyright>
        © {currentYear} - Meest Group - Postal & Transport Services.{' '}
        {t('all_rights_reserved')}
      </TextCopyright>
      {(footerLinks?.playMarket || footerLinks?.appStore) && (
        <AppLinksContainer>
          {footerLinks?.playMarket && (
            <StoreLink
              href={footerLinks.playMarket}
              type='google'>
              <VisuallyHidden>{t('get_it_on_google_play')}</VisuallyHidden>
              <GoogleMarketIcon />
            </StoreLink>
          )}
          {footerLinks?.appStore && (
            <StoreLink
              href={footerLinks.appStore}
              type='apple'>
              <VisuallyHidden>{t('download_on_the_app_store')}</VisuallyHidden>
              <AppleStoreIcon />
            </StoreLink>
          )}
        </AppLinksContainer>
      )}
    </FooterBottomContainer>
  );
};

export default observer(FooterBottom);
