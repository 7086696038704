import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../features/services/services-subsection/services-subsection.styles';
import { useStore } from '../../stores/store';
import { ParcelDetailsSearchInputsContainer } from '../parcel-details/parcel-details.styles';
import InputSelectWithSidebar from '../../common/input-select-with-sidebar/input-select-with-sidebar.component';
import ParcelDeliveryOptionsTabs from '../../features/parcel-creation/parcel-delivery-options-tabs/parcel-delivery-options-tabs.component';
import ParcelReceivingOptionsTabs from '../../features/parcel-creation/parcel-delivery-options-tabs/parcel-receiving-options-tabs.component';
import { FirstMile, LastMile } from '../../models/parcelDeliveryMiles';
import ParcelDeliveryOption from '../../features/parcel-creation/parcel-delivery-option/parcel-delivery-option.component';
import DeliveryPrice from '../../features/parcel-creation/parcel-delivery-option/delivery-price.component';

const ParcelDelivery = () => {
  const {
    parcelCreationStore: {
      selectedLastMile,
      setFirstMile,
      setLastMile,
      selectedFirstMile,
    },
  } = useStore();

  const { t } = useTranslation();

  return (
    <ServicesSubSectionContainer id='deliveryOptionsSubSectionContainer'>
      <ServicesSubSectionHeader>
        {t('delivery_options_delivery')}
      </ServicesSubSectionHeader>
      <ParcelDetailsSearchInputsContainer
        $layout='column'
        $gap='2rem'
        id='deliveryOptionsSearchContainer'>
        <InputSelectWithSidebar<FirstMile>
          disabled={false}
          name='sending'
          onSelectHandler={(firstMile) => {
            setFirstMile(firstMile);
          }}
          selectedFromOutsideItem={selectedFirstMile}
          renderSelectedItem={(firstMile) => (
            <ParcelDeliveryOption
              deliveryOption={firstMile}
              isPriceResponsive
              showDurationInCard={false}
            />
          )}
          placeholder={t('choose_option')}
          label={t('sending_option')}
          sidebarTitle={t('choose_sending_options')}
          SidebarContent={ParcelDeliveryOptionsTabs}
          error={t('field_is_required')}
          errorValidationPredicate={(firstMile) => firstMile === undefined}
          infoMessage={t('could_be_paid_only_by_cash_at_the_agents_office')}
          showInfoMessagePredicate={(firstMile) =>
            firstMile?.service.code === 'agent'
          }
          maxInputHeight='100%'
        />

        {selectedFirstMile && (
          <DeliveryPrice
            deliveryOption={selectedFirstMile}
            isPriceResponsive
            responsiveContainerId='deliveryPriceResponsive'
          />
        )}

        <InputSelectWithSidebar<LastMile>
          disabled={!selectedFirstMile}
          name='receiving'
          onSelectHandler={(lastMile) => setLastMile(lastMile)}
          renderSelectedItem={(lastMile) => (
            <ParcelDeliveryOption
              deliveryOption={lastMile}
              isPriceResponsive
              showDurationInCard={false}
            />
          )}
          selectedFromOutsideItem={selectedLastMile} // required to reset form when new first mile is selected
          placeholder={t('choose_option')}
          label={t('receiving_option')}
          sidebarTitle={t('choose_receiving_options')}
          SidebarContent={ParcelReceivingOptionsTabs}
          error={t('field_is_required')}
          errorValidationPredicate={(lastMile) => lastMile === undefined}
          maxInputHeight='100%'
        />
        {selectedLastMile && (
          <DeliveryPrice
            deliveryOption={selectedLastMile}
            isPriceResponsive
            responsiveContainerId='deliveryPriceResponsive'
          />
        )}
      </ParcelDetailsSearchInputsContainer>
    </ServicesSubSectionContainer>
  );
};

export default observer(ParcelDelivery);
