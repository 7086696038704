import React, { useEffect, useRef, useState } from 'react';
import { Cluster, MarkerClusterer } from '@googlemaps/markerclusterer';
import { useMap } from '@vis.gl/react-google-maps';
import { renderToStaticMarkup } from 'react-dom/server';
import { ClusterMarker } from './cluster-marker.component';
import { ReactComponent as LocationMarkerMeest } from '../../assets/location-marker-meest.svg';
import { ReactComponent as LocationMarkerPolonez } from '../../assets/location-marker-polonez.svg';
import { mapConfig, WithGeocode } from './map.config';
import { client } from '../../config';
import { isMeest } from '../../utils/client/client.utils';

const getLocationMarker = () => {
  const svgs = {
    meest: <LocationMarkerMeest />,
    polonez: <LocationMarkerPolonez />,
  };

  return svgs[client] || null;
};

interface ClusteredMarkersProps<T extends WithGeocode> {
  locationPoints: T[];
  iconUrl?: string;
  onMarkerClick: (option: T) => void;
  onClusterClick?: (clusterData: T[], clusterZoom: number) => void;
}

const ClusteredMarkers = <T extends WithGeocode>({
  locationPoints,
  iconUrl,
  onMarkerClick,
  onClusterClick,
}: ClusteredMarkersProps<T>) => {
  const map = useMap(mapConfig.id);

  const [AdvancedMarkerElementClass, setAdvancedMarkerElementClass] = useState<
    typeof google.maps.marker.AdvancedMarkerElement | null
  >(null);

  const markerToDataMap = useRef(
    new Map<google.maps.marker.AdvancedMarkerElement, T>()
  );

  const clustererRef = useRef<MarkerClusterer | null>(null);

  const handleClusterClick = (
    _: google.maps.MapMouseEvent,
    cluster: Cluster,
    mapParam: google.maps.Map
  ) => {
    if (cluster.markers) {
      const markerData = cluster.markers
        .map((marker) =>
          markerToDataMap.current.get(
            marker as google.maps.marker.AdvancedMarkerElement
          )
        )
        .filter(Boolean) as T[];

      if (cluster.bounds) mapParam.fitBounds(cluster.bounds);

      onClusterClick && onClusterClick(markerData, mapParam.getZoom()!);
    }
  };

  useEffect(() => {
    const loadMarkerLibrary = async () => {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker'
      )) as typeof google.maps.marker;
      setAdvancedMarkerElementClass(() => AdvancedMarkerElement);
    };

    loadMarkerLibrary();
  }, []);

  useEffect(() => {
    if (!map || !AdvancedMarkerElementClass) return;

    const customRenderer = {
      render: ({
        count,
        position,
      }: {
        count: number;
        position: google.maps.LatLng;
      }) => {
        const clusterContent = renderToStaticMarkup(
          <ClusterMarker count={count} />
        );

        return new AdvancedMarkerElementClass({
          position,
          content: new DOMParser().parseFromString(clusterContent, 'text/html')
            .body.firstChild as HTMLElement,
        });
      },
    };

    const clustererInstance = new MarkerClusterer({
      map,
      renderer: customRenderer,
      onClusterClick: handleClusterClick,
    });

    clustererRef.current = clustererInstance;

    return () => {
      clustererInstance.clearMarkers();
      clustererInstance.setMap(null);
      clustererRef.current = null;
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, AdvancedMarkerElementClass]);

  useEffect(() => {
    const clusterer = clustererRef.current;
    if (!clusterer || !AdvancedMarkerElementClass) return;

    const markersToDisplay = locationPoints;
    const iconElement =
      iconUrl && isMeest(client)
        ? `<img src="${iconUrl}" alt="Map Icon" style="width: 4rem; height: 4rem;" />`
        : renderToStaticMarkup(getLocationMarker());

    const currentMarkerToDataMap = markerToDataMap.current;

    clusterer.clearMarkers();
    currentMarkerToDataMap.clear();

    if (!markersToDisplay.length) return;

    const newMarkers = markersToDisplay.map((point) => {
      const marker = new AdvancedMarkerElementClass({
        position: {
          lat: point.geocode.latitude,
          lng: point.geocode.longitude,
        },
        content: new DOMParser().parseFromString(iconElement, 'text/html').body
          .firstChild as HTMLElement,
      });

      marker.addListener('click', () => onMarkerClick(point));

      currentMarkerToDataMap.set(marker, point);
      return marker;
    });

    clusterer.addMarkers(newMarkers);

    return () => {
      clusterer.clearMarkers();
      currentMarkerToDataMap.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPoints, iconUrl, AdvancedMarkerElementClass]);

  return null;
};

const MemoizedClusteredMarkers = React.memo(ClusteredMarkers) as <
  T extends WithGeocode,
>(
  props: ClusteredMarkersProps<T>
) => JSX.Element;

export default MemoizedClusteredMarkers;
