import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { AppRoute, SearchParam } from '../../constants/route';
import { generateMyRoute } from '../../utils/uri/uri.utils';

interface Props {
  children: React.ReactNode;
}

const UnauthorizedRoute = ({ children }: Props) => {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get(SearchParam.REDIRECT);
  const {
    parcelCreationStore: { setIsParcelCreationInProgress },
    userStore: { user, isNeedMigrationAgreement },
  } = useStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsParcelCreationInProgress(false);
  }, [setIsParcelCreationInProgress]);

  return !user || !user?.phone_verified || isNeedMigrationAgreement ? (
    children
  ) : (
    <Navigate
      to={redirect || generateMyRoute(AppRoute.PARCEL_LIST)}
      replace
    />
  );
};

export default observer(UnauthorizedRoute);
