import React, { ReactNode, Ref, forwardRef, useState } from 'react';
import { Placement } from '@floating-ui/react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Input,
  Icon,
  Value,
  ValueIcon,
  ValueText,
  SelectClear,
} from './select.styles';
import {
  Dropdown,
  DropdownIcon,
  DropdownItem,
  DropdownItems,
} from '../dropdown/dropdown.styles';
import withCloseOnOutsideClick, {
  WithCloseOnOutsideClickProps,
} from '../../HOCs/withCloseOnOutsideClick.hoc';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down-icon.svg';
import Button from '../button/button.component';
import { ButtonIconGlyph } from '../button/icons';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../popover/popover.component';

export interface IDropdownItem<T extends string | number> {
  label: string;
  value: T;
  icon?: React.ReactNode;
  glyph?: ButtonIconGlyph;
}

interface Props<T extends string> extends WithCloseOnOutsideClickProps {
  width?: string;
  items: IDropdownItem<T>[];
  defaultValue?: IDropdownItem<T>;
  placeholder?: string;
  value?: IDropdownItem<T>;
  dropdownPosition?: Placement;
  onChange?: (item: IDropdownItem<T>) => void;
  onClear?: () => void;
  showClear?: boolean;
  title?: ReactNode;
  shouldMatchWidthOfInput?: boolean;
  isDisabled?: boolean;
}

function Select<T extends string>(
  {
    isOpened,
    setIsOpened,
    items,
    value,
    onChange,
    onClear,
    showClear,
    width,
    dropdownPosition = 'bottom-start',
    placeholder,
    defaultValue,
    title,
    shouldMatchWidthOfInput = false,
    isDisabled = false,
  }: Props<T>,
  ref: Ref<HTMLDivElement>
) {
  const [selectedItem, setSelectedItem] = useState<
    IDropdownItem<T> | undefined
  >(defaultValue);

  const { t } = useTranslation();

  const handleChange = (item: IDropdownItem<T>) => {
    setIsOpened(false);
    setSelectedItem(item);
    onChange && onChange(item);
  };

  const handleClear = () => {
    setIsOpened(false);
    setSelectedItem(undefined);
    onClear && onClear();
  };

  const val = value !== undefined ? value : selectedItem;

  return (
    <Container
      className='flag-emoji-font'
      $isOpened={isOpened}
      ref={ref}>
      <Popover
        shouldMatchReferenceWidth={shouldMatchWidthOfInput}
        placement={dropdownPosition}>
        {title}
        <PopoverTrigger asChild>
          <Input
            disabled={isDisabled}
            $width={width}
            onClick={() => setIsOpened((prev) => !prev)}>
            <Value>
              {val?.icon && <ValueIcon>{val?.icon}</ValueIcon>}
              <ValueText>{t(val?.label || placeholder || '')}</ValueText>
            </Value>
            <Icon>
              <ArrowDownIcon />
            </Icon>
          </Input>
        </PopoverTrigger>

        <PopoverContent>
          <Dropdown>
            <DropdownItems>
              {items.map((item) => (
                <PopoverClose key={item.value}>
                  <DropdownItem
                    type='button'
                    $isSelected={item.value === val?.value}
                    onClick={() => handleChange(item)}>
                    {item.icon && <DropdownIcon>{item.icon}</DropdownIcon>}
                    {t(item.label)}
                  </DropdownItem>
                </PopoverClose>
              ))}
            </DropdownItems>
            {showClear && (
              <SelectClear>
                <PopoverClose>
                  <Button
                    contentStyle='thin'
                    appearance='text'
                    onClick={handleClear}>
                    {t('clear')}
                  </Button>
                </PopoverClose>
              </SelectClear>
            )}
          </Dropdown>
        </PopoverContent>
      </Popover>
    </Container>
  );
}

export default withCloseOnOutsideClick(forwardRef(Select));
