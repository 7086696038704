import { useTranslation } from 'react-i18next';
import { ProhibitedItems } from '../../../models/prohibitedItems';
import {
  ProhibitedListItemContainer,
  ProhibitedListItemImage,
  ProhibitedListItemDescriptionContainer,
  ProhibitedListItemDescription,
  ProhibitedListItemTagsContainer,
} from './prohibited-list-item.styles';
import { getProhibitedTags } from './helpers';

interface Props {
  prohibitedItem: ProhibitedItems;
}

const ProhibitedListItem = ({ prohibitedItem }: Props) => {
  const { t } = useTranslation();
  const { icon, code, description, tags } = prohibitedItem;

  const prohibitedTags = getProhibitedTags(tags, t);

  return (
    <ProhibitedListItemContainer>
      <ProhibitedListItemImage
        $imageUrl={icon}
        $iconContent={code}
      />
      <ProhibitedListItemDescriptionContainer>
        <ProhibitedListItemDescription>
          {description}
        </ProhibitedListItemDescription>
        {prohibitedTags && (
          <ProhibitedListItemTagsContainer>
            {prohibitedTags}
          </ProhibitedListItemTagsContainer>
        )}
      </ProhibitedListItemDescriptionContainer>
    </ProhibitedListItemContainer>
  );
};

export default ProhibitedListItem;
