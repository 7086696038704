import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ModalTextBody } from '../../../common/modal-dialog/modal-dialog.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
  isLoading: boolean;
}

const DeleteAccountDialog = ({
  isOpen = false,
  onClose,
  onApprove,
  isLoading,
}: Props) => (
  <ModalDialog
    isLoading={isLoading}
    isDanger
    approveBtnContent={t('yes_remove_my_account')}
    cancelBtnContent={t('cancel')}
    isOpen={isOpen}
    onApprove={onApprove}
    onClose={onClose}>
    <HeaderSecondary>
      <Trans
        i18nKey={t('are_you_sure_remove_account')}
        components={{
          1: <br />,
        }}
      />
    </HeaderSecondary>
    <ModalTextBody $textAlign='center'>
      {t('delete_account_warning_explanation')}
    </ModalTextBody>
  </ModalDialog>
);

export default observer(DeleteAccountDialog);
