import { Modals } from '../../constants/modals';
import { store } from '../../stores/store';

export const handlePaymentRedirect = async (link?: string) => {
  const win = window.open(link);
  if (win === null) {
    store.modalStore.open({
      id: Modals.PAYMENT,
      name: Modals.PAYMENT,
      props: { link },
    });
  }

  return Promise.resolve(!!win);
};
