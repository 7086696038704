import { FirebaseError, initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { translate } from '../../../i18n';
import { envConfig } from '../../config';

export const enum FirebaseErrorCodes {
  auth_email_already_in_use = 'auth/email-already-in-use',
  auth_user_not_found = 'auth/user-not-found',
  auth_requires_recent_login = 'auth/requires-recent-login',
  auth_wrong_password = 'auth/wrong-password',
  auth_popup_closed_by_user = 'auth/popup-closed-by-user',
  auth_cancelled_popup_request = 'auth/cancelled-popup-request',
  auth_network_request_failed = 'auth/network-request-failed',
  auth_too_many_requests = 'auth/too-many-requests',
}

const firebaseConfig = {
  apiKey: envConfig.REACT_APP_FIREBASE_API_KEY ?? '',
  authDomain: envConfig.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '',
  projectId: envConfig.REACT_APP_FIREBASE_PROJECT_ID ?? '',
  storageBucket: envConfig.REACT_APP_FIREBASE_STORAGE_BUCKET ?? '',
  messagingSenderId: envConfig.REACT_APP_FIREBASE_MSG_SENDER_ID ?? '',
  appId: envConfig.REACT_APP_FIREBASE_APP_ID ?? '',
  measurementId: envConfig.REACT_APP_FIREBASE_MEASUREMENT_ID ?? '',
};

// Initialize Firebase
initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: 'select_account',
});

const appleProvider = new OAuthProvider('apple.com');

appleProvider.addScope('email');
appleProvider.addScope('name');

export const auth = getAuth();

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);

export const signInWithApplePopup = async () =>
  signInWithPopup(auth, appleProvider);

const ERRORS_WITHOUT_TOAST = [
  FirebaseErrorCodes.auth_popup_closed_by_user,
  FirebaseErrorCodes.auth_cancelled_popup_request,
];

export const handleFirebaseError = (
  error: FirebaseError,
  customFormatter?: (
    error: string,
    status?: number,
    code?: FirebaseErrorCodes
  ) => React.ReactNode
) => {
  let toastMessage = '';
  const firebaseCode = error.code as FirebaseErrorCodes;
  if (ERRORS_WITHOUT_TOAST.includes(firebaseCode)) {
    return;
  }

  switch (firebaseCode) {
    case FirebaseErrorCodes.auth_email_already_in_use:
      toastMessage = translate('email_is_already_in_use');
      break;
    case FirebaseErrorCodes.auth_user_not_found:
      toastMessage = translate('user_with_provided_email_doesnt_exist');
      break;
    case FirebaseErrorCodes.auth_requires_recent_login:
      toastMessage = translate('relogin_required');
      break;
    case FirebaseErrorCodes.auth_wrong_password:
      toastMessage = translate('password_is_incorrect');
      break;
    case FirebaseErrorCodes.auth_too_many_requests:
      toastMessage = translate('signin_too_many_requests');
      break;
    case FirebaseErrorCodes.auth_network_request_failed:
      toastMessage = translate('you_are_offline');
      break;
    default:
      toastMessage = error.message;
  }

  return customFormatter
    ? customFormatter(toastMessage, undefined, firebaseCode)
    : toastMessage;
};
