import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from 'react-error-boundary';

const ErrorBoundary = (props: ErrorBoundaryProps) => (
  <ReactErrorBoundary {...props} />
);

export default ErrorBoundary;
