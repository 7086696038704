import { useTranslation } from 'react-i18next';
import MeestComingSoonImg from '../../assets/meest-coming-soon-image.png';
import PolonezComingSoonImg from '../../assets/polonez-coming-soon-image.png';
import StatusPage from '../../common/status-page/status-page.component';
import { client } from '../../config';

const getImage = () => {
  const images = {
    meest: MeestComingSoonImg,
    polonez: PolonezComingSoonImg,
  };
  return images[client];
};

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <StatusPage
      title={t('coming_soon')}
      body={t('currently_working_check_later')}
      imgSrc={getImage()}
    />
  );
};

export default ComingSoon;
