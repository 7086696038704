import ModalDialog from '../../modal-dialog/modal-dialog.component';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  content: React.ReactNode;
}

const DesktopManualDialog = ({ onClose, isOpen, content }: Props) => (
  <ModalDialog
    onClose={onClose}
    isOpen={isOpen}
    width='540px'
    displayButtons={false}>
    {content}
  </ModalDialog>
);

export default DesktopManualDialog;
