import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import {
  Footer,
  FooterDownload,
  FooterStores,
  FooterTitle,
  Steps,
  Step,
  StepContent,
  Content,
  StepDecoration,
  StoreLink,
  QRCodeContainer,
} from './agent-dropoff-instructions.styles';
import { VisuallyHidden } from '../../../common/visually-hidden/visually-hidden.styles';
import { ReactComponent as GoogleMarketIcon } from '../../../assets/google-play-store.svg';
import { ReactComponent as AppleStoreIcon } from '../../../assets/apple-store.svg';
import {
  HeaderSecondary,
  TextBody,
} from '../../../common/typography/typography.styles';
import { ReactComponent as ShipmentIcon } from '../../../assets/shipments-icon.svg';
import { ReactComponent as DeliveryIcon } from '../../../assets/delivery.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/money.svg';
import { ReactComponent as TruckIcon } from '../../../assets/truck.svg';
import { ReactComponent as MeestStoreQrCode } from '../../../assets/meest-store-qr.svg';
import { useStore } from '../../../stores/store';
import {
  decodeHtmlEntities,
  formatStringPascalCase,
  getAddressFormatted,
} from '../pudo-list-item/helpers';

const steps: {
  title: string;
  description: string;
  icon: React.ReactNode;
}[] = [
  {
    title: 'manual_pack',
    description: 'manual_pack_description',
    icon: <ShipmentIcon />,
  },
  {
    title: 'manual_agent_office',
    description: 'manual_agent_instructions_office_description',
    icon: <DeliveryIcon />,
  },
  {
    title: 'manual_pay_agent',
    description: 'manual_pay_agent_description',
    icon: <MoneyIcon />,
  },
  {
    title: 'manual_track',
    description: 'manual_track_description',
    icon: <TruckIcon />,
  },
];

const AgentDropoffInstructions = () => {
  const { t } = useTranslation();

  const {
    parcelCreationStore: { selectedSenderPudo },
  } = useStore();

  const agentAddressFormatted = useMemo(() => {
    if (!selectedSenderPudo) return;
    const address = `${decodeHtmlEntities(formatStringPascalCase(selectedSenderPudo.name.split(' ')))} (${decodeHtmlEntities(getAddressFormatted(selectedSenderPudo))})`;
    return address;
  }, [selectedSenderPudo]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>
        {t('parcel_creation_agent_instructions_title')}
      </ServicesSubSectionHeader>
      <Content>
        <Steps>
          {steps.map((step, index) => (
            <Step key={step.title}>
              <StepDecoration $isLast={index === steps.length - 1}>
                {step.icon}
              </StepDecoration>
              <StepContent>
                <HeaderSecondary
                  as='p'
                  $size='normal'>
                  {t(step.title)}
                </HeaderSecondary>
                <TextBody>
                  {t(step.description, {
                    officeInfo: agentAddressFormatted,
                  })}
                </TextBody>
              </StepContent>
            </Step>
          ))}
        </Steps>
        <Footer>
          <FooterDownload>
            <FooterTitle>
              {t('parcel_creation_agent_instructions_footer')}
            </FooterTitle>
            <FooterStores>
              <StoreLink
                href='#'
                $type='google'>
                <VisuallyHidden>{t('get_it_on_google_play')}</VisuallyHidden>
                <GoogleMarketIcon />
              </StoreLink>
              <StoreLink
                href='#'
                $type='apple'>
                <VisuallyHidden>
                  {t('download_on_the_app_store')}
                </VisuallyHidden>
                <AppleStoreIcon />
              </StoreLink>
            </FooterStores>
          </FooterDownload>
          <QRCodeContainer>
            <MeestStoreQrCode />
          </QRCodeContainer>
        </Footer>
      </Content>
    </ServicesSubSectionContainer>
  );
};

export default observer(AgentDropoffInstructions);
