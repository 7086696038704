import { useEffect } from 'react';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import { useModal } from '../common/modal';
import { Modals } from '../constants/modals';
import { useStore } from '../stores/store';
import { removeLanguagePrefix } from '../utils/uri/uri.utils';
import { SearchParam } from '../constants/route';

interface UseNavigationBlockerOptions {
  Route: string;
  isInProgress: Boolean;
  setIsInProgress: (value: boolean) => void;
  service?: string;
}

export const useNavigationBlocker = ({
  Route,
  isInProgress,
  setIsInProgress,
  service = undefined,
}: UseNavigationBlockerOptions) => {
  const {
    userStore: { logout, isLogoutRequested, setIsLogoutRequested },
    navStore: { setSelectedService },
  } = useStore();

  const { openModal, closeModal } = useModal();

  const shouldBlockPredicate: BlockerFunction = ({
    nextLocation,
    currentLocation,
  }) => {
    const currentPath = removeLanguagePrefix(currentLocation.pathname);
    const nextPath = removeLanguagePrefix(nextLocation.pathname);

    if (
      currentPath === nextPath &&
      currentLocation.search === nextLocation.search
    ) {
      return false;
    }

    const navigateToSearchParam = new URLSearchParams(nextLocation.search).get(
      SearchParam.NAVIGATE_TO
    );

    const shouldBlock =
      isInProgress &&
      (navigateToSearchParam !== null
        ? !navigateToSearchParam.includes(Route)
        : !nextPath.includes(Route));

    return shouldBlock;
  };

  const blocker = useBlocker(shouldBlockPredicate);

  const handleStay = () => {
    closeModal(Modals.LEAVE_THIS_PAGE);

    blocker.reset?.();

    if (isLogoutRequested) {
      setIsLogoutRequested(false);
    }

    if (setSelectedService) {
      setSelectedService(service);
    }
  };

  const handleLeave = () => {
    setIsInProgress(false);

    if (isLogoutRequested) {
      logout({ shouldRedirectToMainPage: false });
    }

    blocker.proceed?.();
  };

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openModal({
        id: Modals.LEAVE_THIS_PAGE,
        name: Modals.LEAVE_THIS_PAGE,
        props: {
          onApprove: handleStay,
          onClose: handleLeave,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state]);

  return blocker;
};
