import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  @media (max-width: 650px) {
    background-color: ${({ theme }) => theme.colors.namedColors.white};
    align-items: flex-start;
    width: 100%;
  }
`;

export const BlockWrapper = styled.div`
  padding: 4rem 6rem 2.8rem 6rem;
  max-width: 119.6rem;
  width: 100%;
  border-radius: 3rem;
  background-color: ${({ theme }) => theme.colors.namedColors.white};

  @media (max-width: 650px) {
    border-radius: 0;
    padding: 2.4rem 1.6rem;
  }
`;

export const ContinentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`;

export const ContinentHeader = styled.h2`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
`;

export const CountriesContainer = styled.div`
  display: flex;

  margin-bottom: 3.2rem;
  gap: 2.4rem;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const CountryInfo = styled.span`
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  padding: 1.2rem 1.6rem 1.2rem 2.4rem;
  width: 25.1rem;
  min-height: 7.2rem;
  gap: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.2rem;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.colors.baseColors.grey150};

  &:hover {
    background: ${({ theme }) => theme.colors.baseColors.grey5};
    transition-property: border-color, background-color, color, outline-color;
    transition-duration: 0.2s;
  }

  @media (max-width: 650px) {
    width: 100%;
    min-height: 5.6rem;
    padding: 0.8rem 1.2rem;
    border-radius: 1rem;
  }
`;

export const CountryImage = styled.img`
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 3.4rem;
  min-height: 2.4rem;
`;
