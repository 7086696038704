import { createContext } from 'react';
import { Modals } from '../../constants/modals';

export type TModals = (typeof Modals)[keyof typeof Modals];

export type TModalProps = Record<string, any>;

export interface IModal {
  id: string;
  name: TModals;
  opensManually?: boolean;
  closesManually?: boolean;
  props?: TModalProps;
}

export type Context = {
  currentModal: IModal | null;
  openModal: (modal: IModal) => void;
  updateModal: (id: string, props: TModalProps) => void;
  closeModal: (id: string) => void;
  isModalVisible: (id: string) => boolean;
};

export const ModalContext = createContext({} as Context);
