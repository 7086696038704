import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { useStore } from '../../stores/store';
import {
  BlockWrapper,
  Container,
  ContinentContainer,
  ContinentHeader,
  CountriesContainer,
  CountryImage,
  CountryInfo,
} from './root.styled';
import {
  constructLocalePath,
  generateLocaleRoute,
} from '../../utils/uri/uri.utils';
import { CountriesResponse } from '../../models/countriesModel';

interface GroupedCountries {
  [continent: string]: CountriesResponse[];
}

const RootComponent = () => {
  const [groupedCountries, setGroupedCountries] = useState<GroupedCountries>(
    {}
  );
  const {
    localizationsStore: { availableCountries },
  } = useStore();

  useEffect(() => {
    if (!availableCountries) return;

    const grouped = availableCountries.reduce(
      (acc: GroupedCountries, country: CountriesResponse) => {
        if (!acc[country.continent]) {
          acc[country.continent] = [];
        }
        acc[country.continent].push(country);
        return acc;
      },
      {}
    );

    Object.keys(grouped).forEach((continent) => {
      grouped[continent] = grouped[continent].sort((a, b) =>
        a.local_name.localeCompare(b.local_name)
      );
    });

    setGroupedCountries(grouped);
  }, [availableCountries]);

  return (
    <Container>
      <BlockWrapper>
        {Object.entries(groupedCountries).map(([continent, countriesArr]) => (
          <ContinentContainer key={continent}>
            <ContinentHeader>{continent}</ContinentHeader>
            <CountriesContainer>
              {countriesArr.map((country) => (
                <Link
                  key={country.en_name}
                  to={generateLocaleRoute(
                    undefined,
                    constructLocalePath(
                      country.default_language,
                      country.country_code
                    )
                  )}
                  style={{ textDecoration: 'none' }}>
                  <CountryInfo key={country.country_code}>
                    <CountryImage
                      width={35}
                      height={24}
                      src={country.flag_image_svg}
                      alt=''
                    />
                    <span>{country.local_name}</span>
                  </CountryInfo>
                </Link>
              ))}
            </CountriesContainer>
          </ContinentContainer>
        ))}
      </BlockWrapper>
    </Container>
  );
};

export default observer(RootComponent);
