import styled from 'styled-components';
import { ServicesSubSectionContainer } from '../../services/services-subsection/services-subsection.styles';
import { FontSizes } from '../../../common/typography/typography.styles';
import { FieldContainer } from '../../../common/form-field-text/form-field-text.styles';

export const ParcelItemsManagementContainer = styled(
  ServicesSubSectionContainer
)``;

export const ParcelItemContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

export const ParcelInfo = styled.div``;

export const ParcelProps = styled.div`
  display: grid;
  grid-template-columns: 12rem auto;
  align-items: center;
  row-gap: 8px;
  column-gap: 12px;
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: start;
  gap: 1.6rem;
`;

export const ActionButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  display: flex;
`;

export const EditButton = styled(ActionButton)`
  color: ${({ theme }) => theme.colors.namedColors.primary};

  &:disabled {
    color: #0070cca1;
  }
`;

export const RemoveButton = styled(ActionButton)`
  color: #e82a53;

  &:disabled {
    color: #e82a5393;
  }
`;

export const ParcelItemHeader = styled.span`
  display: inline-block;
  font-size: ${FontSizes.mediumPlus};
  font-weight: 500;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.2rem;
`;

export const ParcelItemPropName = styled.span`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  font-size: ${FontSizes.small};
  font-style: normal;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
`;

export const ParcelItemPropValue = styled.span`
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
`;

export const ParcelItemSeparator = styled.span`
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.baseColors.grey50};
  margin-top: -0.4rem;
  margin-bottom: -0.4rem;
`;

export const ParcelItemEditFormContainer = styled.div`
  ${FieldContainer} {
    margin-bottom: 0;
  }
`;

export const FormInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2.4rem;
  column-gap: 2rem;
  margin-bottom: 2.4rem;

  ${FieldContainer}:nth-child(1) {
    grid-column: 1 / -1;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }
`;

interface BtnsBlockProps {
  $editMode: boolean;
}

export const FormBtnsBlock = styled.div<BtnsBlockProps>`
  display: flex;
  gap: 2.4rem;
  width: 34.4rem;
  margin-left: auto;

  width: ${({ $editMode }) => ($editMode ? '34.4rem' : '16rem')};

  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: 1.6rem;
  }
`;

export const Content = styled.div`
  display: grid;
  gap: 2.4rem;
`;
