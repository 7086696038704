import { useTranslation } from 'react-i18next';
import ModalDialog from '../modal-dialog/modal-dialog.component';
import {
  HeaderSecondary,
  Link,
  TextBody,
} from '../typography/typography.styles';
import { Content } from './payment-modal.styles';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  link: string;
}

const PaymentModal = ({ onClose, isOpen, link }: Props) => {
  const { t } = useTranslation();
  return (
    <ModalDialog
      onClose={onClose}
      isOpen={isOpen}
      width='400px'
      displayButtons={false}>
      <HeaderSecondary>{t('payment_leave_this_page')}</HeaderSecondary>
      <Content>
        <TextBody $textAlign='center'>
          {t('payment_leave_this_page_description_1')}
        </TextBody>
        <TextBody $textAlign='center'>
          {t('payment_leave_this_page_description_2')}
        </TextBody>
      </Content>

      <Link
        onClick={onClose}
        target='_blank'
        $color='#0070CC'
        href={link}>
        {link}
      </Link>
    </ModalDialog>
  );
};

export default PaymentModal;
