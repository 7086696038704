import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import {
  HeaderMain,
  TextBody,
} from '../../../common/typography/typography.styles';
import { AuthMessageScreen } from '../auth-messege-screen/auth-message-screen.styles';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import { generateMyRoute } from '../../../utils/uri/uri.utils';
import { AppRoute } from '../../../constants/route';
import { Buttons } from '../sign-up/sign-up.styles';

const DataMigrationAgreement = () => {
  const {
    userStore: { migrateUser, isMigratingInProgress },
    navStore: { setAuthCurrentScreen, closeSidebarByName },
  } = useStore();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClick = () => {
    closeSidebarByName('nav_sidebar', () => {
      setAuthCurrentScreen('auth-tabs');
    });
    setAuthCurrentScreen('auth-tabs');
    navigate(generateMyRoute(AppRoute.PARCEL_LIST));
  };

  const handleSubmit = async () => {
    await migrateUser(true);
    handleClick();
  };

  const handleCancel = () => {
    migrateUser(false);
    handleClick();
  };

  return (
    <AuthMessageScreen $height='fit-content'>
      <HeaderMain>{t('data_migration')}</HeaderMain>
      <TextBody $textAlign='center'>
        {t('data_migration_confirmation')}
      </TextBody>
      <Buttons>
        <Button
          isLoading={isMigratingInProgress}
          size='large'
          onClick={handleSubmit}>
          {t('yes')}
        </Button>
        <Button
          size='large'
          appearance='tertiary'
          onClick={handleCancel}>
          {t('no')}
        </Button>
      </Buttons>
    </AuthMessageScreen>
  );
};

export default observer(DataMigrationAgreement);
