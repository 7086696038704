import { useEffect, useRef, useState } from 'react';
import { Container, Text } from './truncated-text-with-tooltip.styles';
import { FontSizes } from '../typography/typography.styles';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../tooltip/tooltip.component';
import { theme } from '../../styles/themes';

interface Props {
  children: string;
  textType?: 'body' | 'label';
  color?: string;
  fontSize?: string;
  fontWeight?: number;
}

const TruncatedTextWithTooltip = ({
  children,
  color,
  textType = 'body',
  fontSize = FontSizes.normal,
  fontWeight = 500,
}: Props) => {
  const [truncated, setTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      setTruncated(textElement.scrollWidth > textElement.clientWidth);
    }
  }, [children]);

  return (
    <Tooltip placement='top'>
      <TooltipTrigger asChild>
        <Container>
          <Text
            ref={textRef}
            $isTruncated={truncated}
            $fontSize={fontSize}
            $fontWeight={fontWeight}
            $color={
              color ??
              (textType === 'label'
                ? theme.colors.baseColors.grey100
                : theme.colors.normalText.color)
            }>
            {children}
          </Text>
        </Container>
      </TooltipTrigger>
      {truncated ? (
        <TooltipContent
          maxWidth='300px'
          wordBreak='break-all'>
          {children}
        </TooltipContent>
      ) : undefined}
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
