import { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  TValidationMethod,
  getIsFieldInvalid,
} from '../utils/forms/forms.utils';

interface Props {
  name: string;
  validationMethod?: TValidationMethod;
}

export const useFormField = ({ name, validationMethod }: Props) => {
  const [field, meta, helpers] = useField(name);
  const formikContext = useFormikContext();
  const [isTyping, setIsTyping] = useState(false);
  const isInvalid = validationMethod
    ? getIsFieldInvalid({ validationMethod, meta, isTyping })
    : false;
  const { t } = useTranslation();

  return {
    field,
    meta,
    helpers,
    formikContext,
    isTyping,
    isInvalid,
    t,
    setIsTyping,
  };
};
