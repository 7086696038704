import { ToastPosition } from 'react-toastify';
import { ToastAppearance } from './types';
import { ToastContent } from './toast-message';

export const positions: ToastPosition[] = [
  'top-right',
  'top-center',
  'top-left',
  'bottom-right',
  'bottom-center',
  'bottom-left',
];

export const appearances: ToastAppearance[] = [
  'success',
  'info',
  'error',
  'warning',
];

export function normalizeToToastContent(
  message: string | ToastContent | unknown
): ToastContent {
  if (typeof message === 'string') {
    return { primaryMessage: message };
  }

  if (
    typeof message === 'object' &&
    message !== null &&
    'primaryMessage' in message &&
    typeof (message as ToastContent).primaryMessage === 'string'
  ) {
    return message as ToastContent;
  }

  return { primaryMessage: String(message) };
}
