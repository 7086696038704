import styled, { css } from 'styled-components';
import { generateMediaQueryStyles } from '../../utils/styles/styles-utils';

export interface PaddingProps {
  $pr?: string;
  $pl?: string;
  $pt?: string;
  $pb?: string;
  $prMobile?: string;
  $plMobile?: string;
  $ptMobile?: string;
  $pbMobile?: string;
  $prTablet?: string;
  $plTablet?: string;
  $ptTablet?: string;
  $pbTablet?: string;
  $prDesktop?: string;
  $plDesktop?: string;
  $ptDesktop?: string;
  $pbDesktop?: string;
}

export const BasePaddingComponent = <T extends keyof JSX.IntrinsicElements>(
  tagName: T
) => styled(tagName)<PaddingProps & React.ComponentProps<T>>`
  ${({ $pr, $pl, $pt, $pb }) => css`
    ${$pr && `padding-right: ${$pr};`}
    ${$pl && `padding-left: ${$pl};`}
      ${$pt && `padding-top: ${$pt};`}
      ${$pb && `padding-bottom: ${$pb};`}
  `}

  ${({ $prMobile, $plMobile, $ptMobile, $pbMobile }) => css`
    ${$prMobile &&
    generateMediaQueryStyles('padding-right', $prMobile, '480px')}
    ${$plMobile && generateMediaQueryStyles('padding-left', $plMobile, '480px')}
      ${$ptMobile &&
    generateMediaQueryStyles('padding-top', $ptMobile, '480px')}
      ${$pbMobile &&
    generateMediaQueryStyles('padding-bottom', $pbMobile, '480px')}
  `}

    ${({ $prTablet, $plTablet, $ptTablet, $pbTablet }) => css`
    ${$prTablet &&
    generateMediaQueryStyles('padding-right', $prTablet, '768px')}
    ${$plTablet && generateMediaQueryStyles('padding-left', $plTablet, '768px')}
      ${$ptTablet &&
    generateMediaQueryStyles('padding-top', $ptTablet, '768px')}
      ${$pbTablet &&
    generateMediaQueryStyles('padding-bottom', $pbTablet, '768px')}
  `}

    ${({ $prDesktop, $plDesktop, $ptDesktop, $pbDesktop }) => css`
    ${$prDesktop &&
    generateMediaQueryStyles('padding-right', $prDesktop, '1024px')}
    ${$plDesktop &&
    generateMediaQueryStyles('padding-left', $plDesktop, '1024px')}
      ${$ptDesktop &&
    generateMediaQueryStyles('padding-top', $ptDesktop, '1024px')}
      ${$pbDesktop &&
    generateMediaQueryStyles('padding-bottom', $pbDesktop, '1024px')}
  `}
`;
