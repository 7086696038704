import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { HeaderFourth, TextBody } from '../typography/typography.styles';
import Button from '../button/button.component';
import {
  Description,
  ImageWrapper,
  Background,
  Buttons,
  Content,
  Container,
} from './status-page.styles';

interface Props {
  title: string;
  body: React.ReactNode;
  imgSrc: string;
  showSupportButton?: boolean;
}

const StatusPage = ({ title, body, imgSrc, showSupportButton }: Props) => {
  const { resetBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container>
      <Background>
        <Content>
          <ImageWrapper>
            <img
              src={imgSrc}
              alt=''
            />
          </ImageWrapper>
          <Description>
            <HeaderFourth>{title}</HeaderFourth>
            <TextBody $textAlign='center'>{body}</TextBody>
          </Description>
          <Buttons>
            <Button
              fullWidth={false}
              onClick={() => {
                resetBoundary && resetBoundary();
                navigate(-1);
              }}>
              {t('back')}
            </Button>
            {showSupportButton && (
              <Button
                fullWidth={false}
                appearance='secondary'>
                {t('support')}
              </Button>
            )}
          </Buttons>
        </Content>
      </Background>
    </Container>
  );
};

export default StatusPage;
