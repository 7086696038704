import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { InputContainer } from '../form-field-text/form-field-text.styles';
import {
  SidebarContainer,
  Select,
  InputPlaceholderDark,
  IconWrapper,
  ArrowContainer,
} from './info-select-with-sidebar.styles';
import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right-icon.svg';
import { Theme } from './types';
import Sidebar from '../sidebar-right/sidebar.component';
import { SidebarName } from '../../types/sidebar.types';
import Spinner from '../spinner/spinner.component';

interface Props {
  placeholder: string;
  sidebarTitle: string;
  sidebarName: SidebarName;
  children: React.ReactNode;
  disabled?: boolean;
  theme: Theme;
  icon?: React.ReactNode;
  isLoading?: boolean;
  bodyBackground?: string;
  onInputClick?: () => void;
}

const InfoSelectWithSidebar = ({
  placeholder,
  sidebarTitle,
  sidebarName,
  disabled,
  theme,
  icon,
  children,
  isLoading = false,
  bodyBackground,
  onInputClick,
}: Props) => {
  const {
    navStore: { toggleSidebarByName, closeAllRightSidebars },
  } = useStore();

  const handleInputClick = () => {
    if (!disabled) {
      toggleSidebarByName(sidebarName);
      onInputClick && onInputClick();
    }
  };

  return (
    <SidebarContainer
      $theme={theme}
      $isDisabled={disabled}
      $bodyBackground={bodyBackground}>
      <InputContainer
        $error={false}
        $maxInputHeight='12rem'>
        <Select
          tabIndex={0}
          $isDisabled={disabled}
          onClick={handleInputClick}>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <InputPlaceholderDark>{placeholder}</InputPlaceholderDark>
          <ArrowContainer>
            <ArrowRightIcon />
          </ArrowContainer>
        </Select>
      </InputContainer>

      <Sidebar
        name={sidebarName}
        sidebarWidth='48rem'
        withBlurredBackground
        contentWithVerticalScroll
        header={sidebarTitle}
        onClose={closeAllRightSidebars}>
        {isLoading ? <Spinner /> : children}
      </Sidebar>
    </SidebarContainer>
  );
};

export default observer(InfoSelectWithSidebar);
