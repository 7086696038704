import styled, { css } from 'styled-components';
import {
  FontSizes,
  TextInput,
  TextInputPlaceholder,
} from '../typography/typography.styles';

interface StyledTextAreaProps {
  $borderNone?: boolean;
}
interface TextAreaContainerProps {
  $error?: boolean;
}

export const TextAreaContainer = styled.div<TextAreaContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  border-radius: 1rem;
  min-height: 13rem;

  ${({ $error }) =>
    $error &&
    css`
      border-color: ${({ theme }) => theme.colors.baseColors.red100};
    `}
  ]
`;

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  ${TextInput}
  width: 100%;
  height: 100%;
  min-height: 13rem;
  padding: 1rem 1.6rem;
  font-size: ${FontSizes.normal};
  resize: none;
  outline: none;
  border: none;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.colors.namedColors.white};

  &::placeholder {
    ${TextInputPlaceholder}
    font-size: ${FontSizes.normal};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.baseColors.grey10};
  }
`;

export const Counter = styled.div`
  text-align: right;
  ${TextInputPlaceholder}
  font-size: ${FontSizes.small};
`;
