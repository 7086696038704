import Button from '../button/button.component';
import MeestBoxImage from '../../assets/meest-box-big-image.png';
import PolonezBoxImage from '../../assets/polonez-box-big-image.png';
import MeestLoupeImage from '../../assets/meest-big-loupe.png';
import PolonezLoupeImage from '../../assets/polonez-big-loupe.png';
import {
  ButtonWrapper,
  EmptyListContainer,
  EmptyParcelIconWrapper,
  Header,
  TextBody,
} from './empty-list.styles';
import { client } from '../../config';

type EmptyListImageType = 'box' | 'loupe';

interface EmptyListProps {
  title?: string;
  description: string;
  onClick?: () => void;
  buttonText?: string;
  isSidebar?: boolean;
  imageType?: EmptyListImageType;
}

const getImage = (type: EmptyListImageType) => {
  const images = {
    box: {
      meest: MeestBoxImage,
      polonez: PolonezBoxImage,
    },
    loupe: {
      meest: MeestLoupeImage,
      polonez: PolonezLoupeImage,
    },
  };
  return images[type][client];
};

const imageMap: Record<EmptyListImageType, string> = {
  box: getImage('box'),
  loupe: getImage('loupe'),
};

const EmptyList = ({
  title,
  description,
  onClick,
  buttonText,
  isSidebar = false,
  imageType = 'box',
}: EmptyListProps) => (
  <EmptyListContainer $isSidebar={isSidebar}>
    <EmptyParcelIconWrapper $isSidebar={isSidebar}>
      <img
        src={imageMap[imageType]}
        alt=''
      />
    </EmptyParcelIconWrapper>
    <div>
      {title && <Header>{title}</Header>}
      <TextBody>{description}</TextBody>
    </div>
    {buttonText && (
      <ButtonWrapper>
        <Button
          onClick={onClick}
          appearance='primary'>
          {buttonText}
        </Button>
      </ButtonWrapper>
    )}
  </EmptyListContainer>
);

export default EmptyList;
