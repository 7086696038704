import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactDOM from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import {
  Content,
  Container,
  Text,
  CloseIconContainer,
  CookiesIconContainer,
  AcceptButton,
  PrivacyPolicy,
} from './cookies.styles';
import { ReactComponent as CookiesSVG } from '../../../assets/main-page/cookies/cookies.svg';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import CloseButton from '../../../common/close-button/close-button.component';

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(true);
  const {
    localizationsStore: { selectedCountry },
    commonStore: { acceptedAllCookies, setAcceptedAllCookies },
  } = useStore();
  const { t } = useTranslation();

  const handleAcceptAllCookies = () => {
    setAcceptedAllCookies('true');
    document.cookie = 'cookies_accepted=true; SameSite=Strict; Secure';
  };

  if (acceptedAllCookies) {
    return null;
  }

  return (
    isVisible &&
    ReactDOM.createPortal(
      <Container onClick={(event) => event.stopPropagation()}>
        <CloseIconContainer>
          <CloseButton onClick={() => setIsVisible(false)} />
        </CloseIconContainer>
        <Content>
          <CookiesIconContainer>
            <CookiesSVG />
          </CookiesIconContainer>
          <Text>
            <Trans
              i18nKey='cookie_banner'
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                1: <strong style={{ fontWeight: 500 }} />,
                2: <PrivacyPolicy target='_blank' />,
              }}
              values={{
                privacyLink: selectedCountry?.links.privacy_policy ?? '',
              }}
            />
          </Text>
          <AcceptButton>
            <Button onClick={handleAcceptAllCookies}>
              {t('cookie_accept')}
            </Button>
          </AcceptButton>
        </Content>
      </Container>,
      document.querySelector('#dropdown-root')!
    )
  );
};

export default observer(Cookies);
