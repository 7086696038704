import styled from 'styled-components';

export const PudoWrapper = styled.div`
  padding: 1rem 1.6rem;
  border: ${({ theme }) => `1px solid ${theme.colors.baseColors.grey50}`};
  border-radius: 10px;
  margin-bottom: 2.4rem;

  @media (max-width: 650px) {
    margin-bottom: 1.2rem;
  }
`;
