import { v4 as uuid } from 'uuid';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { HsCode, HsCodeResponse } from '../../../models/hsCode';
import InputSearchWithSidebar from '../../../common/input-search-with-sidebar/input-search-with-sidebar.component';
import { useStore } from '../../../stores/store';
import { ReactComponent as ArrowRightIcon } from '../../../assets/arrow-right-icon.svg';

const ItemCategorySearch = () => {
  const { setFieldValue, getFieldProps } = useFormikContext();

  const {
    parcelCreationStore: { searchItemsByDescription },
  } = useStore();

  const { t } = useTranslation();

  return (
    <InputSearchWithSidebar<HsCodeResponse>
      name='category_search'
      inputValue={getFieldProps('category').value ?? ''}
      placeholder={t('choose_category')}
      label={t('item_category')}
      sidebarTitle={t('category')}
      sidebarInputPlaceholder=''
      inputIcon={ArrowRightIcon}
      isOptionSelected={(a, b) => a?.hsCode === b?.hsCode}
      getSearchOptions={(filter) => searchItemsByDescription(filter)}
      debounceSidebarInputChange
      onSearchOptionSelected={(category) => {
        setFieldValue('category', category.description, true);
        setFieldValue('hsCode', new HsCode(category), false);
      }}
      getKeyForSearchOption={() => uuid()}
      getDisplayValueForSearchOption={(hsCode) => hsCode.description ?? ''}
    />
  );
};

export default ItemCategorySearch;
