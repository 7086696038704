import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import InsuranceFee from '../../features/parcel-creation/insurance-fee/insurance-fee.component';
import ParcelTaxesInfo from '../../features/parcel-creation/parcel-taxes-info/parcel-taxes-info';
import ParcelItemsManagement from '../../features/parcel-creation/parcel-items-management/parcel-items-management.component';
import { useStore } from '../../stores/store';

const ParcelContent = () => {
  const {
    parcelCreationStore: { fetchInsuranceLimits, insuranceLimits, shipment },
  } = useStore();

  useEffect(() => {
    fetchInsuranceLimits();
  }, [fetchInsuranceLimits]);

  return (
    <>
      <ParcelItemsManagement />
      <InsuranceFee insuranceLimits={insuranceLimits} />
      <ParcelTaxesInfo shipment={shipment} />
    </>
  );
};

export default observer(ParcelContent);
