import styled from 'styled-components';
import { ServicePageContainer } from '../../features/services/services-page-container/services-page-container.styles';
import { ServicesSubSectionContainer } from '../../features/services/services-subsection/services-subsection.styles';

export const PersonalDataContainer = styled(ServicePageContainer)`
  ${ServicesSubSectionContainer}:nth-child(4) {
    form .inputs {
      display: grid;
      width: 100%;
      > *:last-child {
        grid-column: 1 / -1;
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
      }
    }
  }
  ${ServicesSubSectionContainer}:nth-child(3) {
    .location-inputs {
      display: flex;
      gap: 2.4rem;
      max-width: 724px;
    }

    @media (max-width: 750px) {
      form {
        .location-inputs {
          flex-direction: column;
          gap: 1.6rem;
        }
      }
    }
  }
`;
