import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from '../stores/store';
import useOfflineStatus from './use-offline-status.hook';
import { initializeTagManager } from '../utils/gtm/gtm';
import usePageTracking from './use-page-tracking.hook';
import useNavigateNewLocale from './use-navigate-to-new-locale.hook';

let didInit = false;
// eslint-disable-next-line import/no-mutable-exports
export let scrollbarWidth = 0;

initializeTagManager();

export const useInitApp = () => {
  const {
    commonStore: {
      toastErrorMissingPhoneNumber,
      setAppLoaded,
      appLoaded,
      meestToken,
      isGuestParcelCreation,
    },
    userStore: { getCurrentUser, user },
    localizationsStore: {
      fetchCountries,
      setInitialLocalization,
      isLoadingCountriesApi,
      selectedLocalization,
    },
  } = useStore();
  const { t } = useTranslation();

  const location = useLocation();
  const isAuthPage = location.pathname.includes('auth');

  useOfflineStatus();
  usePageTracking();
  const navigateToNewLocale = useNavigateNewLocale();

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      fetchCountries();
      if (!meestToken) {
        setInitialLocalization();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meestToken && !isGuestParcelCreation) {
      getCurrentUser();
    } else {
      setAppLoaded(true);
    }
  }, [getCurrentUser, setAppLoaded, meestToken, isGuestParcelCreation]);

  // Sync user's language with app's language
  useEffect(() => {
    if (
      user?.lang &&
      selectedLocalization?.code &&
      user?.lang !== selectedLocalization?.code
    ) {
      navigateToNewLocale({ langCode: user?.lang });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.lang]);

  useEffect(() => {
    !user?.phone &&
      user &&
      !isAuthPage &&
      toastErrorMissingPhoneNumber(
        <>
          {t('please_add_your_phone number_in_the')}{' '}
          <b>{t('account_settings')}</b>{' '}
        </>
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appLoaded) {
      scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    }
  }, [appLoaded]);

  return {
    showLoadingSpinner: !appLoaded || isLoadingCountriesApi,
  };
};
