import { makeAutoObservable } from 'mobx';

export default class NotificationsStore {
  notifications: string[] = ['n1'];

  constructor() {
    makeAutoObservable(this);
    // makePersistable(this, {
    //   name: 'NotificationsStore',
    //   properties: ['notifications'],
    //   storage: window.localStorage,
    // });
  }

  get countNotifications() {
    return this.notifications.length;
  }
}
