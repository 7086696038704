export const validatePassword = (password: string) => {
  const validationErrorIndexes: number[] = [];

  if (password.length < 8 || password.length > 32)
    validationErrorIndexes.push(0);
  if (!password.match(/(?=.*\d)/)) validationErrorIndexes.push(1);
  if (!password.match(/(?=.*[a-z])/)) validationErrorIndexes.push(2);
  if (!password.match(/(?=.*[A-Z])/)) validationErrorIndexes.push(3);
  if (!password.match(/(?=.*[@$!%*#?&-])/)) validationErrorIndexes.push(4);
  if (!password.match(/^\S*$/)) validationErrorIndexes.push(5);

  return validationErrorIndexes;
};
