import styled, { css } from 'styled-components';
import { TextInputPlaceholder } from '../typography/typography.styles';

export const InputPlaceholder = styled.span`
  ${TextInputPlaceholder};
`;

interface Props {
  $isDisabled?: boolean;
}

export const ArrowIconContainer = styled.div<Props>`
  display: flex;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  right: 1.6rem;

  svg path {
    transition: fill 0.2s;
  }

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      &:hover {
        svg path {
          fill: ${({ theme }) => theme.colors.namedColors.primary};
        }
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &:hover {
        svg path {
          fill: ${({ theme }) => theme.colors.baseColors.grey80};
        }
      }
    `}
`;

export const InputSelect = styled.div<Props>`
  position: relative;
  cursor: pointer;
  padding: 1rem 5.2rem 1rem 1.6rem !important;

  ${({ $isDisabled }) =>
    !$isDisabled &&
    css`
      &:hover ${ArrowIconContainer} {
        svg path {
          fill: ${({ theme }) => theme.colors.namedColors.primary};
        }
      }
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      &:hover ${ArrowIconContainer} {
        svg path {
          fill: ${({ theme }) => theme.colors.baseColors.grey80};
        }
      }
    `}
`;
