export interface FeedbackCategory {
  label: string;
  value: string;
}

export const feedbackCategories: FeedbackCategory[] = [
  {
    label: 'leave_feedback_category_longterm_delivery',
    value: 'long_term_delivery',
  },
  {
    label: 'leave_feedback_category_poor_quality_service',
    value: 'poor_quality_service',
  },
  {
    label: 'leave_feedback_category_technical_problems',
    value: 'technical_problems',
  },
  {
    label: 'leave_feedback_category_changing_branch',
    value: 'changing_branch',
  },
  {
    label: 'leave_feedback_category_other',
    value: 'other',
  },
];

export const SERVICE_PARCEL_LIST = 'shipments:parcellist';
export const SERVICE_LEAVE_FEEDBACK = 'accountsettings:leavefeedback';
