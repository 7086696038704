import styled, { css } from 'styled-components';
import meestOvalFigure from '../../../../../assets/meest-oval-figure.svg';
import polonezOvalFigure from '../../../../../assets/polonez-oval-figure.svg';
import { FontSizes } from '../../../../../common/typography/typography.styles';
import { DeliveryServiceProps } from '../../../../parcel-creation/parcel-delivery-option/parcel-delivery-option.styles';
import { WLClient } from '../../../../../config';

export const ParcelDeliveryOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  flex: 1 0 0;
`;

interface ImgContainerProps {
  $imgUrl: string;
}

const getImgUrl = (client: WLClient) => {
  const imgs = {
    meest: meestOvalFigure,
    polonez: polonezOvalFigure,
  };

  return imgs[client] || null;
};

export const DeliveryImg = styled.span<ImgContainerProps>`
  display: inline-block;
  width: 3.6rem;
  height: 3.6rem;
  min-width: 3.6rem;
  min-height: 3.6rem;
  ${({ $imgUrl }) => css`
    background: url(${$imgUrl});
  `}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const DeliveryImgContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  min-width: 4.8rem;
  min-height: 4.8rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.placeholderText.color};
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 4.8rem;
    height: 4.8rem;
  }
`;

export const DeliveryDescription = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
`;

export const DeliveryDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  font-weight: 700;
  font-size: ${FontSizes.medium};

  @media (max-width: 768px) {
    gap: 4px;
  }
`;

export const DeliveryTitle = styled.span`
  display: flex;
  gap: 1.2rem;
`;

export const DeliveryCarrier = styled.span`
  font-size: ${FontSizes.normal};
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

export const DeliveryService = styled.span<DeliveryServiceProps>`
  display: flex;
  align-items: center;
  font-size: ${FontSizes.small};
  color: ${({ theme }) => theme.colors.namedColors.primary};
  line-height: 1.6rem;
  font-weight: 500;
  &::before {
    content: '';
    display: inline-block;
    ${({ $client }) => css`
      background: url(${getImgUrl($client)}) no-repeat left padding-box;
    `}
    width: 4px;
    height: 4px;
    margin-right: 8px; /* Space between icon and text */
  }
`;

export const DeliveryDuration = styled.span`
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  font-size: ${FontSizes.small};
  font-weight: 400;
  line-height: 1.6rem;

  display: flex;
  align-items: center;
  gap: 4px;
`;

export const DeliveryPriceContainer = styled.div`
  justify-self: end;
  font-size: ${FontSizes.medium};
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
`;

export const PriceText = styled.span``;

export const DeliveryPriceContainerMob = styled(DeliveryPriceContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${PriceText} {
    display: none;
  }
`;

export const CurrencySymbol = styled.span`
  margin-left: 4px;
`;

export const BorderedWrapper = styled.div`
  padding: 1.2rem 1.6rem;
`;

interface OptionProps {
  $isSelected?: boolean;
}

export const SelectOption = styled.div<OptionProps>`
  padding: 1.2rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  border-radius: 10px;
  transition: background-color 0.25s ease-out;

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.baseColors.grey5};
    `}
`;
