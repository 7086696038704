import { observer } from 'mobx-react-lite';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ServicesMenu from '../../features/services/services-menu/services-menu.component';
import {
  ServicesContent,
  ServicesRouteContainer,
  ServicesRouteMobile,
} from './services.styles';
import { ServicesContainer } from '../../features/header/header-services/header-services.styles';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { useStore } from '../../stores/store';
import { AppRoute } from '../../constants/route';

const Services = () => {
  const {
    commonStore: { isGuestParcelCreation },
    navStore: { isServicesSidebarVisible, setServicesSidebarVisibility },
  } = useStore();
  const isMobile = useIsMobile();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(AppRoute.MY)) {
      setServicesSidebarVisibility(true);
    }

    return () => {
      if (location.pathname.includes(AppRoute.MY)) {
        setServicesSidebarVisibility(false);
      }
    };
  }, [setServicesSidebarVisibility, location]);

  return (
    <>
      {!isMobile && (
        <ServicesRouteContainer
          $isGuestParcelCreation={isGuestParcelCreation}
          $isSidebarVisible={isServicesSidebarVisible}>
          <ServicesContainer $isVisible={isServicesSidebarVisible}>
            <ServicesMenu />
          </ServicesContainer>
          <ServicesContent>
            <Outlet />
          </ServicesContent>
        </ServicesRouteContainer>
      )}
      {isMobile && (
        <ServicesRouteMobile>
          <Outlet />
        </ServicesRouteMobile>
      )}
    </>
  );
};

export default observer(Services);
