import { useEffect } from 'react';
import { useStore } from '../stores/store';

const useLogoutUnverifiedUser = () => {
  const {
    userStore: { user, logout },
    navStore: { setAuthCurrentScreen },
  } = useStore();

  useEffect(() => {
    if (user && !user.phone_verified) {
      logout();
      setAuthCurrentScreen('auth-tabs');
    }
  }, [user, logout, setAuthCurrentScreen]);
};

export default useLogoutUnverifiedUser;
