import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GlobusIcon } from '../../../assets/globus-icon.svg';
import { useStore } from '../../../stores/store';
import { HeaderBtn, HeaderBtnContent } from '../header-btn/header-btn.styles';
import { Container } from './lang-switcher.styles';
import { Dropdown } from '../../../common/dropdown/dropdown.styles';
import MenuItem from '../../../common/menu-item/menu-item.component';
import { HeaderText } from '../header.styles';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../../../common/popover/popover.component';
import { PreferencesDataValues } from '../../../models/preferencesDataValues';
import { Language } from '../../../models/language';
import useNavigateNewLocale from '../../../hooks/use-navigate-to-new-locale.hook';

const LangSwitcher = () => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const {
    localizationsStore: {
      availableLocalizations,
      selectedLocalization,
      setIsLanguageChanging,
    },
    userStore: { user, updatePreferences },
    commonStore: { toastSuccess },
    parcelCreationStore: { shipment, getShipmentDetails },
  } = useStore();

  const { t } = useTranslation();
  const navigateToNewLocale = useNavigateNewLocale();

  const handleLanguageChange = async (language: Language) => {
    setIsLanguageChanging(true);
    navigateToNewLocale({ langCode: language.code });

    // When app language is changed we should also update user's language
    if (user) {
      const updatePrefRequest = {
        lang: language.code,
        country: user.country,
        currency: user.currency,
        measures: user.measures,
      } as PreferencesDataValues;

      const success = await updatePreferences(updatePrefRequest);
      success && toastSuccess(t('data_updated_successfully'));
    }

    if (shipment) {
      await getShipmentDetails(shipment.id);
    }
    setIsLanguageChanging(false);
  };

  return (
    <Container>
      <Popover
        placement='bottom-end'
        onOpenChange={setIsPopoverVisible}
        open={isPopoverVisible}>
        <PopoverTrigger
          asChild
          onClick={() => setIsPopoverVisible((v) => !v)}>
          <HeaderBtn $isActive={isPopoverVisible}>
            <HeaderBtnContent>
              <GlobusIcon />
            </HeaderBtnContent>
            <HeaderText>
              {selectedLocalization?.code.toLocaleUpperCase()}
            </HeaderText>
          </HeaderBtn>
        </PopoverTrigger>
        <PopoverContent>
          <Dropdown
            $width='19rem'
            $isOpened>
            {availableLocalizations.map((l) => (
              <PopoverClose key={l.code}>
                <MenuItem
                  onClick={() => handleLanguageChange(l)}
                  size='small'
                  contentWeight={500}
                  showBackgroundOnSelected={false}
                  isSelected={selectedLocalization?.code === l.code}>
                  {l.code.toLocaleUpperCase()} - {l.languageLabel}
                </MenuItem>
              </PopoverClose>
            ))}
          </Dropdown>
        </PopoverContent>
      </Popover>
    </Container>
  );
};

export default observer(LangSwitcher);
