import styled, { css, keyframes } from 'styled-components';
import { SupportLinkType } from '../../../models/countriesModel';

const slideInFromRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(10px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const LiveChatWrapper = styled.div`
  position: fixed;
  z-index: 1118000;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  align-items: flex-end;
  bottom: 4%;
  right: 2.4rem;
`;

interface LiveChatContainerProps {
  $width?: string;
  $height?: string;
  $type?: SupportLinkType;
}

export const TextWrapper = styled.span`
  display: none;
  opacity: 0;
  gap: 0.4rem;
  transition: opacity 0.3s ease-in-out;
  animation: ${slideInFromRight} 0.3s ease-in-out;
`;

export const Text = styled.span`
  margin-left: 0.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.05rem;
`;

export const LiveChatContainer = styled.a<LiveChatContainerProps>`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.namedColors.white};
  min-width: ${({ $width = '4.8rem' }) => $width};
  height: ${({ $height = '4.8rem' }) => $height};
  border-radius: 3.2rem;
  background-color: ${({ theme }) => theme.colors.namedColors.primary};
  transition:
    transform 0.15s ease-in-out,
    padding 0.3s ease-in-out,
    border-radius 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px rgba(5, 35, 107, 0.08);

  svg {
    min-width: 2.4rem;
  }

  &:not(:last-child) {
    margin-right: 6px;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colors.liveChat.backgroundColorActive};
    transition:
      transform 0.1s ease-in-out,
      background-color 0.1s ease-in-out;
  }

  ${({ $type }) =>
    ($type === 'email' || $type === 'phone') &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          transform: none;
          border-radius: 3rem;
          padding: 0 1.2rem;

          ${TextWrapper} {
            display: flex;
            opacity: 1;
            animation: ${slideInFromRight} 0.3s ease-in-out;
          }
        }
      }
    `}
`;
