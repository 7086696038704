import { useState, useEffect, useRef } from 'react';
import { Formik, FormikState, useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../assets/magnifying-glass-icon.svg';
import { ReactComponent as CloseCircleIcon } from '../../assets/close-circle-icon.svg';
import {
  ClearSearchButton,
  InputSearchContainer,
  InputListSearchContainer,
  SearchButtonContainer,
  SearchIconContainer,
} from './input-list-search.styles';
import { useStore } from '../../stores/store';
import { InputContainer } from '../form-field-text/form-field-text.styles';
import { ButtonSpinner } from '../button/button.styles';
import { IParcelListPathValue } from '../../routes/parcel-list/config';
import {
  MAX_CHAR_LENGTH,
  MaxLength,
} from '../form-field-text/form-field-text.component';
import InputMessage from '../input-message/input-message.component';
import { client } from '../../config';

const getPlaceholder = () => {
  const placeholders = {
    meest: 'track_number,_phone,_parcel_content',
    polonez: 'search',
  };

  return placeholders[client];
};

interface SearchFormChangeHandlerProps {
  routeName?: IParcelListPathValue['path'];
  setInputSearchValue: (value: string) => void;
}

const FormChangeHandler = ({
  routeName,
  setInputSearchValue,
}: SearchFormChangeHandlerProps) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (routeName) {
      resetForm();
      setInputSearchValue('');
    }
  }, [routeName, resetForm, setInputSearchValue]);

  return null;
};

interface Props {
  listType: 'parcel' | 'receiver';
  isDisabled?: boolean;
  onChange?: (value: string) => void;
  routeName?: IParcelListPathValue['path'];
  maxLength?: MaxLength;
}

interface SearchFormValues {
  searchQuery: string;
}

const initialValues: SearchFormValues = {
  searchQuery: '',
};

const InputListSearch = ({
  listType,
  isDisabled,
  onChange,
  routeName,
  maxLength = MAX_CHAR_LENGTH,
}: Props) => {
  const {
    parcelListStore: { getParcelsSearch, isLoadingSearch },
  } = useStore();

  const [inputSearchValue, setInputSearchValue] = useState('');
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [showSearchButtonContainer, setShowSearchButtonContainer] =
    useState(false);

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const isParcelType = listType === 'parcel';
  const searchHandler = isParcelType
    ? getParcelsSearch
    : onChange || (() => {});

  useEffect(() => {
    setShowSearchIcon(!inputSearchValue);
    setShowSearchButtonContainer(!!inputSearchValue);
  }, [inputSearchValue]);

  const clearInput = (
    resetForm: (nextState?: Partial<FormikState<SearchFormValues>>) => void
  ) => {
    resetForm();
    setInputSearchValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
    searchHandler('');
  };

  const handleFormSubmit = (values: SearchFormValues) => {
    if (values.searchQuery.trim() !== '') {
      searchHandler(values.searchQuery);
    }
  };

  return (
    <InputListSearchContainer>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}>
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <InputSearchContainer
              $isDisabled={isDisabled}
              $treatWholeInputAsSearch
              $hIconPosition='left'
              $showSearchIcon={showSearchIcon}>
              <InputContainer
                $error={false}
                $fontSize='1.4rem'>
                {inputSearchValue && (
                  <ClearSearchButton
                    $showSearchButtonContainer={showSearchButtonContainer}
                    onClick={() => clearInput(resetForm)}>
                    <CloseCircleIcon />
                  </ClearSearchButton>
                )}
                <input
                  maxLength={maxLength}
                  value={values.searchQuery}
                  ref={inputRef}
                  onChange={(event) => {
                    setInputSearchValue(event.target.value);
                    handleChange(event);
                    if (event.target.value === '') {
                      searchHandler('');
                    }
                  }}
                  onBlur={handleBlur}
                  type='text'
                  id='searchQuery'
                  name='searchQuery'
                  placeholder={t(
                    `${isParcelType ? getPlaceholder() : 'search_receivers'}`
                  )}
                  className='flag-emoji-font'
                />
                {showSearchIcon && (
                  <SearchIconContainer
                    $hIconPosition='left'
                    $disableSearchHover>
                    <SearchIcon />
                  </SearchIconContainer>
                )}
                {showSearchButtonContainer && (
                  <SearchButtonContainer
                    $contentStyle='thin'
                    $fullWidth={false}
                    onClick={() => handleSubmit()}
                    $isLoading={isLoadingSearch}>
                    {isLoadingSearch ? <ButtonSpinner /> : t('search')}
                  </SearchButtonContainer>
                )}
              </InputContainer>
              {errors.searchQuery && (
                <InputMessage type='error'>
                  {t(errors.searchQuery)}
                </InputMessage>
              )}
            </InputSearchContainer>

            <FormChangeHandler
              routeName={routeName}
              setInputSearchValue={setInputSearchValue}
            />
          </form>
        )}
      </Formik>
    </InputListSearchContainer>
  );
};

export default observer(InputListSearch);
