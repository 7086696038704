import {
  RadioContainer,
  HiddenRadio,
  Label,
} from './custom-radio-button.styles';

interface RadioButtonProps<T> {
  label: string;
  value: T;
  name: string;
  isSelected: boolean;
  onSelect: (value: T) => void;
}

const CustomRadioButton = <T,>({
  label,
  value,
  name,
  isSelected,
  onSelect,
}: RadioButtonProps<T>) => (
  <RadioContainer checked={isSelected}>
    <HiddenRadio
      type='radio'
      name={name}
      checked={isSelected}
      onChange={() => onSelect(value)}
    />
    <Label onClick={() => onSelect(value)}>{label}</Label>
  </RadioContainer>
);

export default CustomRadioButton;
