import styled from 'styled-components';

export const ServicePageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @media screen and (max-width: 649px) {
    margin: 0 auto;
    padding-bottom: 1.6rem;
  }
`;
