import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import { HeaderBtn, HeaderBtnContent } from '../header-btn/header-btn.styles';
import {
  HeaderNavContainer,
  UserAvatar,
  UserNavMobileContainer,
  ActionsDesktop,
} from './header-user.styles';
import NavUserMenu from './header-user-menu.component';
import { Dropdown } from '../../../common/dropdown/dropdown.styles';
import Button from '../../../common/button/button.component';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../common/popover/popover.component';

interface Props {
  isMainPage: boolean;
}

const NavUser = ({ isMainPage }: Props) => {
  const {
    userStore: { getInitials },
    navStore: {
      toggleLeftSidebar,
      isNavbarPopupOpened,
      toggleNavbarPopupOpened,
    },
  } = useStore();

  const handleStartParcelCreation = useStartParcelCreation();
  const { t } = useTranslation();

  return (
    <>
      <ActionsDesktop>
        {isMainPage && (
          <Button
            fullWidth={false}
            onClick={() => {
              handleStartParcelCreation();
            }}>
            {t('create_parcel')}
          </Button>
        )}
        <Popover
          initialOpen={isNavbarPopupOpened}
          open={isNavbarPopupOpened}
          onOpenChange={toggleNavbarPopupOpened}
          placement='bottom-end'>
          <PopoverTrigger asChild>
            <HeaderBtn onClick={toggleNavbarPopupOpened}>
              <HeaderBtnContent>
                <UserAvatar>{getInitials()}</UserAvatar>
              </HeaderBtnContent>
            </HeaderBtn>
          </PopoverTrigger>
          <PopoverContent>
            <HeaderNavContainer>
              <Dropdown $width='27.2rem'>
                <NavUserMenu />
              </Dropdown>
            </HeaderNavContainer>
          </PopoverContent>
        </Popover>
      </ActionsDesktop>

      <UserNavMobileContainer>
        <HeaderBtn onClick={toggleLeftSidebar}>
          <HeaderBtnContent>
            <UserAvatar>{getInitials()}</UserAvatar>
          </HeaderBtnContent>
        </HeaderBtn>
      </UserNavMobileContainer>
    </>
  );
};

export default observer(NavUser);
