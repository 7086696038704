import styled, { css } from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';

interface Props {
  $withDetails?: boolean;
}

export const PhoneLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.namedColors.primary};
`;

export const CopyPhone = styled.button`
  appearance: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.namedColors.primary};
  padding: 0;
  font: inherit;
  cursor: pointer;

  span {
    position: relative;
    top: 2px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const TimeLabel = styled.div`
  font-size: ${FontSizes.small};
  font-style: normal;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const MoreDetails = styled.div`
  display: flex;
  justify-content: end;
`;

export const PudoName = styled.span`
  font-size: ${FontSizes.medium};
  font-style: normal;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  word-break: break-word;
`;

export const AddressLabel = styled.span`
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
`;

export const MaxWeight = styled.span``;

export const PudoListItemContainer = styled.div<Props>`
  display: grid;
  gap: 0.4rem 1.2rem;
  align-items: center;

  ${({ $withDetails }) =>
    $withDetails
      ? css`
          grid-template-columns: 1fr 105px;
          justify-content: space-between;
          grid-template-areas:
            'name weight'
            'address distance'
            'time more';

          > *:nth-child(2n) {
            justify-self: end;
          }

          ${MoreDetails} {
            grid-area: more;
          }

          ${TimeLabel} {
            grid-area: time;
          }

          ${PudoName} {
            grid-area: name;
          }

          ${AddressLabel} {
            grid-area: address;
          }

          ${MaxWeight} {
            grid-area: weight;
            align-self: start;
          }
        `
      : css`
          ${TimeLabel}:empty {
            display: none;
          }
        `}
`;

export const PudoNameDetailed = styled.span`
  font-size: ${FontSizes.mediumPlus};
  font-style: normal;
  font-weight: 700;
  line-height: 2.2rem;
  letter-spacing: 0.5px;
  overflow-wrap: anywhere;
`;

export const TimeLabelDetailed = styled(TimeLabel)`
  font-size: ${FontSizes.normal};
  margin-bottom: 4px;
`;

export const AddressLabelDetailed = styled.span`
  font-size: ${FontSizes.medium};
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
`;

export const PudoLeftColumn = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 3px;
  flex: 0 0 26.5rem;
`;

export const DistanceLabel = styled.span`
  font-size: ${FontSizes.small};
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 4px;
  padding: 0 4px;
  grid-area: distance;
`;

export const PudoRightColumn = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3.2rem;
`;

export const PudoDetailsContainer = styled.div`
  padding: 2.4rem;
  height: calc(100dvh - 2.4rem);
  position: relative;
  cursor: default;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const BackToListBtn = styled.div`
  align-self: flex-start;
`;

export const NameAndWeightBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 8px;
  gap: 1rem;

  ${PudoNameDetailed} {
    flex: 0 0 33rem;
  }

  > *:last-child {
    flex-shrink: 0;
  }
`;

export const AddressAndDistancBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const DistanceDetailed = styled(DistanceLabel)`
  font-size: ${FontSizes.normal};
`;

export const SeparatorLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.baseColors.grey50};
  margin-bottom: 1.6rem;
`;

export const LabelDetailed = styled(TimeLabelDetailed)`
  margin-bottom: 8px;
`;

export const InfoDetailed = styled.span`
  font-size: ${FontSizes.medium};
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.5px;
`;

export const InfoGrayDetailed = styled(InfoDetailed)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const WorkingHoursBlock = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1.8rem;
  row-gap: 2px;
  margin-bottom: 1.6rem;

  ${InfoDetailed} {
    line-height: 2.2rem;
  }
`;

export const PaymentBlock = styled.div`
  margin-bottom: 1.6rem;
`;

export const SupportBlock = styled.div`
  margin-bottom: 2.4rem;
  display: grid;
  justify-content: start;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 1.375;
  font-weight: 500;
  svg {
    cursor: pointer;
  }
`;

export const PudoDetailsContent = styled.div``;

export const ButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
