import { Geocode } from '../../models/pudoItem';
import { MapPosition } from './map.config';

export const createBoundingBox = (center: Geocode, radiusKm: number) => {
  const { latitude, longitude } = center;

  // Delta in latitude, straightforward (1 degree ~111.32 km)
  const latDelta = radiusKm / 111.32; // 1 degree of latitude = ~111.32 km
  // Delta in longitude, adjusted for latitude
  const lngDelta = radiusKm / (111.32 * Math.cos((latitude * Math.PI) / 180));

  const sw = {
    lat: latitude - latDelta,
    lng: longitude - lngDelta,
  };

  const ne = {
    lat: latitude + latDelta,
    lng: longitude + lngDelta,
  };

  return new google.maps.LatLngBounds(sw, ne);
};

export const toLatLng = (geocode: Geocode): google.maps.LatLngLiteral => ({
  lat: geocode.latitude,
  lng: geocode.longitude,
});

export const toGeocode = (latLng: google.maps.LatLng | undefined) => {
  if (latLng)
    return {
      latitude: latLng.lat(),
      longitude: latLng.lng(),
    };
};

// // GPT 4o
export function calculateDistance(point1: Geocode, point2: Geocode): number {
  const R = 6371; // Radius of the Earth in kilometers
  const lat1 = point1.latitude * (Math.PI / 180); // Convert degrees to radians
  const lat2 = point2.latitude * (Math.PI / 180); // Convert degrees to radians
  const deltaLat = (point2.latitude - point1.latitude) * (Math.PI / 180); // Difference in latitude
  const deltaLon = (point2.longitude - point1.longitude) * (Math.PI / 180); // Difference in longitude

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = parseFloat((R * c).toFixed(3)); // Distance in kilometers
  return distance;
}

export function updateMapPosition(
  map: google.maps.Map | null | undefined,
  position: MapPosition | undefined
) {
  if (!map) return;
  if (!position) return;
  const { center, zoom } = position;
  if (center) map.setCenter(center);
  if (zoom) map.setZoom(zoom);
}
