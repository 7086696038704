import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactDOM from 'react-dom';
import { ReactComponent as LiveChatIcon } from '../../../assets/main-page/live-chat/chat.svg';
import { ReactComponent as CloseChatIcon } from '../../../assets/main-page/live-chat/close.svg';
import { ReactComponent as CopyIcon } from '../../../assets/copy-icon.svg';
import { useStore } from '../../../stores/store';
import {
  LiveChatContainer,
  LiveChatWrapper,
  Text,
  TextWrapper,
} from './live-chat.styles';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import {
  EMAIL_AND_PHONE_TYPES,
  filterAndSortSupportLinks,
  getHref,
  getIcon,
  handleClick,
} from '../../../utils/live-chat/live-chat.utils';
import { copyToClipboard } from '../../../utils/generic/generic.utils';
import { SupportLinkType } from '../../../models/countriesModel';

const LiveChat = () => {
  const {
    localizationsStore: { selectedCountry },
  } = useStore();

  const [isLiveChatVisible, setIsLiveChatVisible] = useState(false);

  const isMobile = useIsMobile();

  if (!selectedCountry) return;

  const links = filterAndSortSupportLinks(selectedCountry?.support);

  if (links.length === 0) return;

  return ReactDOM.createPortal(
    <LiveChatWrapper>
      {isLiveChatVisible && (
        <>
          {links.map(({ url, type }) => (
            <LiveChatContainer
              key={url}
              href={getHref(url, type, isMobile)}
              onClick={() =>
                handleClick({
                  url,
                  type: type as SupportLinkType,
                  isMobile,
                  copyToClipboard,
                })
              }
              target={
                !EMAIL_AND_PHONE_TYPES.includes(type) ? '_blank' : undefined
              }
              $type={type}>
              {getIcon(type)}
              {!isMobile && EMAIL_AND_PHONE_TYPES.includes(type) && (
                <TextWrapper>
                  <Text>{url}</Text>
                  <CopyIcon />
                </TextWrapper>
              )}
            </LiveChatContainer>
          ))}
        </>
      )}

      <LiveChatContainer
        as='div'
        $width='6rem'
        $height='6rem'
        onClick={() => setIsLiveChatVisible(!isLiveChatVisible)}>
        {!isLiveChatVisible ? <LiveChatIcon /> : <CloseChatIcon />}
      </LiveChatContainer>
    </LiveChatWrapper>,
    document.querySelector('#dropdown-root')!
  );
};

export default observer(LiveChat);
