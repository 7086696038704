import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Title,
  WidgetCalculatorContainer,
  WidgetCalculatorWrapper,
  WidgetCalculatorSection,
  BackgroundImage,
} from './widget-calculator.styles';

import WidgetCalculatorBackgroundWebp1x from '../../../assets/main-page/widget-calculator/widget-calculator-bgr@1x.webp';
import WidgetCalculatorBackgroundWebp2x from '../../../assets/main-page/widget-calculator/widget-calculator-bgr@2x.webp';
import WidgetCalculatorBackground1x from '../../../assets/main-page/widget-calculator/widget-calculator-bgr@1x.jpg';
import WidgetCalculatorBackground2x from '../../../assets/main-page/widget-calculator/widget-calculator-bgr@2x.jpg';

import WidgetCalculatorForm, {
  FormValues,
} from './widget-calculator-form.component';
import { SectionSubtitle } from '../common/typography.styles';
import { SearchParam } from '../../../constants/route';
import { theme } from '../../../styles/themes';

const WidgetCalculator = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<FormValues | undefined>(undefined);

  useEffect(() => {
    const dataParam = searchParams.get(SearchParam.DATA);

    if (dataParam) {
      try {
        const parsedData: FormValues = JSON.parse(
          decodeURIComponent(dataParam)
        );
        setFormData(parsedData);
        navigate(location.pathname, { replace: true });
      } catch (error) {
        console.error('Failed to decode URI parameter', error);
      }
    }
  }, [searchParams, navigate, location.pathname]);

  return (
    <WidgetCalculatorWrapper>
      <WidgetCalculatorSection>
        <WidgetCalculatorContainer>
          <picture>
            <source
              srcSet={`${WidgetCalculatorBackgroundWebp1x} 1x, ${WidgetCalculatorBackgroundWebp2x} 2x`}
              type='image/webp'
            />
            <BackgroundImage
              src={WidgetCalculatorBackground1x}
              srcSet={`${WidgetCalculatorBackground1x} 1x, ${WidgetCalculatorBackground2x} 2x`}
              alt=''
            />
          </picture>
          <SectionSubtitle $color={theme.colors.namedColors.white}>
            {t('your_comfortable_post')}
          </SectionSubtitle>
          <Title>{t('global_parcel_delivery')}</Title>
          <WidgetCalculatorForm defaultValues={formData} />
        </WidgetCalculatorContainer>
      </WidgetCalculatorSection>
    </WidgetCalculatorWrapper>
  );
};

export default WidgetCalculator;
