import { css } from 'styled-components';
import { FontSizes } from './app/common/typography/typography.styles';
import { PasswordValidatorContainer } from './app/common/password-validator/password-validator.styles';
import { SidebarContainer } from './app/common/sidebar-right/sidebar.styles';
import { UserNavMobileContainer } from './app/features/header/header-user/header-user.styles';

import { ParcelDetailsSearchInputsContainer } from './app/routes/parcel-details/parcel-details.styles';
import { ParcelStepLeftContainer } from './app/features/parcel-creation/parcel-step-left-container/parcel-step-left-container.styles';
import {
  DeliveryPriceContainerMob,
  ParcelDeliveryOptionContainer,
  PriceText,
} from './app/features/parcel-creation/parcel-delivery-option/parcel-delivery-option.styles';
import { SelectOption } from './app/features/parcel-creation/parcel-delivery-options-tabs/parcel-delivery-options-tabs.styles';
import { PudoSearchContainer } from './app/features/parcel-creation/parcel-pudo-search-sidebar/parcel-pudo-search-sidebar.styles';
import {
  AddressAndDistancBlock,
  AddressLabel,
  AddressLabelDetailed,
  BackToListBtn,
  ButtonsBlock,
  InfoDetailed,
  NameAndWeightBlock,
  PudoDetailsContainer,
  PudoLeftColumn,
  PudoListItemContainer,
  PudoName,
  PudoNameDetailed,
  PudoRightColumn,
  TimeLabelDetailed,
} from './app/features/parcel-creation/pudo-list-item/pudo-list-item.styles';
import { ParcelParticipantDetailsContainer } from './app/features/parcel-creation/parcel-participant-personal/parcel-participant-personal.styles';
import { PudoFilterContainer } from './app/features/parcel-creation/pudo-filter/pudo-filter.styles';
import {
  FilterSection,
  FilterSectionTitle,
  FiltersHeading,
} from './app/common/filters/filters.styles';

export const MediaQueries = css`
  #parcelProgressMobile {
    display: none;
  }

  #deliveryPriceResponsive {
    display: none;
  }

  @media (max-width: 1200px) {
    ${ParcelDetailsSearchInputsContainer} {
      flex-direction: column;
    }
  }

  @media (max-width: 1100px) {
    ${ParcelDeliveryOptionContainer} {
      padding-right: 0 !important;
    }

    ${DeliveryPriceContainerMob} {
      display: none;
    }

    #deliveryPriceResponsive {
      margin-top: -1rem;
      display: flex;
      flex-direction: row;
      font-weight: 500;
      width: 100%;
      ${PriceText} {
        display: inline-block;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      margin-bottom: 1rem;
    }

    .Toastify__toast-container--top-center {
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 650px) {
    body {
      overflow-x: hidden;
    }

    ${SidebarContainer} {
      width: 100%;
    }

    ${UserNavMobileContainer} {
      display: flex;
    }

    ${ParcelStepLeftContainer} {
      margin-top: 6rem;
      padding-bottom: 0;
    }

    #parcelProgressMobile {
      display: flex;
    }

    ${ParcelParticipantDetailsContainer} {
      form {
        .inputs {
          flex-direction: column;
          gap: 1.6rem;
        }
      }
    }

    ${PudoSearchContainer} {
      padding: 1.2rem;
    }

    ${SelectOption} {
      padding: 1.2rem;
    }

    ${PudoListItemContainer} {
      ${PudoLeftColumn} {
        flex: 0 0 22rem;
      }

      ${PudoRightColumn} {
        gap: 2.2rem;
      }

      ${PudoDetailsContainer} {
        padding-bottom: 8.5rem;
      }

      ${NameAndWeightBlock} {
        ${PudoNameDetailed} {
          flex: 0 0 22rem;
          font-size: ${FontSizes.medium};
        }
      }

      ${AddressAndDistancBlock} {
        margin-bottom: 1.2rem;
        ${AddressLabelDetailed} {
          flex: 0 0 22rem;
        }
      }

      ${ButtonsBlock} {
        margin-top: auto;
      }

      ${PudoName} {
        font-size: ${FontSizes.normal};
      }

      ${AddressLabel} {
        font-size: ${FontSizes.small};
      }

      ${InfoDetailed} {
        font-size: ${FontSizes.normal};
      }

      ${TimeLabelDetailed} {
        font-size: ${FontSizes.small};
      }

      ${BackToListBtn} {
        margin-bottom: 2.4rem;
        font-size: ${FontSizes.normal};

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }
      }
    }

    ${PudoFilterContainer} {
      ${FiltersHeading} {
        margin-bottom: 2px;
      }

      ${FilterSection} {
        margin-top: 1.2rem;
        padding: 1.6rem;
      }

      ${FilterSectionTitle} {
        margin-bottom: 1.2rem;
      }
    }
  }

  @media (max-width: 550px) {
    ${PasswordValidatorContainer} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }
  }
`;
