import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SpinnerOverlay, SpinnerCircle } from '../spinner/spinner.styles';
import { ProgressBarWrapper, Wrapper } from './page-loader.styles';
import ProgressBar from '../progress-bar/progress-bar.component';

interface Props {
  isVisible: boolean;
}
const getRandomInteger = (min: number, max: number) => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);

  return Math.floor(Math.random() * (maxValue - minValue) + minValue);
};

const PageLoader = ({ isVisible }: Props) => {
  const [progress, setProgress] = useState(0);
  const [shouldRemoveFromDom, setShouldRemoveFromDom] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const timer = useRef<any>();
  const oldProgress = useRef<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const initialValue = useRef<number>(0);

  useLayoutEffect(() => {
    const progressBar = document.querySelector(
      '.js-loading-screen-preloader-fill'
    );
    if (!progressBar) return;

    progressBar.setAttribute('data-animation', 'stop');
    const progressBarValue = parseInt(
      progressBar.getAttribute('data-progress')!,
      10
    );
    initialValue.current = progressBarValue;
    setProgress(progressBarValue);
    const loadingItems = document.querySelectorAll('.js-loading-screen-item');
    loadingItems.forEach((item) => {
      item.remove();
    });
  }, []);

  const handleRemoveFromDom = () => {
    setShouldRemoveFromDom(true);
  };

  useEffect(() => {
    const el = ref.current;
    if (!isVisible && el) {
      clearTimeout(timer.current);
      setProgress(100);
      el.addEventListener('transitionend', handleRemoveFromDom);
      el.classList.add('is-hiding');
    }

    return () => {
      el?.removeEventListener('transitionend', handleRemoveFromDom);
    };
  }, [isVisible]);

  useLayoutEffect(() => {
    if (!isVisible) return;
    if (initialValue.current < progress || initialValue.current === 0)
      setIsAnimated(true);

    clearTimeout(timer.current);

    const diff = getRandomInteger(1, 10);

    timer.current = setTimeout(
      () => {
        setProgress(Math.min((oldProgress.current || progress) + diff, 90));
      },
      getRandomInteger(200, 500)
    );
    oldProgress.current = progress;
  }, [progress, isVisible, isAnimated]);

  if (shouldRemoveFromDom) return null;

  return (
    <Wrapper ref={ref}>
      <SpinnerOverlay $isFullScreen>
        <ProgressBarWrapper>
          <ProgressBar
            size='medium'
            isAnimated={isAnimated}
            width={progress}
          />
        </ProgressBarWrapper>
        <SpinnerCircle />
      </SpinnerOverlay>
    </Wrapper>
  );
};

export default PageLoader;
