import Skeleton from 'react-loading-skeleton';
import {
  Card,
  Header,
  Items,
  Info,
  InfoItem,
  InfoAbout,
  ItemsIcon,
  InfoItemLine,
  Footer,
} from './parcel-list-card.styles';
import { ReactComponent as ShipmentsIcon } from '../../../assets/shipments-icon.svg';
import { IParcelListPathValue } from '../../../routes/parcel-list/config';

interface Props {
  routeName: IParcelListPathValue['path'];
}

const ParcelListCardSkeleton = ({ routeName }: Props) => (
  <Card $routeName={routeName}>
    <Header>
      <Skeleton
        containerClassName='flex-1'
        width='100%'
        height='2rem'
        borderRadius={6}
      />
    </Header>
    <Info>
      <InfoItem>
        <InfoAbout>
          <Skeleton
            width='100px'
            height='1.5rem'
            borderRadius={6}
          />
          <br />
          <Skeleton
            width='100px'
            height='1.5rem'
            borderRadius={6}
          />
        </InfoAbout>
        <Skeleton
          width='100px'
          height='1.5rem'
          borderRadius={6}
        />
        <InfoItemLine />
      </InfoItem>
      <InfoItem>
        <InfoAbout>
          <Skeleton
            width='100px'
            height='1.5rem'
            borderRadius={6}
          />
          <br />
          <Skeleton
            width='100px'
            height='1.5rem'
            borderRadius={6}
          />
        </InfoAbout>
        <Skeleton
          width='100px'
          height='1.5rem'
          borderRadius={6}
        />
      </InfoItem>
    </Info>
    <Items>
      <ItemsIcon>
        <ShipmentsIcon />
      </ItemsIcon>{' '}
      <Skeleton
        width='100px'
        height='1.5rem'
        borderRadius={6}
      />
    </Items>
    {routeName !== 'drafts' && (
      <Footer>
        <Skeleton
          containerClassName='flex-1'
          width='100%'
          height='4rem'
          borderRadius={6}
        />
      </Footer>
    )}
  </Card>
);

export default ParcelListCardSkeleton;
