import { t } from 'i18next';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ModalTextBody } from '../../../common/modal-dialog/modal-dialog.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
}

const QuitEditItemDialog = ({ isOpen = false, onClose, onApprove }: Props) => (
  <ModalDialog
    isDanger
    approveBtnContent={t('quit')}
    cancelBtnContent={t('cancel')}
    isOpen={isOpen}
    onApprove={onApprove}
    onClose={onClose}>
    <HeaderSecondary>{t('quit_editing?')}</HeaderSecondary>
    <ModalTextBody>{t('changes_you_made_will_not_be_saved')}</ModalTextBody>
  </ModalDialog>
);

export default QuitEditItemDialog;
