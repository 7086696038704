import { ReactComponent as ProgressCompletedIcon } from '../../../../../assets/progress-completed-icon.svg';
import { ReactComponent as ProgressActiveIcon } from '../../../../../assets/progress-active-icon.svg';
import { ReactComponent as ProgressFutureIcon } from '../../../../../assets/progress-future-icon.svg';
import { IStatusHistory } from '../../../../../models/parcelDeliveryMiles';
import { formatDate } from '../../../../../utils/generic/generic.utils';
import { translate } from '../../../../../../i18n';

export type ParcelHistoryProgress = 'completed' | 'active' | 'future';

type Icon = {
  [key in ParcelHistoryProgress]: React.ReactNode;
};

const icons: Icon = {
  completed: <ProgressCompletedIcon />,
  active: <ProgressActiveIcon />,
  future: <ProgressFutureIcon />,
};

export const getIcon = (progress: ParcelHistoryProgress) => icons[progress];

export const getStatusProgress = (
  statusIdex: number,
  currentStatusIndex: number,
  lastIndex: number
): ParcelHistoryProgress => {
  if (statusIdex < lastIndex && statusIdex === currentStatusIndex) {
    return 'active';
  }

  if (statusIdex > currentStatusIndex) {
    return 'future';
  }

  return 'completed';
};

export const isParcelSent = (history: IStatusHistory[] | null) =>
  history?.find(
    (x) => !!x.created_at && (x.status === 'sent' || x.status === 'in_delivery')
  );

export const isParcelReadyForPickup = (history: IStatusHistory[] | null) =>
  !!history?.find((x) => x.status === 'ready_to_pick_up')?.created_at;

export const normalizeHistory = (
  history: IStatusHistory[] | null,
  deliveryDateTime: string | null
) => {
  if (!history) return null;
  if (!deliveryDateTime) return history;

  const normalizedHistory = [...history];
  const readyToPickupIndex = normalizedHistory.findIndex(
    (x) => x.status === 'ready_to_pick_up'
  );

  if (readyToPickupIndex <= -1) return normalizedHistory;

  const readyToPickUpNormalized: IStatusHistory = {
    ...normalizedHistory[readyToPickupIndex],
    created_at: `~${formatDate(deliveryDateTime)}`,
    tracking: [
      {
        created_at: `~${formatDate(deliveryDateTime)}`,
        text: translate('approximate_delivery_date'),
        carrier: null,
        city: null,
        code: null,
        country: null,
        detail_text: null,
        state: null,
      },
    ],
  };

  normalizedHistory[readyToPickupIndex] = readyToPickUpNormalized;

  return normalizedHistory;
};
