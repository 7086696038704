import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  TopDirectionsHeader,
  TopDirectionsItems,
  TopDirectionsStyledNavLink,
  Wrapper,
} from './top-directions.styles';
import { Container } from '../common/container.styles';
import { useStore } from '../../../stores/store';
import { TopDirections as TopDirectionModel } from '../../../models/topDirectionsModels';

const TopDirections = () => {
  const {
    marketingStore: { topDirections, fetchTopDirections },
    localizationsStore: { selectedCountry, languageCode },
  } = useStore();
  const { t } = useTranslation();

  useEffect(() => {
    const handler = async () => {
      await fetchTopDirections();
    };
    handler();
  }, [fetchTopDirections]);

  const getLocalizedDirections = (item: TopDirectionModel) => {
    switch (languageCode) {
      case 'uk':
        return item.directionsUk;
      case 'ru':
        return item.directionsRu;
      default:
        return item.directions;
    }
  };

  return (
    <Container>
      <Wrapper>
        <Content>
          <TopDirectionsHeader>{t('top_directions')}</TopDirectionsHeader>
          <TopDirectionsItems>
            {topDirections
              ?.filter(
                (item: TopDirectionModel) =>
                  item.name === selectedCountry?.en_name
              )
              .map((item: TopDirectionModel) => {
                const localizedDirections = getLocalizedDirections(item);
                return localizedDirections.map(
                  (direction: string, index: number) => (
                    <TopDirectionsStyledNavLink
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}-${index}`}
                      target='_blank'
                      to='#'>
                      {direction}
                    </TopDirectionsStyledNavLink>
                  )
                );
              })}
          </TopDirectionsItems>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default observer(TopDirections);
