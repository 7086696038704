import styled, { css } from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FontSizes } from '../typography/typography.styles';

interface LinkProps {
  $bold?: boolean;
  $size?: 'small' | 'medium';
  $hasUnderlineOnHover?: boolean;
}

const linkStyles = css<LinkProps>`
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
  font: inherit;
  padding: 0;
  font-size: ${({ $size = 'medium' }) =>
    $size === 'medium' ? FontSizes.normal : FontSizes.small};
  font-weight: ${({ $bold = false }) => ($bold ? 500 : 400)};
  line-height: 2rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.link.color};
  text-decoration: none;
  border-bottom: 1px solid
    ${({ $hasUnderlineOnHover = false, theme }) =>
      $hasUnderlineOnHover ? 'transparent' : theme.colors.link.color};
  transition: border-bottom-color 0.2s;

  &:hover,
  &:active {
    border-bottom-color: ${({ $hasUnderlineOnHover = false, theme }) =>
      $hasUnderlineOnHover ? theme.colors.link.color : 'transparent'};
  }
`;

export const Link = styled(ReactRouterLink)<LinkProps>`
  ${linkStyles}
`;

export const ExternalLink = styled.a<LinkProps>`
  ${linkStyles}
`;

const LinkFooter = styled.a`
  &:link,
  &:visited {
    font-size: ${FontSizes.normal};
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.textCopyright.color};
    text-decoration: none;
    /* border-bottom: 1px solid transparent;
    transition: all .2s; */
  }

  &:hover,
  &:active {
    /* border-bottom: 1px solid ${({ theme }) =>
      theme.colors.textCopyright.color}; */
  }
`;
