import styled, { DefaultTheme } from 'styled-components';
import Modal from 'styled-react-modal';
import { TextBody } from '../typography/typography.styles';
import { CloseButtonContainer } from '../close-button/close-button.styles';

export const BASE_MODAL_Z_INDEX = 11000;

interface CustomModalProps {
  $widthModal: string;
  $paddingModal?: string;
  theme: DefaultTheme;
}

export const ModalDialogContainer = Modal.styled`
  position: relative;
  padding: 3.2rem 4rem;
  border-radius: 1.6rem;
  text-align: center;
  z-index: ${BASE_MODAL_Z_INDEX};
  font-family: Montserrat, 'Open Sans', sans-serif;
  width: ${(props: CustomModalProps) => props.$widthModal};
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.colors.namedColors.white};
  border: 1px solid  ${({ theme }: { theme: DefaultTheme }) => theme.colors.baseColors.grey50}; 
  margin-left: 16px;
  margin-right: 16px;

  @media (max-width: 650px) {
    padding: ${(props: CustomModalProps) => (props.$paddingModal ? props.$paddingModal : '3.2rem 1.6rem')};

  }

  ${CloseButtonContainer} {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
  }
`;

export const ModalTextBody = styled(TextBody)`
  margin: 1.6rem 0 2.4rem 0;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  letter-spacing: 0.5px;
  text-align: center;
`;
