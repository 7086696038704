import { useEffect, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RemoveTrailingSlash = memo(
  ({ children }: { children: React.ReactNode }) => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (pathname.length > 1 && pathname.endsWith('/')) {
        navigate(pathname.slice(0, -1) + search, { replace: true });
      }
    }, [pathname, search, navigate]);

    return children;
  }
);

export default RemoveTrailingSlash;
