import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { HeaderSecondary } from '../typography/typography.styles';
import {
  SidebarBody,
  SidebarContainer,
  SidebarHeader,
  SidebarInner,
} from './sidebar.styles';
import { useStore } from '../../stores/store';
import { BlurredBackground } from '../blurred-background/blurred-background.styles';
import CloseButton from '../close-button/close-button.component';
import { LogoImgWrapper } from '../../features/header/header.styles';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { SidebarName } from '../../types/sidebar.types';
import {
  lockBodyScroll,
  unlockBodyScroll,
} from '../../utils/generic/generic.utils';

interface Props {
  children: React.ReactNode;
  name: SidebarName;
  header?: string | JSX.Element;
  sidebarWidth?: string;
  onClose?: () => void;
  withBlurredBackground?: boolean;
  contentWithVerticalScroll?: boolean;
  withoutHeader?: boolean;
  isHeaderLogo?: boolean;
  backgroundColor?: string;
}

export const SIDEBAR_ZINDEX_BASE = 10000;

const Sidebar = ({
  children,
  name,
  header,
  withBlurredBackground,
  contentWithVerticalScroll,
  sidebarWidth = '48rem',
  withoutHeader,
  onClose,
  isHeaderLogo,
  backgroundColor,
}: Props) => {
  const {
    navStore: {
      openedRightSidebars,
      isSidebarOpenedByName,
      isSidebarClosingByName,
      closeSidebarByName,
    },
  } = useStore();

  const isMobile = useIsMobile();
  useEffect(() => {
    if (isSidebarOpenedByName(name)) {
      lockBodyScroll();
    }

    return () => {
      if (openedRightSidebars.length === 1) {
        unlockBodyScroll();
      }
    };
  }, [isSidebarOpenedByName, name, openedRightSidebars.length]);

  if (!isSidebarOpenedByName(name) && !isSidebarClosingByName(name)) {
    return null;
  }

  const justifyContent = isMobile && isHeaderLogo ? 'center' : 'space-between';
  const padding = isMobile && isHeaderLogo ? '0.8rem 1.6rem' : '2rem 0';
  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    closeSidebarByName(name);
    onClose && onClose();
  };

  const calculateZindex = () => {
    const sidebarIndex = openedRightSidebars.indexOf(name);
    return SIDEBAR_ZINDEX_BASE * (sidebarIndex > 0 ? sidebarIndex : 0 + 1);
  };

  const ContentWrapper = contentWithVerticalScroll
    ? React.Fragment
    : SidebarInner;

  return (
    <>
      <SidebarContainer
        $backgroundColor={backgroundColor}
        $isOpened={isSidebarOpenedByName(name)}
        $withoutHeader={withoutHeader}
        $width={sidebarWidth}
        $zIndex={calculateZindex()}>
        {!withoutHeader && (
          <SidebarHeader
            $justifyContent={justifyContent}
            $padding={padding}>
            {header && !isHeaderLogo ? (
              <>
                <HeaderSecondary>{header}</HeaderSecondary>
                <CloseButton
                  size='big'
                  onClick={handleClose}
                />
              </>
            ) : (
              <LogoImgWrapper>{header}</LogoImgWrapper>
            )}
          </SidebarHeader>
        )}

        <SidebarBody
          $contentWithVerticalScroll={contentWithVerticalScroll}
          $padding={isMobile ? '2.4rem 1.6rem' : '2.4rem'}>
          <ContentWrapper>{children}</ContentWrapper>
        </SidebarBody>
      </SidebarContainer>
      {withBlurredBackground && (
        <BlurredBackground onClick={isMobile ? undefined : handleClose} />
      )}
    </>
  );
};

export default observer(Sidebar);
