import styled, { css } from 'styled-components';
import {
  FontSizes,
  TextLabel,
} from '../../../common/typography/typography.styles';

interface Props {
  $isActive: boolean;
  $isCompleted: boolean;
}

export const ParcelStepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5;
  color: ${({ theme }) => theme.colors.baseColors.grey65};

  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.baseColors.grey65};

  svg {
    width: 70%;
    height: 70%;
  }
`;

export const ParcelStepSeparator = styled.div<{ $tooltip: any; $step: number }>`
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 12px;
  height: ${({ $tooltip, $step }) =>
    $tooltip && $step === 2 ? '44px' : '12px'};
  width: 1px;
  background-color: ${({ theme }) => theme.colors.baseColors.grey65};
`;

export const ParcelStepTitleContainer = styled.div<Props>`
  ${({ $isActive }) =>
    $isActive
      ? css`
          ${ParcelStepNumber} {
            border-color: ${({ theme }) => theme.colors.namedColors.main};
            color: ${({ theme }) => theme.colors.namedColors.main};
          }

          ${TextLabel} {
            color: ${({ theme }) => theme.colors.namedColors.main};
          }
        `
      : css`
          ${TextLabel} {
            color: ${({ theme }) => theme.colors.baseColors.grey65};
          }
        `}
  ${({ $isCompleted }) =>
    $isCompleted &&
    css`
      ${TextLabel} {
        color: ${({ theme }) => theme.colors.baseColors.grey150};
      }
      ${ParcelStepSeparator} {
        background-color: ${({ theme }) => theme.colors.namedColors.primary};
      }
      ${ParcelStepNumber} {
        background-color: ${({ theme }) => theme.colors.namedColors.primary};
        border-color: ${({ theme }) => theme.colors.namedColors.primary};
        color: ${({ theme }) => theme.colors.namedColors.primary};
      }
    `}
`;

export const ParcelStepTitleBody = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
  justify-content: start;
  height: 2.5rem;
`;

export const ParcelStepTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;

  ${TextLabel} {
    padding-bottom: 1.2rem;
    color: ${({ theme }) => theme.colors.baseColors.grey150};
    font-weight: 400;
  }
`;
