import { currencies, Currency } from '../../constants/currency';

export const getFormattedPrice = (
  totalPrice: number | undefined,
  currencySymbol: string
): string => {
  const currency = currencies.find(
    (curr: Currency) => curr.symbol === currencySymbol
  );
  if (currency && currency.prefixPlace) {
    return `${currencySymbol} ${totalPrice ? totalPrice?.toFixed(2) : 0}`;
  }
  return `${totalPrice?.toFixed(2)} ${currencySymbol}`;
};
