import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../stores/store';
import Button from '../../../common/button/button.component';
import { printBlob } from '../../../utils/generic/generic.utils';

const PrintLabelButton = () => {
  const {
    parcelListStore: { shipment, isPrintingLabel, printLabel },
    commonStore: { toastError },
  } = useStore();
  const { t } = useTranslation();

  const handlePrintLabel = async (shipmentId: number) => {
    try {
      const blob = await printLabel(shipmentId);
      await printBlob(
        blob,
        shipment!.file_names?.label ||
          `${shipment!.tracking_numbers.first_mile[0]}.pdf`
      );
    } catch (error: any) {
      toastError(t(error.message));
    }
  };
  return (
    <Button
      isLoading={isPrintingLabel}
      onClick={() => handlePrintLabel(shipment!.id)}>
      {t('print_label')}
    </Button>
  );
};

export default observer(PrintLabelButton);
