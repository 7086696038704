import { observer } from 'mobx-react-lite';

import { useStore } from '../../../stores/store';
import WelcomeScreenLayout, {
  WelcomeScreenCheckboxes,
} from '../welcome-screen-layout/welcome-screen-layout.component';

const WelcomeSocialAccount = () => {
  const {
    userStore: { emailSignInProgress, submitWithTermsAndConditions },
    navStore: { setAuthTabIndex, setAuthCurrentScreen },
  } = useStore();

  const handleSubmit = ({
    termsAndConditions,
    newsSubscription,
  }: WelcomeScreenCheckboxes) => {
    submitWithTermsAndConditions({
      termsAndConditions,
      newsletters: newsSubscription,
    });
  };

  const handleBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setAuthCurrentScreen('auth-tabs');
    setAuthTabIndex(0);
  };

  return (
    <WelcomeScreenLayout
      isLoading={emailSignInProgress}
      text='old_account_notification'
      onSubmit={handleSubmit}
      onBackClick={handleBack}
    />
  );
};

export default observer(WelcomeSocialAccount);
