import styled from 'styled-components';
import { TextInputPlaceholder } from '../../../common/typography/typography.styles';

export const InputPlaceholderGrayDark = styled.span`
  ${TextInputPlaceholder};
  color: ${({ theme }) => theme.colors.baseColors.grey150};
`;

export const SidebarContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const HeaderContent = styled.h3`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  margin-bottom: 1.2rem;
`;

export const DateContainer = styled.div``;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const TimeContainer = styled.div``;
