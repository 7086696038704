import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import { Content, CustomHeader, MarginContainer } from './insurance-fee.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import InsuranceTooltip from '../../../common/insurance-tooltip/insurance-tooltip.component';
import { InsuranceLimits } from '../../../models/insuranceLimits';
import { transformToDecimal } from '../../../utils/forms/forms.utils';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import {
  GridContainer,
  PriceValue,
  PriceTitle,
} from '../../../routes/parcel-content/parcel-content.styles';
import CustomSkeleton from '../../../common/skeletons/custom-skeleton-component';
import { meest } from '../../../styles/meest';
import { client } from '../../../config';
import { isMeest } from '../../../utils/client/client.utils';

interface FormValues {
  insuranceAmount: number;
}

const calculateInsuranceFee = (amount: number, limits?: InsuranceLimits) => {
  if (!limits) return 0;

  if (Number(amount) === limits?.min_insurance) {
    return 0;
  }
  if (amount > limits?.max_insurance) {
    return parseFloat((limits.max_insurance * 0.05).toFixed(2));
  }
  return parseFloat((amount * 0.05).toFixed(2));
};

interface Props {
  insuranceLimits: InsuranceLimits | undefined;
}

const InsuranceFee = ({ insuranceLimits }: Props) => {
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: {
      setInsuranceFee,
      modifyShipmentInsurance,
      insurance,
      insuranceFee,
      shipment,
      isLoadingShipmentApi,
    },
    localizationsStore: { isLanguageChanging },
  } = useStore();

  const [isEditing, setIsEditing] = useState(false);
  const [insuranceAmount, setInsuranceAmount] = useState(
    insurance || insuranceLimits?.min_insurance || 0
  );
  const [insuranceFeeAmount, setInsuranceFeeAmount] = useState(
    insuranceFee || calculateInsuranceFee(insuranceAmount, insuranceLimits!)
  );

  const { t } = useTranslation();

  const shipmentCurrencyCode = shipment?.converted_price?.currency;

  useEffect(() => {
    if (insuranceLimits && (!!insurance || insurance === 0)) {
      setInsuranceFee(calculateInsuranceFee(insurance, insuranceLimits));
      setInsuranceAmount(
        insurance === 0 ? insuranceLimits.min_insurance : insurance
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceLimits, insurance, setInsuranceFee]);

  const titleEl = (
    <CustomHeader>
      {t('insurance')}
      <InsuranceTooltip />
    </CustomHeader>
  );

  const handleInputChange = (val: string) => {
    const inputValue = parseFloat(val);
    setInsuranceFeeAmount(calculateInsuranceFee(inputValue, insuranceLimits));
    setInsuranceAmount(inputValue);
  };

  const handleFormSubmit = (formValues: FormValues) => {
    const val =
      typeof formValues.insuranceAmount === 'string'
        ? parseFloat(formValues.insuranceAmount)
        : formValues.insuranceAmount;
    setInsuranceFee(calculateInsuranceFee(val, insuranceLimits));
    modifyShipmentInsurance(insuranceAmount);
    setIsEditing(false);
  };

  const insuranceFeeElement = (
    <GridContainer>
      <PriceTitle>{t('insurance_fee')}</PriceTitle>
      {!insuranceLimits ? (
        <CustomSkeleton
          width='12rem'
          height='1.8rem'
        />
      ) : (
        <PriceValue
          $free={
            insuranceAmount <= insuranceLimits.min_insurance || !insuranceAmount
          }>
          {insuranceFeeAmount > 0 &&
          insuranceAmount > insuranceLimits.min_insurance
            ? `${getFormattedPrice(insuranceFeeAmount, getCurrencySymbol(shipmentCurrencyCode))}`
            : t('free')}
        </PriceValue>
      )}
    </GridContainer>
  );

  const validationSchema = Yup.object({
    insuranceAmount: Yup.number()
      .max(
        insuranceLimits?.max_insurance ?? 0,
        t('max_insurance_amount', {
          maxInsuranceAmount: getFormattedPrice(
            insuranceLimits?.max_insurance,
            getCurrencySymbol(shipmentCurrencyCode)
          ),
        })
      )
      .min(
        insuranceLimits?.min_insurance ?? 0,
        t('min_insurance_amount', {
          minInsuranceAmount: getFormattedPrice(
            insuranceLimits?.min_insurance,
            getCurrencySymbol(shipmentCurrencyCode)
          ),
        })
      )
      .required(
        t('min_insurance_amount', {
          minInsuranceAmount: getFormattedPrice(
            insuranceLimits?.min_insurance,
            getCurrencySymbol(shipmentCurrencyCode)
          ),
        })
      ),
  });

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader $isEditable>
        {titleEl}
        {!isEditing && (
          <Button
            size='large'
            theme={meest}
            contentStyle={isMeest(client) ? 'thin' : 'normal'}
            fullWidth={false}
            appearance='text'
            onClick={() => setIsEditing(true)}
            disabled={isLoadingShipmentApi || isLanguageChanging}>
            {t('edit')}
          </Button>
        )}
      </ServicesSubSectionHeader>

      <Content>
        {isEditing ? (
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              insuranceAmount:
                insurance === 0 && insuranceLimits
                  ? insuranceLimits?.min_insurance
                  : insurance,
            }}
            onSubmit={(values) => handleFormSubmit(values)}>
            {({ isValid, handleSubmit, setFieldValue, resetForm }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                <MarginContainer
                  $mbTablet='1.6rem'
                  $mb='2rem'>
                  <FormFieldText
                    validationMethod='always'
                    label='insurance_amount'
                    name='insuranceAmount'
                    infoText={getCurrencySymbol(shipmentCurrencyCode)}
                    inputMode='decimal'
                    onChange={(event) => {
                      const val = transformToDecimal(event.target.value);
                      setFieldValue(event.target.name, val);
                      handleInputChange(val);
                    }}
                  />
                </MarginContainer>
                {insuranceFeeElement}
                <MarginContainer $mt='2.4rem'>
                  <FlexContainer $justify='end'>
                    <Button
                      appearance='secondary'
                      onClick={() => {
                        resetForm();
                        const val =
                          insurance === 0 && insuranceLimits
                            ? insuranceLimits?.min_insurance
                            : insurance;

                        setInsuranceAmount(val);
                        setInsuranceFeeAmount(
                          calculateInsuranceFee(val, insuranceLimits)
                        );

                        setIsEditing(false);
                      }}>
                      {t('cancel')}
                    </Button>
                    <Button
                      type='submit'
                      disabled={!isValid}>
                      {t('save_changes')}
                    </Button>
                  </FlexContainer>
                </MarginContainer>
              </form>
            )}
          </Formik>
        ) : (
          <GridContainer>
            <PriceTitle>{t('insurance_amount')}</PriceTitle>
            {!insuranceLimits ? (
              <CustomSkeleton
                width='8rem'
                height='1.8rem'
              />
            ) : (
              <PriceValue>{`${getFormattedPrice(insuranceAmount, getCurrencySymbol(shipmentCurrencyCode))}`}</PriceValue>
            )}
          </GridContainer>
        )}
        {!isEditing && insuranceFeeElement}
      </Content>
    </ServicesSubSectionContainer>
  );
};

export default observer(InsuranceFee);
