export const DIGITS_REGEX = /^\d+$/;
export const DIGITS_WITH_DOT = /^\d+(\.\d+)?$/;
export const LETTER_REGEX = /^[a-zA-Z]+$/;
export const LETTER_REGEX_WITH_SPACE = /^[a-zA-Z\s]+$/;
export const LATIN_LETTER_REGEX = /^[A-Za-zÀ-ÿ ,.\\'‘’-]*$/;
export const LATIN_LETTER_WITH_NUMBERS_REGEX = /^[A-Za-zÀ-ÿ0-9 .\\'‘’-]*$/;
export const LATIN_LETTER_WITH_COMMA_REGEX = /^[A-Za-zÀ-ÿ0-9 ,.\\'‘’-]*$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const ONLY_CYRILLIC = /^[а-яА-ЯёЁєЄґҐіІїЇ']+$/;
export const IS_NOT_CYRILLIC = /^[^а-яА-ЯёЁєЄґҐіІїЇ']*$/;
export const ONLY_LETTERS_REGEX = /^[^\W\d_]+([-][^\W\d_]+)*$/;
export const ALL_LANG_LETTER_REGEX =
  /^[^a-zA-Z\u00C0-\u024F\u0370-\u03FF\u0400-\u04FF\u0500-\u052F\u1E00-\u1EFF]*$/;
export const NO_RULE_REGEX = /^.*$/;

export const VALIDATION_RULE_LATIN = 'is-latin';
export const VALIDATION_RULE_LATIN_WITH_NUMBERS = 'is-latin-with-numbers';
export const VALIDATION_RULE_LATIN_WITH_COMMA = 'is-latin-with-coma';
export const VALIDATION_RULE_IS_NOT_CYRILLIC = 'is-not-cyrillic';
export const VALIDATION_RULE_ONLY_LETTERS = 'only-letters';
export const VALIDATION_RULE_ONLY_DIGITS = 'only-digits';
export const VALIDATION_RULE_DIGITS_WITH_DOT = 'digits-with-dot';
export const NO_VALIDATION_RULE = 'no-rule';
