import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import InputSearchWithSidebar from '../input-search-with-sidebar/input-search-with-sidebar.component';
import { CountriesResponse } from '../../models/countriesModel';
import { useStore } from '../../stores/store';
import { SidebarName } from '../../types/sidebar.types';

interface Props {
  name: SidebarName;
  placeholder?: string;
  label?: string;
  tabIndex?: number;
  initialCountry?: CountriesResponse;
  formikValidateField: (
    field: string
  ) => Promise<void> | Promise<string | undefined>;
  onSearchFinished?: () => void;
  onCountrySelected?: (countryCode: string) => void;
}

const CountriesFormFieldSearch = ({
  name,
  placeholder = '',
  label,
  tabIndex = 0,
  initialCountry,
  onSearchFinished,
  onCountrySelected,
  formikValidateField,
}: Props) => {
  const [, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const {
    localizationsStore: { availableCountries },
    userStore,
  } = useStore();
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);

  const { t } = useTranslation();

  useEffect(() => {
    setFieldValue(name, selectedCountry?.country_code, true);
    if (selectedCountry && onCountrySelected) {
      onCountrySelected(selectedCountry.country_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, selectedCountry, setFieldValue]);

  return (
    <InputSearchWithSidebar<CountriesResponse>
      name={name}
      placeholder={placeholder}
      label={label}
      sidebarTitle={t('country')}
      sidebarInputPlaceholder={t('start_typing_country')}
      tabIndex={tabIndex}
      displayAllOptionsWithEmptyFilter
      onSearchFinished={() => onSearchFinished && onSearchFinished()}
      getKeyForSearchOption={(country) => country.country_code}
      getDisplayValueForSearchOption={(country) =>
        `${country.flag_emoji_2} ${t(country.countryDetails.localized_name)}`
      }
      errorMessage={meta.error}
      onMainInputClick={() => formikValidateField(name)}
      inputValue={`${
        selectedCountry?.flag_emoji_2 || ''
      } ${t(selectedCountry?.countryDetails.localized_name || '')}`}
      isOptionSelected={(country) =>
        country?.country_code === selectedCountry?.country_code
      }
      onSearchOptionSelected={(country) => {
        setSelectedCountry(country);
      }}
      getSearchOptions={(filter) => {
        const userCountry = userStore.user?.country;
        const countries = filter
          ? availableCountries!.filter((i) =>
              i.countryDetails.localized_name
                .toLowerCase()
                .includes(filter.toLocaleLowerCase().trim())
            )
          : availableCountries;
        if (!userCountry) return Promise.resolve(countries);

        const found = countries!.find(
          (country) => userCountry === country.country_code
        );
        if (found) {
          const filteredArray = countries!.filter(
            (country) => country.country_code !== userCountry
          );
          return Promise.resolve([found, ...filteredArray]);
        }
        return Promise.resolve(countries);
      }}
    />
  );
};

export default observer(CountriesFormFieldSearch);
