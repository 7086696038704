import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import {
  LeftSidebarContainer,
  CloseButtonWrapper,
  LeftSidebarBody,
} from './left-sidebar.styles';
import { BlurredBackground } from '../blurred-background/blurred-background.styles';
import CloseButton from '../close-button/close-button.component';

interface Props {
  children: any;
  withBlurredBackground?: boolean;
  onClose?: () => void;
}

const LeftSidebar = ({ children, withBlurredBackground, onClose }: Props) => {
  const {
    navStore: { isLeftSidebarOpened, isLeftSidebarClosing, toggleLeftSidebar },
  } = useStore();

  if (!isLeftSidebarOpened && !isLeftSidebarClosing) return null;

  return (
    <>
      <LeftSidebarContainer $isOpened={isLeftSidebarOpened}>
        <LeftSidebarBody>
          <CloseButtonWrapper>
            <CloseButton
              onClick={() => {
                toggleLeftSidebar();
                onClose && onClose();
              }}
            />
          </CloseButtonWrapper>
          {children}
        </LeftSidebarBody>
      </LeftSidebarContainer>
      {withBlurredBackground && <BlurredBackground />}
    </>
  );
};

export default observer(LeftSidebar);
