import styled from 'styled-components';

export const CellContainer = styled.div<{ $width: string; $justify?: string }>`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  justify-content: ${({ $justify = 'start' }) => $justify};
  width: ${({ $width }) => $width};
`;

export const LastCell = styled(CellContainer)`
  display: flex;
  gap: 20px;
  transition: background-color 0.2s;
  > *:last-child {
    margin-left: auto;
  }
`;

export const ListRow = styled.div`
  --background-color-hover: #f5f6f7;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  padding-left: 8px;
  min-width: fit-content;

  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-top: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  transition: background-color 0.2s;

  &:focus-visible {
    background-color: var(--background-color-hover);
    outline: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--background-color-hover);
      ${LastCell} {
        position: sticky;
        right: 0;
        top: 0;
        background-color: var(--background-color-hover);
      }
    }
  }
`;
