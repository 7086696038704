import { Container, LogoImgWrapper, LogoLink } from './header.styles';
import { ReactComponent as MeestLogo } from '../../assets/meest-logo.svg';
import { VisuallyHidden } from '../../common/visually-hidden/visually-hidden.styles';
import { generateLocaleRoute } from '../../utils/uri/uri.utils';

interface Props {
  onClick?: () => void;
}

const HeaderSimple = ({ onClick }: Props) => (
  <Container>
    <LogoImgWrapper>
      <LogoLink
        to={generateLocaleRoute()}
        onClick={onClick}>
        <VisuallyHidden>Meest logo</VisuallyHidden>
        <MeestLogo />
      </LogoLink>
    </LogoImgWrapper>
  </Container>
);

export default HeaderSimple;
