import { useEffect, useRef } from 'react';

export const useInputsRef = ({ inputsToShow }: { inputsToShow: any }) => {
  const inputWeightRef = useRef<HTMLInputElement | null>(null);
  const inputLengthRef = useRef<HTMLInputElement | null>(null);
  const inputWidthRef = useRef<HTMLInputElement | null>(null);
  const inputHeightRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputWeightRef?.current?.focus();
  }, [inputsToShow.weight]);
  useEffect(() => {
    inputLengthRef?.current?.focus();
  }, [inputsToShow.size_z]);
  useEffect(() => {
    inputWidthRef?.current?.focus();
  }, [inputsToShow.size_x]);
  useEffect(() => {
    inputHeightRef?.current?.focus();
  }, [inputsToShow.size_y]);

  return {
    inputWeightRef,
    inputLengthRef,
    inputWidthRef,
    inputHeightRef,
  };
};
