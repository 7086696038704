import styled from 'styled-components';
import { Link, TextBody } from '../../../common/typography/typography.styles';
import { AuthContainer } from '../../../routes/authorization/authorization.styles';

export const Container = styled.div`
  --sections-gap: 24px;

  display: grid;
  gap: var(--sections-gap);

  @media screen and (min-width: 650px) {
    --sections-gap: 32px;
  }
`;

interface ButtonsProps {
  $gap?: string;
}
export const Buttons = styled.div<ButtonsProps>`
  display: grid;
  gap: ${({ $gap = '12px' }) => $gap};

  @media screen and (min-width: 650px) {
    gap: 16px;
  }
`;

export const Recaptcha = styled.div`
  display: flex;
  justify-content: center;
`;

export const EmailConfirmationScreen = styled(AuthContainer)`
  min-height: auto;
  margin: auto;
`;

export const EmailConfirmationLink = styled(Link)`
  width: 100%;
  margin-top: 2.4rem;
  & > * {
    width: 100%;
  }
  &:hover {
    border-bottom: none;
  }
`;

export const EmailConfirmationText = styled(TextBody)`
  margin-top: 1.6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;
