import styled from 'styled-components';
import { RadioProps } from './radio-group.component';
import { TextLabel } from '../typography/typography.styles';

export const Container = styled.div``;

export const Label = styled(TextLabel)`
  font-weight: 400;
  margin-bottom: 6px;
`;

export const Group = styled.div<RadioProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: center;
  gap: 24px;
`;
