import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/store';
import FormFieldText from '../../common/form-field-text/form-field-text.component';
import Button from '../../common/button/button.component';
import { EditFormProps } from '../../features/services/services-subsection/services-subsection.component';
import { PersonalDataValues } from '../../models/personalDataValues';
import { isInitialValuesEqualCurrentValues } from '../../utils/forms/forms.utils';
import { userDataSchema } from '../../utils/forms/schemas.utils';
import { AccountSettingsForm } from '../../common/form/form.styles';

const PersonalDataEditForm = ({ onCancel }: EditFormProps) => {
  const {
    userStore: { user, updatePersonalData },
    commonStore: { toastSuccess },
  } = useStore();

  const { t } = useTranslation();

  const handleFormSubmit = async (
    formValues: PersonalDataValues,
    formikHelpers: FormikHelpers<PersonalDataValues>
  ) => {
    const success = await updatePersonalData(formValues);
    success && toastSuccess(t('data_updated_successfully'));
    onCancel && onCancel();
    formikHelpers.resetForm();
  };

  const initialValues: PersonalDataValues = {
    first_name: user?.first_name ?? '',
    middle_name: user?.middle_name ?? '',
    last_name: user?.last_name ?? '',
  };

  const personalDataValidationSchema = Yup.object(userDataSchema);

  return (
    <Formik
      validationSchema={personalDataValidationSchema}
      initialValues={initialValues}
      onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
      {({ handleSubmit, isValid, isSubmitting, values }) => (
        <AccountSettingsForm
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <div className='inputs'>
            <FormFieldText
              maxLength={50}
              name='first_name'
              placeholder=''
              label={`${t('first_name')}`}
            />

            <FormFieldText
              maxLength={50}
              name='middle_name'
              placeholder=''
              label={t('middle_name')}
              isOptional
            />

            <FormFieldText
              maxLength={50}
              name='last_name'
              placeholder=''
              label={t('last_name')}
            />
          </div>

          <div className='buttons'>
            <Button
              appearance='secondary'
              disabled={isSubmitting}
              onClick={() => onCancel && onCancel()}>
              {t('cancel')}
            </Button>
            <Button
              isLoading={isSubmitting}
              disabled={
                !isValid ||
                isInitialValuesEqualCurrentValues(initialValues, values)
              }
              type='submit'>
              {t('save_changes')}
            </Button>
          </div>
        </AccountSettingsForm>
      )}
    </Formik>
  );
};

export default observer(PersonalDataEditForm);
