import { translate } from '../../../../i18n';
import {
  Measure,
  convertCmToInches,
  convertKgToLb,
  getMeasureUnits,
} from '../../../utils/parcel-measurements.ts/parcel-measurements-utils';

const MAX_WEIGHT_KG = 30;
const MAX_WEIGHT_LB = 66;
const INITIAL_WEIGHT_KG = 1;
const INITIAL_WIDTH_CM = 20;
const INITIAL_HEIGHT_CM = 10;
const INITIAL_LENGTH_CM = 30;

export const getWeightConfig = (measure: Measure = Measure.KG_CM) => {
  const suffix = getMeasureUnits(measure).weight;
  return {
    suffix,
    translation: translate(suffix),
    maxWeight: measure === Measure.LB_IN ? MAX_WEIGHT_LB : MAX_WEIGHT_KG,
  };
};

export const getLengthConfig = (measure: Measure = Measure.KG_CM) => {
  const suffix = getMeasureUnits(measure).length;

  return {
    suffix,
    translation: translate(suffix),
  };
};

export const getInitialDimensions = (measure: Measure = Measure.KG_CM) => {
  const isLbMeasure = measure === Measure.LB_IN;
  return {
    weight: isLbMeasure ? convertKgToLb(INITIAL_WEIGHT_KG) : INITIAL_WEIGHT_KG,
    length: isLbMeasure
      ? convertCmToInches(INITIAL_LENGTH_CM)
      : INITIAL_LENGTH_CM,
    width: isLbMeasure ? convertCmToInches(INITIAL_WIDTH_CM) : INITIAL_WIDTH_CM,
    height: isLbMeasure
      ? convertCmToInches(INITIAL_HEIGHT_CM)
      : INITIAL_HEIGHT_CM,
  };
};

export const getFormulaDivider = (measure: Measure) =>
  ({
    [Measure.LB_IN]: 139,
    [Measure.KG_CM]: 5000,
  })[measure];

interface ICalculateVolumetricWeight {
  length: number;
  width: number;
  height: number;
  measure: Measure;
}
export const calculateVolumetricWeight = ({
  length,
  width,
  height,
  measure,
}: ICalculateVolumetricWeight) => {
  const formulaDivider = getFormulaDivider(measure);

  return (length * width * height) / formulaDivider;
};
