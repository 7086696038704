import { Fill, ProgressBarSize, Wrapper } from './progress-bar.styles';

interface Props {
  width?: number;
  isAnimated?: boolean;
  size?: ProgressBarSize;
}

const ProgressBar = ({ width, isAnimated = false, size = 'small' }: Props) => (
  <Wrapper $size={size}>
    <Fill
      $width={width}
      $isAnimated={isAnimated}
    />
  </Wrapper>
);

export default ProgressBar;
