import styled, { css } from 'styled-components';

export const Container = styled.div`
  .react-tabs__tab-list.react-tabs__tab-list {
    margin-bottom: 2.4rem;
  }
  .react-tabs__tab {
    padding: 0.6rem !important;
  }
`;

const CardInteractionStyle = css`
  background-color: var(--hover-color);
  border-color: var(--hover-color);

  &::before {
    opacity: 1;
  }
`;

export const Card = styled.div`
  --hover-color: #f5f6f7;
  --border-radius: 1.2rem;
  --animation-duration: 0.3s;

  position: relative;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #eaecee;
  border-radius: var(--border-radius);
  transition-property: background-color, border-color;
  transition-duration: var(--animation-duration);
  margin: 0 1.2rem 1.2rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 1px 4px 0px #05236b1f;
    opacity: 0;
    transition: opacity var(--animation-duration);
    pointer-events: none;
    border-radius: var(--border-radius);
  }

  &:focus-visible {
    ${CardInteractionStyle};
    outline: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${CardInteractionStyle};
    }
  }
`;
