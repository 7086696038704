import { Navigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import { AppRoute } from '../../constants/route';
import { generateLocaleRoute } from '../../utils/uri/uri.utils';

const LocaleSupportedCheckRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    localizationsStore: { availableLanguageDashCountryPairs },
  } = useStore();
  const { locale } = useParams();

  if (!availableLanguageDashCountryPairs) return null;

  const pathAllowed =
    !!locale && availableLanguageDashCountryPairs.includes(locale);

  if (!pathAllowed) {
    // Redirect to not-found if 1st path part is not in allowed set of len-country pairs
    return (
      <Navigate
        to={generateLocaleRoute(AppRoute.NOT_FOUND)}
        replace
      />
    );
  }

  return children;
};

export default observer(LocaleSupportedCheckRoute);
