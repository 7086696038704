import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { ReactComponent as InfoIcon } from '../../../../../assets/info-tip.svg';

import { useStore } from '../../../../../stores/store';
import { HeaderSecondary } from '../../../../../common/typography/typography.styles';
import { MarginContainer } from '../../../../../common/base-margin/base-margin.component';
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '../../../../../common/tabs/tabs.component';
import DeliveryTabTitle from '../../../../parcel-creation/parcel-delivery-options-tabs/parcel-delivery-tab-title.component';
import ParcelDeliveryOption from '../parcel-delivery-option/parcel-delivery-option.component';
import { getFirstMilesByDeliveryMethodSorted } from '../../../../../utils/parcel-creation/parcel-creation-utils';
import { SelectOption } from '../parcel-delivery-option/parcel-delivery-option.styles';
import Button from '../../../../../common/button/button.component';
import { AppRoute, SearchParam } from '../../../../../constants/route';
import { PaddingContainer } from '../../widget-calculator.styles';
import {
  FooterButtons,
  Footer,
  FooterInfoBlock,
  FooterInfoText,
  OptionsContainer,
  TabListContainer,
  TabPanelFlexContainer,
  Container,
} from './estimated-delivery-cost.styles';
import {
  generateLocaleRoute,
  generateStartParcelCreationRoute,
} from '../../../../../utils/uri/uri.utils';
import { useMediaQuery } from '../../../../../hooks/use-media-query.hook';
import { useStartParcelCreation } from '../../../../../hooks/use-start-parcel-creation.hook';
import EstimatedDeliveryCostMobile from './estimated-delivery-cost-mobile.component';
import { getCheapestDeliveryOptions } from '../../helpers';

interface Props {
  onClear: () => void;
}

const EstimatedDeliveryCost = ({ onClear }: Props) => {
  const {
    widgetCalculatorStore: {
      countryDeparture,
      countryDestination,
      parcelDimensions,
      firstMiles,
      lastMiles,
      middleMileMethodsWithMaxDuration,
      selectedMiddleMileMethod,
      setMiddleMileMethod,
      setShowEstimatedDeliveryCost,
      resetStore,
    },
    parcelCreationStore: { shippingType },
    navStore: { setServicesSidebarVisibility },
    userStore: { user, measures },
  } = useStore();
  const isTablet = useMediaQuery('(max-width: 767px)');
  const { resetForm } = useFormikContext();
  const handleStartParcelCreation = useStartParcelCreation();

  const navigate = useNavigate();

  const middleMileMethod = selectedMiddleMileMethod ?? 'air';

  const lowestFirstMilePrices = getFirstMilesByDeliveryMethodSorted(
    firstMiles ?? [],
    middleMileMethod
  );

  const cheapestDeliveryOptions = getCheapestDeliveryOptions(
    lowestFirstMilePrices,
    lastMiles,
    middleMileMethod
  );

  const handleClick = () => {
    setServicesSidebarVisibility(false);
    const urlState = { state: { key: new Date().getTime() } };
    if (shippingType === 'international') {
      const data = encodeURIComponent(
        JSON.stringify({
          // Clear zip + city info because on 1st step user should provide its own data
          countryDeparture: {
            countryCode: countryDeparture?.countryCode,
          },
          countryDestination: {
            countryCode: countryDestination?.countryCode,
          },
          measures,
          parcelDimensions,
        })
      );
      const fullUrl = `${generateStartParcelCreationRoute()}?${SearchParam.DATA}=${data}`;

      if (user) {
        handleStartParcelCreation(fullUrl);
      } else {
        const redirectUrl = encodeURIComponent(fullUrl);
        navigate(
          `${generateLocaleRoute(AppRoute.AUTH)}?${SearchParam.REDIRECT}=${redirectUrl}`,
          urlState
        );
      }
    } else {
      navigate(generateLocaleRoute(AppRoute.COMING_SOON), urlState);
    }
  };

  const handleSelectMiddleMile = (index: number) => {
    setMiddleMileMethod(middleMileMethodsWithMaxDuration[index].method);
  };

  return (
    <Container>
      {isTablet && middleMileMethodsWithMaxDuration.length > 2 ? (
        <EstimatedDeliveryCostMobile handleClick={handleClick} />
      ) : (
        <Tabs
          defaultIndex={0}
          onChange={(index) => handleSelectMiddleMile(index)}
          theme='gray'>
          <TabListContainer>
            <HeaderSecondary>{t('estimated_delivery_cost')}</HeaderSecondary>
            <TabList>
              {middleMileMethodsWithMaxDuration.map((mile, i) => (
                <Tab
                  fullWidth={isTablet}
                  tabIndex={i.toString()}
                  theme={mile.method === 'expedited' ? 'red' : 'blue'}
                  key={mile.method}>
                  <DeliveryTabTitle method={mile.method} />
                </Tab>
              ))}
            </TabList>
          </TabListContainer>
          <MarginContainer
            $mt='24px'
            $mb='24px'>
            <TabPanelFlexContainer>
              {middleMileMethodsWithMaxDuration.map((middleMile) => (
                <TabPanel key={middleMile.method}>
                  <OptionsContainer
                    $isOneOption={cheapestDeliveryOptions.length === 1}>
                    {cheapestDeliveryOptions
                      .slice(0, 4)
                      .map((deliveryOption, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <SelectOption key={index}>
                          <ParcelDeliveryOption
                            deliveryOption={deliveryOption}
                          />
                        </SelectOption>
                      ))}
                    {cheapestDeliveryOptions.length !== 1 && (
                      <Button
                        size='large'
                        appearance='link'
                        contentStyle='thin'
                        onClick={handleClick}>
                        {t('see_more_options')}
                      </Button>
                    )}
                  </OptionsContainer>
                </TabPanel>
              ))}
            </TabPanelFlexContainer>
          </MarginContainer>
        </Tabs>
      )}
      <PaddingContainer>
        <Footer>
          <FooterInfoBlock>
            <InfoIcon />
            <FooterInfoText>
              <p>{t('delivery_cost_info_1')}</p>
              <p>{t('delivery_cost_info_2')}</p>
              <p>{t('delivery_cost_info_3')}</p>
            </FooterInfoText>
          </FooterInfoBlock>
          <FooterButtons>
            <Button
              fullWidth={false}
              appearance='secondary'
              size='large'
              onClick={() => {
                onClear();
                resetForm({
                  values: {
                    departure_country:
                      countryDeparture?.countryDetails.localized_name ?? '',
                    destination_country: '',
                    weight: '',
                    size_x: '',
                    size_y: '',
                    size_z: '',
                  },
                });
                resetStore(countryDeparture);
                setShowEstimatedDeliveryCost(false);
              }}>
              {t('clear')}
            </Button>
            <Button
              fullWidth={false}
              size='large'
              onClick={handleClick}>
              {t('create_parcel')}
            </Button>
          </FooterButtons>
        </Footer>
      </PaddingContainer>
    </Container>
  );
};

export default observer(EstimatedDeliveryCost);
