import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextServicesData } from '../../../common/typography/typography.styles';
import {
  ServicesSubSectionHeader,
  ServicesSubSectionContainer,
  SubSectionDataItem,
  SubsectionDataItems,
} from './services-subsection.styles';
import Button from '../../../common/button/button.component';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import { client } from '../../../config';
import { isMeest } from '../../../utils/client/client.utils';

export interface SubSectionData {
  label: string;
  value?: string | JSX.Element;
}

export interface EditFormProps {
  onCancel?: () => void;
}

export type ServicesSubsectionAppearance = 'default' | 'with-big-button';

interface Props {
  title: string;
  data: SubSectionData[];
  EditForm?: FC<EditFormProps>;
  allowEdit?: boolean;
  editText?: string;
  appearance?: ServicesSubsectionAppearance;
}

const ServicesSubsection = ({
  title,
  data,
  EditForm,
  allowEdit = true,
  editText = 'edit',
  appearance = 'default',
}: Props) => {
  const [isEditingMode, setEditingMode] = useState(false);
  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const handleEditMode = () => {
    setEditingMode(true);
  };

  return (
    <ServicesSubSectionContainer $appearance={appearance}>
      <ServicesSubSectionHeader $isEditable={allowEdit}>
        {t(title)}
        {isMobile && appearance === 'with-big-button'
          ? null
          : allowEdit &&
            !isEditingMode && (
              <Button
                fullWidth={false}
                contentStyle={isMeest(client) ? 'thin' : 'normal'}
                appearance={isMeest(client) ? 'text' : 'link'}
                size='large'
                hideLinkUnderline
                onClick={handleEditMode}>
                {t(editText)}
              </Button>
            )}
      </ServicesSubSectionHeader>
      {!isEditingMode && (
        <SubsectionDataItems>
          {data.map((x) => (
            <SubSectionDataItem key={x.label}>
              <TextServicesData $type='label'>{t(x.label)}</TextServicesData>
              <TextServicesData $type='data'>{x.value}</TextServicesData>
            </SubSectionDataItem>
          ))}
        </SubsectionDataItems>
      )}

      {!isEditingMode && appearance === 'with-big-button' && isMobile && (
        <Button
          contentStyle={isMeest(client) ? 'thin' : 'normal'}
          appearance={isMeest(client) ? 'text' : 'link'}
          onClick={handleEditMode}>
          {t(editText)}
        </Button>
      )}

      {isEditingMode && EditForm && (
        <EditForm onCancel={() => setEditingMode(false)} />
      )}
    </ServicesSubSectionContainer>
  );
};

export default ServicesSubsection;
