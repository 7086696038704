import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  HeaderMain,
  Link,
  TextBody,
} from '../../../common/typography/typography.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import Button from '../../../common/button/button.component';
import { useStore } from '../../../stores/store';
import {
  EMAIL_REGEX,
  VALIDATION_RULE_IS_NOT_CYRILLIC,
} from '../../../constants/validation';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import { AuthorizationForm } from '../auth-tabbed-container/auth-tabbed-container.styles';
import { AuthMessageScreen } from '../auth-messege-screen/auth-message-screen.styles';

interface IFormValues {
  email: string;
}

const initialValues: IFormValues = {
  email: '',
};

const PasswordRestore = () => {
  const {
    userStore: { passwordResetProgress, resetPasswordStart },
    navStore: { setAuthTabIndex, setAuthCurrentScreen },
  } = useStore();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
      .matches(EMAIL_REGEX, t('email_is_incorrect'))
      .required(t('email_is_required.')),
  });

  const handleFormSubmit = async (
    formValues: IFormValues,
    formikHelpers: FormikHelpers<IFormValues>
  ) => {
    await resetPasswordStart(formValues.email);
    formikHelpers.resetForm();
  };

  function handleBackToSignIn(e: React.MouseEvent<HTMLButtonElement>): void {
    e.preventDefault();

    setAuthCurrentScreen('auth-tabs');
    setAuthTabIndex(0);
  }

  return (
    <AuthMessageScreen $height='min'>
      <HeaderMain>{t('restore_your_password')}</HeaderMain>
      <TextBody>{t('enter_your_email_to_reset_your_password')}</TextBody>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
        {({ handleSubmit, isValid, dirty }) => (
          <AuthorizationForm
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <FormFieldText
              name='email'
              placeholder={t('enter_your_email')}
              label={t('email')}
              inputMode='email'
            />

            <Button
              isLoading={passwordResetProgress}
              disabled={!dirty || !isValid}
              type='submit'>
              {t('request_password_reset')}
            </Button>
          </AuthorizationForm>
        )}
      </Formik>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        as='button'
        $bold
        onClick={handleBackToSignIn}>
        &larr; {t('return_to_sign_in_page')}
      </Link>
    </AuthMessageScreen>
  );
};

export default observer(PasswordRestore);
