import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  LeftSide,
  LogoLink,
  Content,
  Container,
  RightSide,
  LogoImgWrapper,
  HeaderBurgerWrapper,
  HeaderBurger,
  HeaderBurgerInner,
} from './header.styles';
import { ReactComponent as MeestLogo } from '../../assets/meest-logo.svg';
import { ReactComponent as PolonezLogo } from '../../assets/polonez-logo.svg';
import LangSwitcher from './lang-switcher/lang-switcher.component';
import HeaderSignin from './header-signin-btn/header-signin.component';
import { useStore } from '../../stores/store';
import HeaderUser from './header-user/header-user.component';
import { AppRoute } from '../../constants/route';
import { VisuallyHidden } from '../../common/visually-hidden/visually-hidden.styles';
import IconButton from '../../common/icon-button/icon-button';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import { generateLocaleRoute } from '../../utils/uri/uri.utils';
import Sidebar from '../../common/sidebar-right/sidebar.component';
import Authorization from '../../routes/authorization/authorization.component';
import { client } from '../../config';

const ROUTES_WITHOUT_BURGER_MENU = [
  AppRoute.NOT_FOUND,
  AppRoute.SERVER_ERROR,
  AppRoute.COMING_SOON,
];

const getLogo = () => {
  const logos = {
    meest: <MeestLogo />,
    polonez: <PolonezLogo />,
  };

  return logos[client] || null;
};
const getLogoDescription = () => {
  const descriptions = {
    meest: 'Meest logo',
    polonez: 'Polonez logo',
  };

  return descriptions[client] || '';
};

const Header = () => {
  const location = useLocation();
  const {
    userStore: { user },
    commonStore: { isGuestParcelCreation },
    navStore: {
      isServicesSidebarVisible,
      setAuthCurrentScreen,
      setServicesSidebarVisibility,
    },
    parcelCreationStore: { isParcelCreationInProgress },
  } = useStore();
  const { t } = useTranslation();

  const mainPagePathname = generateLocaleRoute();
  const isAuthPage = location.pathname.includes('auth');
  const isRootPage = location.pathname === AppRoute.ROOT;
  const isMainPage = location.pathname === mainPagePathname;
  const isMobile = useIsMobile();

  const isPageWithoutMenu =
    isMainPage ||
    ROUTES_WITHOUT_BURGER_MENU.some((item) => location.pathname.includes(item));

  const loginGuestButton = (
    <IconButton
      size='large'
      appearance='text'
      $pl='0.8rem'
      $pr='0.8rem'
      $pt='0.8rem'
      $pb='0.8rem'
      $ml='-0.8rem'
      icon={{ glyph: 'login' }}
      href={generateLocaleRoute(AppRoute.AUTH)}
    />
  );

  let content;
  if (isRootPage) {
    content = <LogoImgWrapper>{getLogo()}</LogoImgWrapper>;
  } else if (isAuthPage) {
    content = (
      <Content $justifyContent='center'>
        <LogoLink to={mainPagePathname}>
          <VisuallyHidden>{getLogoDescription()}</VisuallyHidden>
          {getLogo()}
        </LogoLink>
      </Content>
    );
  } else {
    content = (
      <>
        <Content>
          <LeftSide>
            {!isPageWithoutMenu && !isMobile && (
              <HeaderBurgerWrapper
                $isActive={isServicesSidebarVisible}
                onClick={() =>
                  setServicesSidebarVisibility(!isServicesSidebarVisible)
                }>
                <HeaderBurger>
                  <HeaderBurgerInner />
                </HeaderBurger>
              </HeaderBurgerWrapper>
            )}
            <LogoLink to={mainPagePathname}>
              <VisuallyHidden>Meest logo</VisuallyHidden>
              {getLogo()}
            </LogoLink>
            {/* <ServicesContainer>
              <HeaderServices />
            </ServicesContainer> */}
          </LeftSide>
          <RightSide>
            {!isParcelCreationInProgress && <LangSwitcher />}
            {!isAuthPage && user && <HeaderUser isMainPage={isMainPage} />}
            {!isAuthPage && !user && !isGuestParcelCreation && (
              <HeaderSignin isMainPage={isMainPage} />
            )}
            {!isAuthPage && isGuestParcelCreation && loginGuestButton}
          </RightSide>
        </Content>
        <Sidebar
          onClose={() => {
            setAuthCurrentScreen('auth-tabs');
          }}
          name='nav_sidebar'
          isHeaderLogo={isMobile}
          header={isMobile ? <MeestLogo /> : t('authorization')}
          withBlurredBackground
          contentWithVerticalScroll>
          <Authorization viewMode='sidebar' />
        </Sidebar>
      </>
    );
  }

  return <Container $isAuthPage={isAuthPage}>{content}</Container>;
};

export default observer(Header);
