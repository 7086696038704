import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';
import { ServicesUserInfoContainer } from '../services-user-info/services-user-info.styles';
import { LinkButton } from '../../../common/button/button.styles';

export const ServicesMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  ${LinkButton} {
    z-index: 1;
  }

  height: 100%;
  @media screen and (max-width: 649px) {
    height: calc(100% - 4.8rem);
  }
`;

export const ServicesMenuHeader = styled.div`
  ${ServicesUserInfoContainer} {
    margin-bottom: 1.5rem;
  }

  padding: 2.4rem 1.6rem;

  @media screen and (max-width: 649px) {
    padding: 0.8rem 2.4rem 2.4rem;
  }
`;

export const ServicesMenuBody = styled.div`
  position: relative;
  padding: 0 1.6rem 1.6rem;
  flex-grow: 1;

  /* Style the scrollbar */

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.baseColors.grey5};
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.baseColors.grey80};
    border-radius: 6px;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  height: 100%;

  a:last-child {
    margin-top: auto;
  }
`;

export const Section = styled.div`
  --section-horizontal-padding: 1.2rem;

  display: grid;
  gap: 4px;
`;

export const SectionTitle = styled.h3`
  padding: 0.4rem var(--section-horizontal-padding);
  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  text-transform: uppercase;
`;

export const ServicesSubItems = styled.div``;
