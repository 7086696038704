import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import {
  HeaderMain,
  Link,
  TextBody,
} from '../../../common/typography/typography.styles';
import { useStore } from '../../../stores/store';
import { AuthMessageScreen } from '../auth-messege-screen/auth-message-screen.styles';
import agent from '../../../api/agent';
import { useTimeout } from '../../../hooks/use-timeout';

const RestoreEmailSent = () => {
  const { t } = useTranslation();

  const {
    commonStore: { currentAuthData },
    navStore: { setAuthTabIndex, setAuthCurrentScreen, initialTimeout },
  } = useStore();

  const { timeoutValue, resetTimeout } = useTimeout(initialTimeout);

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();

    setAuthCurrentScreen('auth-tabs');
    setAuthTabIndex(0);
  }

  async function handleSendAgain(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    resetTimeout(initialTimeout);
    try {
      await agent.OobActions.sendResetPasswordEmail(currentAuthData!.email);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <AuthMessageScreen>
      <HeaderMain>{t('youre_almost_there')}</HeaderMain>
      <TextBody>
        <Trans
          i18nKey='email_sent_confirmation'
          components={{
            1: <b />,
          }}
          values={{
            email: currentAuthData?.email,
          }}
        />
      </TextBody>
      {timeoutValue !== 0 ? (
        <TextBody>
          {t('email_check_spam_timeout', { timeout: timeoutValue })}
        </TextBody>
      ) : (
        <TextBody>
          <Trans
            i18nKey='email_check_spam_timeout_reached'
            components={{
              1: (
                // eslint-disable-next-line
                <Link
                  as='button'
                  onClick={handleSendAgain}></Link>
              ),
            }}
          />
        </TextBody>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        as='button'
        $bold
        onClick={handleClick}>
        &larr; {t('return_to_sign_in_page')}
      </Link>
    </AuthMessageScreen>
  );
};

export default observer(RestoreEmailSent);
