import styled, { css } from 'styled-components';

interface Props {
  $numOfDigits?: number;
  $isInvalid?: boolean;
}

export const Container = styled.div`
  display: grid;
  gap: 6px;
`;

export const Input = styled.input.attrs({
  inputMode: 'numeric',
})`
  display: block;
  padding: 14px 0;
  background-color: #fff;
  border: 1px solid #dcdde0;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 0.5px;
  text-align: center;
  font-family: inherit;
  transition: border-color 0.2s;
  min-width: 0;
  outline: none;

  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.namedColors.primary};
  }
`;

export const Inputs = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ $numOfDigits = 4 }) =>
    `repeat(${$numOfDigits}, 1fr)`};
  gap: 8px;

  ${({ $isInvalid = false }) =>
    $isInvalid &&
    css`
      ${Input} {
        border-color: #ed5576;
      }
    `}
`;
