import styled from 'styled-components';
import { TertiaryButton } from '../button/button.styles';

export const ZoomControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-radius: 1rem;
  position: relative;
  &:after {
    display: block;
    content: '';
    width: calc(100% - 1.6rem);
    height: 0.1rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: ${({ theme }) => theme.colors.baseColors.grey50};
  }

  ${TertiaryButton} {
    --button-color: ${({ theme }) => theme.colors.baseColors.grey100};
  }
`;
