import { useEffect, useId, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ScrollableContent,
  DropdownSelectedOption,
  DropdownSelector,
  PhoneCodeDropdownContainer,
} from './phone-code-dropdown.styles';
import { TextLabel } from '../../typography/typography.styles';
import Sidebar from '../../sidebar-right/sidebar.component';
import { useStore } from '../../../stores/store';
import InputSearch from '../../input-search/input-search.component';
import { ReactComponent as ArrowDownIcon } from '../../../assets/arrow-down-icon.svg';
import { MenuItems } from '../../menu-item/menu-item.styles';
import MenuItem from '../../menu-item/menu-item.component';
import { SidebarName } from '../../../types/sidebar.types';
import { VALIDATION_RULE_LATIN } from '../../../constants/validation';

export interface CountryOption {
  countryName: string;
  unicodeFlag: string;
  callingCode: string;
  countryCode: string;
}

const countryOptionFormat = (countryOption?: CountryOption) => {
  if (!countryOption) return '';
  return `+${countryOption.callingCode}`;
};

interface Props {
  items: CountryOption[];
  label?: string;
  selectedCountryCode?: string;
  onSelected?: (option: CountryOption) => void;
  isDropdownDisabled?: boolean;
}

const PhoneCodeDropdown = ({
  items,
  label,
  selectedCountryCode,
  onSelected = undefined,
  isDropdownDisabled = false,
}: Props) => {
  const {
    navStore: { isSidebarOpenedByName, toggleSidebarByName },
  } = useStore();

  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<
    CountryOption | undefined
  >(undefined);
  const [filter, setFilter] = useState<string | undefined>();
  const [filteredItems, setFilteredItems] = useState<CountryOption[]>([]);

  const sidebarId: SidebarName = `phone_code_dropdown_${useId()}`;

  const onSelect = (opt: CountryOption) => {
    onSelected && onSelected(opt);
    toggleSidebarByName(sidebarId);
  };

  useEffect(() => {
    if (selectedCountryCode !== undefined) {
      setSelectedOption(
        items.find((i) => i.countryCode === selectedCountryCode)
      );
    }
  }, [selectedCountryCode, setSelectedOption, items]);

  useEffect(() => {
    if (filter) {
      setFilteredItems(
        items.filter((i) =>
          i.countryName
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase().trim())
        )
      );
    } else {
      setFilteredItems(items);
    }
  }, [setFilteredItems, filter, items]);

  const handleDropdownSelectorClick = () => {
    if (isDropdownDisabled) return;
    toggleSidebarByName(sidebarId);
  };

  return (
    <PhoneCodeDropdownContainer>
      {label && <TextLabel>{label}</TextLabel>}
      <DropdownSelector
        $padding='1rem 1.2rem'
        $isOpened={isSidebarOpenedByName(sidebarId)}
        onClick={handleDropdownSelectorClick}
        disabled={isDropdownDisabled}>
        <DropdownSelectedOption>
          <span className='flag-emoji-font'>{selectedOption?.unicodeFlag}</span>
          <span>{countryOptionFormat(selectedOption)}</span>
          <ArrowDownIcon />
        </DropdownSelectedOption>
      </DropdownSelector>
      <Sidebar
        name={sidebarId}
        withBlurredBackground
        header={t('select_country_code')}
        onClose={() => setFilter(undefined)}>
        <InputSearch
          name='search_country'
          searchIconPosition='left'
          showClearInputButton
          placeholder={t('start_typing_country_name')}
          shouldValidateSearchQuery
          onChange={(value) => setFilter(value)}
          validationRule={VALIDATION_RULE_LATIN}
        />
        {filteredItems.length > 0 && (
          <ScrollableContent $heightOfAboveContent='15rem'>
            <MenuItems>
              {filteredItems.map((i) => (
                <MenuItem
                  key={i.countryName}
                  isSelected={selectedOption?.countryName === i.countryName}
                  onClick={() => {
                    onSelect(i);
                    setFilter(undefined);
                  }}
                  suffix={`+${i.callingCode}`}>
                  {i.unicodeFlag} {i.countryName}
                </MenuItem>
              ))}
            </MenuItems>
          </ScrollableContent>
        )}
      </Sidebar>
    </PhoneCodeDropdownContainer>
  );
};

export default observer(PhoneCodeDropdown);
