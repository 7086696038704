import { useTranslation } from 'react-i18next';
import Button from '../../../common/button/button.component';
import { TogglePudoDisplayModeButtonContainer } from './parcel-pudo-search-sidebar.styles';

interface Props {
  mode: 'list' | 'map';
  onClick: () => void;
  disabled?: boolean;
}

const TogglePudoDisplayModeButton = ({ mode, disabled, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <TogglePudoDisplayModeButtonContainer id='test'>
      <Button
        disabled={disabled}
        icon={{
          glyph: mode,
          position: 'left',
        }}
        onClick={onClick}
        $pt='0.6rem'
        $pb='0.6rem'
        $pl='1.6rem'
        $pr='1.6rem'>
        {t(mode)}
      </Button>
    </TogglePudoDisplayModeButtonContainer>
  );
};

export default TogglePudoDisplayModeButton;
