import styled, { css } from 'styled-components';
import {
  TextBody as BodyText,
  FontSizes,
} from '../../common/typography/typography.styles';

interface HeaderCellProps {
  $width: string;
  $isLoading?: boolean;
  $centerPenultimate?: boolean;
}

interface ContainerProps {
  $isEmpty?: boolean;
  $isFiltersApplied?: boolean;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: ${({ $isEmpty }) => ($isEmpty ? '100%' : 'fit-content')};
  height: auto;
  max-width: 1104px;
  width: 100%;

  ${({ $isEmpty, $isFiltersApplied }) =>
    $isEmpty &&
    !$isFiltersApplied &&
    css`
      max-height: calc(100dvh - 10rem);
    `}

  @media screen and (min-width: 650px) {
    background-color: ${({ theme }) => theme.colors.namedColors.white};
    border-radius: 1.2rem;
    border: 1px solid
      ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
    padding: 24px;
    max-width: 1104px;
    max-height: calc(100dvh - 10rem);
  }
`;

export const TextBody = styled(BodyText)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  text-align: center;
`;

export const Header = styled.div`
  margin-bottom: var(--services-subsection-gap);

  @media screen and (min-width: 650px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
`;

export const ViewMode = styled.div`
  display: none;
  margin: 0;
  padding: 0;
  gap: 8px;
  align-items: center;
  @media screen and (min-width: 650px) {
    display: flex;
  }
`;

export const FiltersDesktop = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 12px;

  @media screen and (min-width: 650px) {
    margin-bottom: 32px;
    display: flex;
  }
`;

export const ViewModeDivider = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.baseColors.grey50};
`;

export const Filters = styled.div`
  margin-bottom: 12px;

  @media screen and (min-width: 650px) {
    margin-bottom: 32px;
    display: flex;
  }
`;

interface FiltersMobileProps {
  $isSticky?: boolean;
  $isNoFilters?: boolean;
}

export const FiltersMobile = styled.div<FiltersMobileProps>`
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  ${({ $isNoFilters }) =>
    $isNoFilters
      ? css`
          grid-template-columns: 1fr 40px;
        `
      : css`
          grid-template-columns: 1fr 40px 40px;
        `};
  gap: 8px;
  transition: background-color 0.3s;
  margin-bottom: 1.2rem;

  ${({ $isSticky }) =>
    $isSticky &&
    css`
      padding: 0.8rem 0;
      background-color: ${({ theme }) => theme.colors.namedColors.white};
      box-shadow: 0 0 0 64px ${({ theme }) => theme.colors.namedColors.white};
      clip-path: inset(0 -64px);
    `}
  @media screen and (min-width: 650px) {
    display: none;
  }

  button {
    height: 4rem;
  }
`;

export const MobileSortItems = styled.div``;

export const FiltersLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;
`;
export const FiltersRight = styled.div``;

export const Grid = styled.ul`
  display: grid;
  gap: 0.8rem;
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 1rem;

  @media screen and (min-width: 650px) {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    gap: 2.4rem;
    padding-bottom: 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background: ${({ theme }) => theme.colors.baseColors.grey5};
      border: 2px solid ${({ theme }) => theme.colors.namedColors.white};
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.baseColors.grey80};
      border: 2px solid ${({ theme }) => theme.colors.baseColors.grey5};
      border-radius: 6px;
    }
  }
`;
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  width: auto;
  overflow: auto;
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  border-bottom: none;
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;

  &::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    margin-block-start: 0.4rem;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.baseColors.grey5};
    border: 2px solid ${({ theme }) => theme.colors.namedColors.white};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.baseColors.grey80};
    border: 2px solid ${({ theme }) => theme.colors.baseColors.grey5};
    border-radius: 6px;
  }
`;
interface SkeletonContainerProps {
  $width?: string;
  $pb?: string;
  $display?: string;
}

export const SkeletonContainer = styled.span<SkeletonContainerProps>`
  display: ${({ $display = 'block' }) => $display};
  width: ${({ $width = 'auto' }) => $width};
  padding-bottom: ${({ $pb = '0' }) => $pb};
  overflow: hidden;
`;

export const ListSkeleton = styled(List)`
  overflow: hidden;
  border-bottom: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  border-radius: 1.2rem;
`;

export const ParcelListRowHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
  gap: 12px;
  padding-left: 8px;
  min-width: fit-content;
  min-height: 5rem;
  position: sticky;
  top: 0;
  z-index: 1;

  background-color: ${({ theme }) => theme.colors.baseColors.grey5};
`;

export const HeaderCell = styled.div<HeaderCellProps>`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 16px 8px;
  font-size: ${FontSizes.small};
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  width: ${({ $width }) => $width};

  &:last-child {
    justify-content: center;
  }

  &:first-child {
    padding-left: 28px;
  }

  ${({ $centerPenultimate }) =>
    $centerPenultimate &&
    `
      &:nth-last-child(2) {
        justify-content: center;
      }
    `}
`;

export const ListViewContainer = styled.div`
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  border-radius: 1.2rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
`;

export const ListRowSkeleton = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 12px;
  align-items: center;
  padding-left: 8px;
  min-height: 5rem;
`;
