import { calculateDistance } from '../../../common/map/map.helpers';
import { Geocode, PudoItem } from '../../../models/pudoItem';
import {
  convertKmToMiles,
  Measure,
} from '../../../utils/parcel-measurements.ts/parcel-measurements-utils';

function filterPudoByNameOrByAddress(pudo: PudoItem, nameOrAddress: string) {
  return (
    pudo.name
      .toLocaleLowerCase()
      .includes(nameOrAddress.toLocaleLowerCase().trim()) ||
    `${pudo.address.street}, ${pudo.address.building}`
      .toLocaleLowerCase()
      .includes(nameOrAddress.toLocaleLowerCase().trim())
  );
}

export interface LocalPudoFilter {
  searchString?: string;
  ids?: string[];
}

export async function getFilteredPudos(
  pudos: PudoItem[] | undefined,
  filter: LocalPudoFilter
) {
  let result: PudoItem[] = pudos ?? [];

  if (filter.ids) {
    result = result.filter((pudo) => filter.ids?.includes(pudo.id));
  }
  if (filter.searchString) {
    result = result.filter((pudo) =>
      filterPudoByNameOrByAddress(pudo, filter.searchString!)
    );
  }
  return result;
}

const PUDO_NEW_SEARCH_DISTANCE_THRESHOLD_KM = 2;

export function calculateCurrentAndMaxDistances(
  pudos: PudoItem[],
  prevCenter: Geocode,
  newCenter: Geocode,
  measure?: Measure
) {
  const isLbMeasure = measure === Measure.LB_IN;

  const defaultMaxDistance = isLbMeasure
    ? convertKmToMiles(PUDO_NEW_SEARCH_DISTANCE_THRESHOLD_KM)
    : PUDO_NEW_SEARCH_DISTANCE_THRESHOLD_KM;

  const distances =
    pudos.length > 0
      ? pudos.map((o) => parseFloat(o.distance.distance))
      : [defaultMaxDistance];
  // Slightly reduce max distance threshold so new request is done
  // when center pin reaches near the end of marker's cloud.
  const maxDistance = Math.max(...distances);

  const currentDistanceKm = calculateDistance(prevCenter, newCenter);
  const currentDistance = isLbMeasure
    ? convertKmToMiles(currentDistanceKm)
    : currentDistanceKm;

  return [currentDistance, maxDistance];
}
