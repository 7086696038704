import styled, { css } from 'styled-components';
import { Tab as ReactTab, TabProps } from 'react-tabs';
import { TabsCursor, TabsSize, TabsTheme } from './tabs.component';
import { TabTheme } from './tab.component';

interface ContainerProps {
  $theme?: TabsTheme;
  $size?: TabsSize;
  $contentWeight?: 500 | 700;
  $cursor?: TabsCursor;
}

const getSize = (size: TabsSize) =>
  ({
    mini: {
      tabList: css`
        padding: 4px;
        border-radius: 8px;
      `,
      tab: css`
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 6px;
      `,
    },
    small: {
      tabList: css`
        padding: 6px;
        border-radius: 12px;
      `,
      tab: css`
        font-size: 14px;
        padding: 6px 16px;
        border-radius: 10px;
      `,
    },
    medium: {
      tabList: css`
        padding: 6px;
        border-radius: 12px;
      `,
      tab: css`
        font-size: 14px;
        padding: 12px 16px;
        border-radius: 10px;
      `,
    },
  })[size];

export const Container = styled.div<ContainerProps>`
  ${({ $size = 'small' }) => {
    const sizes = getSize($size);
    return css`
      .react-tabs {
        &__tab-list {
          ${sizes.tabList};
        }

        &__tab {
          ${sizes.tab};
        }
      }
    `;
  }}
  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
      margin: 0;
      display: flex;
      justify-content: space-between;
      gap: 2px;
      background-color: ${({ $theme = 'white', theme }) =>
        $theme === 'white'
          ? theme.colors.namedColors.white
          : theme.colors.baseColors.grey5};
    }

    &__tab {
      position: relative;
      list-style: none;
      cursor: ${({ $cursor = 'pointer' }) => $cursor};
      display: flex;
      justify-content: center;
      align-items: center;
      transition-property: color, background-color;
      transition-duration: 0.2s;
      font-family: Montserrat, 'Open Sans', sans-serif;
      font-weight: ${({ $contentWeight = 500 }) => $contentWeight};
      line-height: 1.14;
      letter-spacing: 0.5px;
      text-align: center;
      color: ${({ theme }) => theme.colors.baseColors.grey150};

      &--selected {
        color: ${({ theme }) => theme.colors.namedColors.white};
        background-color: var(--tab-background-color-selected);
        svg path {
          fill: ${({ theme }) => theme.colors.namedColors.white};
        }
      }

      &--disabled {
        cursor: default;
      }

      &:focus {
        outline: none;
      }
    }

    &__tab-panel {
      display: none;
      overflow-y: auto;

      &--selected {
        display: block;
      }
    }
  }
`;

const getTabTheme = (tabTheme: TabTheme) =>
  ({
    blue: css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.namedColors.primary};
    `,
    red: css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.baseColors.red100};
    `,
    polonez: css`
      --tab-background-color-selected: ${({ theme }) =>
        theme.colors.baseColors.blue100};
    `,
  })[tabTheme];

interface TabContainerProps extends TabProps {
  $theme?: TabTheme;
  $fullWidth?: boolean;
}

export const TabContainer = styled(ReactTab)<TabContainerProps>`
  display: block;
  width: ${({ $fullWidth = true }) => ($fullWidth ? '100%' : 'auto')};
  ${({ $theme = 'blue' }) => css`
    ${getTabTheme($theme)}
  `}
`;
