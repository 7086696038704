import { VALIDATION_RULE_LATIN } from '../../constants/validation';
import { createValidationRule } from './forms.utils';

export const parcelParticipantDataSchema = {
  first_name: createValidationRule(VALIDATION_RULE_LATIN)
    .test(
      'len',
      'please_enter_your_first_name',
      (value) => !!(value && value.length >= 1)
    )
    .required('this_field_cannot_be_empty'),

  last_name: createValidationRule(VALIDATION_RULE_LATIN)
    .test(
      'len',
      'please_enter_your_last_name',
      (value) => !!(value && value.length >= 1)
    )
    .required('this_field_cannot_be_empty'),
};

export const userDataSchema = {
  ...parcelParticipantDataSchema,
  middle_name: createValidationRule(VALIDATION_RULE_LATIN)
    .test('len', 'please_enter_your_middle_name', (value) => {
      if (!value) return true;
      return value.length >= 1;
    })
    .notRequired(),
};
