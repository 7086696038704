import styled, { css, keyframes } from 'styled-components';

interface Props {
  $mapDragging?: boolean;
  $mapLoading?: boolean;
}

// Animation for mapLoading
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
`;

const verticalAnimation = keyframes`
  0% {
    transform: translateY(-1rem);
  }
  50% {
    transform: translateY(-0.2rem);
  }
  100% {
    transform: translateY(-1rem);
  }
`;

const opacityAnimation = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;

export const MapCenterIconHolder = styled.div<Props>`
  position: relative;
  transition: all 0.2s;
  &:before {
    display: block;
    content: '';
    width: 3.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.baseColors.grey150};
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.3;
    transition: all 0.2s;
  }
  &:after {
    display: block;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.baseColors.grey150};
    position: absolute;
    bottom: 0.2rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.2s;
    opacity: 0;
  }
  svg {
    transition: all 0.2s;
  }
  ${({ $mapDragging }) =>
    $mapDragging &&
    css`
      &:before {
        width: 3rem;
        height: 1.2rem;
        opacity: 0.2;
      }
      &:after {
        bottom: 0.1rem;
        opacity: 0.5;
      }
      svg {
        transform: translateY(-1rem);
      }
    `}

  ${({ $mapLoading }) =>
    $mapLoading &&
    css`
      &:before {
        animation: ${pulse} 1.5s infinite ease-in-out;
      }
      &:after {
        animation: ${opacityAnimation} 1.5s infinite ease-in-out;
      }
      svg {
        animation: ${verticalAnimation} 1.5s infinite ease-in-out;
      }
    `}
`;
