import styled from 'styled-components';
import { TextBody } from '../../../common/typography/typography.styles';
import { BaseMarginComponent } from '../../../common/base-margin/base-margin.component';
import { TextButton } from '../../../common/button/button.styles';

export const MarginContainer = styled(BaseMarginComponent('div'))``;

export const Title = styled(TextBody)`
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const CustomTextButton = styled(TextButton)`
  & svg path {
    fill: ${({ theme }) => theme.colors.namedColors.primary};
  }
`;
