import styled from 'styled-components';

export const ParcelStepRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  min-width: 42rem;
  width: 42rem;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  border-radius: 1.2rem;
  position: sticky;
  top: 1.6rem;
  left: 0;
  height: 100%;

  @media screen and (max-width: 649px) {
    display: none;
  }
`;
