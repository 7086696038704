import styled, { css } from 'styled-components';
import { FontSizes } from '../typography/typography.styles';

export const Icon = styled.div`
  display: flex;
  transition: transform 0.3s;
  color: #75777b;
  flex-shrink: 0;
  width: 1.14em;
  height: 1.14em;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Input = styled.button.attrs({ type: 'button' })<{
  $width?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid #dcdde0;
  border-radius: 10px;
  font-size: ${FontSizes.normal};
  box-sizing: border-box;
  color: #2c363f;
  outline: none;
  padding: 0.72em 1.07em;
  transition-property: border-color, box-shadow;
  transition-duration: 0.3s;
  cursor: pointer;
  max-width: ${({ $width = '100%' }) => $width};
  width: 100%;
  gap: 8px;
  font-family: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:focus-visible {
    border-color: #008cff;
  }

  &:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  &:disabled {
    color: #96999e;
    cursor: default;
  }
`;
interface ContainerProps {
  $isOpened?: boolean;
}
export const Container = styled.div<ContainerProps>`
  position: relative;

  ${({ $isOpened }) =>
    $isOpened &&
    css`
      ${Icon} {
        transform: rotate(180deg);
      }

      ${Input} {
        border-color: ${({ theme }) => theme.colors.select.borderColorFocus};
      }
    `}
`;
export const Value = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
`;
export const ValueText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ValueIcon = styled.div`
  width: 1.14em;
  height: 1.14em;
  flex-shrink: 0;
  color: #75777b;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const SelectClear = styled.div`
  padding-top: 8px;
  margin-top: 4px;
  border-top: 1px solid #dcdde0;
`;
