import styled from 'styled-components';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../common/button/button.styles';

export const Section = styled.div`
  padding: 24px 0;

  @media screen and (min-width: 650px) {
    padding: 60px 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 40px;
  flex-direction: column;
  background-color: #e5f4ff;
  border-radius: 30px;
  padding: 48px 60px;
  min-height: 292px;
  overflow: hidden;
  @media (max-width: 650px) {
    gap: 232px;
    padding: 32px 24px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  gap: 16px;
  flex-direction: column;
  max-width: 624px;
`;

export const Header = styled.h3`
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1px;
  @media (max-width: 650px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Descriptions = styled.p`
  max-width: 550px;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  @media (max-width: 650px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
  gap: 16px;

  ${SecondaryButton},
  ${PrimaryButton} {
    min-width: 120px;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const ImageWrapperDesktop = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  max-width: 480px;
  max-height: 292px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const ImageWrapperMobile = styled.div`
  max-width: 319px;
  height: 254px;
  display: block;
  position: absolute;
  right: 0;
  bottom: 164px;

  img {
    height: 100%;
    width: 100%;
  }
`;
