import { Trans, useTranslation } from 'react-i18next';
import ModalDialog from '../../../common/modal-dialog/modal-dialog.component';
import { HeaderSecondary } from '../../../common/typography/typography.styles';
import { ModalTextBody } from '../../../common/modal-dialog/modal-dialog.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onApprove: () => void;
}

const ModalLeaveParcelCreation = ({
  isOpen = false,
  onApprove,
  onClose,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      isDanger={false}
      approveBtnContent={t('quit_remain')}
      cancelBtnContent={t('quit_leave')}
      isOpen={isOpen}
      useApproveLogicForDialogClosing
      onApprove={onApprove}
      onClose={onClose}>
      <HeaderSecondary>{t('quit_title')}</HeaderSecondary>
      <ModalTextBody>
        <Trans
          i18nKey='quit_text'
          components={{ br: <br /> }}
        />
      </ModalTextBody>
    </ModalDialog>
  );
};

export default ModalLeaveParcelCreation;
