import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
  ServicesSubSectionMainContent,
  ServicesSubSectionRow,
  ServicesSubSectionTitle,
  ServicesSubSectionValue,
} from '../../services/services-subsection/services-subsection.styles';
import { Heading, Value } from './insurance-details-summary.styles';
import { useStore } from '../../../stores/store';
import InsuranceTooltip from '../../../common/insurance-tooltip/insurance-tooltip.component';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';

const MIN_INSURANCE_FEE = 3;

const InsuranceDetailsSummary = () => {
  const {
    userStore: { getCurrencySymbol },
    parcelCreationStore: { shipment },
  } = useStore();

  const { t } = useTranslation();

  const { insurance, insurance_fee: insuranceFee } = shipment!;

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>
        <Heading>
          {t('insurance')}
          <InsuranceTooltip />
        </Heading>
      </ServicesSubSectionHeader>
      <ServicesSubSectionMainContent>
        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>
            {t('insurance_amount')}
          </ServicesSubSectionTitle>
          <ServicesSubSectionValue>{`${getFormattedPrice(insurance, getCurrencySymbol(shipment?.converted_price?.currency))}`}</ServicesSubSectionValue>
        </ServicesSubSectionRow>

        <ServicesSubSectionRow>
          <ServicesSubSectionTitle>
            {t('insurance_fee')}
          </ServicesSubSectionTitle>
          <Value>
            {insuranceFee > MIN_INSURANCE_FEE ? (
              `${getFormattedPrice(insuranceFee, getCurrencySymbol(shipment?.converted_price?.currency))}`
            ) : (
              <span>{t('free')}</span>
            )}
          </Value>
        </ServicesSubSectionRow>
      </ServicesSubSectionMainContent>
    </ServicesSubSectionContainer>
  );
};

export default observer(InsuranceDetailsSummary);
