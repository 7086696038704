import styled from 'styled-components';
import {
  HeaderSecondary,
  TextBody as BodyText,
} from '../typography/typography.styles';

interface Props {
  $isSidebar: boolean;
}

export const EmptyListContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 3.2rem;
  text-align: center;
  margin: auto;
  height: ${({ $isSidebar }) => ($isSidebar ? '100%' : '100dvh')};
  justify-content: center;

  @media screen and (max-width: 650px) {
    height: auto;
    margin-top: 8.9rem;
  }
`;

export const EmptyParcelIconWrapper = styled.div<Props>`
  max-width: 16.8rem;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 650px) {
    max-width: ${({ $isSidebar }) => ($isSidebar ? '16.8rem' : '45.6rem')};
  }

  @media screen and (max-height: 750px) {
    max-width: 30.6rem;
  }

  @media screen and (max-height: 600px) {
    max-width: 19.6rem;
  }
`;

export const Header = styled(HeaderSecondary)`
  && {
    margin-bottom: 0.4rem;
  }
`;

export const TextBody = styled(BodyText)`
  max-width: 40rem;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  width: 14rem;
`;
