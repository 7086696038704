import styled, { css, keyframes } from 'styled-components';
import { CloseButtonContainer } from '../close-button/close-button.styles';

interface Props {
  $isOpened: boolean;
}

const slideFromRight = keyframes`
    0% {
      transform: translate(0);
    }

    100% {
      transform: translateX(-100vw);
    }
`;

export const LeftSidebarContainer = styled.div<Props>`
  position: fixed;
  background-color: white;
  z-index: 9000;
  width: 100vw;
  top: 0;
  right: -100vw;
  border: none;

  transition: transform 0.4s ease-out;

  ${({ $isOpened }) =>
    $isOpened &&
    css`
      animation: ${slideFromRight} 0.4s;
      transform: translateX(-100vw);
    `}
`;

export const LeftSidebarBody = styled.div`
  height: 100dvh;
  overflow: auto;
`;

export const CloseButtonWrapper = styled.div`
  padding: 0.8rem 1.6rem 0;
  ${CloseButtonContainer} {
    margin-left: auto;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};

    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.passwordValidationMarker.backgroundColor};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.placeholderText.color};
    }
  }
`;
