import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import {
  FlexContainer,
  FlexContainerMob,
} from './delivery-details-form.styles';
import { useStore } from '../../../stores/store';
import { Address } from '../../../models/parcelDeliveryMiles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import { getShipmentCountryName } from '../../../utils/parcel-creation/parcel-creation-utils';
import {
  VALIDATION_RULE_IS_NOT_CYRILLIC,
  VALIDATION_RULE_LATIN_WITH_COMMA,
  VALIDATION_RULE_LATIN_WITH_NUMBERS,
} from '../../../constants/validation';
import PlacesFormFieldSearch from '../../../common/form-field-places-search/form-field-places-search.component';
import InputSearchWithSidebar from '../../../common/input-search-with-sidebar/input-search-with-sidebar.component';
import {
  getBuildingFromPlaceDetails,
  getPredictionsWithUserInput,
  getStreetFromPlaceDetails,
} from '../../../utils/google-places/google-places.utils';
import { PLACES_API_MINIMAL_REQUEST_LENGTH } from '../../../stores/placesSearchStore';
import { getElementTypeByName } from '../../../models/parcelCreationFlowNavigation';

export const courierDeliveryDetailsValidationSchema = Yup.object({
  country: Yup.string().required('this_field_cannot_be_empty!'),
  region: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  city: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  post_code: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  street: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  building: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).required(
    'this_field_cannot_be_empty'
  ),
  section: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_NUMBERS
  ).notRequired(),
  apartment: createValidationRule(
    VALIDATION_RULE_LATIN_WITH_COMMA
  ).notRequired(),
  note: createValidationRule(VALIDATION_RULE_LATIN_WITH_COMMA).notRequired(),
  buzz_code: createValidationRule(
    VALIDATION_RULE_IS_NOT_CYRILLIC
  ).notRequired(),
  full_address: Yup.string().notRequired(),
});

export const enum UserRole {
  sender = 'sender',
  receiver = 'receiver',
}

interface FormValues {
  country: string;
  region: string;
  city: string;
  post_code: string;
  street: string;
  building: string;
  section: string;
  apartment: string;
  note: string;
  buzz_code: string;
}

interface ChangeHandlerProps {
  selectedStreet?: string;
  setAddress: (address: Address) => void;
  streetFromField?: boolean;
}

const ChangeHandler = ({
  setAddress,
  selectedStreet,
  streetFromField,
}: ChangeHandlerProps) => {
  const { values, setFieldValue, validateField, touched } =
    useFormikContext<FormValues>();

  useEffect(() => {
    if (streetFromField) return;
    if (selectedStreet || (!selectedStreet && touched)) {
      setFieldValue('street', selectedStreet);
      setAddress({ ...values, street: selectedStreet });
      validateField('street');
    }
  }, [
    selectedStreet,
    setAddress,
    setFieldValue,
    touched,
    validateField,
    values,
    streetFromField,
  ]);

  useEffect(() => {
    setAddress(values);
  }, [setAddress, values]);

  return null;
};

const DeliveryDetailsForm = ({ role }: { role: UserRole }) => {
  const {
    parcelCreationStore: {
      shipment,
      countryDeparture,
      countryDestination,
      setSenderAddress,
      setReceiverAddress,
      currentElements,
    },
    placesSearchStore: { getAutocompletePredictions, getPlaceDetails },
    userStore: { user: accountUser },
  } = useStore();

  const { t } = useTranslation();

  const isUserSender = role === UserRole.sender;
  const isUserReceiver = role === UserRole.receiver;
  const user = isUserSender ? shipment?.sender : shipment?.recipient;
  const country = isUserSender ? countryDeparture : countryDestination;
  const initialCountry = getShipmentCountryName(country, t);

  const additionalFieldsType = getElementTypeByName(
    currentElements,
    'additional-fields'
  );

  const isHideFields = additionalFieldsType === 'hide';

  const [selectedStreet, setSelectedStreet] = useState<string | undefined>(
    user?.address?.street
  );
  const [initialValues, setInitialValues] = useState({
    country: initialCountry,
    region: user?.address?.region,
    city: user?.address?.city,
    post_code: user?.address?.post_code,
    street: user?.address?.street,
    building: user?.address?.building,
    section: user?.address?.section,
    apartment: user?.address?.apartment,
    buzz_code: user?.address?.buzz_code,
    note: user?.address?.note,
    full_address: user?.address?.full_address,
  });

  useEffect(() => {
    if (isUserSender) {
      if (
        user?.address?.region === accountUser?.address?.region &&
        user?.address?.city === accountUser?.address?.city &&
        user?.address?.post_code === accountUser?.address?.post_code
      ) {
        setInitialValues({
          country: initialCountry,
          region: user?.address?.region || accountUser?.address?.region || '',
          city: user?.address?.city || accountUser?.address?.city || '',
          post_code:
            user?.address?.post_code || accountUser?.address?.post_code || '',
          street: user?.address?.street || accountUser?.address?.street || '',
          building:
            user?.address?.building || accountUser?.address?.building || '',
          section:
            user?.address?.section || accountUser?.address?.section || '',
          apartment:
            user?.address?.apartment || accountUser?.address?.apartment || '',
          buzz_code:
            user?.address?.buzz_code || accountUser?.address?.buzz_code || '',
          note: user?.address?.note || accountUser?.address?.note || '',
          full_address: accountUser?.address?.full_address,
        });
        setSelectedStreet(accountUser?.address?.street ?? '');
      }
    }

    if (isUserReceiver) {
      setInitialValues({
        country: initialCountry,
        region: (user?.address?.region || countryDestination!.region) ?? '',
        city: (user?.address?.city || countryDestination!.city) ?? '',
        post_code:
          (user?.address?.post_code || countryDestination!.zipCode) ?? '',
        street: user?.address?.street ?? '',
        building: user?.address?.building ?? '',
        section: user?.address?.section ?? '',
        apartment: user?.address?.apartment ?? '',
        buzz_code: user?.address?.buzz_code ?? '',
        note: user?.address?.note ?? '',
        full_address: user?.address?.full_address ?? '',
      });
      setSelectedStreet(user?.address?.street ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.address]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>
        {t('delivery_details')}
      </ServicesSubSectionHeader>
      <Formik
        enableReinitialize
        validationSchema={courierDeliveryDetailsValidationSchema}
        initialValues={initialValues}
        onSubmit={() => {}}>
        {({
          handleSubmit,
          setFieldValue,
          values,
          resetForm,
          setValues,
          validateField,
        }) => {
          const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value, name } = event.target;
            setFieldValue(name, value);
          };
          return (
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}>
              <ChangeHandler
                selectedStreet={selectedStreet}
                setAddress={
                  isUserSender ? setSenderAddress : setReceiverAddress
                }
                streetFromField={isUserReceiver && isHideFields}
              />
              <FlexContainer>
                <FormFieldText
                  name='country'
                  label={t('country')}
                  disabled
                />

                {!isHideFields && (
                  <FormFieldText
                    name='region'
                    label={t('province/state')}
                    onChange={onChange}
                  />
                )}
              </FlexContainer>

              {isUserReceiver && isHideFields && (
                <FlexContainer>
                  <PlacesFormFieldSearch
                    selectedCountryCode={country?.countryCode.toLocaleLowerCase()}
                    name='full_address'
                    placeholder=''
                    label={t('address')}
                    formikValidateField={validateField}
                    setValues={({
                      zipCode: post_code,
                      address,
                      street,
                      ...props
                    }) => {
                      setValues({
                        ...values,
                        ...props,
                        full_address: address,
                        street,
                        post_code,
                        country: initialCountry,
                      });
                      setSelectedStreet(street);
                    }}
                  />
                </FlexContainer>
              )}

              <FlexContainer>
                {isHideFields && (
                  <FormFieldText
                    name='region'
                    label={t('province/state')}
                    onChange={onChange}
                  />
                )}

                <FormFieldText
                  name='city'
                  label={t('city')}
                  onChange={onChange}
                />

                {!isHideFields && (
                  <FormFieldText
                    name='post_code'
                    label={t('zip-code')}
                    onChange={onChange}
                  />
                )}
              </FlexContainer>
              {isHideFields && (
                <FlexContainer>
                  {isHideFields && (
                    <FormFieldText
                      name='post_code'
                      label={t('zip-code')}
                      onChange={onChange}
                    />
                  )}

                  {isUserReceiver && (
                    <FormFieldText
                      name='street'
                      label={t('street')}
                      onChange={onChange}
                    />
                  )}

                  {isUserSender && (
                    <InputSearchWithSidebar<google.maps.places.AutocompletePrediction>
                      name='street'
                      label={t('street')}
                      inputValue={selectedStreet ?? ''}
                      sidebarTitle={t('search_street')}
                      sidebarInputPlaceholder={t('start_typing_your_address')}
                      canModifyInput={false}
                      debounceSidebarInputChange
                      shouldValidateSearchQuery
                      onSearchFinished={() => {
                        resetForm({ values: { ...values, building: '' } });
                      }}
                      getSearchOptions={async (filter) => {
                        const predictions = await getAutocompletePredictions({
                          cityName: initialValues.city,
                          value: filter ?? '',
                          countryConstraint:
                            country?.countryCode.toLocaleLowerCase(),
                        });

                        return getPredictionsWithUserInput(
                          predictions,
                          filter,
                          PLACES_API_MINIMAL_REQUEST_LENGTH
                        );
                      }}
                      onSearchOptionSelected={async (place) => {
                        if (place.place_id) {
                          const placeDetails = await getPlaceDetails(place);

                          if (placeDetails) {
                            const street = getStreetFromPlaceDetails(
                              placeDetails.address_components
                            );
                            const building = getBuildingFromPlaceDetails(
                              placeDetails.address_components
                            );

                            setSelectedStreet(street);

                            setValues({ ...values, building, street });
                          } else {
                            setSelectedStreet(place.description);
                            setValues({ ...values, street: place.description });
                          }
                        }
                      }}
                      onInputChange={setSelectedStreet}
                      getKeyForSearchOption={(addressPrediction) =>
                        addressPrediction.place_id
                      }
                      getDisplayValueForSearchOption={(addressPrediction) =>
                        addressPrediction.description
                      }
                      validationRule={VALIDATION_RULE_LATIN_WITH_COMMA}
                    />
                  )}
                </FlexContainer>
              )}
              {!isHideFields && (
                <FlexContainer>
                  <InputSearchWithSidebar<google.maps.places.AutocompletePrediction>
                    name='street'
                    label={t('street')}
                    inputValue={selectedStreet ?? ''}
                    sidebarTitle={t('search_street')}
                    sidebarInputPlaceholder={t('start_typing_your_address')}
                    canModifyInput={false}
                    debounceSidebarInputChange
                    shouldValidateSearchQuery
                    onSearchFinished={() => {
                      resetForm({ values: { ...values, building: '' } });
                    }}
                    getSearchOptions={async (filter) => {
                      const predictions = await getAutocompletePredictions({
                        cityName: initialValues.city,
                        value: filter ?? '',
                        countryConstraint:
                          country?.countryCode.toLocaleLowerCase(),
                      });

                      return getPredictionsWithUserInput(
                        predictions,
                        filter,
                        PLACES_API_MINIMAL_REQUEST_LENGTH
                      );
                    }}
                    onSearchOptionSelected={async (place) => {
                      if (place.place_id) {
                        const placeDetails = await getPlaceDetails(place);

                        if (placeDetails) {
                          const street = getStreetFromPlaceDetails(
                            placeDetails.address_components
                          );
                          const building = getBuildingFromPlaceDetails(
                            placeDetails.address_components
                          );

                          setSelectedStreet(street);

                          setValues({ ...values, building, street });
                        } else {
                          setSelectedStreet(place.description);
                          setValues({ ...values, street: place.description });
                        }
                      }
                    }}
                    onInputChange={setSelectedStreet}
                    getKeyForSearchOption={(addressPrediction) =>
                      addressPrediction.place_id
                    }
                    getDisplayValueForSearchOption={(addressPrediction) =>
                      addressPrediction.description
                    }
                    validationRule={VALIDATION_RULE_LATIN_WITH_COMMA}
                  />
                </FlexContainer>
              )}

              <FlexContainer>
                <FlexContainerMob>
                  <FormFieldText
                    name='building'
                    label={t('building')}
                    onChange={onChange}
                  />

                  {isHideFields && (
                    <FormFieldText
                      name='apartment'
                      label={t('apartment')}
                      onChange={onChange}
                      isOptional
                    />
                  )}

                  {!isHideFields && (
                    <FormFieldText
                      name='section'
                      label={t('section')}
                      onChange={onChange}
                    />
                  )}
                </FlexContainerMob>

                {!isHideFields && (
                  <FlexContainerMob>
                    <FormFieldText
                      name='apartment'
                      label={t('apartment')}
                      onChange={onChange}
                    />

                    <FormFieldText
                      name='buzz_code'
                      label={t('buzz_code')}
                      onChange={onChange}
                    />
                  </FlexContainerMob>
                )}
              </FlexContainer>
              <FlexContainer>
                <FormFieldText
                  name='note'
                  placeholder=''
                  label={t('note')}
                  maxLength={255}
                  onChange={onChange}
                  isOptional
                />
              </FlexContainer>
            </form>
          );
        }}
      </Formik>
    </ServicesSubSectionContainer>
  );
};

export default DeliveryDetailsForm;
