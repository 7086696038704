import { Geocode } from '../../models/pudoItem';

export type MapConfig = {
  id: string;
  mapId: string;
  mapTypeId: MapTypeId;
  defaultMapCenter: Geocode;
  containerStyle: { width: string; height: string };
  restrictionsRadiusKm: number;
  zoom: { max: number; min: number };
};

type MapTypeId = 'hybrid' | 'roadmap' | 'satellite' | 'terrain';

export const mapConfig: MapConfig = {
  id: 'pudo_map',
  mapId: 'fe8acff5d2ed3ab0', // managed in google cloud console. mapId is connected to styledId.
  mapTypeId: 'roadmap',
  defaultMapCenter: {
    latitude: 32.0853, // Default latitude
    longitude: 34.7818, // Default longitude
  },
  containerStyle: {
    width: '100%',
    height: '100%',
  },
  restrictionsRadiusKm: 100,
  zoom: {
    min: 9, // how far we can zoom-out
    max: 18, // how close we can zoom-in
  },
};

export interface WithGeocode {
  id: string;
  geocode: Geocode;
}

export interface MapPosition {
  zoom?: number;
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
}
