import styled from 'styled-components';
import { Form } from 'formik';
import {
  ButtonTextThin,
  FontSizes,
} from '../../common/typography/typography.styles';
import { SecondaryFilledButton } from '../../common/button/button.styles';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-radius: 1.2rem;
  padding: 1.6rem;
  border: 0.1rem solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};

  @media screen and (min-width: 650px) {
    padding: 2.4rem 2.4rem 3.2rem 2.4rem;
  }
`;

export const SettingsFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 2rem;
`;

export const Description = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
`;

export const SpanDescription = styled.span`
  cursor: pointer;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.namedColors.primary};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 16rem;
  align-self: flex-end;
`;

export const BadgeContainer = styled.div`
  display: flex;
  gap: 1.2rem;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
`;

export const CategoryButton = styled(SecondaryFilledButton)<{
  $isActive: boolean;
}>`
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  width: fit-content;

  &:hover {
    & span {
      color: ${({ theme }) => theme.colors.namedColors.primary};
    }
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    `
    border-color: ${theme.colors.namedColors.primary};
    & span {
      color: ${theme.colors.namedColors.primary};
    }
  `}
`;

export const CategoryButtonText = styled.span`
  ${ButtonTextThin}
  font-size: ${FontSizes.medium};
  color: ${({ theme }) => theme.colors.baseColors.grey150};

  text-align: start;
`;

export const FormFeedback = styled(Form)`
  width: 100%;
  max-width: 110.4rem;
  height: 100%;

  @media screen and (max-width: 650px) {
    margin-bottom: 1.6rem;
  }
`;
