import React from 'react';
import { ReactComponent as MeestStoreQrCode } from '../../../assets/meest-store-qr.svg';
import { QRCodeContainer } from './download-app.styles';

const AppDownloadQRCode: React.FC = () => (
  <QRCodeContainer>
    <MeestStoreQrCode
      width={155}
      height={155}
    />
  </QRCodeContainer>
);

export default AppDownloadQRCode;
