import styled from 'styled-components';

interface Props {
  $isSidebarVisible: boolean;
  $isGuestParcelCreation: boolean | null;
}

export const ServicesContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: var(--services-container-gap);
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 109.6rem;
  width: 100%;
  margin: 0 auto;
`;

export const ServicesRouteContainer = styled.div<Props>`
  --services-container-gap: 1.6rem;
  --services-container-padding: 1.6rem;
  --services-sidebar-width: 27rem;

  max-width: 144rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  padding: var(--services-container-padding);
  transition: 0.3s ease-out;
  transition-property: padding-left, max-width;
  position: relative;
  padding-left: ${({ $isSidebarVisible }) =>
    $isSidebarVisible
      ? `calc(var(--services-sidebar-width) + var(--services-container-gap) * 2)`
      : `var(--services-container-padding)`};

  @media screen and (min-width: 768px) {
    --services-container-gap: 2.4rem;
    --services-container-padding: 2.4rem;
  }
`;

export const ServicesRouteMobile = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 1.6rem 1.6rem 0 1.6rem;
`;
