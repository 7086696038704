import { useTranslation } from 'react-i18next';
import MeestBrokenPCImage from '../../assets/meest-error-page-pc-image.png';
import PolonezBrokenPCImage from '../../assets/polonez-error-page-pc-image.png';
import StatusPage from '../../common/status-page/status-page.component';
import { client } from '../../config';

const getImage = () => {
  const images = {
    meest: MeestBrokenPCImage,
    polonez: PolonezBrokenPCImage,
  };
  return images[client];
};

const ServerError = () => {
  const { t } = useTranslation();

  return (
    <StatusPage
      title={t('server_error')}
      body={t('server_error_description')}
      imgSrc={getImage()}
    />
  );
};

export default ServerError;
