import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParcelListShipment } from '../../../models/parcelDeliveryMiles';
import {
  getStatusBadgeConfig,
  getFavoriteLabel,
  listPathValues,
  IParcelListPathValue,
  getAddress,
  ICardActionItem,
  PARCEL_LIST_SIDEBARS,
} from '../../../routes/parcel-list/config';
import { ReactComponent as StarPlusIcon } from '../../../assets/star-plus.svg';
import { ReactComponent as StarMinusIcon } from '../../../assets/star-minus.svg';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';
import { ReactComponent as ArchiveIcon } from '../../../assets/archive-drawer.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/refresh.svg';
import { ReactComponent as BookOpenIcon } from '../../../assets/book-open.svg';
import { ReactComponent as InvoiceIcon } from '../../../assets/invoice.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit-icon.svg';
import { useStore } from '../../../stores/store';
import { formatDate, printBlob } from '../../../utils/generic/generic.utils';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import { getFormattedPrice } from '../../../utils/price/get-formatted-price';
import { SidebarName } from '../../../types/sidebar.types';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';

interface Props {
  parcel: ParcelListShipment;
  routeName: IParcelListPathValue['path'];
  onToggleFavorite: (value: boolean) => void;
  onToggleArchive: (value: boolean) => void;
  onCopy: () => void;
}

export const useParcelItem = ({
  parcel,
  routeName,
  onToggleFavorite,
  onToggleArchive,
  onCopy,
}: Props) => {
  const {
    status,
    is_favorite: isFavorite,
    items: parcelItems,
    recipient,
    sender,
    created_at: createdAt,
    delivery_at: deliveryAt,
    flags,
    // eslint-disable-next-line
    converted_price,
  } = parcel;
  const {
    parcelListStore: { getParcel, getInvoiceData, getParcelManual },
    navStore: { closeSidebarByName, toggleSidebarByName },
    userStore: { getCurrencySymbol },
    commonStore: { toastError },
  } = useStore();

  const { t } = useTranslation();
  const handleStartParcelCreation = useStartParcelCreation();
  const NO_INFORMATION_TEXT = t('no_information');

  const [isLoadingParcel, setIsLoadingParcel] = useState(false);
  const isMobile = useIsMobile();
  const statusConfig = getStatusBadgeConfig(status);
  const favoritesLabel = getFavoriteLabel(isFavorite);
  const isArchivePage = routeName === listPathValues.archive.path;
  const isParcelListPage = routeName === listPathValues.parcellist.path;
  const isDraftsPage = routeName === listPathValues.drafts.path;
  const senderAddress = getAddress(sender!);
  const recipientAddress = getAddress(recipient!);
  const senderName =
    sender?.first_name && sender?.last_name
      ? `${sender?.first_name} ${sender?.last_name}`
      : NO_INFORMATION_TEXT;
  const recipientName =
    recipient?.first_name && recipient?.last_name
      ? `${recipient?.first_name} ${recipient?.last_name}`
      : NO_INFORMATION_TEXT;
  const createdDate = formatDate(createdAt) ?? NO_INFORMATION_TEXT;
  const deliveryDate = deliveryAt
    ? `~${formatDate(deliveryAt)}`
    : NO_INFORMATION_TEXT;
  const itemDescriptions = parcelItems?.length
    ? parcelItems
        .slice(0, 3)
        .map((item) => item.description_standart)
        .join(', ')
    : NO_INFORMATION_TEXT;
  const sidebarName: SidebarName = `${PARCEL_LIST_SIDEBARS.details}_${parcel.id}`;
  const actions: ICardActionItem[] = useMemo(() => {
    const baseActions = [
      {
        label: favoritesLabel,
        value: '1',
        onClick: isFavorite
          ? () => onToggleFavorite(false)
          : () => onToggleFavorite(true),
        icon: isFavorite ? <StarMinusIcon /> : <StarPlusIcon />,
      },
      {
        label: 'copy_details',
        value: '3',
        onClick: onCopy,
        icon: <CopyIcon />,
      },
      {
        label: isArchivePage ? 'restore' : 'archive',
        value: '4',
        onClick: isArchivePage
          ? () => {
              closeSidebarByName(sidebarName);
              onToggleArchive(false);
            }
          : () => {
              closeSidebarByName(sidebarName);
              onToggleArchive(true);
            },
        icon: isArchivePage ? <RefreshIcon /> : <ArchiveIcon />,
      },
    ];

    const favoritesIndex = baseActions.findIndex(
      (action) => action.label === favoritesLabel
    );

    if (flags?.can_receive_invoice) {
      baseActions.splice(favoritesIndex + 1, 0, {
        label: 'invoice',
        value: '2',
        onClick: async () => {
          try {
            const blob = await getInvoiceData(parcel.id);
            await printBlob(
              blob,
              parcel.file_names?.invoice || `${parcel.tracking_number}.pdf`
            );
          } catch (error: any) {
            toastError(t(error.message));
          }
        },
        icon: <InvoiceIcon />,
      });
    }

    if (isParcelListPage && favoritesIndex !== -1) {
      baseActions.splice(
        status === 'paid' ? favoritesIndex + 2 : favoritesIndex + 1,
        0,
        {
          label: 'instructions',
          value: '0',
          onClick: () => {
            getParcelManual({
              shipmentId: parcel.id,
              shouldShowDontShowAgain: false,
            });
          },
          icon: <BookOpenIcon />,
        }
      );
    }

    if (isDraftsPage && status === 'draft') {
      baseActions.splice(favoritesIndex + 1, 0, {
        label: 'edit',
        value: '0',
        onClick: () => {
          handleStartParcelCreation(undefined, parcel.id);
        },
        icon: <EditIcon />,
      });
    }

    return baseActions;
  }, [
    favoritesLabel,
    isFavorite,
    onCopy,
    isArchivePage,
    status,
    flags?.can_receive_invoice,
    isParcelListPage,
    onToggleFavorite,
    closeSidebarByName,
    sidebarName,
    onToggleArchive,
    getInvoiceData,
    parcel.id,
    parcel.file_names?.invoice,
    parcel.tracking_number,
    toastError,
    t,
    getParcelManual,
    isDraftsPage,
    handleStartParcelCreation,
  ]);

  const handleClick = async (id: number) => {
    toggleSidebarByName(sidebarName);
    setIsLoadingParcel(true);
    const shipment = await getParcel(id);

    if (shipment) {
      setIsLoadingParcel(false);
    }
  };

  const handleKeyDown = (event: any, id: number) => {
    if (event.key === ' ' || event.key === 'Enter') {
      if (event.key === ' ') {
        event.preventDefault();
      }
      handleClick(id);
    }
  };

  const canPay = flags?.can_pay;
  const isPaymentProcessing = flags?.payment_in_process;
  const totalPrice = getFormattedPrice(
    converted_price?.total_price,
    getCurrencySymbol(converted_price?.currency)
  );

  return {
    statusConfig,
    favoritesLabel,
    senderAddress,
    recipientAddress,
    senderName,
    recipientName,
    createdDate,
    deliveryDate,
    itemDescriptions,
    actions,
    sidebarName,
    isLoadingParcel,
    canPay,
    isPaymentProcessing,
    totalPrice,
    handleClick,
    handleKeyDown,
  };
};
