import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';

export const ServicesUserInfoContainer = styled.div`
  --avatar-size: 5.4rem;

  display: grid;
  gap: 12px;
  padding: 0.4rem 0;
`;

export const ServicesUserAbout = styled.div`
  display: grid;
  grid-template-columns: var(--avatar-size) 1fr;
  gap: 1.2rem;
  align-items: center;
`;

export const ServicesUserAvatar = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.componentColors.userAvatar};
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.colors.namedColors.white};
`;

export const ServicesUserName = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: ${FontSizes.normal};
  font-style: normal;
  font-weight: 700;
  line-height: 1.6rem;
  letter-spacing: 0.5px;
  word-break: break-word;
`;

export const ContactContainer = styled.div`
  overflow: hidden;
  max-width: 100%;
`;

export const ServicesUserCopy = styled.button`
  background: none;
  padding: 0;
  border: none;
  font: inherit;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.baseColors.grey150};
  cursor: pointer;
  align-self: start;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const ServicesContent = styled.div`
  display: grid;
  gap: 4px;
`;

export const ServicesUserNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;
