import { ReactComponent as TelegramIcon } from '../../assets/main-page/live-chat/telegram.svg';
import { ReactComponent as ViberIcon } from '../../assets/main-page/live-chat/viber.svg';
import { ReactComponent as PhoneIcon } from '../../assets/main-page/live-chat/phone-icon.svg';
import { ReactComponent as EmailIcon } from '../../assets/main-page/live-chat/email-icon.svg';
import { ReactComponent as ContactUsIcon } from '../../assets/main-page/live-chat/contact-us.svg';

import { SupportLinkType } from '../../models/countriesModel';
import { translate } from '../../../i18n';

export const EMAIL_AND_PHONE_TYPES: SupportLinkType[] = ['email', 'phone'];

export const getIcon = (type: SupportLinkType): JSX.Element | null => {
  const iconMap: Record<SupportLinkType, JSX.Element> = {
    telegram: <TelegramIcon />,
    viber: <ViberIcon />,
    phone: <PhoneIcon />,
    email: <EmailIcon />,
    contact_us: <ContactUsIcon />,
  };
  return iconMap[type] || null;
};

export const getHref = (
  url: string,
  type: SupportLinkType,
  isMobile: boolean
): string | undefined => {
  if (type === 'phone' && isMobile) {
    return `tel:${url}`;
  }
  return EMAIL_AND_PHONE_TYPES.includes(type) ? undefined : url;
};

export const handleClick = ({
  url,
  type,
  isMobile,
  copyToClipboard,
}: {
  url: string;
  type: SupportLinkType;
  isMobile: boolean;
  copyToClipboard: (options: { text: string; successMessage: string }) => void;
}): void => {
  if (EMAIL_AND_PHONE_TYPES.includes(type) && !(isMobile && type === 'phone')) {
    const messageKey =
      type === 'email'
        ? 'contactSupport_emailCopied'
        : 'contactSupport_phoneCopied';
    const successMessage = translate(messageKey);
    copyToClipboard({
      text: url,
      successMessage,
    });
  }
};

export interface SupportLink {
  type: SupportLinkType;
  url: string;
}

export const filterAndSortSupportLinks = (
  supportLinks: SupportLink[][] | undefined,
  allowedTypes: SupportLinkType[] = ['telegram', 'viber', 'phone', 'email']
): SupportLink[] =>
  supportLinks
    ?.flat()
    .filter(({ type }) => allowedTypes.includes(type))
    .sort((a, b) => a.type.localeCompare(b.type)) || [];
