import styled, { css } from 'styled-components';

import { TextInput, TextLabel } from '../../typography/typography.styles';

interface Props {
  $isOpened: boolean;
  disabled: boolean;
  $padding?: string;
}

export const DropdownSelector = styled.div<Props>`
  min-width: 8.7rem;
  max-height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  border-radius: 10px;
  box-sizing: border-box;
  color: #333;
  cursor: default;
  outline: none;
  padding: ${({ $padding }) => $padding || '1rem 0.8rem 1rem 1.2rem'};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.baseColors.grey5};
      cursor: not-allowed;
      svg {
        display: none;
      }
    `}
`;

interface ScrollableContentProps {
  $heightOfAboveContent?: string; // is required to calculate how much height we have on screen for the this component where
  // scrollable content is displayed
  $heightOfAboveContentMobile?: string;
  $mt?: string;
}

export const ScrollableContent = styled.div<ScrollableContentProps>`
  flex-grow: 1;
  gap: 4px;
  overflow-y: auto;
  margin-top: ${({ $mt = '1rem' }) => $mt};
  border-radius: 10px;
  position: relative;
  cursor: default;
  ${({ $heightOfAboveContent = '13.9rem' }) => css`
    height: calc(100dvh - ${$heightOfAboveContent});
    height: calc(-webkit-fill-available - ${$heightOfAboveContent});
  `};
  -webkit-overflow-scrolling: auto !important;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.colors.namedColors.white};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.baseColors.grey65};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.baseColors.grey100};
    border-radius: 6px;
  }

  @media (max-width: 650px) {
    ${({ $heightOfAboveContentMobile = '13.9rem' }) => css`
      height: calc(100dvh - ${$heightOfAboveContentMobile});
      height: calc(-webkit-fill-available - ${$heightOfAboveContentMobile});
    `};
  }
`;

export const DropdownSelectedOption = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  border-color: ${({ theme }) => theme.colors.baseColors.grey50};
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  ${TextInput}
`;

export const PhoneCodeDropdownContainer = styled.div`
  position: relative;

  ${TextLabel} {
    color: ${({ theme }) => theme.colors.baseColors.grey100};
    display: inline-block;
    margin-bottom: 6px;
  }
`;

export const PhoneSearchContainer = styled.div``;
