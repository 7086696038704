import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';
import ModalDialog from '../../../../common/modal-dialog/modal-dialog.component';
import { BarcodeWrapper, QrcodeWrapper } from '../details-sidebar.styles';
import { useIsMobile } from '../../../../hooks/use-is-mobile.hook';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  modalContent: 'qrcode' | 'barcode';
  firstMile: string[];
}

const CodeGenerationDialog = ({
  isOpen = false,
  onClose,
  modalContent,
  firstMile,
}: Props) => {
  const isMobile = useIsMobile();

  return (
    <ModalDialog
      width='auto'
      isOpen={isOpen}
      onClose={onClose}
      displayButtons={false}>
      {modalContent === 'qrcode' && (
        <QrcodeWrapper>
          <QRCodeSVG
            size={isMobile ? 240 : 300}
            level='L'
            value={`${firstMile}`}
          />
        </QrcodeWrapper>
      )}
      {modalContent === 'barcode' && (
        <BarcodeWrapper>
          <Barcode
            width={3}
            value={`${firstMile}`}
          />
        </BarcodeWrapper>
      )}
    </ModalDialog>
  );
};
export default CodeGenerationDialog;
