import styled from 'styled-components';
import { Card, CardImage, Cards } from '../common/card.styles';

export const Header = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 24px;

  @media screen and (min-width: 650px) {
    grid-template-columns: 330px minmax(200px, 684px);
    justify-content: space-between;
    margin-bottom: 32px;
    min-height: 120px;
  }
`;

export const AdvantagesCardImage = styled(CardImage)`
  --size: 64px;

  width: var(--size);
  height: var(--size);
  color: #0061af;
  transition: color 0.2s;
  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 650px) {
    --size: 80px;
  }
`;

export const AdvantagesCard = styled(Card)`
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${AdvantagesCardImage} {
        color: #008cff;
      }
    }
  }
`;

export const AdvantagesCards = styled(Cards)`
  --cols: 2;
  grid-template-columns: repeat(var(--cols), 1fr);

  @media screen and (min-width: 650px) {
    --cols: 4;
  }
`;
