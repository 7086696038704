import styled, { css } from 'styled-components';
import { TextParcelDeliveryOptionTabTitle } from '../../../common/typography/typography.styles';
import { TDeliveryMethod } from './parcel-delivery-tab-title.component';
import { Container as TabsContainer } from '../../../common/tabs/tabs.styles';
import { scrollStyles } from '../../../common/scroll/scroll.styles';

export const Container = styled.div`
  height: 100%;

  .react-tabs {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 2.4rem;
    height: 100%;

    @media (max-width: 650px) {
      gap: 1.6rem;
    }
  }
  .react-tabs__tab {
    padding: 0.6rem !important;
  }

  .react-tabs__tab-panel {
    overflow: auto;
    ${scrollStyles};
  }

  ${TabsContainer} {
    height: 100%;
  }
`;

export const AdvantageTag = styled.span`
  @media (max-width: 650px) {
    flex: 1 1 60%;
  }
`;

export const TabsContainerTop = styled.div`
  display: grid;
  gap: 0.8rem;
`;

export const DeliveryTabTitleContainer = styled.span<{
  $method?: TDeliveryMethod;
  $isSelected?: boolean;
}>`
  color: ${({ theme, $isSelected }) =>
    $isSelected ? 'inherit' : theme.colors.tab.color};
  ${TextParcelDeliveryOptionTabTitle}
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  text-align: center;
  svg {
    fill: ${({ $method, theme }) =>
      $method === 'expedited'
        ? theme.colors.baseColors.red100
        : theme.colors.namedColors.primary};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      ${AdvantageTag} {
        color: var(--tab-background-color-selected);
      }
    `}

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 0.6rem;
  }
  @media (min-width: 651px) {
    min-width: 10rem;
  }
`;

interface OptionProps {
  $isSelected?: boolean;
}

export const SelectOption = styled.div<OptionProps>`
  padding: 1.2rem 1.2rem 1.2rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.colors.baseColors.grey50};
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.25s ease-out;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.baseColors.grey5};
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: ${({ theme }) => theme.colors.baseColors.grey5};
    `}
`;
