export const BREAKPOINTS = {
  mobile: {
    num: 650,
    css: '650px',
  },
  desktop: {
    num: 1024,
    css: '1024px',
  },
  tablet: {
    num: 768,
    css: '768px',
  },
};
