import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  ServicesSubSectionContainer,
  ServicesSubSectionHeader,
} from '../../services/services-subsection/services-subsection.styles';
import CallCourierWithSidebar from '../call-courier-with-sidebar/call-courier-with-sidebar.component';
import { useStore } from '../../../stores/store';

const CallCourierForm = () => {
  const {
    parcelCreationStore: { getAllDates },
  } = useStore();

  const { t } = useTranslation();

  useEffect(() => {
    getAllDates();
  }, [getAllDates]);

  return (
    <ServicesSubSectionContainer>
      <ServicesSubSectionHeader>
        {t('create_parcel_courier_pick_up_date')}
      </ServicesSubSectionHeader>

      <CallCourierWithSidebar />
    </ServicesSubSectionContainer>
  );
};

export default observer(CallCourierForm);
