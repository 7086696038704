import styled from 'styled-components';
import {
  FontSizes,
  HeaderMain,
} from '../../../common/typography/typography.styles';

export const AuthTabsContainer = styled.div`
  ${HeaderMain} {
    margin-bottom: 2.4rem;
  }

  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
      border-radius: 1.2rem;
      background: ${({ theme }) => theme.colors.tab.backgroundColor};
      height: 4.4rem;
      margin: 0 0 3.2rem;
      padding: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.2rem;
    }

    &__tab {
      display: inline-block;
      list-style: none;
      padding: 0.6rem 1.6rem;
      width: 50%;
      text-align: center;
      cursor: pointer;
      transition: border-bottom-color 0.2s;
      color: ${({ theme }) => theme.colors.namedColors.primary};
      font-size: ${FontSizes.normal};
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.05rem;

      &--selected {
        border-radius: 1rem;
        background: ${({ theme }) => theme.colors.namedColors.primary};
        color: ${({ theme }) => theme.colors.namedColors.white};
      }

      &:focus {
        outline: none;
      }
    }

    &__tab-panel {
      display: none;

      &--selected {
        display: block;
      }
    }
  }
`;
export const AuthorizationForm = styled.form`
  display: grid;
  gap: var(--sections-gap, 24px);
`;
