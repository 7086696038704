import * as Yup from 'yup';
import {
  DeliveryMethod,
  FirstMile,
  LastMile,
} from '../../../models/parcelDeliveryMiles';
import { getMiddleMileByDeliveryMethod } from '../../../utils/parcel-creation/parcel-creation-utils';
import { ParcelDimensions } from '../../../models/shipmentCountryInfo';

const meestCarriers = ['MEESTPOST', 'meest'];

export const getMaxValidation = (
  value: string,
  context: Yup.TestContext<Yup.AnyObject>,
  configValue?: number,
  ignoreValidation?: boolean
) => {
  if (ignoreValidation) return true;

  const allDimensionFieldsProvided = (
    ['weight', 'size_z', 'size_x', 'size_y'] as Array<keyof ParcelDimensions>
  ).every((key) => !!context.parent[key]);

  if (!allDimensionFieldsProvided) return true;
  if (!value) return false;
  if (!configValue) return true;
  return +value <= configValue;
};

export const getMinValidation = (
  value: string,
  context: Yup.TestContext<Yup.AnyObject>,
  ignoreValidation?: boolean,
  configValue: number = 0
) => {
  if (ignoreValidation) return true;

  const allDimensionFieldsProvided =
    context.options.context &&
    Object.keys(context.options.context).every((key) => !!context.parent[key]);

  if (!allDimensionFieldsProvided) return true;
  if (!value) return false;
  if (+value === 0) return false;
  if (!configValue) return true;
  return +value >= configValue;
};

export const getCheapestDeliveryOptions = (
  lowestFirstMilePrices: FirstMile[],
  lastMiles: LastMile[] | null,
  middleMileMethod: DeliveryMethod
) =>
  lowestFirstMilePrices
    .map((firstMile) => {
      const lastMileOptions = lastMiles!
        .filter(
          (lastMile) => lastMile.rate_schema_id === firstMile.rate_schema_id
        )
        .map((lastMile) => ({
          firstMile,
          lastMile,
          price: {
            total_price:
              getMiddleMileByDeliveryMethod(firstMile, middleMileMethod)!
                .summary_price + lastMile.price,
          },
        }));

      return lastMileOptions;
    })
    .flat()
    .sort((a, b) => {
      // Check if either carrierId is 'meest', and prioritize them
      if (
        meestCarriers.includes(a.lastMile!.carrier.code) &&
        !meestCarriers.includes(b.lastMile!.carrier.code)
      ) {
        return -1; // a comes first
      }
      if (
        !meestCarriers.includes(a.lastMile!.carrier.code) &&
        meestCarriers.includes(b.lastMile!.carrier.code)
      ) {
        return 1; // b comes first
      }
      // If both are 'meest' or both are not 'meest', sort by price ascending
      return a.price.total_price - b.price.total_price;
    });
