import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Container } from '../common/container.styles';
import {
  Header,
  Wrapper,
  Section,
  Descriptions,
  ButtonWrapper,
  TextWrapper,
  ImageWrapperDesktop,
  ImageWrapperMobile,
} from './authentication.styles';
import authentication_car_desktop from '../../../assets/main-page/authentication/authentication_car_desktop.webp';
import authentication_car_mobile from '../../../assets/main-page/authentication/authentication_car_mobile.webp';

import { useStore } from '../../../stores/store';
import { AppRoute } from '../../../constants/route';
import Button from '../../../common/button/button.component';
import { generateLocaleRoute } from '../../../utils/uri/uri.utils';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';

const AuthenticationComponent = () => {
  const {
    navStore: { setAuthTabIndex },
    userStore: { user },
  } = useStore();

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const { t } = useTranslation();

  if (user) return null;

  const handleAuthenticateButtonClick = (tabIndex: number) => {
    window.scrollTo({ top: 0 });
    setAuthTabIndex(tabIndex);
    navigate(generateLocaleRoute(AppRoute.AUTH));
  };

  return (
    <Container>
      <Section>
        <Wrapper>
          <TextWrapper>
            <Header>{t('still_dont_have_an_account')}</Header>
            <Descriptions>{t('register_signin_to_get_benefits')}</Descriptions>
          </TextWrapper>
          {isMobile && (
            <ImageWrapperMobile>
              <img
                width={319}
                height={254}
                src={authentication_car_mobile}
                alt=''
              />
            </ImageWrapperMobile>
          )}
          <ButtonWrapper>
            <Button
              fullWidth={false}
              size='large'
              onClick={() => {
                handleAuthenticateButtonClick(1);
              }}>
              {t('register')}
            </Button>
            <Button
              fullWidth={false}
              appearance='secondary'
              size='large'
              onClick={() => {
                handleAuthenticateButtonClick(0);
              }}>
              {t('sign_in')}
            </Button>
          </ButtonWrapper>
          <ImageWrapperDesktop>
            <img
              width={480}
              height={292}
              decoding='async'
              loading='lazy'
              src={authentication_car_desktop}
              alt=''
            />
          </ImageWrapperDesktop>
        </Wrapper>
      </Section>
    </Container>
  );
};

export default observer(AuthenticationComponent);
