import { css, styled } from 'styled-components';

export type ProgressBarSize = 'small' | 'medium';

export const Wrapper = styled.div<{ $size?: ProgressBarSize }>`
  width: 100%;
  height: ${({ $size = 'small' }) =>
    (
      ({
        small: '2px',
        medium: '4px',
      }) as const
    )[$size]};
  background-color: #e0e3e7;
`;

export const Fill = styled.div<{
  $width?: number;
  $isAnimated?: boolean;
}>`
  width: ${({ $width = 100 }) => `${$width}%`};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.progressBar.backgroundColor};

  ${({ $isAnimated }) =>
    $isAnimated &&
    css`
      transition: width 0.2s linear;
    `}
`;
