import styled, { css } from 'styled-components';

export const scrollStyles = css`
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.baseColors.grey65};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.baseColors.grey100};
    border-radius: 6px;
  }
`;

export const Scrollbar = styled.div`
  ${scrollStyles};
`;
