import { ToastPrimaryMessage, ToastSecondaryMessage } from './toasts.styles';

export interface ToastContent {
  primaryMessage: string;
  secondaryMessage?: string;
}

const ToastMessage: React.FC<{ content: ToastContent }> = ({ content }) => (
  <div>
    <ToastPrimaryMessage>{content.primaryMessage}</ToastPrimaryMessage>
    <br />
    {content.secondaryMessage && (
      <ToastSecondaryMessage>{content.secondaryMessage}</ToastSecondaryMessage>
    )}
  </div>
);

export default ToastMessage;
