import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FooterContainer, FooterContent } from './footer.styles';
import FooterTop from './footer-top.component';
import FooterBottom from './footer-bottom.component';
import { useFooter } from './helpers/FooterContext';
import { useStore } from '../../stores/store';

const Footer = () => {
  const { footerDisplayOption } = useFooter();
  const {
    localizationsStore: { selectedCountry },
    marketingStore: { fetchFooterLinks },
  } = useStore();

  useEffect(() => {
    if (selectedCountry?.country_code) {
      fetchFooterLinks(selectedCountry?.country_code);
    } else {
      fetchFooterLinks('default');
    }
  }, [fetchFooterLinks, selectedCountry]);

  if (footerDisplayOption === 'hidden') {
    return null;
  }

  return (
    <FooterContainer>
      {footerDisplayOption === 'full' && (
        <FooterContent>
          <FooterTop />
        </FooterContent>
      )}
      <FooterContent>
        <FooterBottom />
      </FooterContent>
    </FooterContainer>
  );
};

export default observer(Footer);
