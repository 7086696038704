import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionsDesktop, Container } from './header-signin.styles';
import { ReactComponent as LoginIcon } from '../../../assets/login-icon.svg';
import { useStore } from '../../../stores/store';
import { HeaderBtn } from '../header-btn/header-btn.styles';
import Button from '../../../common/button/button.component';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';
import { AppRoute } from '../../../constants/route';
import {
  generateLocaleRoute,
  generateStartParcelCreationRoute,
} from '../../../utils/uri/uri.utils';

interface Props {
  isMainPage: boolean;
}

const HeaderSignin = ({ isMainPage }: Props) => {
  const {
    userStore: { user },
    navStore: { toggleSidebarByName },
  } = useStore();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleStartParcelCreation = useStartParcelCreation();

  const handleCreateParcel = () => {
    if (user) {
      handleStartParcelCreation();
    } else {
      navigate(
        generateLocaleRoute(
          `${AppRoute.AUTH}?redirect=${generateStartParcelCreationRoute()}`
        )
      );
    }
  };

  return (
    <Container>
      <HeaderBtn onClick={() => navigate(generateLocaleRoute(AppRoute.AUTH))}>
        <LoginIcon />
      </HeaderBtn>
      <ActionsDesktop>
        {isMainPage && (
          <Button
            fullWidth={false}
            onClick={handleCreateParcel}>
            {t('create_parcel')}
          </Button>
        )}
        <Button
          fullWidth={false}
          appearance='secondary'
          onClick={() => toggleSidebarByName('nav_sidebar')}>
          {t('login')}
        </Button>
      </ActionsDesktop>
    </Container>
  );
};

export default HeaderSignin;
