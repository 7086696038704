import Skeleton from 'react-loading-skeleton';
import {
  ReceiverData,
  ReceiverName,
  ReceiverNotes,
} from './receiver-card.styles';

const ReceiverCardSkeleton = () => (
  <>
    <ReceiverName>
      <Skeleton
        width='100%'
        height='1.5rem'
        borderRadius={6}
      />
    </ReceiverName>

    <ReceiverData>
      <Skeleton
        width='100%'
        height='1.5rem'
        borderRadius={6}
      />
    </ReceiverData>

    <ReceiverData>
      <Skeleton
        width='100%'
        height='1.5rem'
        borderRadius={6}
      />
    </ReceiverData>

    <ReceiverData>
      <Skeleton
        width='100%'
        height='1.5rem'
        borderRadius={6}
      />
    </ReceiverData>

    <ReceiverNotes>
      <Skeleton
        width='100%'
        height='1.5rem'
        borderRadius={6}
      />
    </ReceiverNotes>
  </>
);

export default ReceiverCardSkeleton;
