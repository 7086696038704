import styled from 'styled-components';

interface Props {
  $showBackToTop: boolean;
}

export const Container = styled.button<Props>`
  border: none;
  position: fixed;
  cursor: pointer;
  z-index: 1118000;
  bottom: 2.4rem;
  left: 50%;
  transform: ${(props) =>
    props.$showBackToTop
      ? ` translateX(-50%) translateY(0%)`
      : `translateX(-50%) translateY(100%)`};
  display: flex;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 3.2rem;
  padding: 1.2rem;
  opacity: ${(props) => (props.$showBackToTop ? 1 : 0)};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  background: ${({ theme }) => theme.colors.namedColors.white};
  box-shadow: 0 0 10px 0 rgba(4, 57, 100, 0.11);

  svg {
    transform: rotate(180deg);
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
    transition-property: border-color, background-color, color, outline-color;
    transition-duration: 0.2s;

    svg {
      g {
        path {
          fill: ${({ theme }) => theme.colors.namedColors.white};
        }
      }
    }
  }
`;
