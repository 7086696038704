import { SeparatorContainer, SeparatorLine } from './separator.styles';

interface Props {
  content?: string;
}

const Separator = ({ content }: Props) => (
  <SeparatorContainer>
    <SeparatorLine $width='100%' />
    {content && (
      <>
        {content}
        <SeparatorLine $width='100%' />
      </>
    )}
  </SeparatorContainer>
);

export default Separator;
