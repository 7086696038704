import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 40%;
  max-width: 100%;

  @media (max-width: 900px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;
