import { createGlobalStyle } from 'styled-components';
import { NormalizeStyles } from './app/shared/styles/normalize.styles';
import { MediaQueries } from './media-queries.styles';
import { VisuallyHiddenStyles } from './app/common/visually-hidden/visually-hidden.styles';

export const GlobalStyles = createGlobalStyle`
  :root {
    --services-subsection-gap: 1.2rem;

    @media screen and (min-width: 768px) {
      --services-subsection-gap: 2.4rem;
    }
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    word-break: normal;
  }

  h1,h2,h3,p {
    margin: 0;
    padding: 0;
  }

  html, body {
    /* Addresses mobile browser quirks with viewport height */
    height: -webkit-fill-available;
  }

  html {
    font-size: 62.5%;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    font-style: italic;
    background-repeat: no-repeat;
    background-size: cover;
    shape-margin: 1rem;
  }

  body {
    font-family: Montserrat, "Open Sans", sans-serif;
    background-color: ${({ theme }) => theme.colors.body.backgroundColor};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }

  // removes default browser styling for autcompleted inputs
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
  }
  input[type=number] {
      -moz-appearance: textfield;
  }

  .flag-emoji-font {
    font-family: 'Noto Color Emoji', Montserrat !important;
  }

  ${MediaQueries};

  ${NormalizeStyles};

  .no-scroll {
    position: relative;
    height: 100%;
    overflow-y: hidden;

      @media (max-width: 650px) {
        width: 100%;
        position: fixed;
      }
  }

  .flex-1 {
    flex: 1;
  }

  .visually-hidden {
    ${VisuallyHiddenStyles};
  }
  .gm-style-cc {
      display: none;
  }
`;
