import { useState } from 'react';

interface Props {
  onLocationSuccess: (position: GeolocationPosition) => void;
  onLocationError: (error: GeolocationPositionError) => void;
  onPermissionChange?: (status: PermissionStatus | undefined) => void;
}

const useGeolocation = ({ onLocationSuccess, onLocationError }: Props) => {
  const [isLocationLoading, setIsLocationLoading] = useState(false);

  const requestGeoPermission = async () =>
    navigator.permissions.query({
      name: 'geolocation',
    });

  const handleLocationSuccess: PositionCallback = (
    position: GeolocationPosition
  ) => {
    onLocationSuccess(position);
    setIsLocationLoading(false);
  };

  const handleLocationError: PositionErrorCallback = (
    error: GeolocationPositionError
  ) => {
    onLocationError(error);
    setIsLocationLoading(false);
  };

  const tryGetLocation = () => {
    setIsLocationLoading(true);
    navigator.geolocation.getCurrentPosition(
      handleLocationSuccess,
      handleLocationError
    );
  };

  return {
    isGeolocationAvailable: !!navigator.geolocation,
    isLocationLoading,
    tryGetLocation,
    requestGeoPermission,
  };
};

export default useGeolocation;
