import styled from 'styled-components';

export const Background = styled.div`
  margin: 0 auto;
  padding: 1.6rem;
  min-height: 55rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 144rem;
  width: 100%;

  @media screen and (max-width: 649px) {
    min-height: auto;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 650px) {
    max-width: 45.6rem;
  }
`;

export const Description = styled.div`
  display: grid;
  gap: 0.8rem;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 1.6rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  flex-direction: column;
  margin-top: 2.4rem;

  > * {
    min-width: 160px;
  }

  @media screen and (min-width: 650px) {
    padding: 0 6rem;
    flex-direction: row;
    margin-top: 3.2rem;

    > * {
      width: 50%;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: 4.8rem;
  svg {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 649px) {
    max-width: 35rem;
    margin-bottom: 3.2rem;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  flex-grow: 1;
  height: 100%;
  display: grid;

  @media screen and (max-width: 650px) {
    br {
      display: none;
    }
  }

  @media sceen and (max-width: 650px) {
    padding: 0 3rem;
  }

  @media screen and (max-width: 400px) {
    padding: 0;
  }
`;
