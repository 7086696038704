import { css } from 'styled-components';

export const generateMediaQueryStyles = (
  property: string,
  value: string,
  breakpoint: string
) => css`
  @media (max-width: ${breakpoint}) {
    ${property}: ${value};
  }
`;
