import styled from 'styled-components';
import { ScrollableContent } from '../../../common/form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';
import { FontSizes } from '../../../common/typography/typography.styles';
import { BottomSidebarContainer } from '../../../common/sidebar-bottom/bottom-sidebar.styles';
import { BackToListBtn } from '../pudo-list-item/pudo-list-item.styles';
import { FiltersHeading } from '../../../common/filters/filters.styles';

export const SliderBlock = styled.div`
  margin-top: 2.4rem;

  .rc-slider {
    width: 100%;
  }

  .rc-slider-rail {
    background-color: ${({ theme }) => theme.colors.baseColors.blue10};
  }

  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
  }

  .rc-slider-handle {
    width: 2rem;
    height: 2rem;
    margin-top: -8px;
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
    border: none;

    &-dragging {
      border: none !important;
      box-shadow: none !important;
    }
  }
`;

export const SliderMarksBlock = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SliderMark = styled.span`
  font-size: ${FontSizes.normal};
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: 0.5px;
`;

export const PudoFilterContainer = styled.div`
  ${ScrollableContent} {
    padding: 2.4rem;
    background-color: ${({ theme }) => theme.colors.baseColors.blue0};
    border-radius: 0;
    margin: 0;
    gap: 0;
  }

  ${BottomSidebarContainer} {
    padding: 0;

    ${ScrollableContent} {
      border-radius: 10px 10px 0 0;
    }

    ${BackToListBtn} {
      display: none;
    }

    ${FiltersHeading} {
      text-align: center;
    }

    ${ScrollableContent} {
      padding: 1.6rem 1.6rem 1.6rem 1.6rem;
    }
  }
`;
