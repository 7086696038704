import { useId } from 'react';
import { TextCheckbox } from '../typography/typography.styles';
import {
  CheckboxWrapper,
  CheckboxContainer,
  CheckboxContent,
} from './checkbox.styles';
import { ReactComponent as CheckboxIcon } from '../../assets/checkbox-icon.svg';

interface Props {
  name: string;
  children: React.ReactNode;
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ isChecked, name, children, onChange }: Props) => {
  const id = useId();

  return (
    <CheckboxWrapper>
      <CheckboxContainer>
        <input
          id={name + id}
          name={name}
          type='checkbox'
          checked={isChecked}
          onChange={onChange}
        />
        <CheckboxContent>
          <CheckboxIcon />
        </CheckboxContent>
      </CheckboxContainer>
      {children && <TextCheckbox>{children}</TextCheckbox>}
    </CheckboxWrapper>
  );
};

export default Checkbox;
