import styled from 'styled-components';
import { FontSizes } from '../../../common/typography/typography.styles';
import { ServicesMenuItemContainer } from '../../services/services-menu-item/services-menu-item.styles';
import { ServicesUserInfoContainer } from '../../services/services-user-info/services-user-info.styles';
import { Dropdown } from '../../../common/dropdown/dropdown.styles';

export const HeaderNavContainer = styled.div`
  position: relative;

  ${Dropdown} {
    padding: 1.6rem 0.8rem;

    ${ServicesUserInfoContainer} {
      margin-bottom: 2.4rem;
    }
  }
`;

export const UserNavMobileContainer = styled.div`
  display: none;

  ${ServicesMenuItemContainer}:not(:last-child) {
    margin-bottom: 8px;
  }

  ${ServicesMenuItemContainer} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.baseColors.grey5};
      border-radius: 6px;
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.colors.passwordValidationMarker.backgroundColor};
    }
  }

  ${ServicesUserInfoContainer} {
    margin-bottom: 2.4rem;
  }
`;

export const UserAvatar = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.componentColors.userAvatar};
  color: ${({ theme }) => theme.colors.namedColors.white};
  font-family: Montserrat, 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.5px;

  @media screen and (min-width: 650px) {
    width: 4rem;
    height: 4rem;
    font-size: ${FontSizes.normal};
    font-weight: 700;
  }
`;

export const ActionsDesktop = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media screen and (max-width: 649px) {
    display: none;
  }
`;

export const HeaderMenuItemsContainer = styled.div`
  display: grid;
  gap: 16px;
`;

export const HeaderMenuItems = styled.div`
  display: grid;
  gap: 4px;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  margin: 4px 0;
  background-color: ${({ theme }) => theme.colors.baseColors.grey50};
`;

export const ServicesUserInfoWrapper = styled.div`
  padding: 0 0.8rem;
`;
