import { SpinnerCircle, SpinnerOverlay } from './spinner.styles';

interface SpinnerProps {
  isFullScreen?: boolean;
}

const Spinner = ({ isFullScreen }: SpinnerProps) => (
  <SpinnerOverlay $isFullScreen={isFullScreen}>
    <SpinnerCircle />
  </SpinnerOverlay>
);

export default Spinner;
