import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  DeliveryMethod,
  FirstMile,
  LastMile,
} from '../../../models/parcelDeliveryMiles';
import {
  DeliveryDescription,
  DeliveryDescriptionContainer,
  DeliveryDuration,
  DeliveryImgContainer,
  DeliveryImg,
  ParcelDeliveryOptionContainer,
  DeliveryCarrier,
  DeliveryService,
  DeliveryTitle,
  DeliveryPriceContainer,
} from './parcel-delivery-option.styles';
import { ReactComponent as ClockIcon } from '../../../assets/clock-icon.svg';
import DeliveryPrice from './delivery-price.component';
import { useStore } from '../../../stores/store';
import {
  calculateTotalDuration,
  getCheapestMile,
} from '../../../utils/parcel-creation/parcel-creation-utils';
import { client } from '../../../config';

interface Props {
  deliveryOption: FirstMile | LastMile;
  isPriceResponsive?: boolean;
  middleMileMethod?: DeliveryMethod;
  showDurationInCard?: boolean;
  showTotalDuration?: boolean;
}

const ParcelDeliveryOption = ({
  deliveryOption,
  isPriceResponsive,
  middleMileMethod,
  showDurationInCard = true,
  showTotalDuration = false,
}: Props) => {
  const {
    parcelCreationStore: {
      selectedMiddleMileMethod,
      selectedLastMile,
      lastMiles,
    },
  } = useStore();

  const { t } = useTranslation();

  const carrierName = deliveryOption.carrier.name;
  const serviceName = t(deliveryOption.service.name);
  const imgUrl = deliveryOption.image;
  const middleMileMethodToUse = middleMileMethod ?? selectedMiddleMileMethod;

  // For first miles we should show duration from middle mile
  const duration = showTotalDuration
    ? // For the first mile we should display total duration of all selected miles or cheapest mile if last mile is not selected
      calculateTotalDuration(
        deliveryOption as FirstMile,
        middleMileMethodToUse!,
        selectedLastMile || getCheapestMile(lastMiles!)
      )
    : // For the last mile show only the duration of last mile
      deliveryOption.duration;

  const durationString = `${duration} ${duration > 1 || duration === 0 ? t('days') : t('day')}`;

  return (
    <ParcelDeliveryOptionContainer>
      <DeliveryDescriptionContainer>
        <DeliveryImgContainer>
          <DeliveryImg $imgUrl={imgUrl} />
        </DeliveryImgContainer>
        <DeliveryDescription>
          <DeliveryTitle>
            <DeliveryCarrier>{carrierName}</DeliveryCarrier>
            <DeliveryService $client={client}>{serviceName}</DeliveryService>
          </DeliveryTitle>
        </DeliveryDescription>
      </DeliveryDescriptionContainer>
      <DeliveryPriceContainer>
        <DeliveryPrice
          deliveryOption={deliveryOption}
          middleMileMethod={middleMileMethodToUse!}
          isPriceResponsive={isPriceResponsive}
        />
        {showDurationInCard && (
          <DeliveryDuration>
            <>
              <ClockIcon /> {durationString}
            </>
          </DeliveryDuration>
        )}
      </DeliveryPriceContainer>
    </ParcelDeliveryOptionContainer>
  );
};

export default observer(ParcelDeliveryOption);
