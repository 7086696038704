import { useMap } from '@vis.gl/react-google-maps';
import { ZoomControlsWrapper } from './zoom-controls.styles';
import Button from '../button/button.component';
import { mapConfig } from './map.config';

interface Props {
  disabled: boolean;
}

const ZoomControls = ({ disabled }: Props) => {
  const map = useMap(mapConfig.id);

  const zoomIncrease = () => {
    const zoomIn = map?.getZoom()!;
    map?.setZoom(zoomIn + 1);
  };

  const zoomDecrease = () => {
    const zoomOut = map?.getZoom()!;
    map?.setZoom(zoomOut - 1);
  };

  return (
    <ZoomControlsWrapper>
      <Button
        onClick={zoomIncrease}
        appearance='tertiary'
        icon={{ glyph: 'plus' }}
        size='large'
        fullWidth={false}
        disabled={disabled}
      />
      <Button
        onClick={zoomDecrease}
        appearance='tertiary'
        icon={{ glyph: 'minus' }}
        size='large'
        fullWidth={false}
        disabled={disabled}
      />
    </ZoomControlsWrapper>
  );
};

export default ZoomControls;
