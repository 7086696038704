import styled, { css } from 'styled-components';

export const HeaderServicesMobileContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 650px) {
    display: none;
  }
`;

interface ServicesContainerProps {
  $isVisible: boolean;
}

export const ServicesContainer = styled.div<ServicesContainerProps>`
  position: absolute;
  left: 2.4rem;
  top: var(--services-container-padding);
  flex-shrink: 0;
  width: var(--services-sidebar-width);
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  overflow: hidden;
  border-radius: 1.2rem;
  border: 1px solid
    ${({ theme }) => theme.colors.passwordValidationMarker.backgroundColor};
  height: 100%;
  min-height: calc(100dvh - 10rem);
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  max-height: 549px;
  overflow: auto;

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      transform: translateX(calc(-100% - var(--services-container-gap)));
      opacity: 0;
      pointer-events: none;
    `}
`;

interface ServicesDropdownButtonProps {
  $isActive?: boolean;
}

export const ServicesDropdownButton = styled.button<ServicesDropdownButtonProps>`
  border: none;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  font: inherit;
  font-weight: 700;
  font-size: 1.4rem;
  transition: color 0.2s;

  svg {
    transition: transform 0.2s;
  }

  &:hover {
    color: var(--header-active-color);
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: var(--header-active-color);

      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const ServicesDropdownContainer = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 649px) {
    display: none;
  }
`;
