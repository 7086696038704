import { useTranslation } from 'react-i18next';
import { ReactComponent as AirplaneIcon } from '../../../assets/airplane.svg';
import { ReactComponent as ShipIcon } from '../../../assets/ship.svg';
import { ReactComponent as RocketIcon } from '../../../assets/rocket.svg';
import {
  AdvantageTag,
  DeliveryTabTitleContainer,
} from './parcel-delivery-options-tabs.styles';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';
import Tag, { TagColor } from '../../../common/tag/tag.component';
import { client, WLClient } from '../../../config';

export type TDeliveryMethod = 'air' | 'sea' | 'expedited';

const getTagColor = () => {
  const colors: Record<WLClient, TagColor> = {
    meest: 'blue',
    polonez: 'polonez',
  };
  return colors[client];
};

const getTabTitle = (method: TDeliveryMethod) =>
  (
    ({
      air: {
        tagColor: getTagColor(),
        icon: <AirplaneIcon />,
        text: 'air',
        advantagesText: 'create_parcel_faster',
      },
      sea: {
        tagColor: getTagColor(),
        icon: <ShipIcon />,
        text: 'sea',
        advantagesText: 'create_parcel_cheaper',
      },
      expedited: {
        tagColor: 'red',
        icon: <RocketIcon />,
        text: 'air',
        advantagesText: 'create_parcel_expedited',
      },
    }) as const
  )[method];

export interface DeliveryTabProps {
  method: TDeliveryMethod;
  showAdvantageTag?: boolean;
  isSelected?: boolean;
}

const DeliveryTabTitle = ({
  method,
  showAdvantageTag,
  isSelected,
}: DeliveryTabProps) => {
  const { t } = useTranslation();
  const content = getTabTitle(method);

  return (
    <DeliveryTabTitleContainer
      $isSelected={isSelected}
      $method={method}>
      <FlexContainer
        as='span'
        $justify='center'
        $gap='0.8rem'>
        {content.icon}
        {t(content.text)}
      </FlexContainer>
      {showAdvantageTag && content.advantagesText && (
        <AdvantageTag>
          <Tag
            textColor={isSelected ? 'inherit' : undefined}
            type='filled'
            color={isSelected ? 'white' : content.tagColor}
            size='mini'>
            {t(content.advantagesText)}
          </Tag>
        </AdvantageTag>
      )}
    </DeliveryTabTitleContainer>
  );
};

export default DeliveryTabTitle;
