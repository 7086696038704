import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import BottomSidebar from '../../../common/sidebar-bottom/bottom-sidebar.component';
import { MenuItems } from '../../../common/menu-item/menu-item.styles';
import MenuItem from '../../../common/menu-item/menu-item.component';
import { IDropdownItem } from '../../../common/select/select.component';
import { ShipmentQueryOrder } from '../../../stores/parcelListStore';
import {
  PARCEL_LIST_SIDEBARS,
  orderItems,
  orderItemsDrafts,
} from '../../../routes/parcel-list/config';
import { useStore } from '../../../stores/store';

interface Props {
  selectedValue?: IDropdownItem<ShipmentQueryOrder>;
  isDrafts?: boolean;
  onClick: (item: IDropdownItem<ShipmentQueryOrder>) => void;
}

const ParcelListOrder = ({
  selectedValue,
  isDrafts = false,
  onClick,
}: Props) => {
  const {
    navStore: { toggleSidebarByName },
  } = useStore();

  const { t } = useTranslation();
  const items = isDrafts ? orderItemsDrafts : orderItems;

  return (
    <BottomSidebar
      height='346px'
      name={PARCEL_LIST_SIDEBARS.order}
      withBlurredBackground>
      <MenuItems>
        {items.map((item) => (
          <MenuItem
            key={item.value}
            isSelected={selectedValue?.value === item.value}
            prefix={item.icon}
            onClick={() => {
              toggleSidebarByName(PARCEL_LIST_SIDEBARS.order);
              onClick(item);
            }}>
            {t(item.label)}
          </MenuItem>
        ))}
      </MenuItems>
    </BottomSidebar>
  );
};

export default observer(ParcelListOrder);
