import { Trans } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { LinkSmall } from '../typography/typography.styles';
import { useStore } from '../../stores/store';

const TermsOfUseTranslation = () => {
  const {
    localizationsStore: { selectedCountry },
  } = useStore();

  return (
    <Trans
      i18nKey='terms_of_use'
      components={{
        1: <LinkSmall />,
      }}
      values={{
        termsLink: selectedCountry?.links.terms_of_use ?? '',
        condLink: selectedCountry?.links.delivery_rules ?? '',
        privacyLink: selectedCountry?.links.privacy_policy ?? '',
      }}
    />
  );
};

export default observer(TermsOfUseTranslation);
