import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFooter } from './FooterContext';
import { AppRoute } from '../../../constants/route';
import { generateLocaleRoute } from '../../../utils/uri/uri.utils';
import { useStore } from '../../../stores/store';

const noFooterAuthScreens = ['welcome-old-account', 'welcome-social-account'];

const FooterVisibilityHandler = () => {
  const location = useLocation();
  const { setFooterDisplayOption } = useFooter();

  const {
    localizationsStore: { localeParam },
    navStore: { authCurrentScreen },
  } = useStore();

  useEffect(() => {
    const isMainPage =
      location.pathname === generateLocaleRoute(undefined, localeParam);

    if (isMainPage) {
      setFooterDisplayOption('full');
      return;
    }

    const isParcelCreationFlow = location.pathname.includes(
      AppRoute.PARCEL_CREATE
    );

    const isAuthSubRoute =
      (location.pathname.includes(AppRoute.AUTH) &&
        !location.pathname.endsWith(AppRoute.AUTH)) ||
      noFooterAuthScreens.includes(authCurrentScreen);

    if (isParcelCreationFlow || isAuthSubRoute) {
      setFooterDisplayOption('hidden');
      return;
    }

    setFooterDisplayOption('mini');
  }, [
    location.pathname,
    localeParam,
    setFooterDisplayOption,
    authCurrentScreen,
  ]);

  return null;
};

export default observer(FooterVisibilityHandler);
