import { IDropdownItem } from '../../common/select/select.component';
import { PerPageParcelCount } from '../../stores/parcelListStore';

export type IPerPageItem = IDropdownItem<PerPageParcelCount>;

export const PerPageCountVariants: IPerPageItem[] = [
  {
    label: '12',
    value: 12,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '48',
    value: 48,
  },
  {
    label: '100',
    value: 100,
  },
];
