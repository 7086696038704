import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PudoItem } from '../../../models/pudoItem';
import { ReactComponent as CopyIcon } from '../../../assets/copy-icon.svg';
import {
  AddressAndDistancBlock,
  AddressLabel,
  AddressLabelDetailed,
  ButtonsBlock,
  CopyPhone,
  DistanceDetailed,
  DistanceLabel,
  InfoDetailed,
  InfoGrayDetailed,
  LabelDetailed,
  MaxWeight,
  MoreDetails,
  NameAndWeightBlock,
  PaymentBlock,
  PhoneLink,
  PudoDetailsContainer,
  PudoDetailsContent,
  PudoListItemContainer,
  PudoName,
  PudoNameDetailed,
  SeparatorLine,
  SupportBlock,
  TimeLabel,
  TimeLabelDetailed,
  WorkingHoursBlock,
} from './pudo-list-item.styles';
import { ReactComponent as NavigationIcon } from '../../../assets/navigation-arrow-icon.svg';
import { useStore } from '../../../stores/store';
import Sidebar from '../../../common/sidebar-right/sidebar.component';
import Button from '../../../common/button/button.component';
import {
  Measure,
  convertKgToLb,
  getMeasureUnits,
} from '../../../utils/parcel-measurements.ts/parcel-measurements-utils';
import Tag from '../../../common/tag/tag.component';
import {
  decodeHtmlEntities,
  formatStringPascalCase,
  getTodaysWorkingHours,
  parseWorkingHoursSummary,
  getAddressFormatted,
} from './helpers';
import { SidebarName } from '../../../types/sidebar.types';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import { isMeest } from '../../../utils/client/client.utils';
import { client } from '../../../config';
import {
  constructGoogleMapsUrl,
  copyToClipboard,
} from '../../../utils/generic/generic.utils';

interface Props {
  pudo: PudoItem;
  withDetails?: boolean; // max weight and distance
  openSidebarWithDetails?: boolean;
  shouldShowNavigateTo?: boolean;
  onChoose?: (pudo: PudoItem) => void;
  onMoreDetails?: (pudo: PudoItem) => void;
  onBackToList?: (pudo?: PudoItem) => void;
}

const PudoListItem = ({
  pudo,
  withDetails,
  openSidebarWithDetails,
  shouldShowNavigateTo,
  onChoose,
  onMoreDetails,
  onBackToList,
}: Props) => {
  const {
    localizationsStore: { selectedLocalization },
    userStore: { measures },
    navStore: { toggleSidebarByName, isSidebarOpenedByName },
    parcelCreationStore: { deliveryLimitations },
  } = useStore();

  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const sidebarId: SidebarName = `pudo_list_item_${pudo.id}`;
  const pudoName = useMemo(
    () => decodeHtmlEntities(formatStringPascalCase(pudo.name.split(' '))),
    [pudo]
  );
  const todayWorkingTime = useMemo(() => getTodaysWorkingHours(pudo), [pudo]);
  const workingHoursShort = useMemo(
    () => decodeHtmlEntities(todayWorkingTime),
    [todayWorkingTime]
  );
  const parsedWorkingTime = parseWorkingHoursSummary(
    pudo.working_time_partly_separated,
    t,
    selectedLocalization.code
  );

  const { weight: weightUnit } = getMeasureUnits(measures);
  const weight = useMemo(() => {
    const w =
      pudo.limit_weight !== ''
        ? Number(pudo.limit_weight)
        : Math.floor(deliveryLimitations?.maxWeight ?? 0);
    return `${t('max.')} ${measures === Measure.LB_IN ? convertKgToLb(w).toFixed(1) : w} ${t(weightUnit)}`;
  }, [pudo, deliveryLimitations?.maxWeight, weightUnit, measures, t]);
  const addressShort = useMemo(
    () => decodeHtmlEntities(getAddressFormatted(pudo)),
    [pudo]
  );
  const distance = useMemo(
    () => `${pudo.distance.distance} ${t(pudo.distance.unit_of_measurement)}`,
    [pudo, t]
  );

  const pudoGeocode = useMemo(() => pudo.geocode, [pudo.geocode]);

  const handleNavigateClick = () => {
    window.open(constructGoogleMapsUrl(pudoGeocode), '_blank');
  };

  useEffect(() => {
    if (openSidebarWithDetails && !isSidebarOpenedByName(sidebarId)) {
      toggleSidebarByName(sidebarId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSidebarWithDetails]);

  const phoneNumber = pudo.phone_number
    .split(', ')
    .map((phone) => phone.trim())
    .filter((phone) => phone !== '');

  return (
    <PudoListItemContainer
      $withDetails={withDetails}
      onClick={() => {
        onChoose && onChoose(pudo);
      }}>
      <PudoName>{pudoName!}</PudoName>
      {withDetails && (
        <MaxWeight>
          <Tag size='small'>{weight}</Tag>
        </MaxWeight>
      )}
      <AddressLabel>{addressShort}</AddressLabel>
      {withDetails && (
        <DistanceLabel>
          <NavigationIcon />
          {distance}
        </DistanceLabel>
      )}
      {todayWorkingTime && <TimeLabel>{workingHoursShort}</TimeLabel>}
      {withDetails && (
        <MoreDetails>
          <Button
            fullWidth={false}
            contentStyle='thin'
            appearance={isMeest(client) ? 'link' : 'text-danger'}
            size='mini'
            onClick={(event) => {
              event.stopPropagation();
              onMoreDetails && onMoreDetails(pudo);
              openSidebarWithDetails && toggleSidebarByName(sidebarId);
            }}>
            {t('more_details')}
          </Button>
        </MoreDetails>
      )}
      {withDetails && (
        <Sidebar
          name={sidebarId}
          withoutHeader>
          <PudoDetailsContainer
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <PudoDetailsContent>
              <Button
                fullWidth={false}
                icon={{ glyph: 'chevron-left' }}
                $pl='0'
                $mb='3.2rem'
                size='large'
                appearance='text'
                onClick={(e) => {
                  e.stopPropagation();
                  onBackToList && onBackToList(undefined);
                  toggleSidebarByName(sidebarId);
                }}>
                {t('back_to_list')}
              </Button>
              <NameAndWeightBlock>
                <PudoNameDetailed>{pudoName}</PudoNameDetailed>
                <Tag size='small'>{weight}</Tag>
              </NameAndWeightBlock>
              {todayWorkingTime && (
                <TimeLabelDetailed>{workingHoursShort}</TimeLabelDetailed>
              )}
              <AddressAndDistancBlock>
                <AddressLabelDetailed>{addressShort}</AddressLabelDetailed>
                <DistanceDetailed>
                  <NavigationIcon />
                  {distance}
                </DistanceDetailed>
              </AddressAndDistancBlock>
              <SeparatorLine />
              {parsedWorkingTime.length > 0 && (
                <>
                  <LabelDetailed>{t('working_hours')}</LabelDetailed>
                  <WorkingHoursBlock>
                    {parsedWorkingTime.map((workingTime) => {
                      const key = workingTime.daysRange + workingTime.timeRange;
                      const InfoComponent = workingTime.isClosed
                        ? InfoGrayDetailed
                        : InfoDetailed;

                      return (
                        <Fragment key={key}>
                          <InfoComponent>
                            {workingTime.daysRange}:{' '}
                          </InfoComponent>
                          <InfoComponent>{workingTime.timeRange}</InfoComponent>
                        </Fragment>
                      );
                    })}
                  </WorkingHoursBlock>
                </>
              )}
              <LabelDetailed>{t('payment_options')}</LabelDetailed>
              <PaymentBlock>
                <InfoDetailed>
                  {t('card')}, {t('cash')}, {t('online')}
                </InfoDetailed>
              </PaymentBlock>
              {phoneNumber.length > 0 && (
                <>
                  <LabelDetailed>{t('customer_support')}</LabelDetailed>
                  <SupportBlock>
                    {phoneNumber.map((phone) =>
                      isMobile ? (
                        <PhoneLink
                          key={phone}
                          href={`tel:${phone}`}>
                          <span>{phone}</span>
                        </PhoneLink>
                      ) : (
                        <CopyPhone
                          key={phone}
                          onClick={() => {
                            copyToClipboard({
                              text: phone,
                              successMessage: t('contactSupport_phoneCopied'),
                            });
                          }}>
                          <span>{phone}</span>
                          <CopyIcon />
                        </CopyPhone>
                      )
                    )}
                  </SupportBlock>
                </>
              )}
            </PudoDetailsContent>
            <ButtonsBlock>
              <Button
                type='button'
                onClick={(e) => {
                  e.stopPropagation();
                  onChoose && onChoose(pudo);
                }}>
                {t('choose')}
              </Button>
              {shouldShowNavigateTo && (
                <Button
                  appearance='secondary'
                  onClick={handleNavigateClick}>
                  {t('navigate_to')}
                </Button>
              )}
            </ButtonsBlock>
          </PudoDetailsContainer>
        </Sidebar>
      )}
    </PudoListItemContainer>
  );
};

export default observer(PudoListItem);
