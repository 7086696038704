import styled from 'styled-components';
import { Container } from '../common/container.styles';

interface ContainerDesktopProps {
  $index?: number;
  $currentSlide?: number;
  $backgroundImage: string;
}

export const ContainerBanner = styled(Container)`
  padding: 0;

  @media screen and (min-width: 650px) {
    padding: 0 24px;
  }
`;

export const BannerLink = styled.a`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CardDesktop = styled.div<ContainerDesktopProps>`
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  background-image: ${(props) => `url(${props.$backgroundImage})`};
  background-size: cover;
  border-radius: 30px;
  padding: 50px 32px 48px 60px;
  cursor: pointer;

  @media (max-width: 651px) {
    display: none;
  }
`;

interface ContainerMobileProps {
  $index: number;
  $currentSlide: number;
}

export const CardMobile = styled.div<ContainerMobileProps>`
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  padding: 24px 16px 40px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 364px;
`;

interface IColorText {
  $color?: string;
}

export const Header = styled.h3<IColorText>`
  font-size: 40px;
  color: ${({ $color, theme }) =>
    $color === 'black'
      ? theme.colors.baseColors.grey150
      : theme.colors.namedColors.white};
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1px;

  @media (max-width: 650px) {
    color: ${({ theme }) => theme.colors.baseColors.grey150};
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.5px;
  }
`;

export const Description = styled.p<IColorText>`
  font-size: 16px;
  margin-top: 12px;
  line-height: 22px;
  color: ${({ color, theme }) =>
    color === 'black'
      ? theme.colors.baseColors.grey100
      : theme.colors.namedColors.white};

  letter-spacing: 0.5px;

  @media (max-width: 650px) {
    color: ${({ theme }) => theme.colors.baseColors.grey100};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
  }
`;

export const SliderPagination = styled.div`
  position: absolute;
  top: 50px;
  right: 32px;
  bottom: 56px;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: end;
`;

export const ButtonWithChevron = styled.button`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 8px;
  min-width: 36px;
  max-height: 36px;
  background-color: #f1f2f4;
  color: ${({ theme }) => theme.colors.baseColors.grey100};
  font-weight: 500;
  transition-property: border-color, background-color, color, outline-color;
  transition-duration: 0.2s;
  border: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
    color: ${({ theme }) => theme.colors.namedColors.white};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.namedColors.primary};
    color: ${({ theme }) => theme.colors.namedColors.white};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;

  ${ButtonWithChevron} {
    &:last-child {
      background-color: ${({ theme }) => theme.colors.baseColors.grey150};
      color: ${({ theme }) => theme.colors.namedColors.white};
      transform: rotate(180deg);

      &:hover {
        background-color: ${({ theme }) => theme.colors.namedColors.primary};
        color: ${({ theme }) => theme.colors.namedColors.white};
      }
    }
  }
`;

export const Counter = styled.div`
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.baseColors.grey5};
  opacity: 0.8;
  font-size: 16px;
  max-width: 71px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.baseColors.grey150};

  span {
    padding: 2px 8px;
    color: #121212;

    &:last-child {
      color: ${({ theme }) => theme.colors.baseColors.grey100};
    }
  }
`;

interface PaginationContainerProps {
  $backgroundImage: string;
}

export const PaginationContainer = styled.div<PaginationContainerProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 228px;
  border-radius: 20px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  background-image: ${(props) => `url(${props.$backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 651px) {
    display: none;
  }
`;

export const BulletContainer = styled.div`
  display: flex;
  gap: 8px;
  max-width: 90px;
  padding: 6px 4px;
  background: rgba(245, 246, 247, 0.8);
  border-radius: 6px;
`;

interface Props {
  $isActive: boolean;
}

export const Bullet = styled.span<Props>`
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.namedColors.primary : '#f1f2f4'};
`;

export const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  @media (min-width: 650px) {
    margin: 40px auto 80px;
    gap: 1.2rem;
  }
`;

interface IProps {
  $currentSlide: number;
}

export const ContainerDesktop = styled.div<IProps>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  gap: 10px;

  translate: ${(props) =>
    `calc(${-100 * props.$currentSlide}% - 1.2rem * ${props.$currentSlide})`};
  transition: translate 1s ease-in-out;
`;

export const ContainerMobile = styled.div<IProps>`
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 700ms ease-in-out;
  translate: ${(props) => `${-100 * props.$currentSlide}%`};
`;
