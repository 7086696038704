import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { StoreContext, store } from './app/stores/store';
import { router } from './Router';
import 'react-loading-skeleton/dist/skeleton.css';
import i18n from './i18n';
import { theme } from './app/styles/themes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StoreContext.Provider value={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </I18nextProvider>
  </StoreContext.Provider>
);
