import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import {
  ContactContainer,
  ServicesContent,
  ServicesUserAbout,
  ServicesUserAvatar,
  ServicesUserCopy,
  ServicesUserInfoContainer,
  ServicesUserName,
  ServicesUserNameContainer,
} from './services-user-info.styles';
import { ReactComponent as CopyIcon } from '../../../assets/copy-icon.svg';
import {
  FontSizes,
  TextServicesContact,
} from '../../../common/typography/typography.styles';
import { copyToClipboard } from '../../../utils/generic/generic.utils';
import { Address } from '../../../models/user';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import TruncatedTextWithTooltip from '../../../common/truncated-text-with-tooltip/truncated-text-with-tooltip.component';

const ServicesUserInfo = () => {
  const {
    userStore: { user, getInitials, getUserName },
  } = useStore();

  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const handleCopy = () => {
    const userInfo: string[] = [];

    userInfo.push(getUserName(true));

    userInfo.push(
      user?.phone?.phone_number ? `+${user.phone?.phone_number}` : ''
    );

    const getFullAddress = (address: Address): string => {
      const addressParts: string[] = [
        address.region,
        address.city,
        address.post_code,
        address.street,
        address.building,
        address.section ?? '',
        address.apartment,
      ];
      return addressParts.filter((part) => part !== '').join(', ');
    };

    if (user?.address) {
      const fullAddress = getFullAddress(user.address);
      userInfo.push(fullAddress);
    }

    const staticLink = 'https://vaua1.test-app.link/1ADeT5OvhIb';
    userInfo.push(staticLink);

    const text = userInfo.filter((i) => i !== '').join('\n');

    copyToClipboard({
      text,
      successMessage: t('user_info_copied.'),
    });
  };

  if (!user) return null;

  return (
    <ServicesUserInfoContainer>
      <ServicesUserAbout>
        <ServicesUserAvatar>{getInitials()}</ServicesUserAvatar>
        <ServicesContent onClick={isMobile ? handleCopy : undefined}>
          <ServicesUserNameContainer>
            {getUserName() !== '' && (
              <ServicesUserName>{getUserName(true)}</ServicesUserName>
            )}
            <ServicesUserCopy onClick={isMobile ? undefined : handleCopy}>
              <CopyIcon />
            </ServicesUserCopy>
          </ServicesUserNameContainer>
          <ContactContainer>
            {user?.email && (
              <TruncatedTextWithTooltip fontSize={FontSizes.small}>
                {user?.email}
              </TruncatedTextWithTooltip>
            )}
            {user?.phone && (
              <TextServicesContact>
                +{user?.phone.phone_number}
              </TextServicesContact>
            )}
          </ContactContainer>
        </ServicesContent>
      </ServicesUserAbout>
    </ServicesUserInfoContainer>
  );
};

export default observer(ServicesUserInfo);
