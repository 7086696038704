import { useTranslation } from 'react-i18next';
import { Recipient, Sender } from '../../../../models/parcelDeliveryMiles';
import {
  TextLabel,
  FontSizes,
  TextBody,
} from '../../../../common/typography/typography.styles';
import { copyToClipboard } from '../../../../utils/generic/generic.utils';
import {
  DeliveryDetailsContainer,
  DeliveryDetailsHeader,
  PostName,
  PostImage,
  TrackingNumber,
  Value,
  Row,
  Date,
  DeliveryDetailsFlexContainer,
} from '../details-sidebar.styles';
import { getSubjectDetailsSummary } from '../../../../utils/parcel-creation/parcel-creation-utils';
import { ReactComponent as CopyIcon } from '../../../../assets/copy-icon.svg';

const successMessage = 'Parcel number is copied.';

interface Props {
  subject: Sender | Recipient;
  subjectAddress: string;
  date: string;
  trackingNumber?: string;
  title: string;
}

const DeliveryDetail = ({
  subject,
  subjectAddress,
  date,
  trackingNumber,
  title,
}: Props) => {
  const { t } = useTranslation();

  const {
    carrier: { logo, pudo_info: pudoInfo, branch_uuid: branchUUID } = {},
    address: {
      city,
      building,
      street,
      apartment,
      section,
      buzz_code: buzzCode,
    } = {},
  } = subject;

  const carrier = branchUUID
    ? pudoInfo?.name
    : getSubjectDetailsSummary({
        building,
        street,
        apartment,
        section,
        buzzCode,
        city,
      });
  const fullAddress = pudoInfo ? pudoInfo.full_address : null;
  const pudoWorkingTime = pudoInfo?.working_time_partly_separated?.[0]
    ?.operating_hours?.open_time
    ? `${pudoInfo.working_time_partly_separated[0].operating_hours.open_time} - ${pudoInfo.working_time_partly_separated[0].operating_hours.close_time}`
    : null;

  const hasAddressInfo = !!carrier || !!fullAddress;

  return (
    <Row>
      <TextBody>{title}</TextBody>
      <DeliveryDetailsContainer>
        <DeliveryDetailsHeader $gap='8px'>
          <Value>{subjectAddress}</Value>
          <Date>{date}</Date>
        </DeliveryDetailsHeader>
        <DeliveryDetailsFlexContainer
          $gap='12px'
          $align='center'
          $mb='0.4rem'>
          <PostName>{branchUUID ? carrier : t('address_delivery')}</PostName>
          <PostImage
            src={logo}
            alt=''
          />
        </DeliveryDetailsFlexContainer>
        {!hasAddressInfo && <Value>{t('no_info')}</Value>}
        {pudoWorkingTime && hasAddressInfo && (
          <TextLabel
            as='p'
            $fontSize={FontSizes.small}>
            {pudoWorkingTime}
          </TextLabel>
        )}
        {hasAddressInfo && (
          <TextBody color='#2c363f'>
            {branchUUID ? fullAddress : carrier}
          </TextBody>
        )}
        {trackingNumber && (
          <TrackingNumber
            $mt='1.2rem'
            onClick={() =>
              copyToClipboard({
                text: trackingNumber,
                successMessage,
              })
            }>
            <TextBody
              as='span'
              $color='currentColor'>
              {trackingNumber}
            </TextBody>
            <CopyIcon />
          </TrackingNumber>
        )}
      </DeliveryDetailsContainer>
    </Row>
  );
};

export default DeliveryDetail;
