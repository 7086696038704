import styled from 'styled-components';

export const SignInContainer = styled.div``;

export const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  margin-top: 2.4rem;
`;

export const GuestContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  background-color: ${({ theme }) => theme.colors.baseColors.blue0};
  border-radius: 1.2rem;
  margin-top: 3.2rem;
  padding: 1.6rem;
  @media (min-width: 650px) {
    gap: 1.2rem;
    padding: 2.4rem;
  }
`;
