import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  FontSizes,
  HeaderMain,
  Link,
  TextBody,
} from '../../../common/typography/typography.styles';
import FormFieldText from '../../../common/form-field-text/form-field-text.component';
import Button from '../../../common/button/button.component';
import FormFieldPassword from '../../../common/form-field-password/form-field-password.component';
import {
  GuestContainer,
  SignInContainer,
  SocialButtons,
} from './sign-in.styles.component';
import Separator from '../../../common/separator/separator.component';
import { useStore } from '../../../stores/store';
import SocialButton from '../../../common/social-button/social-button.component';
import {
  EMAIL_REGEX,
  VALIDATION_RULE_IS_NOT_CYRILLIC,
} from '../../../constants/validation';
import { exhaustiveGuard } from '../../../utils/generic/generic.utils';
import { SocialButtonAppearance } from '../../../common/button/types';
import { useStartParcelCreation } from '../../../hooks/use-start-parcel-creation.hook';
import { AuthorizationForm } from '../auth-tabbed-container/auth-tabbed-container.styles';
import { SearchParam } from '../../../constants/route';
import { client } from '../../../config';
import { createValidationRule } from '../../../utils/forms/forms.utils';
import { isMeest } from '../../../utils/client/client.utils';

interface IFormValues {
  email: string;
  password: string;
}

const initialValues: IFormValues = {
  email: '',
  password: '',
};

const SignIn = () => {
  const {
    userStore: {
      emailSignInProgress,
      googleSignInProgress,
      appleSignInProgress,
      googleLogin,
      appleLogin,
      emailLogin,
    },
    commonStore: { setIsGuestParcelCreation },
  } = useStore();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  const handleStartParcelCreation = useStartParcelCreation();

  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
      .matches(EMAIL_REGEX, t('wrong_email_format'))
      .required(t('this_field_cannot_be_empty')),

    password: createValidationRule(VALIDATION_RULE_IS_NOT_CYRILLIC)
      .min(8, '')
      .required(t('this_field_cannot_be_empty')),
  });

  const handleContinueAsGuestButtonClick = async () => {
    setIsGuestParcelCreation(true);
    const url = searchParams.get(SearchParam.REDIRECT);
    handleStartParcelCreation(url || undefined);
  };

  const handleFormSubmit = async (
    formValues: IFormValues,
    { setFieldError, resetForm }: FormikHelpers<IFormValues>
  ) => {
    const { success, loginOrPasswordIsIncorrect } = await emailLogin({
      login: formValues.email,
      password: formValues.password,
    });

    if (!success && loginOrPasswordIsIncorrect) {
      setFieldError('password', t('login_or_password_is_incorrect'));
      return;
    }

    resetForm();
  };

  const handleSocialButtonClick = async (
    socialType: SocialButtonAppearance
  ) => {
    switch (socialType) {
      case 'apple':
        await appleLogin();
        break;
      case 'google':
        await googleLogin();
        break;
      default:
        exhaustiveGuard(socialType);
    }
  };

  return (
    <SignInContainer>
      <HeaderMain>{t('lets_let_you_in')}</HeaderMain>

      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleFormSubmit(values, helpers)}>
        {({ handleSubmit, isValid, dirty }) => (
          <AuthorizationForm
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}>
            <FormFieldText
              name='email'
              placeholder={t('enter_your_email')}
              label={t('email')}
              inputMode='email'
            />

            <FormFieldPassword
              name='password'
              forgotPassword
              placeholder={t('enter_your_password')}
              label={t('password')}
            />

            <Button
              size='large'
              isLoading={emailSignInProgress}
              disabled={!dirty || !isValid}
              type='submit'>
              {t('sign_in')}
            </Button>
          </AuthorizationForm>
        )}
      </Formik>

      <Separator content={t('or')} />

      <SocialButtons>
        <SocialButton
          isLoading={googleSignInProgress}
          appearance='google'
          onClick={() => handleSocialButtonClick('google')}
        />
        <SocialButton
          isLoading={appleSignInProgress}
          appearance='apple'
          onClick={() => handleSocialButtonClick('apple')}
        />
      </SocialButtons>
      {isMeest(client) && (
        <GuestContainer>
          <TextBody
            $color={theme.colors.baseColors.grey150}
            $textAlign='center'>
            {t('want_to_create_parcel')}
          </TextBody>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            as='button'
            type='button'
            onClick={handleContinueAsGuestButtonClick}
            $fontSize={FontSizes.medium}
            $color={theme.colors.namedColors.primary}
            $fontWeight='700'>
            {t('continue_as_guest')}
          </Link>
        </GuestContainer>
      )}
    </SignInContainer>
  );
};

export default observer(SignIn);
