import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { LinkFooter } from '../../common/typography/typography.styles';
import {
  ChangeCountrySelectTitle,
  FooterColumn,
  FooterTopContainer,
  FooterColumnTitle,
  BecomeAnAgentLink,
} from './footer.styles';
import Select, { IDropdownItem } from '../../common/select/select.component';
import { useStore } from '../../stores/store';
import { DEFAULT_COUNTRY } from '../../stores/localizationsStore';
import useNavigateNewLocale from '../../hooks/use-navigate-to-new-locale.hook';

const FooterTop = () => {
  const {
    localizationsStore: { availableCountries, selectedCountry },
    marketingStore: { footerLinks },
    widgetCalculatorStore: { shipmentCountries, resetStore },
  } = useStore();

  const { t } = useTranslation();
  const navigateToNewLocale = useNavigateNewLocale();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedCountry]);

  if (!availableCountries || !footerLinks) return;

  const mapCountries = availableCountries.map((country) => ({
    value: country.country_code,
    label: `${country.flag_emoji_2} ${country.countryDetails.localized_name}`,
  }));

  const country = selectedCountry || DEFAULT_COUNTRY;

  const handleChangeCountry = async (item: IDropdownItem<string>) => {
    const newCountry =
      shipmentCountries.find((c) => c.countryCode === item.value) ?? null;

    resetStore(newCountry);
    navigateToNewLocale({
      countryCode: item.value.toLocaleLowerCase(),
      withPageReload: true,
    });
  };

  return (
    <FooterTopContainer>
      <FooterColumn>
        <FooterColumnTitle>{t('footer_our_company')}</FooterColumnTitle>
        <LinkFooter href={footerLinks.aboutUs}>
          {t('footer_about_us')}
        </LinkFooter>
        <LinkFooter
          href={
            selectedCountry?.links.privacy_policy ?? footerLinks.privacyPolicy
          }>
          {t('footer_privacy_policy')}
        </LinkFooter>
        <LinkFooter
          href={selectedCountry?.links.terms_of_use ?? footerLinks.termOfUse}>
          {t('footer_terms_of_use')}
        </LinkFooter>
        <LinkFooter
          href={
            selectedCountry?.links.delivery_rules ?? footerLinks.deliveryRules
          }>
          {t('footer_delivery_rules')}
        </LinkFooter>
      </FooterColumn>

      <FooterColumn>
        <FooterColumnTitle>{t('footer_services')}</FooterColumnTitle>
        <LinkFooter href={footerLinks.internationalShipping}>
          {t('footer_international_shipping')}
        </LinkFooter>
        <LinkFooter href={footerLinks.allServices}>
          {t('footer_all_services')}
        </LinkFooter>
      </FooterColumn>

      <FooterColumn>
        <FooterColumnTitle>{t('footer_contact_us')}</FooterColumnTitle>
        <LinkFooter href={footerLinks.contactCenter}>
          {t('footer_contact_center')}
        </LinkFooter>
        <LinkFooter href={footerLinks.customerSupport}>
          {t('footer_customer_support')}
        </LinkFooter>
        <LinkFooter href={footerLinks.faq}>{t('footer_faq')}</LinkFooter>
      </FooterColumn>

      <FooterColumn>
        <FooterColumnTitle>{t('footer_location')}</FooterColumnTitle>
        <Select
          shouldMatchWidthOfInput
          title={
            <ChangeCountrySelectTitle>
              {t('footer_location_text')}
            </ChangeCountrySelectTitle>
          }
          width='100%'
          items={mapCountries}
          dropdownPosition='bottom-center'
          value={{
            value: country.country_code,
            label: `${country.flag_emoji_2} ${t(country.countryDetails.localized_name)}`,
          }}
          onChange={handleChangeCountry}
        />
        <BecomeAnAgentLink
          target='_blank'
          rel='noopener noreferrer'
          href={footerLinks.becomeAgentLink}>
          {t('become_an_agent_button')}
        </BecomeAnAgentLink>
      </FooterColumn>
    </FooterTopContainer>
  );
};

export default observer(FooterTop);
