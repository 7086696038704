import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import {
  Header,
  Info,
  Container,
  Props,
  PropValue,
  RemoveButton,
} from './promocode-summary-form.styles';

interface IPromocodeItemProps {
  code: string;
  savings: string;
  onRemove: () => void;
}

const PromocodeItem = ({ code, savings, onRemove }: IPromocodeItemProps) => (
  <Container>
    <Info>
      <Header>{code}</Header>
      <Props>
        <PropValue>{savings}</PropValue>
      </Props>
    </Info>
    <RemoveButton onClick={onRemove}>
      <DeleteIcon />
    </RemoveButton>
  </Container>
);

export default PromocodeItem;
