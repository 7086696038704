import React from 'react';
import { MarginContainer } from '../base-margin/base-margin.component';
import HintTooltip from '../hint-tooltip/hint-tooltip.component';
import { TextLabel } from '../typography/typography.styles';
import { LabelWrapper, Note } from './input.styles';
import { VisuallyHidden } from '../visually-hidden/visually-hidden.styles';

interface Props {
  inputId?: string;
  children: React.ReactNode;
  isOptional?: boolean;
  optionalText?: string;
  hint?: string;
  labelEndElement?: React.ReactNode;
  isHidden?: boolean;
  labelColor?: string;
}

const FieldLabel = ({
  inputId,
  children,
  isOptional,
  optionalText,
  hint,
  labelEndElement,
  isHidden,
  labelColor,
}: Props) => {
  const labelElement = (
    <LabelWrapper>
      <TextLabel
        $color={labelColor}
        htmlFor={inputId}>
        {children}
      </TextLabel>
      {isOptional && <Note>({optionalText})</Note>}
      {hint && <HintTooltip text={hint} />}
      {labelEndElement && (
        <MarginContainer $ml='auto'>{labelEndElement}</MarginContainer>
      )}
    </LabelWrapper>
  );

  return isHidden ? (
    <VisuallyHidden>{labelElement}</VisuallyHidden>
  ) : (
    labelElement
  );
};

export default FieldLabel;
