import styled from 'styled-components';
import { FontSizes } from '../typography/typography.styles';

export const SeparatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.4rem;
  gap: 9px;

  font-size: ${FontSizes.normal};
  color: ${({ theme }) => theme.colors.baseColors.grey80};
  font-weight: 400;
  letter-spacing: 0.05rem;
  line-height: 2rem;
  word-break: keep-all;
`;

interface Props {
  $width?: string;
}

export const SeparatorLine = styled.span<Props>`
  display: inline-block;
  width: ${({ $width = '35%' }) => $width};
  height: 0.1rem;
  background-color: ${({ theme }) => theme.colors.placeholderText.color};
`;
