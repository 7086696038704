import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  getPlaceDetails,
  getPredictions,
  getCityFromPlaceDetails,
  getCountryFromPlaceDetails,
  getStreetFromPlaceDetails,
  getZipCodeFromPlaceDetails,
  getBuildingFromPlaceDetails,
  getRegionFromPlaceDetails,
  getApartmentFromPlaceDetails,
  tryGetBuildingFromPrediction,
} from '../utils/google-places/google-places.utils';
import { store } from './store';
import { createValidationRule } from '../utils/forms/forms.utils';
import { VALIDATION_RULE_IS_NOT_CYRILLIC } from '../constants/validation';

export const PLACES_API_MINIMAL_REQUEST_LENGTH = 4;

export interface PlacesSearchProps {
  address: string;
  city: string;
  country: string;
  street: string;
  zipCode: string;
  building: string;
  region: string;
  apartment: string;
  section: string;
}

export default class PlacesSearchStore {
  placeInfo: google.maps.places.PlaceResult | null = null;

  prediction: google.maps.places.AutocompletePrediction | null = null;

  address: string = '';

  country: string = '';

  region: string = '';

  city: string = '';

  zipCode: string = '';

  street: string = '';

  building: string = '';

  section: string = '';

  apartment: string = '';

  buzzCode: string = '';

  note: string = '';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'PlacesSearchStore',
      properties: [
        'placeInfo',
        'prediction',
        'address',
        'country',
        'region',
        'city',
        'zipCode',
        'street',
        'building',
        'section',
        'apartment',
        'buzzCode',
        'note',
      ],
      storage: window.sessionStorage,
    });
  }

  getAutocompletePredictions = async ({
    value,
    predictionTypes,
    countryConstraint,
    cityName,
  }: {
    value: string;
    predictionTypes?: string[];
    countryConstraint?: string;
    cityName?: string;
  }) => {
    if (value.length < PLACES_API_MINIMAL_REQUEST_LENGTH) return [];

    // do not allow non-latin letters when searching for predictions
    const nonLatinLettersFound = !createValidationRule(
      VALIDATION_RULE_IS_NOT_CYRILLIC
    ).isValidSync(value);
    if (nonLatinLettersFound) return [];

    try {
      return await getPredictions(
        value,
        predictionTypes,
        countryConstraint,
        cityName
      );
    } catch (e) {
      console.error(e);
      if (typeof e === 'string') {
        store.commonStore.toastError(e);
      }
      return [];
    }
  };

  getPlaceDetails = async (
    prediction: google.maps.places.AutocompletePrediction,
    callback?: (props: PlacesSearchProps) => void
  ) => {
    try {
      const info = await getPlaceDetails(prediction.place_id);
      runInAction(async () => {
        this.prediction = prediction;
        this.setPlaceDetails(info, callback);
      });
      return info;
    } catch (e) {
      console.error(e);
      if (typeof e === 'string') {
        store.commonStore.toastError(e);
      }
      return null;
    }
  };

  setUserPlaceDetails = () => {
    runInAction(() => {
      this.address = store.userStore.getAddress() ?? '';

      this.city = store.userStore.user?.address?.city ?? '';
      this.country = store.userStore.user?.country ?? '';
      this.street = store.userStore.user?.address?.street ?? '';
      this.zipCode = store.userStore.user?.address?.post_code ?? '';
      this.building = store.userStore.user?.address?.building ?? '';
      this.region = store.userStore.user?.address?.region ?? '';
      this.apartment = store.userStore.user?.address?.apartment ?? '';
      this.section = store.userStore.user?.address?.section ?? '';
      this.buzzCode = store.userStore.user?.address?.buzz_code ?? '';
      this.note = store.userStore.user?.address?.note ?? '';
    });
  };

  setPlaceDetails = async (
    placeInfo: google.maps.places.PlaceResult | null,
    callback?: (props: PlacesSearchProps) => void
  ) => {
    this.placeInfo = placeInfo;

    if (placeInfo) {
      this.address = placeInfo.formatted_address ?? '';
      this.city = getCityFromPlaceDetails(placeInfo.address_components);
      this.country = getCountryFromPlaceDetails(placeInfo.address_components);
      this.street = getStreetFromPlaceDetails(placeInfo.address_components);
      this.zipCode = getZipCodeFromPlaceDetails(placeInfo.address_components);
      this.building = tryGetBuildingFromPrediction(
        this.prediction,
        getBuildingFromPlaceDetails(placeInfo.address_components)
      );
      this.region = getRegionFromPlaceDetails(placeInfo.address_components);
      this.apartment = getApartmentFromPlaceDetails(
        placeInfo.address_components
      );
      this.section = '';
    } else {
      this.address = '';
      this.country = '';
      this.region = '';
      this.city = '';
      this.zipCode = '';
      this.street = '';
      this.building = '';
      this.section = '';
      this.apartment = '';
      this.buzzCode = '';
      this.note = '';
    }
    callback &&
      callback({
        address: this.address,
        city: this.city,
        country: this.country,
        street: this.street,
        zipCode: this.zipCode,
        building: this.building,
        region: this.region,
        apartment: this.apartment,
        section: this.section,
      });
  };
}
