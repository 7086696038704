import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { FlexContainer } from '../../../common/flex-container/flex-container.styles';

interface SliderItemContainerProps {
  $isCardsEnough?: boolean;
}

export const PartnersCardContainer = styled.div<
  Partial<SliderItemContainerProps>
>`
  margin-top: 3.2rem;
  display: flex;
  align-items: center;
  gap: 0;
  flex-wrap: nowrap;

  @media screen and (min-width: 650px) {
    width: ${({ $isCardsEnough }) => !$isCardsEnough && '100%'};
    gap: 1.2rem;
    justify-content: center;
  }
`;

export const PartnersCard = styled.div`
  width: 10.25rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 6rem;
  padding: 1rem 1.4rem;
  background-color: ${({ theme }) => theme.colors.namedColors.white};
  border-radius: 1.2rem;
  min-width: 100%;

  @media screen and (min-width: 650px) {
    width: 100%;
    height: 8rem;
  }

  @media screen and (min-width: 779px) {
    height: 10rem;
  }
`;

export const PartnersCardImage = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  justify-content: center;

  & svg {
    transform: scale(0.5);
    overflow: visible;
  }

  @media screen and (min-width: 375px) {
    & svg {
      transform: scale(0.4);
    }
  }

  @media screen and (min-width: 561px) {
    & svg {
      transform: scale(0.6);
    }
  }

  @media screen and (min-width: 779px) {
    & svg {
      transform: scale(0.7);
    }
  }
`;

export const SwiperSlideCustom = styled(SwiperSlide)``;
