import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { ModalProvider as StyledModalProvider } from 'styled-react-modal';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { AppContainer } from '../../../App.styles';
import { GlobalStyles } from '../../../global.styles';
import { ModalBlurredBackground } from '../../common/blurred-background/blurred-background.styles';
import { FooterProvider } from '../../features/footer/helpers/FooterContext';
import FooterVisibilityHandler from '../../features/footer/helpers/FooterVisibilityHandler';
import Header from '../../features/header/header.component';
import Footer from '../../features/footer/footer.component';
import ToastContainer from '../../common/toasts/toast.component';
import { useInitApp } from '../../hooks/use-init-app.hook';
import '../../../fonts.css';
import { useIsMobile } from '../../hooks/use-is-mobile.hook';
import LeftSidebar from '../../common/sidebar-left/left-sidebar.component';
import ServicesMenu from '../../features/services/services-menu/services-menu.component';
import { useStore } from '../../stores/store';
import { AppRoute } from '../../constants/route';
import { useGuestShippingModeReset } from '../../hooks/use-guest-shipping-reset.hook';
import { useCountryAndLocalizationFromUrl } from '../../hooks/use-country-and-localization-from-url';
import { WidgetCalculatorFrameWrapper } from '../../features/main/widget-calculator/widget-calculator.styles';
import PageLoader from '../../common/page-loader/page-loader.component';
import ErrorBoundary from '../../common/error-boundary/error-boundary.component';
import ServerError from '../../routes/server-error/server-error.component';
import agent from '../../api/agent';
import HeaderSimple from '../../features/header/header-simple';
import { ModalProvider, ModalRoot } from '../../common/modal';
import RemoveTrailingSlash from '../../common/remove-trailing-slash/remove-trailing-slash.component';
import LiveChat from '../../features/main/live-chat/live-chat.component';
import { generateLocaleRoute } from '../../utils/uri/uri.utils';

const FallbackErrorBoundary = () => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <>
      <HeaderSimple onClick={resetBoundary} /> <ServerError />
    </>
  );
};

const footerElements = (
  <>
    <FooterVisibilityHandler />
    <Footer />
  </>
);

const allowedRoutes = [
  AppRoute.PARCEL_LIST,
  AppRoute.PARCEL_CREATE,
  AppRoute.DRAFTS,
  AppRoute.ARCHIVE,
  AppRoute.LEAVE_FEEDBACK,
];

const AppLayout = () => {
  useCountryAndLocalizationFromUrl();
  useGuestShippingModeReset();

  const { showLoadingSpinner } = useInitApp();
  const {
    localizationsStore: { languageCode },
    navStore: { openedRightSidebars, isLeftSidebarOpened },
  } = useStore();
  const isMobile = useIsMobile();
  const location = useLocation();
  const isAuthPage = location.pathname.includes(AppRoute.AUTH);
  const isRootPage = location.pathname.endsWith(AppRoute.ROOT);

  const isCalcPage = location.pathname.endsWith(AppRoute.CALCULATOR);

  const isExactPortalPage = location.pathname === generateLocaleRoute();

  const isSidebarClosed =
    openedRightSidebars.length === 0 && !isLeftSidebarOpened;

  const isLiveChatVisible =
    (isExactPortalPage ||
      allowedRoutes.some((route) => location.pathname.includes(route))) &&
    isSidebarClosed;

  useEffect(() => {
    document.documentElement.lang = languageCode;
  }, [languageCode]);

  if (isCalcPage)
    return (
      <WidgetCalculatorFrameWrapper>
        <PageLoader isVisible={showLoadingSpinner} />
        <GlobalStyles />
        <Outlet />
      </WidgetCalculatorFrameWrapper>
    );

  return (
    <RemoveTrailingSlash>
      <GlobalStyles />
      <PageLoader isVisible={showLoadingSpinner} />
      <ErrorBoundary
        onError={(error, info) => {
          agent.ErrorTracking.trackError(error.message, info.componentStack);
        }}
        FallbackComponent={FallbackErrorBoundary}>
        <StyledModalProvider backgroundComponent={ModalBlurredBackground}>
          <ModalProvider>
            <FooterProvider>
              <AppContainer>
                <ToastContainer />
                <Header />
                <Outlet />
                {/* only on /auth and /root page footer should be visible without scrolling */}
                {(isAuthPage || isRootPage) && footerElements}
              </AppContainer>
              {!isAuthPage && !isRootPage && footerElements}
            </FooterProvider>
            {isMobile && (
              <LeftSidebar withBlurredBackground>
                <ServicesMenu />
              </LeftSidebar>
            )}
            <ScrollRestoration />
            {/* <OnboardingManual /> */}
            {isLiveChatVisible && <LiveChat />}
            <ModalRoot />
          </ModalProvider>
        </StyledModalProvider>
      </ErrorBoundary>
    </RemoveTrailingSlash>
  );
};

export default observer(AppLayout);
