import styled from 'styled-components';
import { BottomSidebarContainer } from '../../../common/sidebar-bottom/bottom-sidebar.styles';
import { ScrollableContent } from '../../../common/form-field-phone/phone-code-dropdown/phone-code-dropdown.styles';

export const Container = styled.div`
  ${BottomSidebarContainer} {
    background-color: ${({ theme }) => theme.colors.baseColors.blue0};
  }

  ${ScrollableContent} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.baseColors.blue0};
    border-radius: 0;
    margin: 0;
    gap: 0;
  }
`;
