import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../stores/store';
import ServicesMenuItem from '../../services/services-menu-item/services-menu-item.component';
import ServicesUserInfo from '../../services/services-user-info/services-user-info.component';
import { headerUserBottomSidebar } from '../config';
import { useIsMobile } from '../../../hooks/use-is-mobile.hook';
import {
  HeaderDivider,
  HeaderMenuItems,
  HeaderMenuItemsContainer,
  ServicesUserInfoWrapper,
} from './header-user.styles';
import {
  ServicesMenuItemId,
  ServicesSubMenuItemId,
  accountCardMenuItems,
} from '../../services/services-menu-item/config';
import { generateMyRoute } from '../../../utils/uri/uri.utils';

const NavUserMenu = () => {
  const {
    userStore: { logout },
    navStore: {
      setSelectedMenuSections,
      setSelectedService,
      isSidebarOpenedByName,
      toggleSidebarByName,
      isNavbarPopupOpened,
      toggleLeftSidebar,
      closeNavbarPopup,
    },
    parcelCreationStore: { isParcelCreationInProgress },
    feedbackStore: { isFeedbackInProgress },
  } = useStore();

  const location = useLocation();
  const isMobile = useIsMobile();

  const { t } = useTranslation();

  const onMenuItemClicked = (
    menuIndex: ServicesMenuItemId,
    subMenuId: ServicesSubMenuItemId
  ) => {
    if (isMobile) {
      toggleLeftSidebar();
    }

    if (location.pathname.startsWith(generateMyRoute())) {
      setSelectedMenuSections([menuIndex]);
    }

    setSelectedService(`${menuIndex}:${subMenuId}`);
    if (isSidebarOpenedByName(headerUserBottomSidebar)) {
      toggleSidebarByName(headerUserBottomSidebar);
    }

    if (!isMobile) {
      closeNavbarPopup();
    }
  };

  return (
    <>
      <ServicesUserInfoWrapper>
        <ServicesUserInfo />
      </ServicesUserInfoWrapper>
      <HeaderMenuItemsContainer>
        <HeaderMenuItems>
          <HeaderDivider />
          {accountCardMenuItems.map((menuItem) =>
            menuItem.items.map((subMenuItem) => (
              <ServicesMenuItem
                key={menuItem.id + subMenuItem.id}
                to={generateMyRoute(subMenuItem.route)}
                onClick={() => onMenuItemClicked(menuItem.id, subMenuItem.id)}>
                {t(subMenuItem.title)}
              </ServicesMenuItem>
            ))
          )}
        </HeaderMenuItems>
        <HeaderMenuItems>
          <HeaderDivider />
          <ServicesMenuItem
            appearance='logout'
            onClick={async () => {
              await logout({
                shouldRedirectToMainPage: true,
                omitApiLogout: false,
                interruptLogoutAfterRedirect:
                  isParcelCreationInProgress || isFeedbackInProgress,
              });
              isNavbarPopupOpened && closeNavbarPopup();
            }}>
            {t('logout')}
          </ServicesMenuItem>
        </HeaderMenuItems>
      </HeaderMenuItemsContainer>
    </>
  );
};

export default observer(NavUserMenu);
