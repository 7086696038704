import { Arrow } from './slider.styles';
import { ReactComponent as ChevronLeft } from '../../assets/chevron-left-icon.svg';

export type SliderArrowTheme = 'white' | 'grey' | 'black';

interface Props {
  direction?: 'left' | 'right';
  theme?: SliderArrowTheme;
  onClick?: () => void;
  disabled?: boolean;
}

const SliderArrow = ({
  direction = 'left',
  theme = 'grey',
  onClick,
  disabled = false,
}: Props) => (
  <Arrow
    $disabled={disabled}
    $sliderArrowTheme={theme}
    onClick={onClick}
    $direction={direction}>
    <ChevronLeft />
  </Arrow>
);

export default SliderArrow;
