import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { HTML_CLASSES } from '../constants/html-classes';
import {
  lockBodyScroll,
  unlockBodyScroll,
} from '../utils/generic/generic.utils';
import { SidebarName } from '../types/sidebar.types';

export enum AuthSessionStorageKeys {
  SignupEmailSentTimeoutValue = 'signupEmailSentTimeoutValue',
  RestorePasswordSentTimeoutValue = 'restorePasswordSentTimeoutValue',
}

export type AuthScreen =
  | 'auth-tabs'
  | 'sign-up-email-sent'
  | 'password-reset-form'
  | 'password-reset-email-sent'
  | 'add-phone-number'
  | 'confirm-phone-number'
  | 'welcome-old-account'
  | 'welcome-social-account'
  | 'data-migration-agreement';

export default class NavStore {
  openedRightSidebars: SidebarName[] = [];

  closingRightSidebars: SidebarName[] = [];

  isLeftSidebarOpened: boolean = false;

  isLeftSidebarClosing: boolean = false;

  isPudoFiltersOpened: boolean = false;

  authTabIndex: number = 0;

  selectedMenuSections: string[] = [];

  selectedService?: string;

  isNavbarPopupOpened: boolean = false;

  showSendAgain: boolean = false;

  timerEnd: number = 0;

  initialTimeout = 90;

  isServicesSidebarVisible: boolean = true;

  authCurrentScreen: AuthScreen = 'auth-tabs';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'NavStore',
      properties: ['isServicesSidebarVisible'],
      storage: window.localStorage,
    });
    makePersistable(this, {
      name: 'NavStoreSession',
      properties: [
        'openedRightSidebars',
        'selectedService',
        'authTabIndex',
        'isPudoFiltersOpened',
      ],
      storage: window.sessionStorage,
    });
  }

  setAuthCurrentScreen = (screen: AuthScreen) => {
    this.authCurrentScreen = screen;
  };

  isSidebarOpenedByName = (name: SidebarName, onlyThis?: boolean) => {
    const sidebarOpened = this.openedRightSidebars.includes(name);
    const onlyOneOpened =
      sidebarOpened && this.openedRightSidebars.length === 1;
    return onlyThis ? sidebarOpened && onlyOneOpened : sidebarOpened;
  };

  isSidebarClosingByName = (name: SidebarName) =>
    this.closingRightSidebars.includes(name);

  closeAllRightSidebars = () => {
    runInAction(() =>
      this.openedRightSidebars.forEach((s) => this.toggleSidebarByName(s))
    );
  };

  closeSidebarByName = (name: SidebarName, callback: () => void = () => {}) => {
    if (!this.openedRightSidebars.includes(name)) return;

    runInAction(() => {
      this.openedRightSidebars = this.openedRightSidebars.filter(
        (sn) => sn !== name
      );
      this.closingRightSidebars.push(name);
    });

    setTimeout(() => {
      runInAction(() => {
        this.closingRightSidebars = this.closingRightSidebars.filter(
          (sn) => sn !== name
        );
        callback();
        if (this.openedRightSidebars.length === 0) {
          unlockBodyScroll();
        }
      });
    }, 400);
  };

  showSidebarByName = (name: SidebarName) => {
    if (!this.openedRightSidebars.includes(name)) {
      // this means that requested sidebar is not opened
      runInAction(() => this.openedRightSidebars.push(name));
      if (!document.body.classList.contains(HTML_CLASSES.noScroll)) {
        lockBodyScroll();
      }
    }
  };

  toggleSidebarByName = (name: SidebarName) => {
    if (!this.openedRightSidebars.includes(name)) {
      // this means that requested sidebar is not opened
      runInAction(() => this.openedRightSidebars.push(name));
      if (!document.body.classList.contains(HTML_CLASSES.noScroll)) {
        lockBodyScroll();
      }
    } else {
      this.closeSidebarByName(name);
    }
  };

  toggleLeftSidebar = () => {
    if (this.isLeftSidebarOpened) {
      runInAction(() => {
        this.isLeftSidebarOpened = false;
        this.isLeftSidebarClosing = true;
        setTimeout(() => {
          runInAction(() => {
            this.isLeftSidebarClosing = false;
            unlockBodyScroll();
          });
        }, 400);
      });
    } else {
      runInAction(() => {
        this.isLeftSidebarOpened = true;
        lockBodyScroll();
      });
    }
  };

  setLeftSidebarOpened = (isOpened: boolean) => {
    this.isLeftSidebarOpened = isOpened;
  };

  setAuthTabIndex = (tabIndex: number) => {
    this.authTabIndex = tabIndex;
  };

  setSelectedMenuSections = (indexes: string[]) => {
    this.selectedMenuSections = indexes;
  };

  openMenuSection = (index: string) => {
    if (!this.selectedMenuSections.includes(index)) {
      this.selectedMenuSections.push(index);
    }
  };

  toggleMenuSection = (index: string) => {
    if (this.selectedMenuSections.includes(index)) {
      this.setSelectedMenuSections(
        this.selectedMenuSections.filter((i) => i !== index)
      );
    } else {
      this.setSelectedMenuSections([...this.selectedMenuSections, index]);
    }
  };

  setSelectedService = (service?: string) => {
    this.selectedService = service;
  };

  setPudoFiltersOpened = (isOpened: boolean) => {
    this.isPudoFiltersOpened = isOpened;
  };

  toggleNavbarPopupOpened = () => {
    this.isNavbarPopupOpened = !this.isNavbarPopupOpened;
  };

  closeNavbarPopup = () => {
    this.isNavbarPopupOpened = false;
  };

  setShowSendAgain = (value: boolean) => {
    this.showSendAgain = value;
  };

  setTimerEnd = (value: number) => {
    this.timerEnd = value;
  };

  setServicesSidebarVisibility = (value: boolean) => {
    this.isServicesSidebarVisible = value;
  };

  resetNavStore = () => {
    this.setSelectedService(undefined);
    this.setLeftSidebarOpened(false);
    this.setAuthTabIndex(0);
    this.setSelectedMenuSections([]);
    this.setPudoFiltersOpened(false);
    this.setShowSendAgain(false);
    this.setTimerEnd(0);
    this.setServicesSidebarVisibility(true);
    this.closeAllRightSidebars();
    this.closeNavbarPopup();
    this.setAuthCurrentScreen('auth-tabs');
  };
}
