import Button from '../../../common/button/button.component';

interface Props {
  children: React.ReactNode;
  href: string;
}

const SwitchToOldPortalButton = ({ children, href }: Props) => (
  <Button
    appearance='link'
    hideLinkUnderline
    contentStyle='thin'
    size='small'
    fullWidth
    justifyContent='space-between'
    isExternalLink
    href={href}
    icon={{ glyph: 'chevron-right', position: 'right' }}>
    {children}
  </Button>
);

export default SwitchToOldPortalButton;
