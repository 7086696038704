import React, { AnchorHTMLAttributes } from 'react';
import { ExternalLink as SExternalLink } from './link.styles';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  size?: 'small' | 'medium';
  children: React.ReactNode;
  hasUnderlineOnHover?: boolean;
  as?: keyof JSX.IntrinsicElements;
}

const ExternalLink = ({
  size = 'medium',
  children,
  hasUnderlineOnHover = false,
  ...rest
}: Props) => (
  <SExternalLink
    $size={size}
    $hasUnderlineOnHover={hasUnderlineOnHover}
    {...rest}>
    {children}
  </SExternalLink>
);

export default ExternalLink;
