import styled, { css } from 'styled-components';

export const HeaderBtnContent = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.2s;
`;

interface HeaderBtnProps {
  $isActive?: boolean;
}

export const HeaderBtn = styled.button<HeaderBtnProps>`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  transition: color 0.3s;
  color: #2c363f;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: var(--header-active-color);
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.namedColors.primary};
  }
`;
