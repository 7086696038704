import styled from 'styled-components';

export const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.4rem;
`;

export const StarButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 2.4rem 0;

  svg {
    width: 4.4rem;
    height: 4.4rem;
    color: ${({ theme }) => theme.colors.namedColors.secondary};
  }
`;
