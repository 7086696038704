import React from 'react';
import { Container, Group, Label } from './radio-group.styles';

export interface RadioProps {
  label?: string;
  direction?: 'row' | 'column';
  children: React.ReactNode;
}

const RadioGroup = ({ direction, label, children }: RadioProps) => (
  <Container>
    {label && <Label as='p'>{label}</Label>}
    <Group
      role='radiogroup'
      direction={direction}>
      {children}
    </Group>
  </Container>
);

export default RadioGroup;
