import { useId, forwardRef, Ref } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MarginProps } from '../base-margin/base-margin.component';
import { FieldContainer, InputContainer } from './input.styles';
import FieldLabel from './field-label.component';
import Input from './input.component';
import InputMessage from '../input-message/input-message.component';

interface Props {
  isOptional?: boolean;
  name: string;
  placeholder?: string;
  label: string;
  isHidden?: boolean;
  isLabelHidden?: boolean;
  type?: React.HTMLInputTypeAttribute;
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
  hint?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  disabled?: boolean;
  $borderNone?: boolean;
  showValidationError?: boolean;
  value?: string;
  errorMessage?: string;
  optionalText?: string;
  prefix?: {
    element: React.ReactNode;
    isClickable?: boolean;
  };
  suffix?: {
    element: React.ReactNode;
    isClickable?: boolean;
  };
  labelEndElement?: React.ReactNode;
  autoComplete?: string;
  showSkeleton?: boolean;
}

const FormField = (
  {
    name,
    placeholder,
    label,
    type = 'text',
    $borderNone,
    inputMode,
    isHidden,
    isLabelHidden,
    hint,
    maxLength,
    disabled = false,
    onChange,
    onFocus,
    onBlur,
    showValidationError = true,
    isOptional,
    value,
    errorMessage,
    optionalText = 'Optional',
    prefix,
    suffix,
    labelEndElement,
    autoComplete,
    showSkeleton,
  }: Props & MarginProps,
  ref: Ref<HTMLInputElement>
) => {
  const id = useId();
  const inputId = name + id;
  const isInvalid = !!errorMessage;

  return (
    <FieldContainer $isHidden={isHidden}>
      <InputContainer>
        <FieldLabel
          isHidden={isLabelHidden}
          isOptional={isOptional}
          optionalText={optionalText}
          labelEndElement={labelEndElement}
          hint={hint}
          inputId={inputId}>
          {label}
        </FieldLabel>
        {showSkeleton ? (
          <Skeleton
            width='100%'
            height='4rem'
            borderRadius={10}
          />
        ) : (
          <Input
            autoComplete={autoComplete}
            ref={ref}
            type={type}
            id={inputId}
            placeholder={placeholder}
            required={!isOptional}
            inputMode={inputMode}
            name={name}
            disabled={disabled}
            value={value}
            maxLength={maxLength}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            isInvalid={isInvalid}
            prefix={prefix}
            suffix={suffix}
          />
        )}
      </InputContainer>

      {showValidationError && isInvalid && (
        <InputMessage type='error'>{errorMessage}</InputMessage>
      )}
    </FieldContainer>
  );
};

export default forwardRef(FormField);
