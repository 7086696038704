import styled from 'styled-components';
import { TextLabel } from '../typography/typography.styles';
import { BaseMarginComponent } from '../base-margin/base-margin.component';

export const FieldContainer = styled(BaseMarginComponent('div'))`
  width: 100%;

  ${TextLabel} {
    position: relative;
    display: inline-block;
    margin-bottom: 6px;
  }
`;
